import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useStore } from "react-hookstore";
import PropTypes from "prop-types";
import { userProfileStore } from "../state-managements/stores/user-profile-store";

/**
 * Check user profile to determine if user is authorized to access to url
 * @param userProfile { UserProfile }
 * @param authorizedRoles
 * @returns {boolean}
 */
const isAuthorizedToAccess = (userProfile, authorizedRoles) => {
  if (!authorizedRoles || authorizedRoles.length === 0 || !userProfile) {
    return true;
  }

  return authorizedRoles.includes(userProfile.getRole());
};

/*
 * - component: component to render/page to load
 * - Authorized roles: list of strings that are used as conditions to check
 *   if a user clicking on the app is authorized to view the app/URL.
 *
 */
const AuthorizedProcessAppRoute = ({ component: Component, path, authorizedRoles }) => {
  const [userProfile] = useStore(userProfileStore);

  const isAuthorized = isAuthorizedToAccess(userProfile, authorizedRoles);

  return (
    <Route
      render={(matchProps) => {
        if (!isAuthorized) {
          return (
            <Redirect
              to={{
                pathname: "/error/authorized",
                state: { referrer: path }
              }}
            />
          );
        }

        return <Component {...matchProps} />;
      }}
    />
  );
};

AuthorizedProcessAppRoute.defaultProps = {
  authorizedRoles: null
};

AuthorizedProcessAppRoute.propTypes = {
  component: PropTypes.func.isRequired,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  authorizedRoles: PropTypes.arrayOf(PropTypes.string)
};

export default AuthorizedProcessAppRoute;
