import React from "react";
import PropTypes from "prop-types";
import Style from "./StatusTile.module.css";

const StatusTile = ({ title, subTitle, color, active, onClick }) => {
  return (
    <div
      className={Style.status_tile}
      onClick={onClick}
    >
      <div
        className={Style.status_title_color_indicator}
        style={{ backgroundColor: color }}
      />
      <div
        className={Style.status_tile_hover}
        style={active ? { borderColor: "#4A90FF" } : {}}
      />
      <div className={Style.status_tile_title}>{title}</div>
      <div className={Style.status_tile_subtitle}>{subTitle}</div>
    </div>
  );
};

StatusTile.defaultProps = {
  title: "",
  subTitle: "",
  color: "#E2E2EA",
  active: false,
  onClick: null
};

StatusTile.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
  color: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func
};

export default StatusTile;
