/* eslint-disable radix */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import GeneralizedModal from "../../../../../../components/generalized-modal/GeneralizedModal";
import Style from "./EditQueuePriorityModal.module.css";
import useQueuePriorityWorkflowControlContext, {
  QueuePriorityWorkflowControlContextProvider
} from "./contexts/queue-priority-workflow-control-context";
import AddNewLocation from "./features/add-new-location";
import GroupedLocationList from "./features/grouped-location-list";
import FilteredLocationList from "./features/filtered-location-list";
import AllLocationList from "./features/all-location-list";
import EditSortPreference from "./features/edit-sort-preference";

import { STEP_ID_MAP } from "./data/constants";

const EditQueuePriorityModalBase = ({ show, onClose, optionControl }) => {
  const { currentStep, modalHeader, modalFooterButtons } = useQueuePriorityWorkflowControlContext();

  const ModalContentComponent = useMemo(() => {
    switch (currentStep) {
      case STEP_ID_MAP.filteredLocationList:
        return FilteredLocationList;
      case STEP_ID_MAP.groupedLocationList:
        return GroupedLocationList;
      case STEP_ID_MAP.editSortPreference:
        return EditSortPreference;
      case STEP_ID_MAP.addNewLocation:
        return AddNewLocation;
      case STEP_ID_MAP.allLocationList:
        return AllLocationList;
      default:
        return () => {
          return null;
        };
    }
  }, [currentStep]);

  return (
    <GeneralizedModal
      classNameOverrides={{ dialog: Style.modal_dialog }}
      showModal={show}
      centered
      title={modalHeader}
      modalBodyComponent={
        <ModalContentComponent
          onModalClose={onClose}
          optionControl={optionControl}
        />
      }
      modalFooterButtons={modalFooterButtons}
    />
  );
};

EditQueuePriorityModalBase.defaultProps = {
  show: false,
  onClose: () => {},
  optionControl: {}
};

EditQueuePriorityModalBase.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  optionControl: PropTypes.objectOf(PropTypes.any)
};

export const EditQueuePriorityModal = (props) => {
  return (
    <QueuePriorityWorkflowControlContextProvider>
      <EditQueuePriorityModalBase {...props} />
    </QueuePriorityWorkflowControlContextProvider>
  );
};
