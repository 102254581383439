export const augmentItemFields = (item) => {
  const itemToPrint = {};

  // Existing logic when printing, need to check if this is still needed
  Object.entries(item).forEach(([key, value]) => {
    if (key.startsWith("task-")) {
      const newKey = key.replace("task-", "");
      itemToPrint[newKey] = value;
    } else if (key === "name") {
      itemToPrint.identifier = item.identifier || value;
      itemToPrint.order_number = value;
    } else if (key === "sensor_profile_vid") {
      itemToPrint.tracker_serial = value;
      itemToPrint.sensorProfileVid = value;
    } else if (key === "represents") {
      itemToPrint.status_flags = value.status_flag;
    } else {
      itemToPrint[key] = value;
    }
  });
  return itemToPrint;
};
