import React from "react";
import Style from "./QuickActionButtons.module.css";
import { TAB_OPTION_MAP } from "../../../../data/constants";
import useManagementDashboardStateContext from "../../../../contexts/management-dashboard-state-context";
import { ReactComponent as QueueIcon } from "../../../../../../assets/icons/queue.svg";
import xemelgoStyle from "../../../../../../styles/variable";
import useManagementDashboardConfigContext from "../../../../contexts/management-dashboard-config-context";

export const QuickActionButtons = () => {
  const { selectedTab, selectedLocationId } = useManagementDashboardStateContext();
  const { useWorkOrderTrackPageV2 } = useManagementDashboardConfigContext();

  const getURL = () => {
    switch (selectedTab) {
      case TAB_OPTION_MAP.jobStatusTab:
        return `/work-order?locationId=${selectedLocationId}&tab=orders`;
      default:
        return "";
    }
  };

  if (!useWorkOrderTrackPageV2) {
    return null;
  }

  return (
    <div className={`${Style.flex_row} ${Style.quick_action_button_group}`}>
      <a
        className={`${Style.flex_column} ${Style.quick_action_button}`}
        href={getURL()}
      >
        <QueueIcon
          width={40}
          height={40}
          color={xemelgoStyle.theme.APP_BLUE}
        />
        <p className={Style.quick_action_button_title}>Live Queue</p>
      </a>
    </div>
  );
};
