import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Div } from "../../../../../components/div";
import "./style.css";
import { EditLocationForm } from "../../edit-location-form";
import { EditLocationFormV2 } from "../../edit-location-form-v2/EditLocationFormV2";
import { LocationFloorPlan } from "../../../../location-floor-plan";
import { GpsPinHead } from "../../../../../components/interactable-pin/gps-pin-head";
import { XButton } from "../../../../../components/xemelgo-button";
import { DeleteLocationForm } from "../../delete-location-form";
import { useAppConfigProvider } from "../../../../../services/soft-cache-service";
import { getPartnerDisplayValue, getPartnerPropertyFromConfig } from "../../../../list-partners/ListPartnersUtil";

const APP_ID = "myFacility";

export const LocationDetail = ({ appId, featureId, location, onEdit, onDelete, containsLocationsInfo, modelId }) => {
  const configProvider = useAppConfigProvider(APP_ID);
  const partnerModelDisplayName = getPartnerPropertyFromConfig(configProvider, "displayName", "string", "Customer");
  const partnerDisplayProperty = getPartnerPropertyFromConfig(configProvider, "displayProperty", "string", "name");
  const { useV2 } = configProvider.config;
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [dimensionRatio, setDimensionRatio] = useState({ dx: 1, dy: 1 });
  const description = location.getDescription();
  const identifier = location.getIdentifier();
  const category = location.getCategory();
  const roles =
    location.roles &&
    location.roles
      .map((role) => {
        return role.name;
      })
      .join(", ");
  const { managedForPartner: partner } = location;
  const [childCategoryDisplayName, setChildCategoryDisplayName] = useState(null);
  const [childLocationCounts, setChildLocationsCount] = useState(null);

  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!containsLocationsInfo) {
      setChildCategoryDisplayName(null);
      setChildLocationsCount(0);
      return cancelCallback;
    }

    const { category, count } = containsLocationsInfo;
    const { groupDisplayName } = category;

    if (!cancelled) {
      setChildCategoryDisplayName(groupDisplayName);
      setChildLocationsCount(count);
    }

    return cancelCallback;
  }, [containsLocationsInfo, location]);

  const buildPinFn = useCallback(
    (id, name, color) => {
      return (
        <GpsPinHead
          color={color}
          scale={dimensionRatio.dx * 10}
        />
      );
    },
    [dimensionRatio]
  );

  const buildPinTooltip = useCallback((id, name) => {
    return <Div>{name}</Div>;
  }, []);

  const onDimensionRatioChanged = useCallback((ratio) => {
    setDimensionRatio(ratio);
  }, []);

  const onEditButtonClickCallback = useCallback(() => {
    if (useV2) {
      window.fcWidget.hide();
    }
    setShowEditModal(true);
  }, []);

  const onEditCancelCallback = useCallback(() => {
    if (useV2) {
      window.fcWidget.show();
    }
    setShowEditModal(false);
  }, []);

  const onEditSaveCallback = useCallback(() => {
    if (useV2) {
      window.fcWidget.show();
    }
    setShowEditModal(false);
    onEdit(location.id);
  }, [onEdit, location]);

  const onDeleteButtonClick = useCallback(() => {
    setShowDeleteModal(true);
  }, []);

  const onDeleteCancelCallback = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const onDeleteConfirmCallback = useCallback(() => {
    setShowDeleteModal(false);
    onDelete(location.id);
  }, [onDelete, location]);

  const renderEditForm = () => {
    if (useV2) {
      return (
        <EditLocationFormV2
          appId={appId}
          featureId={featureId}
          location={location}
          show={showEditModal}
          onFormClose={onEditCancelCallback}
          onSave={onEditSaveCallback}
          modelId={modelId}
        />
      );
    }
    return (
      <EditLocationForm
        appId={appId}
        featureId={featureId}
        location={location}
        show={showEditModal}
        onFormClose={onEditCancelCallback}
        onSave={onEditSaveCallback}
        modelId={modelId}
      />
    );
  };

  return (
    <Div className="location-details">
      <Div className="location-details-header">
        <Div className="location-details-header-title">{location.name}</Div>
        {showEditModal && renderEditForm()}
        {showDeleteModal && (
          <DeleteLocationForm
            location={location}
            onSubmit={onDeleteConfirmCallback}
            onCancel={onDeleteCancelCallback}
            show={showDeleteModal}
          />
        )}
        <Div className="location-details-header-operations">
          <XButton
            onClick={onEditButtonClickCallback}
            label="Edit"
            className="location-operation-edit-button"
          />
          <XButton
            onClick={onDeleteButtonClick}
            label="Delete"
            className="location-operation-delete-button"
          />
        </Div>
      </Div>
      <Div className="location-details-body">
        <LocationFloorPlan
          location={location}
          buildPinFn={buildPinFn}
          buildPinTooltipFn={buildPinTooltip}
          onDimensionRatioChanged={onDimensionRatioChanged}
          showName
          showTooltip={false}
          className="location-details-floor-plan-map"
        />
        <Div className="location-details-body-descriptions">
          <Div className="location-description-group">
            <Div className="location-description-topic">Description:</Div>
            <Div className="location-description-value">{description || "-"}</Div>
          </Div>
          <Div className="location-description-group">
            <Div className="location-description-topic">{`${category} #:`}</Div>
            <Div className="location-description-value">{identifier || "-"}</Div>
          </Div>
          {roles && (
            <Div className="location-description-group">
              <Div className="location-description-topic">{`${category} Role:`}</Div>
              <Div className="location-description-value">{roles || "-"}</Div>
            </Div>
          )}
          {childCategoryDisplayName && (
            <Div className="location-description-group">
              <Div className="location-description-topic">{`# of ${childCategoryDisplayName}:`}</Div>
              <Div className="location-description-value">{childLocationCounts || "-"}</Div>
            </Div>
          )}
          {partner && (
            <Div className="location-description-group">
              <Div className="location-description-topic">{`${category} ${partnerModelDisplayName}:`}</Div>
              <Div className="location-description-value">
                {getPartnerDisplayValue(partner, partnerDisplayProperty)}
              </Div>
            </Div>
          )}
        </Div>
      </Div>
    </Div>
  );
};

LocationDetail.defaultProps = {
  configuration: {
    operations: ["edit"]
  },
  onEdit: () => {},
  onDelete: () => {},
  containsLocationsInfo: null
};

LocationDetail.propTypes = {
  modelId: PropTypes.string.isRequired,
  location: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    getImagePath: PropTypes.func,
    getId: PropTypes.func,
    getDescription: PropTypes.func
  }).isRequired,
  configuration: PropTypes.shape({
    operations: PropTypes.arrayOf(PropTypes.string)
  }),
  appId: PropTypes.string.isRequired,
  featureId: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  containsLocationsInfo: PropTypes.shape({
    category: PropTypes.shape({
      name: PropTypes.string.isRequired,
      groupDisplayName: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired
    }),
    count: PropTypes.number
  })
};
