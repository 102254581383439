import { VALUE_TYPE_MAP } from "../../../components/paginated-list-table-with-types/data/constants";

export const DEFAULT_ISSUE_TABLE_HEADERS = [
  { id: "issueTypesString", label: "Issue" },
  { id: "locationsString", label: "Location" },
  { id: "comment", label: "Comments", type: VALUE_TYPE_MAP.comment },
  {
    id: "issue_start_time",
    label: "Time Reported",
    type: VALUE_TYPE_MAP.dateTime,
    timeFormat: "MMM D, h:mm A",
    defaultSort: true,
    defaultDirection: "desc"
  },
  {
    id: "issue_end_time",
    label: "Time Resolved",
    type: VALUE_TYPE_MAP.dateTime,
    timeFormat: "MMM D, h:mm A"
  }
];

export const ISSUE_TABLE_OPTIONS = {
  resolve: "resolve",
  updateComment: "updateComment"
};

export const DEFAULT_ISSUE_TABLE_OPTIONS = [
  {
    id: ISSUE_TABLE_OPTIONS.resolve,
    label: "Mark as Resolved"
  },
  {
    id: ISSUE_TABLE_OPTIONS.updateComment,
    label: "Add a Comment"
  }
];
