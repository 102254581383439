import React from "react";
import PropTypes from "prop-types";

import Style from "./ApprovalReportRow.module.css";

export const ApprovalReportRow = ({ headers, row }) => {
  const includedValues = headers.map((header) => {
    return header.id;
  });

  const { id, isAutoApproved } = row;

  const rowValues = includedValues.map((valueId) => {
    return row[valueId];
  });

  return (
    <div
      key={id}
      className={`${Style.flex_row} ${Style.table_item} ${Style.no_bottom_border}`}
    >
      {rowValues.map((rowValue, subIndex) => {
        return (
          <div
            key={`${rowValue}${subIndex}`}
            className={`${Style.flex_row} ${Style.table_column}`}
          >
            <p>{rowValue === 0 ? 0 : rowValue || "-"}</p>
          </div>
        );
      })}
      {isAutoApproved && <p className={`${Style.auto_approved_text}`}>Auto Approved</p>}
    </div>
  );
};

ApprovalReportRow.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  row: PropTypes.shape({
    id: PropTypes.string,
    isAutoApproved: PropTypes.bool
  }).isRequired
};
