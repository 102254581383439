import React, { useContext, useState, useEffect } from "react";
import mixpanel from "mixpanel-browser";
import { useStore } from "react-hookstore";
import { userProfileStore } from "state-managements/stores/user-profile-store";
import useAuthenticationContext from "../authentication-context";
import { APP_LOAD_EVENT } from "../../constants/mixpanel-constant/appLoad";
import configuration from "../../configuration.json";

const IS_MIXPANEL_DEBUG_MODE_ENABLED = false;

export const MixpanelContext = React.createContext();

export const useMixpanelContext = () => {
  return useContext(MixpanelContext);
};

export const MixpanelContextProvider = ({ children }) => {
  const [isMixpanelInitialized, setIsMixpanelInitialized] = useState(false);
  const [userProfile] = useStore(userProfileStore);
  const { isAuthenticated } = useAuthenticationContext();

  useEffect(() => {
    if (isAuthenticated && userProfile && !isMixpanelInitialized) {
      initializeMixPanel(userProfile);
    }
    return () => {
      resetMixPanel();
      setIsMixpanelInitialized(false);
    };
  }, [isAuthenticated]);

  const initializeMixPanel = async (userProfile) => {
    const { mix_panel_tracking_token, isMixpanelTelemetryEnabled } = configuration;
    if (!isMixpanelTelemetryEnabled) {
      return;
    }

    await mixpanel.init(mix_panel_tracking_token, {
      debug: IS_MIXPANEL_DEBUG_MODE_ENABLED,
      loaded: () => {
        setIsMixpanelInitialized(true);
      }
    });

    if (userProfile && userProfile?.profile?.userId) {
      const { profile } = userProfile;
      const { userId, tenantId, email, firstName, lastName, role } = profile;

      const userProperties = {
        tenant: tenantId,
        email,
        first_name: firstName,
        last_name: lastName,
        role,
        user_id: userId
      };

      mixpanel.register(userProperties);

      mixpanel.identify(userId);
      mixpanel.people.set_once({ $name: `${firstName} ${lastName}` });
      mixpanel.people.set_once({ $email: email });
      mixpanel.people.set_once({ Tenant: tenantId });
      mixpanel.people.set_once({ Role: role });
    }

    setIsMixpanelInitialized(true);
    mixpanel.track(APP_LOAD_EVENT);
  };

  const sendMixPanelEvent = (eventName, step = "none", additionalProperties = {}) => {
    const { isMixpanelTelemetryEnabled } = configuration;
    if (!isMixpanelTelemetryEnabled) {
      return;
    }

    mixpanel.track(eventName, { step, ...additionalProperties });
  };

  const resetMixPanel = () => {
    const { isMixpanelTelemetryEnabled } = configuration;
    if (!isMixpanelTelemetryEnabled) {
      return;
    }

    mixpanel.reset();
  };

  const { isMixpanelTelemetryEnabled } = configuration;

  return (
    <MixpanelContext.Provider
      value={{
        sendMixPanelEvent,
        initializeMixPanel,
        resetMixPanel
      }}
    >
      {!isMixpanelInitialized || isMixpanelInitialized ? children : null}
    </MixpanelContext.Provider>
  );
};
