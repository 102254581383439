import React, { useState } from "react";
import Style from "./Tooltip.module.css";

export const Tooltip = ({ children, text, tooltipContainerClassName, textBoxClassName, ...rest }) => {
  const [show, setShow] = useState(false);

  return (
    <div className={`${Style.popover_container}`}>
      <div
        className={`${Style.children_container}`}
        onMouseOver={() => {
          return setShow(true);
        }}
        onMouseOut={() => {
          return setShow(false);
        }}
        {...rest}
      >
        {children}
      </div>
      <div className={show ? `${Style.tooltip_box_visible} ${tooltipContainerClassName}` : `${Style.tooltip_box}`}>
        <div className={`${Style.tooltip_text_box} ${textBoxClassName}`}>{text}</div>
      </div>
    </div>
  );
};
