import React, { useMemo, useEffect } from "react";
import { useReportsPageContext } from "../../context/reportsPageContext";
import Style from "./ReportClassFilter.module.css";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";

const APP_ID = "reports";

const ReportClassFilter = ({ defaultReportClass }) => {
  const { selectedClassFilter, setSelectedClassFilter } = useReportsPageContext();
  const configProvider = useAppConfigProvider(APP_ID);
  const reportGroups = configProvider.getValue("reportGroups", "object") || {};

  useEffect(() => {
    setSelectedClassFilter(defaultReportClass || Object.keys(reportGroups)[0]);
  }, [reportGroups]);

  const classOptions = useMemo(() => {
    return Object.keys(reportGroups);
  }, [reportGroups]);

  return (
    <ul className={`${Style.report_class_filter_container}`}>
      {classOptions.map((eachOption) => {
        return (
          <li
            key={eachOption}
            className={`${Style.flex_row} ${eachOption === selectedClassFilter && Style.active}`}
          >
            <button
              className={`${Style.report_class_button} ${eachOption === selectedClassFilter && Style.active}`}
              type="button"
              onClick={() => {
                setSelectedClassFilter(eachOption);
              }}
            >
              {eachOption}
            </button>
          </li>
        );
      })}
    </ul>
  );
};

export default ReportClassFilter;
