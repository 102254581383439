import React, { useEffect, useRef, useState } from "react";
import { cloneDeep } from "lodash";
import MainPage from "../main-page";
import PicklistVerificationSetup from "./components/picklist-verification-setup";
import useKioskStateContext from "../../contexts/kiosk-state-context";
import UnexpectedItemsModal from "./components/unexpected-items-modal";
import MissingScanModal from "./components/missing-scan-modal";
import SubmissionModal from "./features/submission-modal";
import useKioskConfigContext from "../../contexts/kiosk-config-context";
import { PICKLIST_STATUS_MAP } from "./data/constants";
import { createItemTypeToReportMap } from "./utils/create-item-type-reports";
import { fetchReaderOptions } from "./utils/fetch-reader-options";
import useKioskSearchParams from "../../hooks/use-kiosk-search-params";

export const PicklistVerification = () => {
  const { actionId } = useKioskSearchParams();
  const processedItemRef = useRef({});
  const itemTypeToReportRef = useRef({});
  const [transferOrders, setTransferOrders] = useState([]);
  const [initialItemTypeToReport, setInitialItemTypeToReport] = useState({});
  const [unexpectedItems, setUnexpectedItems] = useState([]);
  const [showMissingScanModal, setShowMissingScanModal] = useState(false);
  const [showSubmissionModal, setShowSubmissionModal] = useState(false);
  const [selectedStage, setSelectedStage] = useState();

  const {
    itemByTag,
    setItemByTag,
    itemTypeReports,
    setItemTypeReports,
    isSubmitting,
    setIsSubmitting,
    setReaderOptions,
    panelValues,
    setPanelValues,
    resetState,
    setLoading
  } = useKioskStateContext();
  const { stageOptions, unexpectedScanTableHeaders, useMissingItemModal, additionalQueryAttributes, actionsMap } =
    useKioskConfigContext();

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  useEffect(() => {
    if (!isSubmitting) {
      return;
    }

    const hasMissingScans = itemTypeReports.some((report) => {
      return report.quantityScanned < report.quantityTotal;
    });

    if (hasMissingScans) {
      setShowMissingScanModal(true);
    } else {
      setShowSubmissionModal(true);
    }
  }, [isSubmitting, itemTypeReports]);

  const initializePicklist = async (selectedTransferOrders, newStage) => {
    setLoading(true);
    setSelectedStage(newStage);
    const fetchReadersPromise = fetchReaderOptions(newStage.value);
    const createReportsPromise = createItemTypeToReportMap(
      selectedTransferOrders,
      additionalQueryAttributes,
      newStage.value
    );

    const [readerOptions, { itemTypeToReport: newItemTypeToReport, transferOrders: newTransferOrders }] =
      await Promise.all([fetchReadersPromise, createReportsPromise]);

    const transferOrdersString = selectedTransferOrders
      .map((transferOrder) => {
        return transferOrder.identifier;
      })
      .sort()
      .join(", ");

    setPanelValues({
      ...panelValues,
      stage: newStage.label,
      transferOrdersString
    });

    itemTypeToReportRef.current = cloneDeep(newItemTypeToReport);
    setReaderOptions(readerOptions);
    setTransferOrders(newTransferOrders);
    setInitialItemTypeToReport(cloneDeep(newItemTypeToReport));
    updateItemTypeReports();
    setLoading(false);
  };

  const updateItemTypeReports = () => {
    const newReports = Object.values(itemTypeToReportRef.current)
      .filter((report) => {
        return report.quantityTotal > 0;
      })
      .sort((a, b) => {
        if (a.status.sortIndex === b.status.sortIndex) {
          return a.identifier.localeCompare(b.identifier);
        }

        return a.status.sortIndex - b.status.sortIndex;
      });

    setItemTypeReports(newReports);
  };

  const handleScannedItems = (newItemByTag) => {
    const newUnexpectedItems = [];

    Object.values(newItemByTag).forEach((item) => {
      const { vid, type } = item;

      if (processedItemRef.current[vid]) {
        return;
      }

      const { id: itemTypeId } = type;
      const report = itemTypeToReportRef.current[itemTypeId];

      if (
        !report ||
        (!report.epcToItemMap[vid]?.isAssociatedWithOrder &&
          Object.keys(report.epcToItemMap).length >= report.quantityTotal)
      ) {
        const typeFields = Object.entries(type).reduce((acc, [key, value]) => {
          return {
            ...acc,
            [`itemType_${key}`]: value
          };
        }, {});

        newUnexpectedItems.push({
          ...item,
          ...typeFields
        });
        return;
      }

      processedItemRef.current[vid] = true;

      report.epcToItemMap[vid] = {
        id: item.itemId,
        ...report.epcToItemMap[vid],
        ...item,
        scanned: true
      };
      report.scannedEpcSet.add(vid);
      report.quantityScanned = report.scannedEpcSet.size;
      report.status = getReportStatus(report.quantityScanned, report.quantityTotal);
    });

    setUnexpectedItems([...unexpectedItems, ...newUnexpectedItems]);
    updateItemTypeReports();
  };

  const handleClearItem = (itemTypeId, epc) => {
    const report = itemTypeToReportRef.current[itemTypeId];

    if (!report.scannedEpcSet.has(epc)) {
      return;
    }

    processedItemRef.current[epc] = false;

    report.scannedEpcSet.delete(epc);
    report.quantityScanned = report.scannedEpcSet.size;
    report.status = getReportStatus(report.quantityScanned, report.quantityTotal);

    const item = report.epcToItemMap[epc];

    if (item?.isAssociatedWithOrder) {
      item.scanned = false;
    } else {
      delete report.epcToItemMap[epc];
    }

    const updatedItemByTag = { ...itemByTag };
    delete updatedItemByTag[epc];

    setItemByTag(updatedItemByTag);
    updateItemTypeReports();
  };

  const handleClearRow = (itemTypeId) => {
    const newItemByTag = { ...itemByTag };

    const epcs = Object.keys(itemTypeToReportRef.current[itemTypeId].epcToItemMap);
    epcs.forEach((epc) => {
      processedItemRef.current[epc] = false;
      delete newItemByTag[epc];
    });
    itemTypeToReportRef.current[itemTypeId] = cloneDeep(initialItemTypeToReport[itemTypeId]);

    setItemByTag(newItemByTag);
    updateItemTypeReports();
  };

  const getReportStatus = (scannedCount, totalCount) => {
    if (scannedCount === 0) {
      return PICKLIST_STATUS_MAP.notScanned;
    }
    if (scannedCount < totalCount) {
      return PICKLIST_STATUS_MAP.partial;
    }
    if (scannedCount === totalCount) {
      return PICKLIST_STATUS_MAP.complete;
    }

    return PICKLIST_STATUS_MAP.extraScanned;
  };

  return (
    <>
      {selectedStage ? (
        <MainPage
          handleScannedItems={handleScannedItems}
          onClearReport={() => {
            itemTypeToReportRef.current = cloneDeep(initialItemTypeToReport);
            processedItemRef.current = {};
            updateItemTypeReports();
          }}
          onClearReportRow={handleClearRow}
          onClearItem={handleClearItem}
        />
      ) : (
        <PicklistVerificationSetup
          stageOptions={stageOptions}
          onConfirm={initializePicklist}
        />
      )}
      {unexpectedItems.length > 0 && (
        <UnexpectedItemsModal
          items={unexpectedItems}
          headers={unexpectedScanTableHeaders}
          onConfirm={() => {
            const newItemByTag = { ...itemByTag };

            unexpectedItems.forEach((item) => {
              delete newItemByTag[item.vid];
            });

            setItemByTag(newItemByTag);
            setUnexpectedItems([]);
          }}
        />
      )}
      {useMissingItemModal && showMissingScanModal && (
        <MissingScanModal
          title={actionsMap[actionId]?.label}
          onCancel={() => {
            setShowMissingScanModal(false);
            setIsSubmitting(false);
          }}
          onConfirm={() => {
            setShowMissingScanModal(false);
            setShowSubmissionModal(true);
          }}
        />
      )}
      {showSubmissionModal && (
        <SubmissionModal
          transferOrders={transferOrders}
          itemTypeToReport={itemTypeToReportRef.current}
          onClose={() => {
            setIsSubmitting(false);
            setShowSubmissionModal(false);
          }}
        />
      )}
    </>
  );
};
