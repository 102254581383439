import { VALUE_TYPE_MAP } from "../../../../data/constants";
import { getFormattedDate } from "../../../../../../common/utils";
import getStatusRenderComponent from "../get-status-render-component";

const NO_END_DATE = 32503680000000;

export const getValueByType = (value, type, timeFormat, csvMode) => {
  switch (type) {
    case VALUE_TYPE_MAP.boolean:
      return typeof value !== "undefined" ? (value ? "Yes" : "No") : "-";
    case VALUE_TYPE_MAP.dateTime:
      return value && value !== NO_END_DATE ? getFormattedDate(value, timeFormat || "hh:mm A MMM Do") : "-";
    case VALUE_TYPE_MAP.date:
      return value && value !== NO_END_DATE ? getFormattedDate(value, timeFormat || "MMM Do") : "-";
    case VALUE_TYPE_MAP.status:
      return csvMode
        ? value.reduce((accumulator, eachStatus) => {
            return `${accumulator && `${accumulator}, `}${eachStatus.title}`;
          }, "")
        : getStatusRenderComponent(value);
    default:
      return value || "-";
  }
};
