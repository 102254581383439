export const isAllExpandedCheck = (currentWorkCenters) => {
  return Object.values(currentWorkCenters).reduce((acc, curr) => {
    return acc && curr.isExpanded;
  }, true);
};

export const getWorkCentersToDisplay = (currentWorkCenters, filteringIds) => {
  const filteredWorkCenters = Object.values(currentWorkCenters).reduce((acc, curr) => {
    const { id } = curr;
    filteringIds.forEach((workCenter) => {
      if (workCenter === id) {
        acc.push(curr);
      }
    });
    return acc;
  }, []);
  if (!filteredWorkCenters.length) {
    return currentWorkCenters;
  }
  return filteredWorkCenters;
};
