import AmazonS3URI from "amazon-s3-uri";
import { getItemFromS3 } from "../../../../common/Utilities";
import { NO_REPORT_URL_KEYWORD } from "../../data/constant";
import { useDisplayBannerContext } from "../../../../context/DisplayBannerContext/DisplayBannerContext";

export const useGetReportCsv = () => {
  const { setShowBanner, setBannerHasError, setBannerTitle } = useDisplayBannerContext();

  const getReportCsv = async (url, shouldDownload, onDataReceived) => {
    try {
      if (url) {
        if (url.includes(NO_REPORT_URL_KEYWORD)) {
          setBannerHasError(true);
          setBannerTitle("This reports has no records. Please select a different report");
          setShowBanner(true);
        } else {
          const { bucket, key } = AmazonS3URI(url);
          const data = await getItemFromS3(bucket, key, shouldDownload);
          if (onDataReceived) {
            onDataReceived(data);
          }
        }
      }
    } catch {
      setBannerHasError(true);
      setBannerTitle("Cannot fetch the report information. Please try again later");
      setShowBanner(true);
    }
  };

  return {
    getReportCsv
  };
};
