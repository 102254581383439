import React, { useState } from "react";
import PropTypes from "prop-types";
import Style from "./ResolveIssueModal.module.css";
import Modal from "../../../../components/modal";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import AutoSizeTextArea from "../../../../components/AutoSizeTextArea/AutoSizeTextArea";
import { STATUS_OPTIONS } from "../../../../components/status-popup-component";
import useMixpanelContext from "../../../../context/mixpanel-context";
import { ISSUE_REPORTING, ISSUE_REPORTING_STEPS } from "../../../../constants/mixpanel-constant/issueReporting";

export const ResolveIssueModal = ({ title, issue, commentEnabled, onClose, onConfirm }) => {
  const { sendMixPanelEvent } = useMixpanelContext();
  const issueClient = useXemelgoClient().getIssueClient();
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const resolveIssue = async () => {
    setIsLoading(true);
    try {
      await issueClient.resolveIssue(issue.id, comment);
      sendMixPanelEvent(ISSUE_REPORTING, ISSUE_REPORTING_STEPS.REPORT_ISSUE_SUCCEEDED);
      setIsLoading(false);
      onConfirm(STATUS_OPTIONS.SUCCESS, "The issue has been resolved.");
    } catch (error) {
      sendMixPanelEvent(ISSUE_REPORTING, ISSUE_REPORTING_STEPS.ISSUE_RESOLVE_FAILED, {
        error_message: typeof err === "string" ? error : error.message
      });

      onConfirm(STATUS_OPTIONS.ERROR, "Failed to resolve issue");
    }
  };

  return (
    <Modal
      title={title}
      className={Style.modal}
      onCancel={onClose}
      onConfirm={resolveIssue}
      confirmDisabled={isLoading}
    >
      <p>
        You are about to resolve the issue
        {issue.issueTypesString && <span className={Style.bold_text}>{` ${issue.issueTypesString}`}</span>}
        {issue.locationsString ? (
          <>
            {" at "}
            <span className={Style.bold_text}>{issue.locationsString}</span>
            {". "}
          </>
        ) : (
          ". "
        )}
        {`${commentEnabled ? "You may add a comment below and press" : "Press "} "Confirm" to proceed.`}
      </p>
      {commentEnabled && (
        <AutoSizeTextArea
          autoFocus
          textareaClassName={Style.textarea_container}
          maxRows={4}
          minRows={4}
          newLineAllowed
          value={comment}
          disabled={isLoading}
          onChangeText={(newText) => {
            setComment(newText);
          }}
        />
      )}
    </Modal>
  );
};

ResolveIssueModal.defaultProps = {
  title: "Mark as Resolved",
  commentEnabled: false
};

ResolveIssueModal.propTypes = {
  title: PropTypes.string,
  issue: PropTypes.shape({
    id: PropTypes.string.isRequired,
    issueTypesString: PropTypes.string,
    locationsString: PropTypes.string
  }).isRequired,
  commentEnabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};
