import _ from "lodash";
import generateLabelStatusCommand from "../generate-status-command";
import generateTagBorderCommand from "../generate-tag-border-command";
import generateTagLabelsCommand from "../generate-tag-labels-command";

export const getZPLCommandWithDefaultTemplate = (templatePropertiesMap, tagConfig) => {
  const newTemplatePropertiesMap = _.cloneDeep(templatePropertiesMap);
  const { sensorProfileVid } = newTemplatePropertiesMap || {};
  if (!sensorProfileVid) {
    return "";
  }

  // extract information w.r.t. config
  const { dimensions = { x: 4, y: 6 }, printDensity = 300, properties = [] } = tagConfig || {};

  const woIdentifierSplit = newTemplatePropertiesMap.identifier.split("-");
  const woIndex = woIdentifierSplit[woIdentifierSplit.length - 1];

  if (
    properties.find((property) => {
      return property.id === "rowCount";
    })
  ) {
    newTemplatePropertiesMap.rowCount = `${parseInt(woIndex)} of ${newTemplatePropertiesMap.total_skid_quantity_ts}`;
  }

  const itemList = properties.map((property) => {
    return {
      label: property.label,
      index: property.index,
      value: newTemplatePropertiesMap[property.id] || ""
    };
  });

  const workOrderStatus =
    newTemplatePropertiesMap.status_flags && newTemplatePropertiesMap.status_flags[0]
      ? newTemplatePropertiesMap.status_flags[0]
      : "";

  // generate command
  const { x, y } = dimensions;
  const tagSizeInDots = {
    x: x * printDensity,
    y: y * printDensity
  };

  return `^XA
      ^RS8
      ${generateTagBorderCommand(tagSizeInDots)}
      ${generateLabelStatusCommand(workOrderStatus, tagSizeInDots)}
      ${generateTagLabelsCommand(itemList, tagSizeInDots, workOrderStatus)}
      ^RFW,H
      ^FD${sensorProfileVid}^FS
      ^XZ`;
};
