import React, { useMemo, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";
import { Checkbox } from "../../../../components/checkbox-component/Checkbox";
import AutoSizeTextArea from "../../../../components/AutoSizeTextArea/AutoSizeTextArea";
import { DateTimePicker } from "../../../../components/DateTimePicker/DateTimePicker";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import SearchDropdown from "../../../../components/SearchDropdown/SearchDropdown";
import Style from "./PartVelocityReportForm.module.css";

const NUM_OF_RUN = "NUM_OF_RUN";
const START_DATE = "START_DATE";

// eslint-disable-next-line no-unused-vars
const PartVelocityReportForm = React.forwardRef(({ initialValues }, ref) => {
  const [selectedLocation, setSelectedLocation] = useState({});
  const [isLocationLoading, setIsLocationLoading] = useState(true);
  const [locationError, setLocationError] = useState(false);

  const [selectedOperation, setSelectedOperation] = useState({});
  const [isOperationLoading, setIsOperationLoading] = useState(false);
  const [operationError, setOperationError] = useState(false);

  const [selectedPart, setSelectedPart] = useState({});
  const [isPartLoading, setIsPartLoading] = useState(false);
  const [partError, setPartError] = useState(false);

  const [runType, setRunType] = useState(START_DATE);

  const [numOfRun, setNumOfRun] = useState("");
  const [numOfRunError, setNumOfRunError] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [startDateError, setStartDateError] = useState(false);

  const [endDate, setEndDate] = useState(null);
  const [endDateError, setEndDateError] = useState(false);

  const [locationTree, setLocationTree] = useState({});
  const [locationOperations, setLocationOperations] = useState({});
  const [partList, setPartList] = useState([]);
  const xemelgoClient = useXemelgoClient();

  const getLocationTree = async () => {
    const locationClient = xemelgoClient.getLocationClient();
    const newLocationTree = await locationClient.getLocationTree(["identifier"]);
    setLocationTree(newLocationTree);
    setIsLocationLoading(false);
  };

  const getParts = async () => {
    const itemTypeClient = xemelgoClient.getItemTypeClient();
    setIsPartLoading(true);
    const newPartList = await itemTypeClient.listItemTypes(["id", "identifier"]);
    setPartList(newPartList);
    setIsPartLoading(false);
  };

  useEffect(() => {
    getLocationTree();
    getParts();
  }, []);

  useEffect(() => {
    const {
      location,
      operation,
      part,
      numOfRuns: newNumOfRun,
      startDate: newStartDate,
      endDate: newEndDate
    } = initialValues || {};
    setSelectedLocation(location || {});
    setSelectedOperation(operation || {});
    setSelectedPart(part || {});
    if (!newNumOfRun) {
      setRunType(START_DATE);
      setStartDate(newStartDate || null);
      setEndDate(newEndDate || null);
    } else {
      setRunType(NUM_OF_RUN);
      setNumOfRun(newNumOfRun);
    }
  }, [initialValues]);

  const getOperations = async () => {
    setIsOperationLoading(true);
    const locationClient = xemelgoClient.getLocationClient();
    const newLocationOperations = await locationClient.getLocationOperations(selectedLocation.id);
    setLocationOperations(newLocationOperations);
    setIsOperationLoading(false);
  };

  useEffect(() => {
    if (selectedLocation.id) {
      getOperations();
    }
  }, [selectedLocation]);

  const locationOptions = useMemo(() => {
    return [
      { id: "all-locations", label: "All" },
      ...Object.values(locationTree)
        .filter((eachLocation) => {
          return eachLocation.category === "Department";
        })
        .map((eachLocation) => {
          return { id: eachLocation.id, label: eachLocation.identifier };
        })
    ];
  }, [locationTree]);

  const operationOptions = useMemo(() => {
    return [
      { id: "all-operations", label: "All" },
      ...(locationOperations.operations || []).map((eachOperation) => {
        return { id: eachOperation.name, label: eachOperation.name };
      })
    ];
  }, [locationOperations]);

  const partOptions = useMemo(() => {
    const partMap = {};
    partList.forEach(({ identifier, id }) => {
      const keySplit = identifier.split("-");

      if (keySplit.length > 1) {
        keySplit.pop();
      }

      const commonKey = keySplit.join("-");

      partMap[commonKey] = { id, identifier: commonKey };
    });

    return [
      { id: "All", label: "All" },
      ...Object.values(partMap).map(({ id, identifier }) => {
        return { id, label: identifier };
      })
    ];
  }, [partList]);

  const validateForm = () => {
    let isValid = true;
    if (!selectedLocation.id) {
      setLocationError(true);
      isValid = false;
    }
    if (!selectedOperation.id) {
      setOperationError(true);
      isValid = false;
    }
    if (!selectedPart.id) {
      setPartError(true);
      isValid = false;
    }
    if (runType === NUM_OF_RUN && !numOfRun) {
      setNumOfRunError(true);
      isValid = false;
    }
    if (runType === START_DATE) {
      if (!startDate) {
        setStartDateError(true);
        isValid = false;
      }
      if (!endDate) {
        setEndDateError(true);
        isValid = false;
      }
      if (endDate < startDate) {
        setStartDateError(true);
        setEndDateError(true);
        isValid = false;
      }
    }
    return isValid;
  };

  const buildRefFunctions = () => {
    return {
      buildPayload: () => {
        const isValid = validateForm();
        if (!isValid) {
          return ["", { error: true }];
        }
        const retPayload = {
          locationId: selectedLocation.id,
          locationName: selectedLocation.label,
          operationName: selectedOperation.label,
          itemTypeId: selectedPart.id,
          itemTypeIdentifier: selectedPart.label
        };
        if (!retPayload.operationName || retPayload.operationName === "All") {
          delete retPayload.operationName;
        }
        if (!retPayload.locationName || retPayload.locationName === "All") {
          delete retPayload.locationName;
          delete retPayload.locationId;
        }

        if (runType === NUM_OF_RUN) {
          retPayload.numberOfRuns = +numOfRun;
        } else if (runType === START_DATE) {
          retPayload.startDateTime = startDate;
          retPayload.endDateTime = endDate;
        }

        return [`${selectedPart.label} - ${selectedLocation.label} - ${selectedOperation.label}`, retPayload];
      }
    };
  };

  if (ref) {
    ref.current = buildRefFunctions();
  }

  return (
    <>
      <div className={`${Style.grid} ${Style.input_group_list}`}>
        <div className={Style.flex_column}>
          <div className={Style.flex_row}>
            <p className={`${Style.attribute_label}`}>Department</p>
            <p className={`${Style.attribute_label} ${Style.label_asterisk}`}>*</p>
          </div>
          <div className={Style.input_container}>
            {isLocationLoading ? (
              <Skeleton height={40} />
            ) : (
              <SearchDropdown
                color="#4A90FF"
                error={locationError}
                placeholder="Select a department"
                options={locationOptions}
                showIcon
                withoutOptionFilter
                selectedItem={selectedLocation}
                onItemSelected={(item) => {
                  setLocationError(false);
                  setSelectedLocation(item);
                  setSelectedOperation({});
                }}
              />
            )}
          </div>
        </div>
        {selectedLocation.id ? (
          <div className={Style.flex_column}>
            <div className={Style.flex_row}>
              <p className={`${Style.attribute_label}`}>Operation</p>
              <p className={`${Style.attribute_label} ${Style.label_asterisk}`}>*</p>
            </div>
            <div className={Style.input_container}>
              {isOperationLoading ? (
                <Skeleton height={40} />
              ) : (
                <SearchDropdown
                  color="#4A90FF"
                  error={operationError}
                  placeholder="Select an operation"
                  showIcon
                  withoutOptionFilter
                  options={operationOptions}
                  selectedItem={selectedOperation}
                  onItemSelected={(item) => {
                    setOperationError(false);
                    setSelectedOperation(item);
                  }}
                />
              )}
            </div>
          </div>
        ) : (
          <div />
        )}
        <div className={Style.flex_column}>
          <div className={Style.flex_row}>
            <p className={`${Style.attribute_label}`}>Part</p>
            <p className={`${Style.attribute_label} ${Style.label_asterisk}`}>*</p>
          </div>
          <div className={Style.input_container}>
            {isPartLoading ? (
              <Skeleton height={40} />
            ) : (
              <SearchDropdown
                color="#4A90FF"
                error={partError}
                options={partOptions}
                placeholder="Select a part"
                showIcon
                selectedItem={selectedPart}
                onItemSelected={(item) => {
                  setPartError(false);
                  setSelectedPart(item);
                }}
              />
            )}
          </div>
        </div>
        <div />
      </div>
      <div className={`${Style.flex_column} ${Style.run_type_container}`}>
        <div className={Style.flex_row}>
          <p className={`${Style.attribute_label}`}>Choose how you would like to run the report</p>
          <p className={`${Style.attribute_label} ${Style.label_asterisk}`}>*</p>
        </div>
        <div className={`${Style.flex_row} ${Style.checkbox_list}`}>
          {/* the below section is commented out because the backend is currently not supporting number of runs */}
          {/* <div className={`${Style.flex_row} ${Style.checkbox}`}>
            <Checkbox
              color="#4A90FF"
              isChecked={runType === NUM_OF_RUN}
              onClick={() => {
                setRunType(NUM_OF_RUN);
                setStartDateError(false);
                setEndDateError(false);
                setStartDate(null);
                setEndDate(null);
              }}
            />
            <p>Number of Runs</p>
          </div> */}
          <div className={`${Style.flex_row} ${Style.checkbox}`}>
            <Checkbox
              color="#4A90FF"
              rounded
              isChecked={runType === START_DATE}
              onClick={() => {
                setRunType(START_DATE);
                setNumOfRunError(false);
                setNumOfRun("");
              }}
            />
            <p>Start Date & Time Range</p>
          </div>
        </div>
        {runType === NUM_OF_RUN && (
          <div className={Style.input_container}>
            <AutoSizeTextArea
              error={numOfRunError}
              value={numOfRun}
              numberOnly
              placeholder="Fill in the number of runs"
              onChangeText={(newText) => {
                setNumOfRunError(false);
                setNumOfRun(newText);
              }}
            />
          </div>
        )}
        {runType === START_DATE && (
          <div className={`${Style.flex_row} ${Style.date_picker_list}`}>
            <div className={`${Style.flex_row} ${Style.date_picker}`}>
              <p>From:</p>
              <DateTimePicker
                color="#4A90FF"
                error={startDateError}
                placeholder="Select date & time"
                value={startDate}
                onTimeChange={(timestamp) => {
                  setStartDateError(false);
                  setStartDate(timestamp);
                }}
              />
            </div>
            <div className={`${Style.flex_row} ${Style.date_picker}`}>
              <p>To:</p>
              <DateTimePicker
                color="#4A90FF"
                error={endDateError}
                placeholder="Select date & time"
                value={endDate}
                onTimeChange={(timestamp) => {
                  setEndDateError(false);
                  setEndDate(timestamp);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
});

PartVelocityReportForm.defaultProps = {
  initialValues: {}
};

PartVelocityReportForm.propTypes = {
  initialValues: PropTypes.shape({
    location: PropTypes.shape({ id: PropTypes.string.isRequired, label: PropTypes.string.isRequired }),
    operation: PropTypes.shape({ id: PropTypes.string.isRequired, label: PropTypes.string.isRequired }),
    part: PropTypes.shape({ id: PropTypes.string.isRequired, label: PropTypes.string.isRequired }),
    numOfRuns: PropTypes.string,
    startDate: PropTypes.number,
    endDate: PropTypes.number
  })
};

export default PartVelocityReportForm;
