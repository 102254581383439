const SUBSCRIBE_STAGE = "warning";

export const getIdsToUnsubscribe = (conditions) => {
  const ids = [];
  conditions.forEach((condition) => {
    if (condition.subscribed && condition.locationId) {
      const subscribedId = condition.conditionId.find((id) => id.type == `${SUBSCRIBE_STAGE}Condition`);
      ids.push(subscribedId.ruleConditionId);
    }
  });

  return ids;
};

const useSubscriptionConditionParser = () => {
  const parse = (ruleConditionId, subscribing, ruleConditions) => {
    const conditions = ruleConditions.find((rule) => {
      return rule.id.includes(ruleConditionId[0]);
    });
    const allLocationCondition = ruleConditions.find((rule) => !rule.locationId);
    const id = conditions.conditionId.find((conditionId) => {
      return conditionId.type === `${SUBSCRIBE_STAGE}Condition` || conditionId.type === "allLocationCondition";
    }).ruleConditionId;
    const unsubscribeIds = [];
    const subscribeIds = [];
    if (!subscribing) {
      unsubscribeIds.push(id);
    } else {
      if (!conditions.locationId) {
        const previouslySubscribedIds = getIdsToUnsubscribe(ruleConditions);
        unsubscribeIds.push(...previouslySubscribedIds);
      } else if (allLocationCondition.subscribed) {
        unsubscribeIds.push(allLocationCondition.id[0]);
      }
      subscribeIds.push(id);
    }

    return {
      subscribeIds,
      unsubscribeIds
    };
  };
  return {
    parse
  };
};

export default useSubscriptionConditionParser;
