import React, { Fragment, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ModalForm } from "../../../../components/modal-form";
import { AddResourceFormFooter } from "./add-resource-form-footer";
import { AddDetectorFormBody } from "./add-detector-form-body";
import { AddLocationFormHeader } from "./add-location-form-header";
import "./style.css";

import LoadingCircle from "../../../../components/loading/LoadingCircle";
import { FeatureConfigurationProvider } from "../../../../domains/feature-configuration-provider";

const FeatureId = "addResource";
export const AddResourceForm = ({
  configuration,
  providedArgument,
  show,
  modelId,
  onCancel,
  onSubmit,
  creationError,
  resources
}) => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resourceName, setResourceName] = useState(modelId);
  const [formSectionNumber, setFormSectionNumber] = useState(1);
  const [submitTicket, setSubmitTicket] = useState(null);

  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!configuration || !providedArgument || !modelId) {
      return cancelCallback;
    }

    const configProvider = FeatureConfigurationProvider.parse(FeatureId, configuration);
    const propertyOrders = configProvider.getValue("propertyOrders", "array", []);
    const modelConfigProvider = configProvider.getModel(modelId);
    const modelDisplayName = modelConfigProvider.getValue("displayName", "string", modelId);
    const propertyMap = modelConfigProvider.getPropertyMap();

    const props = propertyOrders
      .filter((propertyId) => {
        return propertyMap[propertyId];
      })
      .map((propertyId) => {
        const property = propertyMap[propertyId];
        const {
          displayName,
          __addable,
          optional,
          optionallyDependsOn,
          options,
          optionsProvided,
          name,
          unique,
          defaultValue,
          autoPopulate
        } = property;

        let input = options;
        if (optionsProvided) {
          input = providedArgument[propertyId];
        }

        return {
          displayName,
          optional,
          optionallyDependsOn,
          input,
          editable: __addable,
          name: name || propertyId,
          unique,
          defaultValue,
          autoPopulate
        };
      });

    if (!cancelled) {
      setProperties(props);
      setLoading(false);
      setResourceName(modelDisplayName);
    }

    return cancelCallback;
  }, [configuration, providedArgument, modelId]);

  const onUpdateSectionNumber = useCallback((delta) => {
    setFormSectionNumber((current) => {
      return current + delta;
    });
  }, []);

  const onSubmitButtonClicked = useCallback(() => {
    setSubmitTicket(Date.now().toString());
  }, []);

  const onValidationPassedCallback = useCallback(
    (formPayloads) => {
      onSubmit(formPayloads);
      setLoading(true);
    },
    [onSubmit]
  );

  return (
    <>
      {loading && <LoadingCircle />}
      <ModalForm
        show={show}
        title={
          <AddLocationFormHeader
            label={`Add ${resourceName}`}
            onCancel={onCancel}
          />
        }
        footer={
          /* eslint-disable react/jsx-wrap-multilines */
          <AddResourceFormFooter
            addAnotherOnClick={() => {
              return onUpdateSectionNumber(1);
            }}
            removeLastOnClick={() => {
              return onUpdateSectionNumber(-1);
            }}
            showRemoveLastOperation={formSectionNumber > 1}
            onCancel={onCancel}
            onSubmit={onSubmitButtonClicked}
          />
        }
        body={
          /* eslint-disable react/jsx-wrap-multilines */
          !properties.length > 0 ? (
            <LoadingCircle />
          ) : (
            <AddDetectorFormBody
              modelId={modelId}
              properties={properties}
              submitTicket={submitTicket}
              numberOfSections={formSectionNumber}
              onValidationPassed={onValidationPassedCallback}
              creationError={creationError}
              resources={resources}
            />
          )
        }
        className="add-form-modal"
      />
    </>
  );
};

AddResourceForm.defaultProps = {
  show: true,
  onCancel: () => {},
  onSubmit: () => {},
  configuration: null,
  providedArgument: {}
};

AddResourceForm.propTypes = {
  modelId: PropTypes.string.isRequired,
  configuration: PropTypes.shape({
    modelMap: PropTypes.objectOf(
      PropTypes.shape({
        category: PropTypes.shape({
          name: PropTypes.string.isRequired,
          displayName: PropTypes.string.isRequired
        }),
        parentLocationCategory: PropTypes.shape({
          name: PropTypes.string.isRequired,
          displayName: PropTypes.string.isRequired
        }),
        propertyOrders: PropTypes.arrayOf(PropTypes.string),
        properties: PropTypes.objectOf(
          PropTypes.shape({
            __addable: PropTypes.bool,
            __optional: PropTypes.bool,
            __optionallyDependsOn: PropTypes.arrayOf(PropTypes.string),
            displayName: PropTypes.string,
            input: PropTypes.oneOfType([
              PropTypes.bool,
              PropTypes.string,
              PropTypes.arrayOf(
                PropTypes.shape({
                  key: PropTypes.string.isRequired,
                  value: PropTypes.string.isRequired
                })
              )
            ])
          })
        )
      })
    )
  }),
  show: PropTypes.bool,
  providedArgument: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          value: PropTypes.string
        })
      )
    ])
  ),
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
};
