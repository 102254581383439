import React, { useState, useEffect, useMemo } from "react";
import { startCase, camelCase, isEqual, cloneDeep } from "lodash";
import useManagementDashboardDataSourceContext from "../../contexts/management-dashboard-data-source-context";
import Style from "./PopupFilter.module.css";
import { ReactComponent as FilterIcon } from "../../../../assets/icons/filter.svg";
import { ReactComponent as ChevDownIcon } from "../../../../assets/icons/chev-down.svg";
import Filters, { useFiltersConfigBuilder, FILTER_TYPE } from "../../../../components/filters";
import useManagementDashboardStateContext from "../../contexts/management-dashboard-state-context";
import useManagementDashboardConfigContext from "../../contexts/management-dashboard-config-context";
import { EXPEDITED_VALUES, STATUS_SEVERITY_MAP, BACKEND_STATUS_MAP, FRONTEND_STATUS_MAP } from "../../data/constants";
import { SUPPORTED_SETTING_ID_MAP } from "../../../order-track-page-feature-v2/features/wo-settings-menu/hooks/use-settings-builder/data/constants";

const pascalCase = (text) => {
  return startCase(camelCase(text)).replace(/ /g, "");
};

export const PopupFilter = () => {
  const { popupFilterControl, workOrderSettings } = useManagementDashboardConfigContext();
  const { filterValue, setFilterValue, setIsFilterActive, setSelectedMetricType, siteLocationId } =
    useManagementDashboardStateContext();
  const { setFilteredLocationTreeMap, locationTreeMap, allWorkOrders, isAllWorkOrdersLoading } =
    useManagementDashboardDataSourceContext();
  const { filterConfiguration, initialFilterValue, isLoading, applySideFilters } =
    useFiltersConfigBuilder(popupFilterControl);

  const [showMenu, setShowMenu] = useState(false);
  const [currentFiltersValue, setCurrentFiltersValue] = useState(initialFilterValue);

  const numOfFilterSelected = useMemo(() => {
    return popupFilterControl.reduce((accumulator, eachFilterControl) => {
      const { type, id } = eachFilterControl || {};

      const eachFilterValue = currentFiltersValue?.[id];

      switch (type) {
        case FILTER_TYPE.checkboxes:
          return (
            accumulator +
            ((eachFilterValue || []).filter(({ value }) => {
              return value;
            }).length || 0)
          );
        case FILTER_TYPE.dateRange:
          return accumulator + (eachFilterValue?.startTime || eachFilterValue?.endTime ? 1 : 0);
        case FILTER_TYPE.searchDropdown:
          return accumulator + (Object.keys(eachFilterValue?.value || {}).length ? 1 : 0);
        case FILTER_TYPE.input:
          return accumulator + (eachFilterValue?.value?.length || 0);
        default:
      }

      return accumulator;
    }, 0);
  }, [currentFiltersValue, popupFilterControl]);

  const percentageThresholdKeyMap = useMemo(() => {
    return (
      workOrderSettings?.optionControl?.[SUPPORTED_SETTING_ID_MAP.locationThresholdSettingModal]
        ?.percentageThresholdKeyMap || {}
    );
  }, [workOrderSettings]);

  const getFilteredLocationMetrics = (locationTree, workOrderList) => {
    const leafLocationIds = Object.keys(locationTree).filter((eachLocationId) => {
      return locationTree[eachLocationId].childLocations.length <= 1;
    });

    const metrics = leafLocationIds.reduce((accumulator, eachLocationId) => {
      return {
        ...accumulator,
        [eachLocationId]: {
          totalExpeditedCount: 0,
          totalExpeditedLateToStartCriticalCount: 0,
          totalExpeditedLateToStartWarningCount: 0,
          totalExpeditedTimeExceededCriticalCount: 0,
          totalExpeditedTimeExceededWarningCount: 0,
          totalLateToStartCriticalCount: 0,
          totalLateToStartWarningCount: 0,
          totalTimeExceededCriticalCount: 0,
          totalTimeExceededWarningCount: 0,
          totalCount: 0,
          totalCriticalCount: 0,
          totalExpeditedCriticalCount: 0,
          totalExpeditedWarningCount: 0,
          totalWarningCount: 0
        }
      };
    }, {});

    workOrderList.forEach((eachWorkOrder) => {
      const {
        lastDetectedLocationId,
        operationStatus,
        operationSeverity: timeExceededSeverity,
        operationStartStatus,
        operationDelayedStartSeverity: lateToStartSeverity,
        priority
      } = eachWorkOrder;

      const currentWorkOrderLocationMetric = metrics[lastDetectedLocationId];

      const isExpedited = EXPEDITED_VALUES.includes(priority);

      currentWorkOrderLocationMetric.totalCount += 1;

      if (
        operationStatus === BACKEND_STATUS_MAP.timeExceeded ||
        operationStartStatus === BACKEND_STATUS_MAP.lateToStart
      ) {
        const severityList = [];
        if (operationStartStatus === BACKEND_STATUS_MAP.timeExceeded) {
          severityList.push(timeExceededSeverity);
        }
        if (operationStartStatus === BACKEND_STATUS_MAP.lateToStart && !severityList.includes(lateToStartSeverity)) {
          severityList.push(lateToStartSeverity);
        }
        severityList.forEach((eachSeverity) => {
          currentWorkOrderLocationMetric[`total${eachSeverity}Count`] += 1;
          if (isExpedited) {
            currentWorkOrderLocationMetric[`total${STATUS_SEVERITY_MAP.expedited}${eachSeverity}Count`] += 1;
          }
        });
      }

      if (isExpedited) {
        currentWorkOrderLocationMetric[`total${STATUS_SEVERITY_MAP.expedited}Count`] += 1;
      }

      if (operationStatus === BACKEND_STATUS_MAP.timeExceeded) {
        if (isExpedited) {
          currentWorkOrderLocationMetric[
            `total${STATUS_SEVERITY_MAP.expedited}${pascalCase(
              FRONTEND_STATUS_MAP.timeExceeded
            )}${timeExceededSeverity}Count`
          ] += 1;
        } else {
          currentWorkOrderLocationMetric[
            `total${pascalCase(FRONTEND_STATUS_MAP.timeExceeded)}${timeExceededSeverity}Count`
          ] += 1;
        }
      }
      if (operationStartStatus === BACKEND_STATUS_MAP.lateToStart) {
        if (isExpedited) {
          currentWorkOrderLocationMetric[
            `total${STATUS_SEVERITY_MAP.expedited}${pascalCase(
              FRONTEND_STATUS_MAP.lateToStart
            )}${lateToStartSeverity}Count`
          ] += 1;
        } else {
          currentWorkOrderLocationMetric[
            `total${pascalCase(FRONTEND_STATUS_MAP.lateToStart)}${lateToStartSeverity}Count`
          ] += 1;
        }
      }
      metrics[lastDetectedLocationId] = currentWorkOrderLocationMetric;
    });

    return metrics;
  };

  const getFilteredLocationTreeMap = (locationTree, metrics) => {
    const newFilteredLocationTreeMap = locationTree;
    Object.keys(newFilteredLocationTreeMap).forEach((eachLocationId) => {
      newFilteredLocationTreeMap[eachLocationId].metrics = {};
    });
    Object.keys(metrics).forEach((eachLocationId) => {
      if (newFilteredLocationTreeMap[eachLocationId]) {
        newFilteredLocationTreeMap[eachLocationId].metrics = { ...metrics[eachLocationId] };
      }
    });

    Object.keys(newFilteredLocationTreeMap)
      .filter((eachLocationId) => {
        return newFilteredLocationTreeMap[eachLocationId].childLocations.length <= 1;
      })
      .forEach((eachLeafLocationId) => {
        let currentLocation = newFilteredLocationTreeMap[eachLeafLocationId];
        if (!currentLocation.metrics) {
          currentLocation.metrics = {};
        }
        while (currentLocation.directParentId) {
          Object.keys(currentLocation?.metrics).forEach((eachMetricKey) => {
            if (!newFilteredLocationTreeMap[currentLocation.directParentId].metrics[eachMetricKey]) {
              newFilteredLocationTreeMap[currentLocation.directParentId].metrics[eachMetricKey] = 0;
            }
            newFilteredLocationTreeMap[currentLocation.directParentId].metrics[eachMetricKey] +=
              newFilteredLocationTreeMap[eachLeafLocationId].metrics[eachMetricKey] || 0;
          });

          currentLocation = newFilteredLocationTreeMap[currentLocation.directParentId];
        }
      });

    Object.keys(newFilteredLocationTreeMap).forEach((eachLocationId) => {
      const eachLocation = newFilteredLocationTreeMap[eachLocationId];

      const statusCount = {
        totalCriticalCount: 0,
        totalWarningCount: 0,
        totalExpeditedCriticalCount: 0,
        totalExpeditedWarningCount: 0
      };

      Object.keys(eachLocation.metrics).forEach((eachMetricsKey) => {
        if (eachMetricsKey.toLowerCase().includes(STATUS_SEVERITY_MAP.critical.toLowerCase())) {
          statusCount.totalCriticalCount += eachLocation.metrics[eachMetricsKey];
          if (eachMetricsKey.toLowerCase().includes(STATUS_SEVERITY_MAP.expedited.toLocaleLowerCase())) {
            statusCount.totalExpeditedCriticalCount += eachLocation.metrics[eachMetricsKey];
          }
        } else if (eachMetricsKey.toLowerCase().includes(STATUS_SEVERITY_MAP.warning.toLowerCase())) {
          statusCount.totalWarningCount += eachLocation.metrics[eachMetricsKey];
          if (eachMetricsKey.toLowerCase().includes(STATUS_SEVERITY_MAP.expedited.toLocaleLowerCase())) {
            statusCount.totalExpeditedWarningCount += eachLocation.metrics[eachMetricsKey];
          }
        }
      });

      eachLocation.metrics = { ...eachLocation.metrics, ...statusCount };
      const {
        metrics: {
          totalWarningCount,
          totalCriticalCount,
          totalExpeditedWarningCount,
          totalExpeditedCriticalCount,
          totalCount
        }
      } = eachLocation;

      const {
        [percentageThresholdKeyMap.warning]: warningPercentageThreshold,
        [percentageThresholdKeyMap.critical]: criticalPercentageThreshold
      } = eachLocation;

      let status = STATUS_SEVERITY_MAP.healthy;
      if (
        warningPercentageThreshold
          ? parseInt((totalWarningCount / totalCount) * 100) >= warningPercentageThreshold
          : totalWarningCount
      ) {
        status = STATUS_SEVERITY_MAP.warning;
      }
      if (
        criticalPercentageThreshold
          ? parseInt((totalCriticalCount / totalCount) * 100) >= criticalPercentageThreshold
          : totalCriticalCount
      ) {
        status = STATUS_SEVERITY_MAP.critical;
      }

      let expeditedStatus = STATUS_SEVERITY_MAP.healthy;
      if (totalExpeditedWarningCount) {
        expeditedStatus = STATUS_SEVERITY_MAP.warning;
      }
      if (totalExpeditedCriticalCount) {
        expeditedStatus = STATUS_SEVERITY_MAP.critical;
      }

      eachLocation.statuses = { status, expeditedStatus };
    });
    return newFilteredLocationTreeMap;
  };

  useEffect(() => {
    setCurrentFiltersValue(cloneDeep(filterValue || initialFilterValue));
  }, [initialFilterValue, filterValue, showMenu]);

  useEffect(() => {
    if (!isLoading && filterValue) {
      const newIsFilterActive = !isEqual(initialFilterValue, filterValue);

      if (newIsFilterActive) {
        setSelectedMetricType(null);
        const filteredWorkOrders = allWorkOrders.filter((eachWorkOrder) => {
          return applySideFilters(filterConfiguration, filterValue, eachWorkOrder);
        });
        const metrics = getFilteredLocationMetrics(cloneDeep(locationTreeMap), filteredWorkOrders);
        const newFilteredLocationTreeMap = getFilteredLocationTreeMap(cloneDeep(locationTreeMap), metrics);
        setFilteredLocationTreeMap(newFilteredLocationTreeMap);
      }
      setIsFilterActive(newIsFilterActive);
    }
  }, [filterValue, initialFilterValue, isLoading]);

  if (!popupFilterControl?.length || !siteLocationId) {
    return null;
  }

  return (
    <div className={Style.container}>
      <button
        type="button"
        className={Style.filter_button}
        onClick={() => {
          setShowMenu((currentValue) => {
            return !currentValue;
          });
        }}
      >
        <FilterIcon />
        <p className={Style.filter_button_text}>Filters</p>
        <ChevDownIcon />
      </button>
      {showMenu && (
        <div className={Style.filter_menu}>
          <div className={Style.menu_content}>
            <div className={Style.menu_header_container}>
              <div className={Style.menu_title_container}>
                <p className={Style.menu_title_text}>Filter By</p>
                <p className={Style.menu_title_subtext}>
                  {`(${numOfFilterSelected || 0} filter${numOfFilterSelected === 1 ? "" : "s"} selected)`}
                </p>
              </div>
              <button
                type="button"
                className={Style.clear_all_text_button}
                onClick={() => {
                  setCurrentFiltersValue(cloneDeep(initialFilterValue));
                }}
              >
                Clear All
              </button>
            </div>
            <div>
              <Filters
                isLoading={isLoading || isAllWorkOrdersLoading}
                filterConfiguration={filterConfiguration}
                initialFilterValues={{ ...currentFiltersValue }}
                onFilterChange={({ allValues }) => {
                  setCurrentFiltersValue(allValues);
                }}
              />
            </div>
          </div>
          <div className={Style.menu_buttons_container}>
            <button
              type="button"
              className={`${Style.button_base} ${Style.cancel_button}`}
              onClick={() => {
                setShowMenu(false);
                setCurrentFiltersValue(cloneDeep(initialFilterValue));
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className={`${Style.button_base} ${Style.apply_button}`}
              onClick={() => {
                setShowMenu(false);
                setFilterValue(currentFiltersValue);
              }}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
