import { Checkbox } from "@material-ui/core";
import { DatePicker } from "components/DateTimePicker/DateTimePicker";
import SearchDropdown from "components/SearchDropdown/SearchDropdown";
import AutoSizeTextArea from "components/AutoSizeTextArea/AutoSizeTextArea";
import React from "react";

export const renderInput = (type, onChange, value, error, errorMessage, editable, options, numberOnly) => {
  switch (type) {
    case "date":
      return (
        <DatePicker
          value={value}
          error={error}
          errorMessage={errorMessage}
          readOnly={!editable}
          onTimeChange={onChange}
        />
      );
    case "dropdown":
      return (
        <SearchDropdown
          error={error}
          errorMessage={errorMessage}
          options={options}
          selectedItem={value}
          onItemSelected={onChange}
        />
      );
    case "checkbox":
      return (
        <Checkbox
          color="primary"
          checked={!!value}
          onChange={(event) => {
            onChange(event.target.checked);
          }}
        />
      );
    default:
      return (
        <AutoSizeTextArea
          error={error}
          errorMessage={errorMessage}
          readOnly={!editable}
          backgroundColor={!editable && "#fbfafa"}
          value={value}
          onChangeText={onChange}
          numberOnly={numberOnly}
        />
      );
  }
};
