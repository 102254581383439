import React from "react";
import PropTypes from "prop-types";
import Style from "./OnboardModalFooter.module.css";

const OnboardModalFooter = ({
  onClose,
  onSubmit,
  submitDisabled,
  submitLabel
 }) => {
  return (
    <div className={Style.button_container}>
      <button
        tabIndex="-1"
        className={Style.gray_button}
        onClick={onClose}
        type="button"
      >
        Cancel
      </button>
      <button
        disabled={submitDisabled}
        className={Style.blue_button}
        onClick={onSubmit}
        type="button"
      >
        {submitLabel}
      </button>
    </div>
  );
};

OnboardModalFooter.defaultProps = {
  submitDisabled: false
};

OnboardModalFooter.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string.isRequired,
  submitDisabled: PropTypes.bool
};

export default OnboardModalFooter;
