import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { FeatureConfigurationProvider } from "../../../../domains/feature-configuration-provider";
import { Div } from "../../../../components/div";
import { XButton } from "../../../../components/xemelgo-button/x-button";
import { getPartnerDisplayValue } from "../../ListPartnersUtil";

import "./style.css";

const FEATURE_ID = "listPartners";
export const PartnerDetailPane = ({
  partner,
  configuration,
  modelId,
  onDeleteClicked,
  onEditClicked,
  displayProperty
}) => {
  const [properties, setProperties] = useState([]);
  const [partnerDisplayName, setPartnerDisplayName] = useState(modelId);

  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!configuration) {
      return cancelCallback;
    }

    const configProvider = FeatureConfigurationProvider.parse(FEATURE_ID, configuration);
    const modelConfigProvider = configProvider.getModel(modelId);
    const propertyOrders = modelConfigProvider.getValue("propertyOrders", "array", []);
    const modelDisplayName = modelConfigProvider.getValue("displayName", "string", modelId);
    const propertyConfigMap = modelConfigProvider.getPropertyMap();
    setPartnerDisplayName(modelDisplayName);

    if (!partner) {
      return cancelCallback;
    }

    const props = propertyOrders
      .filter((propertyId) => {
        return propertyConfigMap[propertyId];
      })
      .map((propertyId) => {
        const propertyConfig = propertyConfigMap[propertyId];
        const { displayName } = propertyConfig;
        const value = partner[propertyId] || "-";
        return {
          value,
          name: displayName,
          id: propertyId
        };
      });

    if (!cancelled) {
      setProperties(props);
    }

    return cancelCallback;
  }, [configuration, partner, modelId]);

  return (
    <Div className="partner-detail-pane">
      {partner ? (
        <Div className="partner-detail">
          <Div className="partner-detail-header">
            <Div className="partner-detail-header-title">{getPartnerDisplayValue(partner, displayProperty)}</Div>
            <Div className="partner-detail-header-operations">
              <XButton
                onClick={onEditClicked}
                label="Edit"
                className="partner-operation-edit-button"
              />
              <XButton
                onClick={onDeleteClicked}
                label="Delete"
                className="partner-operation-delete-button"
              />
            </Div>
          </Div>
          <Div className="partner-detail-body">
            <Div className="partner-detail-body-descriptions">
              {properties.map((property) => {
                return (
                  <Div
                    className="partner-description-group"
                    key={property.id}
                  >
                    <Div className="partner-description-topic">{`${property.name}:`}</Div>
                    <Div className="partner-description-value">{property.value}</Div>
                  </Div>
                );
              })}
            </Div>
          </Div>
        </Div>
      ) : (
        <Div className="empty-operation">{`Select a ${partnerDisplayName.toLowerCase()} to see more information about it`}</Div>
      )}
    </Div>
  );
};

PartnerDetailPane.defaultProps = {
  configuration: {},
  partner: null
};

PartnerDetailPane.propTypes = {
  partner: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    identifier: PropTypes.string.isRequired,
    description: PropTypes.string
  }),
  modelId: PropTypes.string.isRequired,
  configuration: PropTypes.shape({
    modelMap: PropTypes.objectOf(
      PropTypes.shape({
        displayName: PropTypes.string,
        propertyOrders: PropTypes.arrayOf(PropTypes.string),
        properties: PropTypes.objectOf(
          PropTypes.shape({
            displayName: PropTypes.string.isRequired
          })
        )
      })
    )
  }),
  onEditClicked: PropTypes.func.isRequired,
  onDeleteClicked: PropTypes.func.isRequired
};
