import { v4 as uuidv4 } from "uuid";
import { AddPageInputTypeMap } from "../../../../data/constants";

/**
 * This function validate CSV data and return a valid state, validated data and number of invalid items
 * @param data
 * @param csvAttributeMap
 * @param xemelgoClient
 * @returns
 */
export const validateCsvData = async (data, csvAttributeMap, xemelgoClient) => {
  const itemTypeClient = xemelgoClient.getItemTypeClient();
  const locationClient = xemelgoClient.getLocationClient();
  const itemClient = xemelgoClient.getItemClient();
  const sensorProfileClient = xemelgoClient.getSensorProfileClient();

  const itemTypesToQuery = [];
  const locationsToQuery = [];
  const identifiersToQuery = [];
  const vidsToQuery = [];

  // get list of things to query
  data.forEach((eachDataSet) => {
    const { item_number: itemNumber, location, tracker_serial: vid, item_identifier: itemIdentifier } = eachDataSet;
    if (itemNumber && csvAttributeMap?.item_number?.validateCSVInputWithOptions) {
      itemTypesToQuery.push(itemNumber.value);
    }
    if (location && csvAttributeMap?.location?.validateCSVInputWithOptions) {
      locationsToQuery.push(location.value);
    }
    if (itemIdentifier && csvAttributeMap?.item_identifier?.validateCSVInputWithOptions) {
      identifiersToQuery.push(itemIdentifier.value);
    }
    if (vid?.value) {
      vidsToQuery.push(vid.value.toUpperCase());
    }
  });

  // issue queries
  let itemTypesQueryPromise;
  let locationsQueryPromise;
  let identifiersQueryPromise;
  let vidsToQueryPromise;

  if (itemTypesToQuery.length) {
    const { argsForAPI = {} } = csvAttributeMap.item_number;
    itemTypesQueryPromise = itemTypeClient.getItemTypeByIdentifiers(itemTypesToQuery, argsForAPI.fields);
  }
  if (locationsToQuery.length) {
    const { argsForAPI = {} } = csvAttributeMap.location || {};
    const { categories = [] } = argsForAPI;
    locationsQueryPromise = locationClient.getLocationsByIdentifiers(locationsToQuery, categories);
  }

  if (identifiersToQuery.length) {
    identifiersQueryPromise = itemClient.getItemsByIdentifiers(identifiersToQuery);
  }

  if (vidsToQuery.length) {
    vidsToQueryPromise = sensorProfileClient.activeSensorProfilesCheckByVid(vidsToQuery);
  }

  const [itemTypes = [], locations = [], vids = [], items = []] = await Promise.all([
    itemTypesQueryPromise,
    locationsQueryPromise,
    vidsToQueryPromise,
    identifiersQueryPromise
  ]);

  const itemNumberMap = itemTypes.reduce((newItemNumberMap, itemType) => {
    const { identifier } = itemType;
    return { ...newItemNumberMap, [identifier]: { ...itemType } };
  }, {});

  const locationMap = locations.reduce((newLocationMap, location) => {
    const { identifier } = location;
    return { ...newLocationMap, [identifier]: true };
  }, {});
  const existingIdentifiersMap = items.reduce((newIdentifiersMap, item) => {
    const { identifier } = item;
    return { ...newIdentifiersMap, [identifier]: true };
  }, {});
  const existingVidsMap = vids.reduce((newVidsMap, eachVid) => {
    const { vid } = eachVid;
    return { ...newVidsMap, [vid]: true };
  }, {});

  // validate csv data
  const validatedDataMap = data.reduce((newValidatedMap, eachRow, currentIndex) => {
    let validRow = true;
    const uuid = uuidv4();
    const validatedRowData = { ...eachRow };
    Object.keys(validatedRowData).forEach((id) => {
      const { isRequired, type, label, validateCSVInputWithOptions } = csvAttributeMap[id] || {};
      const lowerCaseLabel = label.toLowerCase();

      const { value } = validatedRowData[id];

      // reset
      validatedRowData[id].isError = false;
      validatedRowData[id].errorMessage = "";

      // if cell value exist
      if (value) {
        switch (type) {
          case AddPageInputTypeMap.DATE_PICKER:
            const date = new Date(value);
            if (Number.isNaN(date.getTime())) {
              validatedRowData[id].isError = true;
              validatedRowData[id].errorMessage = `${lowerCaseLabel} should be in mm/dd/yyyy format. `;

              validRow = false;
            }
            break;
          case AddPageInputTypeMap.NUMBER:
            if (Number.isNaN(value)) {
              validatedRowData[id].isError = true;
              validatedRowData[id].errorMessage = `${lowerCaseLabel} should be numerial.`;
              validRow = false;
            }
            break;
          case AddPageInputTypeMap.SEARCH:
          case AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
            if (!validateCSVInputWithOptions) {
              return;
            }
            switch (id) {
              case "item_number":
                if (!itemNumberMap[value]) {
                  validRow = false;
                  validatedRowData[id].isError = true;
                  validatedRowData[id].errorMessage = `Please enter correct ${lowerCaseLabel}.`;
                } else {
                  validatedRowData[id] = { ...itemNumberMap[value], ...validatedRowData[id] };
                }
                break;
              case "location":
                if (!locationMap[value]) {
                  validRow = false;
                  validatedRowData[id].isError = true;
                  validatedRowData[id].errorMessage = `Please enter correct ${lowerCaseLabel}.`;
                }
                break;
              default:
                break;
            }
            break;
          default:
            if (id === "tracker_serial") {
              if (existingVidsMap[value]) {
                validRow = false;
                validatedRowData[id].isError = true;
                validatedRowData[id].errorMessage = `Duplicated ${lowerCaseLabel}.`;
              }
              existingVidsMap[value] = true;
            }
            if (id === "item_identifier" && validateCSVInputWithOptions) {
              if (existingIdentifiersMap[value]) {
                validRow = false;
                validatedRowData[id].isError = true;
                validatedRowData[id].errorMessage = `Duplicated ${lowerCaseLabel}.`;
              }
              existingIdentifiersMap[value] = true;
            }
            break;
        }
      } else if (isRequired) {
        validatedRowData[id].isRequired = isRequired;
        validatedRowData[id].errorMessage = `${lowerCaseLabel} is required.`;
        validRow = false;
      }
    });

    return {
      ...newValidatedMap,
      [uuid]: { id: uuid, data: validatedRowData, rowNumber: currentIndex + 1, isError: !validRow }
    };
  }, {});
  return { validatedDataMap };
};
