import React, { useMemo } from "react";
import PropTypes from "prop-types";
import usePackageTrackPageStateContext from "../../../../contexts/package-track-page-state-context";
import Style from "./LocationGroup.module.css";
import OptionalLink from "../../../../../../components/optional-link/OptionalLink";
import { ReactComponent as ChevronRight } from "../../../../../../assets/icons/chev-right.svg";
import { ReactComponent as ChevronDown } from "../../../../../../assets/icons/chev-down.svg";
import usePackageTrackPageDataSourceContext from "../../../../contexts/package-track-page-data-source-context";

export const LocationGroup = ({ location, children }) => {
  const { locationMetricsMap } = usePackageTrackPageDataSourceContext();
  const { isLocationExpandedMap, setIsLocationExpandedMap } = usePackageTrackPageStateContext();

  const isCollapsed = useMemo(() => {
    return !isLocationExpandedMap[location.id];
  }, [location, isLocationExpandedMap]);

  const metricsData = useMemo(() => {
    return locationMetricsMap[location.id] || {};
  }, [location, locationMetricsMap]);

  const onExpandCollapseClick = () => {
    const newIsLocationExpandedMap = { ...isLocationExpandedMap, [location.id]: isCollapsed };
    setIsLocationExpandedMap(newIsLocationExpandedMap);
  };

  return (
    <div className={`${Style.flex_column} ${Style.group}`}>
      <div className={`${Style.flex_row} ${Style.container_background}`}>
        <div className={`${Style.flex_row} ${Style.container}`}>
          <div className={`${Style.flex_row} ${Style.header}`}>
            <button
              type="button"
              className={Style.expand_collapse_icon}
              onClick={onExpandCollapseClick}
            >
              {isCollapsed ? (
                <ChevronRight
                  width={9}
                  height={9}
                />
              ) : (
                <ChevronDown
                  width={10}
                  height={8}
                />
              )}
            </button>
            <OptionalLink
              className={Style.header_text}
              active
              route={`/package?locationId=${location.id}`}
            >
              {location.name}
            </OptionalLink>
          </div>
          <div className={`${Style.flex_row} ${Style.metric}`}>
            <p className={Style.metric_header}>Total Packages:</p>
            <p>{metricsData.totalCount}</p>
          </div>
        </div>
        <button
          type="button"
          className={Style.expand_collapse_btn}
          onClick={onExpandCollapseClick}
        >
          {isCollapsed ? "Expand" : "Collapse"}
        </button>
      </div>
      <div className={`${Style.flex_column} ${Style.list} ${isCollapsed && Style.hide_list}`}>{children}</div>
    </div>
  );
};

LocationGroup.defaultProps = {
  location: {},
  children: null
};

LocationGroup.propTypes = {
  location: PropTypes.object,
  children: PropTypes.element
};
