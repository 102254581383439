import React from "react";
import PropTypes from "prop-types";
import Style from "./StatusColorLegend.module.css";

export const StatusColorLegend = ({ legendList }) => {
  const renderEachLegend = ({ label, color }) => {
    return (
      <li
        key={`legend-${label}-${color}`}
        className={Style.color_indicator}
      >
        <div
          data-testid="legend-icon"
          className={Style.color_icon}
          style={{
            backgroundColor: color
          }}
        />
        <p className={Style.color_label}>{label}</p>
      </li>
    );
  };

  return (
    <ul className={Style.group}>
      {legendList.map((eachLegend) => {
        return renderEachLegend(eachLegend);
      })}
    </ul>
  );
};

StatusColorLegend.defaultProps = {
  legendList: []
};

StatusColorLegend.propTypes = {
  legendList: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired, color: PropTypes.string.isRequired })
  )
};
