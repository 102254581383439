import React from "react";
import PropTypes from "prop-types";
import Style from "./ListItem.module.css";

export const ListItem = ({ item, itemContainerClassName }) => {
  return (
    <div className={`${Style.flex_row} ${Style.table_item}`}>
      {item.map(({ id, label, onClick }) => {
        return (
          <div
            key={id}
            className={`${Style.flex_row} ${Style.table_column} ${itemContainerClassName}`}
          >
            <div className={`${Style.table_item_text} ${onClick && Style.table_item_text_active}`}>{label}</div>
          </div>
        );
      })}
    </div>
  );
};

ListItem.defaultProps = {
  item: [],
  itemContainerClassName: ""
};

ListItem.propTypes = {
  item: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
      onClick: PropTypes.func
    })
  ),
  itemContainerClassName: PropTypes.string
};
