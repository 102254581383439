import { createTheme } from "@material-ui/core/styles";
import xemelgoStyle from "../../styles/variable";

const theme = createTheme({
  palette: {
    primary: { 500: xemelgoStyle.theme.APP_BLUE }
  }
});

export default theme;
