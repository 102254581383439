/**
 * Interprets CSV data and updates transfer orders accordingly.
 * @param {Array<Object>} formData - The CSV data to be interpreted.
 * @param {Function} getTransferOrder - A function to retrieve a transfer order based on a tracking number.
 * @param {Boolean} useDefaultLocation - Whether or not to use default locations.
 * @param {String} connectionProperty - connection property name to tracking number
 * @throws {Error} If a transfer order has inconsistent from or to locations.
 */
export const validateCsvData = (formData, getTransferOrder, useDefaultLocation, connectionProperty) => {
  for (let i = 0; i < formData.length; i++) {
    const transferOrder = getTransferOrder(formData[i].tracking_number);
    // check all items on each transfer order to make sure the locations are all consistent.
    // I don't like this... it should not be on every record since location is a first-class field on the transfer order,
    // and it cannot be applied on a per-item basis. But we need to do this to support the CSV format.
    if (!useDefaultLocation) {
      if (transferOrder.fromLocation && transferOrder.fromLocation !== formData[i].from_location) {
        throw new Error(`Transfer order '${transferOrder.trackingNumber}' has inconsistent from locations`);
      } else if (!formData[i].from_location) {
        throw new Error(`Transfer order '${transferOrder.trackingNumber}' is missing the from location`);
      }
      if (transferOrder.toLocation && transferOrder.toLocation !== formData[i].to_location) {
        throw new Error(`Transfer order '${transferOrder.trackingNumber}' has inconsistent to locations`);
      } else if (!formData[i].to_location) {
        throw new Error(`Transfer order '${transferOrder.trackingNumber}' is missing the to location`);
      }
      transferOrder.fromLocation = formData[i].from_location;
      transferOrder.toLocation = formData[i].to_location;
    }
    if (connectionProperty) {
      const connectionValue = formData[i][connectionProperty];
      if (transferOrder.trackingNumber === connectionValue) {
        throw new Error(`Could not set '${transferOrder.trackingNumber}' children to itself `);
      }
      transferOrder.childOf = connectionValue;
    }
    transferOrder.items.push({
      itemType: formData[i].item_number,
      quantity: formData[i].quantity
    });
  }
};
