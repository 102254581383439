/* eslint-disable radix */
import React, { useContext, useState, useReducer } from "react";
import { useStore } from "react-hookstore";
import { userProfileStore } from "../../../../state-managements/stores/user-profile-store";
import { STATUS_SEVERITY_MAP } from "../../data/constants";

const WorkOrderTrackPageDataSourceContext = React.createContext();

const initialState = {
  locationTreeMap: {},
  childrenLocationMetricMap: {},
  workOrderDataList: [],
  locationMetricsMap: {},
  lastUpdatedTime: null,
  backlogThresholdToLocationIdMap: {}
};

const calculateStatuses = (
  locationMetrics,
  { criticalPercentageThreshold, warningPercentageThreshold, backlogCriticalThreshold, backlogWarningThreshold }
) => {
  let status = STATUS_SEVERITY_MAP.healthy;
  if (
    criticalPercentageThreshold
      ? parseInt((locationMetrics.totalCriticalCount / locationMetrics.totalCount) * 100) >= criticalPercentageThreshold
      : locationMetrics.totalCriticalCount
  ) {
    status = STATUS_SEVERITY_MAP.critical;
  } else if (
    warningPercentageThreshold
      ? parseInt((locationMetrics.totalWarningCount / locationMetrics.totalCount) * 100) >= warningPercentageThreshold
      : locationMetrics.totalWarningCount
  ) {
    status = STATUS_SEVERITY_MAP.warning;
  }

  let backlogStatus = STATUS_SEVERITY_MAP.healthy;
  if (backlogCriticalThreshold && locationMetrics.totalCount >= backlogCriticalThreshold) {
    backlogStatus = STATUS_SEVERITY_MAP.critical;
  } else if (backlogWarningThreshold && locationMetrics.totalCount >= backlogWarningThreshold) {
    backlogStatus = STATUS_SEVERITY_MAP.warning;
  }
  return { status, backlogStatus };
};

export const useWorkOrderTrackPageDataSourceContext = () => {
  return useContext(WorkOrderTrackPageDataSourceContext);
};

const locationMetricsReducer = (state, { type, payload }) => {
  switch (type) {
    case "setChildrenLocationMetricMap": {
      const {
        responses,
        childrenLocations,
        backlogThresholdToLocationIdMap,
        locationTreeMap,
        percentageThresholdKeyMap
      } = payload;

      return _setChildrenLocationMetricMap(
        responses,
        childrenLocations,
        backlogThresholdToLocationIdMap,
        locationTreeMap,
        percentageThresholdKeyMap,
        state
      );
    }
    case "updateLocationMetrics": {
      const {
        metrics: {
          value: {
            data: {
              onUpdateMetric: {
                results: [{ groupKey, timestamp, result }]
              }
            }
          }
        },
        childrenLocations,
        locationTreeMap,
        backlogThresholdToLocationIdMap,
        percentageThresholdKeyMap
      } = payload;

      return _updateLocationMetrics(
        groupKey,
        timestamp,
        result,
        childrenLocations,
        locationTreeMap,
        backlogThresholdToLocationIdMap,
        percentageThresholdKeyMap,
        state
      );
    }
    default:
      break;
  }
};

/**
 * Sets the childrenLocationMetricMap
 * @param {Object} responses - children location metrics responses from API
 * @param {Object} childrenLocations - children locations
 * @param {Object} backlogThresholdToLocationIdMap - backlog threshold to location ID map
 * @param {Object} state - current state
 * @returns {Object}
 */
const _setChildrenLocationMetricMap = (
  responses,
  childrenLocations,
  backlogThresholdToLocationIdMap,
  locationTreeMap,
  percentageThresholdKeyMap,
  state
) => {
  // Stores metrics of all locations
  let newLastUpdatedTime = null;

  const { childrenLocationMetrics, newLocationMetricsMap } = responses.reduce(
    (accumulator, [metricsSummary, leafLocationMetricsBreakdown]) => {
      return {
        childrenLocationMetrics: [...accumulator.childrenLocationMetrics, metricsSummary],
        newLocationMetricsMap: {
          ...accumulator.newLocationMetricsMap,
          ...leafLocationMetricsBreakdown
        }
      };
    },
    {
      childrenLocationMetrics: [],
      newLocationMetricsMap: {}
    }
  );

  const newChildrenLocationMetricMap = childrenLocationMetrics.reduce((accumulator, eachLocationMetrics, index) => {
    if (eachLocationMetrics.timestamp > newLastUpdatedTime) {
      newLastUpdatedTime = eachLocationMetrics.timestamp;
    }

    const {
      [percentageThresholdKeyMap.critical]: criticalPercentageThreshold,
      [percentageThresholdKeyMap.warning]: warningPercentageThreshold
    } = locationTreeMap[childrenLocations[index].id] || {};

    const { warningThreshold: backlogWarningThreshold, criticalThreshold: backlogCriticalThreshold } =
      backlogThresholdToLocationIdMap[childrenLocations[index].id] || backlogThresholdToLocationIdMap.all || {};

    const { status, backlogStatus } = calculateStatuses(eachLocationMetrics, {
      criticalPercentageThreshold,
      warningPercentageThreshold,
      backlogCriticalThreshold,
      backlogWarningThreshold
    });

    accumulator[childrenLocations[index].id] = {
      ...eachLocationMetrics,
      criticalPercentageThreshold,
      warningPercentageThreshold,
      backlogWarningThreshold,
      backlogCriticalThreshold,
      backlogStatus,
      status
    };
    return accumulator;
  }, {});

  return {
    ...state,
    childrenLocationMetricMap: newChildrenLocationMetricMap,
    locationMetricsMap: newLocationMetricsMap,
    lastUpdatedTime: newLastUpdatedTime
  };
};

/**
 * Updates location metrics
 * @param {String} groupKey - location group key
 * @param {Number} timestamp - metrics update time
 * @param {Object} result - location metrics
 * @param {Object} childrenLocations - children locations
 * @param {Object} state - current state
 * @returns {Object}
 */
const _updateLocationMetrics = (
  groupKey,
  timestamp,
  result,
  childrenLocations,
  locationTreeMap,
  backlogThresholdToLocationIdMap,
  percentageThresholdKeyMap,
  state
) => {
  const { childrenLocationMetricMap, locationMetricsMap, lastUpdatedTime } = state;
  const updatedMetrics = JSON.parse(result);
  const newLastUpdatedTime = timestamp > lastUpdatedTime ? timestamp : null;

  locationMetricsMap[groupKey] = updatedMetrics;

  const locationsNeedUpdate = childrenLocations.filter((parentLocation) => {
    return parentLocation.childLocations.includes(groupKey);
  });

  if (locationsNeedUpdate.length < 1) {
    return state;
  }

  const newMetrics = {};

  locationsNeedUpdate.forEach((location) => {
    let updatedLocationMetrics = {};
    location.childLocations.forEach((loc) => {
      Object.keys(locationMetricsMap[loc] || {}).forEach((key) => {
        updatedLocationMetrics[key] = (updatedLocationMetrics[key] || 0) + locationMetricsMap[loc][key];
      });
    });

    const statusCount = { critical: 0, warning: 0 };
    Object.keys(updatedLocationMetrics).forEach((eachKey) => {
      if (eachKey.toLowerCase().includes("criticalcount") && updatedLocationMetrics[eachKey]) {
        statusCount.critical += updatedLocationMetrics[eachKey];
      } else if (eachKey.toLowerCase().includes("warningcount") && updatedLocationMetrics[eachKey]) {
        statusCount.warning += updatedLocationMetrics[eachKey];
      }
    });

    updatedLocationMetrics.totalCriticalCount = statusCount.critical;
    updatedLocationMetrics.totalWarningCount = statusCount.warning;

    const {
      [percentageThresholdKeyMap.critical]: criticalPercentageThreshold,
      [percentageThresholdKeyMap.warning]: warningPercentageThreshold
    } = locationTreeMap[location.id] || {};

    const { warningThreshold: backlogWarningThreshold, criticalThreshold: backlogCriticalThreshold } =
      backlogThresholdToLocationIdMap[location.id] || backlogThresholdToLocationIdMap.all || {};

    const { status, backlogStatus } = calculateStatuses(updatedLocationMetrics, {
      criticalPercentageThreshold,
      warningPercentageThreshold,
      backlogCriticalThreshold,
      backlogWarningThreshold
    });

    updatedLocationMetrics = {
      ...updatedLocationMetrics,
      criticalPercentageThreshold,
      warningPercentageThreshold,
      backlogWarningThreshold,
      backlogCriticalThreshold,
      backlogStatus,
      status,
      timestamp
    };

    newMetrics[location.id] = updatedLocationMetrics;
  });

  return {
    ...state,
    childrenLocationMetricMap: { ...childrenLocationMetricMap, ...newMetrics },
    locationMetricsMap: { ...locationMetricsMap },
    lastUpdatedTime: newLastUpdatedTime || lastUpdatedTime
  };
};

export const WorkOrderTrackPageDataSourceContextProvider = ({ children }) => {
  const [locationTreeMap, setLocationTreeMap] = useState(initialState.locationTreeMap);
  const [workOrderDataList, setWorkOrderDataList] = useState(initialState.workOrderDataList);
  const [backlogThresholdToLocationIdMap, setBacklogThresholdToLocationIdMap] = useState(
    initialState.backlogThresholdToLocationIdMap
  );
  const [state, dispatch] = useReducer(locationMetricsReducer, initialState);
  const [userProfile] = useStore(userProfileStore);

  return (
    <WorkOrderTrackPageDataSourceContext.Provider
      value={{
        workOrderDataList,
        setWorkOrderDataList,
        locationTreeMap,
        setLocationTreeMap,
        childrenLocationMetricMap: state.childrenLocationMetricMap,
        locationMetricsMap: state.locationMetricsMap,
        lastUpdatedTime: state.lastUpdatedTime,
        updateLocationMetrics: (payload) => {
          return dispatch({ type: "updateLocationMetrics", payload });
        },
        setChildrenLocationMetricMap: (payload) => {
          return dispatch({ type: "setChildrenLocationMetricMap", payload });
        },
        backlogThresholdToLocationIdMap,
        setBacklogThresholdToLocationIdMap,
        isAdmin: userProfile.isSuperAdmin
      }}
    >
      {children}
    </WorkOrderTrackPageDataSourceContext.Provider>
  );
};
