import React from "react";
import PropTypes from "prop-types";
import Style from "./TextFilterInputComponent.module.css";

const TextFilterInputComponent = ({
  onChange = () => {},
  placeholder = "Type to filter results",
  containerStyle,
  inputClassName
}) => {
  return (
    <div className={containerStyle || Style.container}>
      <input
        className={`${Style.input_field_style} ${inputClassName}`}
        placeholder={placeholder}
        onChange={(event) => {
          onChange(event.target.value);
        }}
      />
    </div>
  );
};

TextFilterInputComponent.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  containerStyle: PropTypes.string,
  inputClassName: PropTypes.string
};

TextFilterInputComponent.defaultProps = {
  placeholder: "Type to filter results",
  onChange: () => {},
  containerStyle: null,
  inputClassName: ""
};

export default TextFilterInputComponent;
