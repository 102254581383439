import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import OrderDetailStyle from "./OrderDetailComponent.module.css";
import { getFormattedDate } from "../../common/utils/get-formatted-date";
import { getStackedXemelgoLogo } from "../../common/Utilities";

const OrderDetailComponent = ({ orderInfo, orderLabel, image, hideLocationDetails, hideStatusDetails }) => {
  const renderStatus = (statusList) => {
    if (!statusList) {
      return null;
    }
    return statusList
      .filter((a, b) => {
        return statusList.indexOf(a) === b;
      })
      .map((eachStatus, index) => {
        return (
          <div
            className={OrderDetailStyle.details_container}
            key={index}
          >
            <div
              style={{ backgroundColor: eachStatus.color }}
              className={OrderDetailStyle.status}
            >
              <p className={OrderDetailStyle.status_text}>{eachStatus.displayText}</p>
            </div>
          </div>
        );
      });
  };

  return (
    <>
      <div className={OrderDetailStyle.grid_card_container_style}>
        <img
          className={OrderDetailStyle.image}
          src={image}
          alt="logo"
        />
      </div>
      <div className={OrderDetailStyle.title_container}>
        {!hideStatusDetails && renderStatus(orderInfo.statusList)}
        <div className={`${OrderDetailStyle.identifier_container} ${OrderDetailStyle.horizontal_split}`}>
          <div className={OrderDetailStyle.identifier_label}>{`${orderLabel} #:`}</div>
          <div className={OrderDetailStyle.identifier_text}>{orderInfo.identifier}</div>
        </div>
        {!hideLocationDetails && (
          <div>
            <div className={OrderDetailStyle.details_container}>
              <div className={OrderDetailStyle.details_label}>{`From Location: `}</div>
              <div className={OrderDetailStyle.details_text}>{orderInfo.transferFrom?.name ?? "none"}</div>
            </div>
            <div className={OrderDetailStyle.details_container}>
              <div className={OrderDetailStyle.details_label}>{`To Location: `}</div>
              <div className={OrderDetailStyle.details_text}>{orderInfo.transferTo?.name ?? "none"}</div>
            </div>
          </div>
        )}
        <div className={OrderDetailStyle.details_container}>
          <div className={OrderDetailStyle.details_label}>{`Last Updated: `}</div>
          <div className={OrderDetailStyle.details_text}>
            {getFormattedDate(orderInfo.timestamp, "hh:mm A - MMM DD, yyyy ")}
          </div>
        </div>
      </div>
    </>
  );
};

OrderDetailComponent.defaultProp = {
  orderInfo: {},
  orderLabel: "Transfer Order",
  image: getStackedXemelgoLogo("dark"),
  hideLocationDetails: false,
  hideStatusDetails: false
};

OrderDetailComponent.propTypes = {
  orderInfo: PropTypes.object.isRequired,
  orderLabel: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  hideLocationDetails: PropTypes.bool.isRequired,
  hideStatusDetails: PropTypes.bool.isRequired
};

export default withRouter(OrderDetailComponent);
