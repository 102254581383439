export const getReportDateString = (datetime) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true
  };
  return new Date(datetime).toLocaleDateString("en-US", options).replace("at ", "");
};
