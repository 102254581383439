import React, { useState, useEffect } from "react";
import { Tabs, Tab, TabList } from "react-web-tabs";
import PropTypes from "prop-types";
import { uniqueId } from "lodash";

const TabListComponent = ({
  tabStructure,
  containerStyle,
  tabListStyle,
  tabListHeaderStyle,
  tabStyle,
  defaultTab,
  focusedTab,
  focusedTabStyle,
  disabledTabStyle,
  mainColor
}) => {
  const initialFocusId = focusedTab || defaultTab;
  const [focusId, setFocusId] = useState(initialFocusId);
  const [defaultId, setDefaultId] = useState(defaultTab);

  useEffect(() => {
    if (focusId !== focusedTab && focusedTab) {
      setFocusId(focusedTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedTab]);

  useEffect(() => {
    if (defaultId !== defaultTab) {
      setDefaultId(defaultTab);
      setFocusId(defaultTab);
    }
    // eslint-disable-next-line
  }, [defaultTab]);

  const RenderTabList = ({ id, display, action, tabStructure }) => {
    return (
      <TabList className={tabListStyle}>
        {display && <p className={tabListHeaderStyle}>{typeof display === "function" ? display() : display}</p>}
        {tabStructure.map((structure) => {
          const { id, display, isDisabled, action, tabStructure } = structure;
          if (tabStructure) {
            return (
              <RenderTabList
                key={id || uniqueId()}
                id={id}
                display={display}
                isDisabled={isDisabled}
                action={action}
                tabStructure={tabStructure}
              />
            );
          }
          return (
            <RenderTab
              key={id}
              id={id}
              display={display}
              isDisabled={isDisabled}
              action={action}
            />
          );
        })}
      </TabList>
    );
  };

  const RenderTab = ({ id, display, isDisabled, action }) => {
    return (
      <Tab
        style={id === focusId && mainColor ? { backgroundColor: mainColor } : {}}
        tabFor={id}
        name={id}
        className={`${tabStyle} ${id === focusId && focusedTabStyle} ${isDisabled && disabledTabStyle}`}
        onClick={(event) => {
          if (typeof action === "function") {
            setFocusId(id);
            action();
          }
        }}
        data-cy-tab-list__tab={id}
      >
        {typeof display === "function" ? display(id === focusId) : display}
      </Tab>
    );
  };

  return (
    <Tabs className={containerStyle}>
      {tabStructure?.map((structure) => {
        const { id, display, action, tabStructure } = structure;
        if (tabStructure) {
          return (
            <RenderTabList
              key={id || uniqueId()}
              id={id}
              display={display}
              action={action}
              tabStructure={tabStructure}
            />
          );
        }
        return (
          <RenderTab
            key={id}
            id={id}
            display={display}
            action={action}
          />
        );
      })}
    </Tabs>
  );
};

export default TabListComponent;

TabListComponent.defaultProps = {
  focusedTab: null,
  defaultTab: null,
  tabListStyle: null,
  tabListHeaderStyle: null,
  containerStyle: null,
  tabStyle: null,
  focusedTabStyle: null,
  disabledTabStyle: "",
  mainColor: ""
};

TabListComponent.propTypes = {
  tabStructure: PropTypes.arrayOf(PropTypes.object).isRequired,
  containerStyle: PropTypes.string,
  tabListStyle: PropTypes.string,
  tabListHeaderStyle: PropTypes.string,
  focusedTabStyle: PropTypes.string,
  disabledTabStyle: PropTypes.string,
  tabStyle: PropTypes.string,
  defaultTab: PropTypes.string,
  focusedTab: PropTypes.string,
  mainColor: PropTypes.string
};
