import React, { useState, useEffect } from "react";
import { AddCircleOutlineTwoTone } from "@material-ui/icons";
import uuid from "uuid";
import Skeleton from "react-loading-skeleton";
import AutoSizeTextArea from "../../components/AutoSizeTextArea/AutoSizeTextArea";
import Style from "./BomManagementFeatureStyle.module.css";
import { useBOMManagementContext } from "../../context/BOMManagementContext/BOMManagementContext";

const BOMManageOperationTable = React.forwardRef(({ onSaveClick, loading }, ref) => {
  const { operationInfo } = useBOMManagementContext();
  const [isEditMode, setIsEditMode] = useState(false);
  const [operationTableInfo, setOperationTableInfo] = useState({});

  const resetOperationTableInfo = () => {
    const newOperationTableInfo = operationInfo
      .sort((a, b) => {
        return a.rank - b.rank;
      })
      .reduce((accumulator, eachOperation) => {
        accumulator[eachOperation.id] = { ...eachOperation };
        return accumulator;
      }, {});
    setOperationTableInfo(newOperationTableInfo);
  };

  useEffect(() => {
    resetOperationTableInfo();
  }, [operationInfo]);

  const handleSaveClick = () => {
    onSaveClick && onSaveClick(operationTableInfo);
    setIsEditMode(false);
    return operationTableInfo;
  };

  const addItem = () => {
    const newOperationTableInfo = { ...operationTableInfo };
    newOperationTableInfo[uuid.v4()] = {
      rank: Object.keys(newOperationTableInfo).length
    };
    setOperationTableInfo(newOperationTableInfo);
  };

  const renderTableRows = (data = {}) => {
    if (loading) {
      return (
        <ul className={`${Style.table_fields} ${Style.flex_column}`}>
          {[...Array(2)].map((_, rowIndex) => {
            return (
              <li key={`${rowIndex}`}>
                <ul>
                  {[...Array(5)].map((_, columnIndex) => {
                    return (
                      <li
                        key={`${rowIndex} ${columnIndex}`}
                        className={columnIndex === 0 ? Style.step_number : ""}
                      >
                        <Skeleton height={28} />
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      );
    }
    return (
      <ul className={`${Style.table_fields} ${Style.flex_column}`}>
        {Object.keys(data).length ? (
          Object.keys(data)
            .sort((aKey, bKey) => {
              return data[aKey].rank - data[bKey].rank;
            })
            .map((eachOperationId, index) => {
              const { name, description } = data[eachOperationId];
              return (
                <li key={eachOperationId}>
                  <ul>
                    <li className={Style.step_number}>{index + 1}</li>

                    <li>
                      <AutoSizeTextArea
                        readOnly={!isEditMode}
                        value={isEditMode ? name : name || "--"}
                        onChangeText={(newText) => {
                          const newOperationTableInfo = { ...operationTableInfo };
                          newOperationTableInfo[eachOperationId].name = newText;
                          setOperationTableInfo(newOperationTableInfo);
                        }}
                      />
                    </li>
                    <li>
                      <AutoSizeTextArea
                        maxRows={3}
                        readOnly={!isEditMode}
                        value={isEditMode ? description : description || "--"}
                        onChangeText={(newText) => {
                          const newOperationTableInfo = { ...operationTableInfo };
                          newOperationTableInfo[eachOperationId].description = newText;
                          setOperationTableInfo(newOperationTableInfo);
                        }}
                      />
                    </li>
                  </ul>
                </li>
              );
            })
        ) : (
          <div className={`${Style.empty_list_row} ${Style.flex_row}`}>
            <p>No operation information</p>
          </div>
        )}
      </ul>
    );
  };

  ref.current = {
    onSaveClick: handleSaveClick,
    addItem,
    setEditingMode: (newIsEditMode) => {
      setIsEditMode(newIsEditMode);
    }
  };

  return (
    <div className={Style.additional_info_container}>
      <div className={`${Style.title_container} ${Style.flex_row}`}>
        <p className={Style.title_text}>Operations</p>
      </div>
      <div className={`${Style.table_container}`}>
        <ul className={`${Style.table_headers} ${Style.flex_row}`}>
          <li className={Style.step_number}>Step #</li>
          <li>Step Name</li>
          <li>Instruction</li>
        </ul>
        {renderTableRows(operationTableInfo)}
        {isEditMode && (
          <button
            className={`${Style.table_button} ${Style.flex_row}`}
            onClick={addItem}
          >
            <AddCircleOutlineTwoTone className={Style.table_button_add_icon} />
            <p>Add another operation</p>
          </button>
        )}
      </div>
    </div>
  );
});

export default BOMManageOperationTable;
