import { useCallback, useState } from "react";
import { useXemelgoClient } from "../../../../services/xemelgo-service";

const errorCode = "Print Tag Issue";

export const useWorkOrderPrintFileWorkflow = (printFileDataConfig) => {
  const [workOrderClient] = useState(useXemelgoClient().getWorkOrderClient());

  const print = useCallback(
    async (workOrdersPayload) => {
      const { headerToPropertyMap, printerName, templateFilePath, delimiter } = printFileDataConfig;

      if (!headerToPropertyMap || Object.keys(headerToPropertyMap).length === 0 || !printerName || !templateFilePath) {
        return {
          error: true,
          errorCode,
          errorMessage:
            "Missing required config data for label printing. Please contact Xemelgo Support for resolution."
        };
      }

      let generatePrintFilePayload;
      try {
        generatePrintFilePayload = {
          headers: Object.keys(headerToPropertyMap),
          payload: workOrdersPayload.map((eachWorkOrder) => {
            return Object.keys(headerToPropertyMap).reduce((accumulator, eachHeaderTitle) => {
              const headerKey = headerToPropertyMap[eachHeaderTitle];
              const headerValue = eachWorkOrder[headerKey] || eachWorkOrder?.custom_properties?.[headerKey] || null;

              if (!headerValue) {
                throw new Error(
                  `Missing information required for label printing: ${headerKey}. Please contact Xemelgo Support for resolution.`
                );
              }
              return { ...accumulator, [eachHeaderTitle]: headerValue };
            }, {});
          }),
          templateFilePath,
          printerName,
          delimiter
        };
      } catch (error) {
        return { error: true, errorCode, errorMessage: error.message };
      }

      await workOrderClient.generateWorkOrderPrintFile(generatePrintFilePayload);
      return generatePrintFilePayload;
    },
    [printFileDataConfig]
  );

  return { print };
};
