import React, { useMemo, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useAppConfigProvider } from "../../../../../../../../services/soft-cache-service";
import AddInventoryFeatureV2 from "../../../../../../../inventory-track-page-feature/features/add-inventory-feature-v2/AddInventoryFeatureV2";
import Style from "./AddButton.module.css";

const SOLUTION_ID = "inventory";
const FEATURE = "trackPage";
export const AddButton = () => {
  const configProvider = useAppConfigProvider(SOLUTION_ID);

  const [showAddInventoryModal, setShowAddInventoryModal] = useState(false);

  const useAddInventoryV2 = useMemo(() => {
    const trackPageConfig = configProvider.getValue(FEATURE, "object") || {};

    return trackPageConfig.onboardingVersion === "V2";
  }, [configProvider]);
  const history = useHistory();

  if (useAddInventoryV2) {
    return (
      <div>
        <button
          type="button"
          className={Style.button}
          onClick={() => {
            setShowAddInventoryModal(true);
          }}
        >
          + Add Item
        </button>
        {showAddInventoryModal && (
          <AddInventoryFeatureV2
            onClose={() => {
              setShowAddInventoryModal(false);
            }}
          />
        )}
      </div>
    );
  }

  return (
    <Link
      className={Style.button}
      to={`${history.location.pathname}/create`}
    >
      + Add Item
    </Link>
  );
};
