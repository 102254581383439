import React, { useState, useEffect, useMemo, Fragment } from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { useXemelgoClient } from "services/xemelgo-service";
import { WorkCenter } from "../work-center/WorkCenter";
import Style from "./WorkCentersGroup.module.css";
import { useScheduleManagementContext } from "../../context/ScheduleManagementContext.context";
import { getWorkCentersToDisplay, isAllExpandedCheck } from "./utils/workCenterGroupUtils";

export const WorkCentersGroup = ({ workCentersGroupId }) => {
  const [loading, setLoading] = useState(true);
  const [allCollapsed, setAllCollapsed] = useState(true);

  // services
  const xemelgoClient = useXemelgoClient();
  const [scheduleOrderClient] = useState(xemelgoClient.getScheduleOrderClient());

  const { currentWorkCenterMap, setCurrentWorkCenterMap, filterInputs } = useScheduleManagementContext();

  const filterWorkCenterIds = useMemo(() => {
    const { workCenters } = filterInputs;

    return workCenters?.value || [];
  }, [filterInputs]);

  const onLoad = async () => {
    const workCenterMap = {};
    const workCenters = await scheduleOrderClient.getWorkCentersByWorkCenterGroupId(workCentersGroupId);
    workCenters.sort((a, b) => {
      return a.identifier.localeCompare(b.identifier);
    });

    workCenters.forEach((workCenter) => {
      const { id, identifier } = workCenter;
      workCenterMap[workCenter.id] = {
        id,
        label: identifier,
        isExpanded: false,
        reloadRequestToggle: Date.now()
      };
    });

    setCurrentWorkCenterMap(workCenterMap);
    setLoading(false);
  };

  // track if all work centers are expanded
  const isAllExpanded = useMemo(() => {
    return isAllExpandedCheck(currentWorkCenterMap);
  }, [currentWorkCenterMap]);

  // update expand state of the work center
  const handleOnClick = (workCenterId, expandedState) => {
    const cloned = _.cloneDeep(currentWorkCenterMap);
    cloned[workCenterId].isExpanded = expandedState;
    setCurrentWorkCenterMap(cloned);
  };

  const workCentersToDisplay = useMemo(() => {
    return getWorkCentersToDisplay(currentWorkCenterMap, filterWorkCenterIds);
  }, [currentWorkCenterMap, filterWorkCenterIds]);

  useEffect(() => {
    setLoading(true);
    onLoad();
  }, [workCentersGroupId]);

  useEffect(() => {
    setAllCollapsed(!isAllExpanded);
  }, [isAllExpanded]);

  return (
    <>
      <div className={Style.small_action_container}>
        <div
          className={Style.header_collapse_btn}
          onClick={() => {
            const cloned = { ...currentWorkCenterMap };
            Object.values(cloned).forEach((workCenter) => {
              workCenter.isExpanded = allCollapsed;
            });
            setCurrentWorkCenterMap(cloned);
          }}
        >
          {`${allCollapsed ? "Expand" : "Collapse"} all work centers`}
        </div>
      </div>
      {loading ? (
        <Skeleton
          className={`${Style.skeleton_header}`}
          count={4}
          height={34}
        />
      ) : (
        <div className={Style.datalist_container}>
          {Object.values(workCentersToDisplay).map((workCenter) => {
            const { id, label, isExpanded, reloadRequestToggle } = workCenter;
            return (
              <WorkCenter
                key={id}
                workCenterId={id}
                label={label}
                isExpanded={isExpanded}
                reloadRequestToggle={reloadRequestToggle}
                onClick={handleOnClick}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

WorkCentersGroup.propTypes = {
  workCentersGroupId: PropTypes.string.isRequired
};
