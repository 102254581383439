import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import Style from "./SideFilter.module.css";

const SideFilter = ({
  onFilterChange,
  filterConfiguration,
  initialFilterValues,
  showHideFiltersControl,
  isLoading,
  isLoadingFilterCategoryIds
}) => {
  const [shouldHideFilter, setShouldHideFilter] = useState(false);
  const [filterValues, setFilterValues] = useState(initialFilterValues);

  useEffect(() => {
    setFilterValues(initialFilterValues);
  }, [initialFilterValues]);

  const updateFilterValues = (filterValueUpdates) => {
    const allCurrentValues = { ...filterValues, ...filterValueUpdates };
    setFilterValues(allCurrentValues);
    onFilterChange({ updates: filterValueUpdates, allValues: allCurrentValues });
  };

  return (
    <div
      className={`${Style.flex_column} ${Style.side_filter_container}`}
      data-cy="side-filter"
    >
      <div className={`${Style.flex_row} ${Style.title_container}`}>
        <p className={`${Style.title}`}>Filter By</p>
        <button
          type="button"
          className={`${Style.hide_filter_button}`}
          onClick={() => {
            setShouldHideFilter((currentValue) => {
              return !currentValue;
            });
          }}
        >
          {showHideFiltersControl && `${shouldHideFilter ? "Show" : "Hide"} Filters`}
        </button>
      </div>
      {!shouldHideFilter && (
        <>
          {filterConfiguration?.categories?.map((filterCategory) => {
            return (
              <div
                key={filterCategory.id}
                className={`${Style.flex_column} ${Style.filter_group}`}
              >
                <p className={`${Style.filter_group_title}`}>{filterCategory.title}</p>
                <div className={`${Style.filter_group_filter}`}>
                  {filterCategory.filters.map((filter) => {
                    return (
                      <div
                        style={{ width: "100%" }}
                        key={`${filterCategory.id}-${filter.id}`}
                      >
                        <p className={Style.filter_title}>{filter.title}</p>
                        {isLoading || isLoadingFilterCategoryIds?.includes(filterCategory.id) ? (
                          <Skeleton
                            height={20}
                            count={4}
                          />
                        ) : (
                          filter.renderComponent(filterValues, updateFilterValues)
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

SideFilter.defaultProps = {
  initialFilterValues: {},
  showHideFiltersControl: true,
  isLoading: false,
  isLoadingFilterCategoryIds: []
};

SideFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  filterConfiguration: PropTypes.shape({
    id: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        filters: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            renderComponent: PropTypes.func,
            filterToApply: PropTypes.func
          })
        )
      })
    )
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialFilterValues: PropTypes.object,
  showHideFiltersControl: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingFilterCategoryIds: PropTypes.arrayOf(PropTypes.string)
};

export default SideFilter;
