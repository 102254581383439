import React from "react";
import PropTypes from "prop-types";
import Style from "./ConfirmItemsList.module.css";

const COLUMNS = 3;

export const ConfirmItemsList = ({ items, solutionDisplayName }) => {
  const lastRowStartIndex = items.length - (items.length % COLUMNS || COLUMNS);

  return (
    <>
      <p className={Style.list_title}>{`List of ${solutionDisplayName}s:`}</p>
      <div className={Style.list_container}>
        {items.map((item, i) => {
          const isBottomRow = i >= lastRowStartIndex;
          return (
            <div
              key={item}
              className={`${Style.list_element} ${isBottomRow ? Style.bottom_row_list_element : ""}`}
            >
              {item}
            </div>
          );
        })}
      </div>
    </>
  );
};

ConfirmItemsList.defaultProps = {
  items: [],
  solutionDisplayName: "item"
};

ConfirmItemsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  solutionDisplayName: PropTypes.string
};
