import React, { useState, useMemo, useEffect, Fragment } from "react";
import { getFormattedDate, getTimezone } from "common/Utilities";
import Spinner from "react-bootstrap/Spinner";
import EditableTableComponent from "../../components/editable-table/EditableTableComponent";
import { CollapsibleListView } from "../../components/collapsible-list-view";
import { useXemelgoClient } from "../../services/xemelgo-service";
import "./style.css";

export const TriggerBasedIdleRuleContent = ({
  ruleConditionsList = [],
  locationDataList = [],
  ruleId,
  onLoad,
  ruleConfig
}) => {
  const [loading, setLoading] = useState(false);
  const [timeToTrigger, setTimeToTrigger] = useState("00:00");
  const [editedTime, setEditedTime] = useState(undefined);
  const [rulePageClient] = useState(useXemelgoClient().getRulePageClient());

  useEffect(() => {
    const condition = ruleConditionsList[0];
    const { tags = {} } = condition;
    const { timeToTrigger } = tags;
    if (timeToTrigger) {
      const formattedDate = getFormattedDate(timeToTrigger, "HH:mm");
      setTimeToTrigger(formattedDate);
    }
  }, ruleConditionsList);

  const buildRuleConditions = (ruleConditions) => {
    let condition = ruleConditions.map((eachCondition) => {
      return {
        id: eachCondition.id,
        locationName: eachCondition.tags.locationName || "All Locations",
        locationId: eachCondition.tags.locationId || "All Locations",
        duration: eachCondition.tags.duration,
        subscribed: eachCondition.hasSubscriptions,
        disableDelete: !eachCondition.tags.locationName
      };
    });
    condition = condition.sort((a, b) => {
      return a.locationName.localeCompare(b.locationName);
    });
    return condition;
  };

  const conditions = useMemo(() => {
    return buildRuleConditions(ruleConditionsList);
  }, [ruleConditionsList]);

  const dropDownOptions = useMemo(() => {
    let options = locationDataList.map((eachLocation) => {
      return { objectTypeName: eachLocation.name, objectTypeId: eachLocation.id };
    });

    conditions.forEach((eachCondition) => {
      options = options.filter((eachOption) => {
        return eachOption.objectTypeId !== eachCondition.locationId;
      });
    });

    return options;
  }, [locationDataList, conditions]);

  const handleSubscriptionChange = async (ruleConditionId, subscribed) => {
    const ruleCondition = ruleConditionsList.find((condition) => {
      return condition.id === ruleConditionId;
    });
    const isLocationCondition = ruleCondition.tags.locationId;
    const subscribedRuleConditionIdList = [];
    const unsubscribedRuleConditionIdList = [];
    if (subscribed) {
      subscribedRuleConditionIdList.push(ruleConditionId);
      if (isLocationCondition) {
        const allLocationRuleCondition = ruleConditionsList.find((each) => {
          return !each.tags.locationId;
        });
        unsubscribedRuleConditionIdList.push(allLocationRuleCondition.id);
      } else {
        const listOfCondition = ruleConditionsList.filter((each) => {
          return each.id !== ruleConditionId;
        });
        listOfCondition.forEach((each) => {
          unsubscribedRuleConditionIdList.push(each.id);
        });
      }
    } else {
      unsubscribedRuleConditionIdList.push(ruleConditionId);
    }
    await rulePageClient.updateSubscriptionForRuleConditions(
      subscribedRuleConditionIdList,
      unsubscribedRuleConditionIdList
    );
    await onLoad();
  };

  const handleAutoFill = (headerId, newValue, data) => {
    const autoFillObject = {
      data,
      changed: true
    };

    if (headerId === "locationName") {
      const itemIndex = dropDownOptions
        .map((i) => {
          return i.objectTypeName;
        })
        .indexOf(newValue);
      const itemId = dropDownOptions[itemIndex].objectTypeId;
      data.locationId = itemId;
    }
    autoFillObject.data = data;

    if (data.name === "" && data.threshold === "") {
      autoFillObject.changed = false;
    }

    return autoFillObject;
  };

  const buildCreateRuleConditionPayloadFromRequest = (requestPayload) => {
    const time = editedTime ? editedTime.split(":") : timeToTrigger.split(":");
    const date = new Date();
    date.setHours(+time[0], +time[1]);
    const formattedTime = date.getTime();
    const tags = {
      duration: requestPayload.duration * 1,
      locationId: requestPayload.locationId,
      locationName: requestPayload.locationName,
      timeToTrigger: formattedTime,
      classes: ruleConfig.classes,
      inventoryIdentifierTemplate: ruleConfig.inventoryIdentifierTemplate
    };
    const conditions = [
      {
        property: "duration",
        op: ">=",
        value: tags.duration
      },
      {
        property: "locationId",
        op: "=",
        value: tags.locationId
      }
    ];
    return { tags, conditions };
  };

  const buildUpdateRuleConditionPayloadFromRequest = (requestPayload) => {
    const time = editedTime ? editedTime.split(":") : timeToTrigger.split(":");
    const date = new Date();
    date.setHours(+time[0], +time[1]);
    const formattedTime = date.getTime();
    const tags = {
      duration: requestPayload.duration * 1,
      locationId: requestPayload.locationId === "All Locations" ? undefined : requestPayload.locationId,
      locationName: requestPayload.locationName === "All Locations" ? undefined : requestPayload.locationName,
      timeToTrigger: formattedTime,
      classes: ruleConfig.classes,
      inventoryIdentifierTemplate: ruleConfig.inventoryIdentifierTemplate
    };
    const conditions = [];
    conditions.push({ property: "duration", op: ">=", value: tags.duration });
    if (tags.locationId) {
      conditions.push({ property: "locationId", op: "=", value: tags.locationId });
    } else {
      conditions.push({ property: "locationId", op: "=", value: "AllLocations" });
    }
    return { id: requestPayload.id, tags, conditions };
  };

  const handleRuleConditionRequests = async (requests) => {
    setLoading(true);
    const tempConditions = [...ruleConditionsList];
    let payload;

    for (const request of requests) {
      const conditionIndex = tempConditions.findIndex((ruleCondition) => {
        return request.id === ruleCondition.id;
      });
      const event = request._event;
      switch (event) {
        case "pending_new":
          payload = buildCreateRuleConditionPayloadFromRequest(request);
          await rulePageClient.createRuleCondition(
            `idle_${payload.tags.locationName.replace(/[^\w\s]/gi, "_")}_${payload.tags.locationId}`,
            payload.tags,
            ruleId,
            payload.conditions,
            "no_repeat"
          );
          break;
        case "pending_update":
          payload = buildUpdateRuleConditionPayloadFromRequest(request);
          await rulePageClient.updateRuleCondition(payload.id, undefined, payload.tags, payload.conditions);
          tempConditions.splice(conditionIndex, 1);
          break;
        case "pending_delete":
          await rulePageClient.removeRuleCondition(request.id, false);
          tempConditions.splice(conditionIndex, 1);
          break;
        default:
          console.log(`Unsupported Event[Name:${event}]`);
          break;
      }
    }

    if (editedTime) {
      const time = editedTime.split(":");
      const date = new Date();
      date.setHours(+time[0], +time[1]);
      const formattedTime = date.getTime();
      for (const condition of tempConditions) {
        delete condition.tags.interval;
        await rulePageClient.updateRuleCondition(
          condition.id,
          undefined,
          { ...condition.tags, timeToTrigger: formattedTime },
          condition.conditions
        );
      }
    }

    setEditedTime(undefined);
    await onLoad();
    const conditions = buildRuleConditions(ruleConditionsList);
    setLoading(false);
    return conditions.map((condition) => {
      return condition.id;
    });
  };

  const headers = [
    {
      displayName: "",
      id: "subscribed",
      cell: {
        input: "switch",
        display: "switch",
        modifiable: true // whether it can be edited after being added
      }
    },
    {
      displayName: "Location",
      id: "locationName",
      cell: {
        input: "dropdown",
        data: dropDownOptions,
        display: "text",
        modifiable: false // whether it can be edited after being added
      }
    },
    {
      displayName: "Idle Duration",
      id: "duration",
      cell: {
        input: "text",
        display: "text",
        modifiable: true, // whether it can be edited after being added
        unit: "hour(s)"
      }
    }
  ];

  const handleValidation = (payloads) => {
    let duration;
    let currentRowId;
    const inputErrorMap = {};

    const validatedObject = {
      errorExists: false,
      errorMap: inputErrorMap
    };
    payloads.forEach((payloadItem) => {
      const inputError = {
        duration: false
      };
      if (payloadItem._event !== "pending_delete") {
        duration = payloadItem.duration;
        currentRowId = payloadItem.id;
        // eslint-disable-next-line no-self-compare
        if (duration === "" || !duration || !(+duration === +duration) || +duration <= 0) {
          inputError.duration = true;
        } else {
          inputError.duration = false;
        }

        inputErrorMap[currentRowId] = inputError;
      }
    });

    const BreakForEachLoop = { exception: "Error exists." };

    try {
      Object.keys(inputErrorMap).forEach((key) => {
        if (inputErrorMap[key].duration) {
          validatedObject.errorExists = true;
          throw BreakForEachLoop;
        } else {
          validatedObject.errorExists = false;
        }
      });
    } catch (e) {
      alert("Please enter valid values in the highlighted field(s).");
      if (e !== BreakForEachLoop) {
        throw e;
      }
    }

    validatedObject.errorMap = inputErrorMap;

    return validatedObject;
  };

  const handleCancel = () => {
    setEditedTime(undefined);
  };

  const renderSettings = () => {
    return (
      <div className="alerts-settings-container">
        <div className="alerts-settings-text">
          Notification Time
          <div className="switch-helper-text">When should the notification be sent?</div>
        </div>
        <div className="early-expiration-container">
          <div>
            <input
              className={editedTime === "" ? "time_input input-error" : "time_input"}
              onChange={(event) => {
                setEditedTime(event.target.value);
              }}
              id="notfication-time"
              type="time"
              value={editedTime !== undefined ? editedTime : timeToTrigger}
            />
            {`(${getTimezone()})`}
          </div>
          {editedTime === "" && <p className="error-text">Enter a time ex. 12:00 AM</p>}
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="loading-circle">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <>
      <>
        <p className="tabbedSectionComponent-contentTitle">Idle</p>
        <p style={{ color: "#343434" }}>
          Sends a notification if an item stays in a location over the set threshold time.
        </p>
      </>
      <div className="note">
        <span>Receive notifications for the locations listed:</span>
      </div>
      <CollapsibleListView
        className="alert-switches"
        key="stock"
        title="Alert Settings"
        content={renderSettings()}
        openAtStart
      />
      <EditableTableComponent
        handleSubscriptionChange={handleSubscriptionChange}
        headers={headers}
        dataList={conditions}
        handleChangesFn={handleRuleConditionRequests}
        handleValidationFn={handleValidation}
        handleAutoFillFn={handleAutoFill}
        hasChanges={!!editedTime}
        cancelFunc={handleCancel}
      />
    </>
  );
};
