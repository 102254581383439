import React, { useCallback } from "react";
import PartVelocityReportForm from "../../components/part-velocity-report-form/PartVelocityReportForm";
import DetectorCoverageReportForm from "../../components/detector-coverage-report-form/DetectorCoverageReportForm";
import { REPORT_TYPES } from "../../../report-detail-feature/data/constant";

const useReportFormFactory = (selectedType, formRef) => {
  const ReportFormComponent = useCallback(
    React.forwardRef(
      (props, ref) => {
        switch (selectedType?.id) {
          case REPORT_TYPES.partVelocity:
            return (
              <PartVelocityReportForm
                {...props}
                ref={ref}
              />
            );
          case REPORT_TYPES.detectorCoverage:
            return (
              <DetectorCoverageReportForm
                {...props}
                ref={ref}
              />
            );
          default:
            return null;
        }
      },
      [selectedType]
    )
  );

  return {
    ReportFormComponent: (props) => {
      return (
        <ReportFormComponent
          ref={formRef}
          {...props}
        />
      );
    }
  };
};

export default useReportFormFactory;
