import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { Delete, Edit } from "@material-ui/icons";
import PropTypes from "prop-types";
import { EditLocationForm } from "../../edit-location-form";
import { Div } from "../../../../../components/div";

export const LocationRecord = ({ appId, featureId, location, showOperations, onRecordUpdated }) => {
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState(null);

  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    const nameSegments = [];
    const parent = location.getParent();
    const locName = location.getName();
    nameSegments.push(locName);
    if (parent) {
      const parentName = parent.getName();
      nameSegments.push(parentName);
    }

    if (!cancelled) {
      setName(nameSegments.join(" - "));
    }

    return cancelCallback;
  }, [location]);
  return (
    <Div className="location-row">
      {showModal && (
        <EditLocationForm
          appId={appId}
          featureId={featureId}
          location={location}
          show={showModal}
          onFormClose={() => {
            return setShowModal(false);
          }}
          onSave={onRecordUpdated}
        />
      )}
      <Div className="location-row-name">{name}</Div>
      <Div
        className="location-row-operations"
        style={{ display: showOperations ? "initial" : "none" }}
      >
        <IconButton
          onClick={() => {
            setShowModal(true);
          }}
        >
          <Edit />
        </IconButton>
        <IconButton>
          <Delete />
        </IconButton>
      </Div>
    </Div>
  );
};

LocationRecord.defaultProps = {
  showOperations: false,
  onRecordUpdated: () => {}
};

LocationRecord.propTypes = {
  location: PropTypes.shape({
    getName: PropTypes.func.isRequired,
    getId: PropTypes.func.isRequired,
    getParent: PropTypes.func.isRequired
  }).isRequired,
  showOperations: PropTypes.bool,
  appId: PropTypes.string.isRequired,
  featureId: PropTypes.string.isRequired,
  onRecordUpdated: PropTypes.func
};
