import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import usePackageTrackPageDataSourceContext from "../../contexts/package-track-page-data-source-context";
import usePackageTrackPageConfigContext from "../../contexts/package-track-page-config-context";
import Style from "./PackageList.module.css";
import PaginatedList from "../../../../components/PaginatedList/PaginatedList";
import useSortableHeader from "../../../../hooks/use-sortable-header";
import OptionalLink from "../../../../components/optional-link/OptionalLink";

import useDebounce from "../../../../hooks/use-debounce";
import usePackageTrackPageStateContext from "../../contexts/package-track-page-state-context";
import { SORT_DIRECTION } from "../../data/constants";
import getValueByType from "./utils/get-value-by-type";

const LoadingComponent = () => {
  return (
    <div className={`${Style.flex_row} ${Style.table_item}`}>
      <div className={`${Style.flex_row} ${Style.table_column}`}>
        <Skeleton
          containerClassName={Style.loading_icon}
          height={24}
        />
      </div>
      <div className={`${Style.flex_row} ${Style.table_column}`}>
        <Skeleton
          containerClassName={Style.loading_icon}
          height={24}
        />
      </div>
      <div className={`${Style.flex_row} ${Style.table_column}`}>
        <Skeleton
          containerClassName={Style.loading_icon}
          height={24}
        />
      </div>
    </div>
  );
};

export const PackageList = ({ location }) => {
  const { freeTextSearchInputText } = usePackageTrackPageStateContext();
  const { locationPackageListMap, isLocationPackageListMapLoading } = usePackageTrackPageDataSourceContext();
  const { listTable } = usePackageTrackPageConfigContext();

  const history = useHistory();

  const freeTextSearchInputTextDebounced = useDebounce(freeTextSearchInputText, 500);

  const filterStringList = useMemo(() => {
    return freeTextSearchInputTextDebounced
      .toLowerCase()
      .split(" ")
      .filter((eachString) => {
        return eachString;
      });
  }, [freeTextSearchInputTextDebounced]);

  const packageList = useMemo(() => {
    return locationPackageListMap[location.id] || [];
  }, [location, locationPackageListMap]);

  const getTitleURLCallbackFn = (item) => {
    return `${history.location.pathname}/detail?itemId=${item.id}`;
  };

  const { defaultOrderBy, defaultOrderDirection } = useMemo(() => {
    const defaultHeader =
      listTable.headers.find((eachHeader) => {
        return eachHeader.defaultSort;
      }) ||
      listTable.headers[0] ||
      {};

    return {
      defaultOrderBy: defaultHeader.id,
      defaultOrderDirection: defaultHeader.defaultDirection || SORT_DIRECTION.descending
    };
  }, [listTable.headers]);

  const { order, orderBy, getSortedData, getSortableHeader } = useSortableHeader(defaultOrderDirection, defaultOrderBy);

  const sortedAndFilteredDataList = useMemo(() => {
    const finalPackageList = packageList.filter((eachWorkOrder) => {
      let shouldInclude = true;
      if (filterStringList.length) {
        const displayString = listTable.headers.reduce((accumulator, eachHeader) => {
          return (
            accumulator + getValueByType(eachWorkOrder[eachHeader.id], eachHeader.type, eachHeader.timeFormat, true)
          );
        }, "");

        filterStringList.forEach((eachFilterString) => {
          if (!displayString.toString().toLowerCase().includes(eachFilterString.toLowerCase())) {
            shouldInclude = false;
          }
        });
      }
      return shouldInclude;
    });
    return getSortedData(finalPackageList, orderBy);
  }, [packageList, order, orderBy, listTable.headers, filterStringList]);

  return (
    <PaginatedList
      // eslint-disable-next-line react/jsx-props-no-spreading
      numItemsPerPage={listTable.pageSize}
      data={sortedAndFilteredDataList}
      header={listTable.headers}
      headerContainerClassName={`${Style.table_header} `}
      renderEmptyList={() => {
        if (isLocationPackageListMapLoading) {
          return [...Array(10)].map((_, index) => {
            return <LoadingComponent key={index} />;
          });
        }
        return (
          <div className={`${Style.flex_row} ${Style.empty_list_container}`}>
            <p className={Style.empty_list_text}>No Data Available</p>
          </div>
        );
      }}
      renderHeader={(eachHeader) => {
        return getSortableHeader(eachHeader, {
          headerContainerClass: `${Style.flex_row} ${Style.table_column}`,
          className: Style.table_header_text
        });
      }}
      renderItem={(eachItem) => {
        if (isLocationPackageListMapLoading) {
          return <LoadingComponent />;
        }
        return (
          <div
            key={eachItem.identifier}
            className={`${Style.flex_row} ${Style.table_item}`}
          >
            {listTable.headers.map((eachHeader, subIndex) => {
              return (
                <OptionalLink
                  key={`${eachItem[eachHeader.id]}${subIndex}`}
                  className={`${Style.flex_row} ${Style.table_column}`}
                  active={!!eachHeader.isNavLink}
                  route={getTitleURLCallbackFn(eachItem)}
                >
                  <div
                    className={`${Style.table_item_text} ${eachHeader.isNavLink && Style.table_item_text_clickable}`}
                  >
                    {getValueByType(eachItem[eachHeader.id], eachHeader.type, eachHeader.timeFormat)}
                  </div>
                </OptionalLink>
              );
            })}
          </div>
        );
      }}
    />
  );
};

PackageList.defaultProps = {
  location: {}
};

PackageList.propTypes = {
  location: PropTypes.object
};
