import React from "react";
import { Route } from "react-router-dom";
import OrderTrackPageFeature from "../../features/order-track-page-feature/OrderTrackPageFeature";
import BulkUpdateOrdersFeature from "../../features/order-track-page-feature/BulkUpdateOrdersFeature";
import OrderDetailPage from "../../components/TrackPageComponents/Order/OrderDetailPage";
import PartDetailPage from "../../components/TrackPageComponents/Order/Part/PartDetailPage";
import AddOrderFeature from "../../features/order-track-page-feature/AddOrderFeature";
import OrderTrackPageFeatureV2 from "../../features/order-track-page-feature-v2";
import { useAppConfigProvider } from "../../services/soft-cache-service";
import { useRoleAuthorization, DEFAULT_ROLES } from "../../apps/hooks/useRoleAuthorization";

const APP_ID = "order";
const TRACK_PAGE_FEATURE = "trackPage";
const ADD_ORDER_FEATURE = "addOrder";

export const WorkOrderStackNavigation = () => {
  const configProvider = useAppConfigProvider(APP_ID);
  const { useV2: useTrackPageV2 } = configProvider.getValue(TRACK_PAGE_FEATURE, "object");

  const { roles: addOrderRoles } = configProvider.getValue(ADD_ORDER_FEATURE, "object");

  const addOrderFeature = { id: ADD_ORDER_FEATURE, roles: addOrderRoles || DEFAULT_ROLES };

  const { isAuthorized } = useRoleAuthorization({
    config: [addOrderFeature]
  });
  return (
    <>
      <Route
        exact
        path="/work-order"
        component={useTrackPageV2 ? OrderTrackPageFeatureV2 : OrderTrackPageFeature}
      />
      {isAuthorized(ADD_ORDER_FEATURE) && (
        <Route
          path="/work-order/create"
          component={AddOrderFeature}
        />
      )}
      <Route
        path="/work-order/detail"
        component={OrderDetailPage}
      />
      <Route
        path="/work-order/part/detail"
        component={PartDetailPage}
      />
      <Route
        path="/work-order/bulk-update"
        component={BulkUpdateOrdersFeature}
      />
    </>
  );
};
