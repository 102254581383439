import React from "react";
import PropTypes from "prop-types";
import Style from "./RecentItemsDisplay.module.css";

const RecentItemsDisplay = ({ label, value }) => {
  return (
    <div className={`${Style.recent_items_container} ${Style.flex_direction_row}`}>
      <span className={Style.horizontal_line} />
      <div className={`${Style.recent_item_count_display_container} ${Style.flex_direction_row}`}>
        <span className={Style.recent_items_label}>{label}</span>
        <span className={Style.recent_item_count_value}> {value} </span>
      </div>
      <span className={Style.horizontal_line} />
    </div>
  );
};

export default RecentItemsDisplay;

RecentItemsDisplay.defaultProps = {
  label: "",
  value: 0
};

RecentItemsDisplay.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number
};
