import React from "react";
import PropTypes from "prop-types";
import Style from "./ListHeader.module.css";

export const ListHeader = ({ onClick, label, headerRowContainerClassName }) => {
  return (
    <div
      className={`${Style.table_column} ${headerRowContainerClassName}`}
      onClick={onClick}
    >
      <p className={`${Style.table_header_text} ${onClick && Style.table_header_text_active}`}>{label}</p>
    </div>
  );
};

ListHeader.defaultProps = {
  onClick: null,
  label: "",
  headerRowContainerClassName: ""
};

ListHeader.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.number]),
  headerRowContainerClassName: PropTypes.string
};
