import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import useMixpanelContext from "../../context/mixpanel-context";
import { KIOSK, KIOSK_STEPS } from "../../constants/mixpanel-constant/kiosk";
import Kiosk from "../../features/kiosk";

export const KioskStackNavigation = () => {
  const { sendMixPanelEvent } = useMixpanelContext();

  useEffect(() => {
    sendMixPanelEvent(KIOSK, KIOSK_STEPS.ENTRY);
  }, []);

  return (
    <Route
      path="/check-out"
      component={Kiosk}
    />
  );
};
