import React, { useMemo } from "react";
import { CloseRounded, LocationOn } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import Style from "./SearchResultContent.module.css";
import LoadingTable from "../../components/loading-table";
import useManagementDashboardStateContext from "../../contexts/management-dashboard-state-context";
import useManagementDashboardDataSourceContext from "../../contexts/management-dashboard-data-source-context";

import { STATUS_COLOR_MAP } from "../../data/constants";

export const SearchResultContent = () => {
  const history = useHistory();

  const {
    mapRef,
    selectedSearchResult,
    setSelectedSearchResult,
    setShowWorkOrderResults,
    isWorkOrderResultsLoading,
    activeLocationTreeMap
  } = useManagementDashboardStateContext();

  const { setSearchResults, searchWorkOrderResults, setSearchWorkOrderResults } =
    useManagementDashboardDataSourceContext();

  const clearSearchResult = () => {
    setSelectedSearchResult({});
    setShowWorkOrderResults(false);
    setSearchWorkOrderResults([]);
    setSearchResults([]);
  };

  const workOrderSearchResultsGroupByLocation = useMemo(() => {
    const resultMap = {};
    searchWorkOrderResults.forEach(({ lastDetectedLocation = {}, ...data }) => {
      const lastDetectedLocationId = lastDetectedLocation.id;
      if (lastDetectedLocationId) {
        if (!resultMap[lastDetectedLocationId]) {
          resultMap[lastDetectedLocationId] = [];
        }
        resultMap[lastDetectedLocationId].push({ ...data });
      }
    });
    return Object.keys(resultMap).map((eachLocationId) => {
      return {
        id: eachLocationId,
        name: activeLocationTreeMap[eachLocationId]?.name,
        category: activeLocationTreeMap[eachLocationId]?.category,
        workOrders: resultMap[eachLocationId]
      };
    });
  }, [searchWorkOrderResults, activeLocationTreeMap]);

  return (
    <>
      <div className={Style.information_header}>
        <div className={`${Style.flex_row} ${Style.information_title_container}`}>
          <p className={Style.information_title}>{`Search Results for '${selectedSearchResult.label}'`}</p>
          <div
            className={Style.search_results_close_button}
            onClick={clearSearchResult}
          >
            <CloseRounded />
          </div>
        </div>
      </div>
      <div className={`${Style.flex_column}`}>
        {isWorkOrderResultsLoading ? (
          <LoadingTable />
        ) : (
          workOrderSearchResultsGroupByLocation.map((eachLocation) => {
            return (
              <div
                className={`${Style.flex_column} ${Style.location_result_group}`}
                key={eachLocation.id}
                onMouseEnter={() => {
                  mapRef.current.hoverLocation(eachLocation.id);
                }}
                onMouseLeave={() => {
                  mapRef.current.resetHover();
                }}
              >
                <div
                  className={`${Style.flex_row}`}
                  style={{ alignItems: "center" }}
                >
                  <div className={`${Style.flex_row} ${Style.location_point_icon}`}>
                    <LocationOn />
                  </div>
                  <p className={Style.location_result_label}>{`${eachLocation.category}:`}</p>
                  <p className={Style.location_result_label}>{eachLocation.name}</p>
                </div>
                {eachLocation.workOrders.map((eachWorkOrder) => {
                  const { hasCurrentOperation = {} } = eachWorkOrder;
                  const { status, start_status, severity, delayed_start_severity } = hasCurrentOperation;
                  return (
                    <div
                      key={eachWorkOrder.id}
                      className={`${Style.flex_row} ${Style.location_work_order_group}`}
                    >
                      <div className={`${Style.flex_row} ${Style.location_work_order}`}>
                        <div className={Style.flex_row}>
                          <p className={Style.location_work_order_label}>WO #:</p>
                          <div
                            className={`${Style.location_work_order_label} ${Style.link}`}
                            onClick={() => {
                              if (eachWorkOrder.itemId) {
                                history.push(`/work-order/detail?itemId=${eachWorkOrder.itemId}`);
                              }
                            }}
                          >
                            {eachWorkOrder.identifier}
                          </div>
                        </div>
                        <div className={`${Style.flex_row} ${Style.location_work_order_status}`}>
                          {status === "Time Exceeded" && (
                            <div
                              className={Style.information_title_status}
                              style={{
                                marginLeft: 0,
                                backgroundColor: STATUS_COLOR_MAP[severity]
                              }}
                            >
                              {`${severity} - Time Exceeded`}
                            </div>
                          )}
                          {start_status === "Delayed" && (
                            <div
                              className={Style.information_title_status}
                              style={{
                                marginLeft: 0,
                                backgroundColor: STATUS_COLOR_MAP[delayed_start_severity]
                              }}
                            >
                              {`${delayed_start_severity} - Late To Start`}
                            </div>
                          )}
                          {eachWorkOrder.priority >= 1 && eachWorkOrder.priority <= 9 && (
                            <div
                              className={Style.information_title_status}
                              style={{
                                marginLeft: 0,
                                backgroundColor: STATUS_COLOR_MAP.Expedited
                              }}
                            >
                              Expedited
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })
        )}
      </div>
    </>
  );
};
