import React, { useContext, useState, useEffect } from "react";
import { useAppConfigProvider } from "../../../../../../services/soft-cache-service";
import { AddPageInputTypeMap, PRINT_TYPES } from "../../../../../../data/constants";

const FEATURE_ID = "addInventory";
const APP_ID = "inventory";

// TODO: clean up these config as all customers move to use formSections
const ENABLE_ONBOARDING_TO_LOCATION = "enableOnboardingToLocation";
const HIDE_SELECT_ONBOARDING_LOCATION_INPUT = "hideSelectOnboardingLocationInput";
const ONBOARDING_TO_LOCATION_REQUIRED = "onboardingToLocationRequired";
const POSSIBLE_ONBOARDING_LOCATION_CATEGORIES = "possibleOnboardingLocationCategories";
const ITEM_TYPE_INFO = "itemTypeInfo";
const ADDITIONAL_ITEM_PROPERTIES = "additionalItemProperties";
const SELECT_ITEM_TYPE_DISABLED = "selectItemTypeDisabled";
const ITEM_TYPE_PROPERTIES_TO_QUERY = "itemTypePropertiesToQuery";

/** ------------------------------- */
const ONBOARDING_OPTIONS = "onboardingOptions";
const TARGET_LOCATION_ENABLED = "targetLocationEnabled";
const DEFAULT_ONBOARDING_TAB = "defaultOnboardingTab";
const IS_TAB_INPUT_EDITABLE = "isTagInputEditable";
const CUSTOM_TEMPLATE = "customTemplate";
const ENCODE_TAG = "encodeTag";
const GET_TAGS_API_URL = "getTagsApiUrl";
const CUSTOM_ITEM_IDENTIFIER_TEMPLATE = "customItemIdentifierTemplate";
const PRINT_ONLY = "printOnly";
const IS_BULK_CREATE_ENABLED = "isBulkCreateEnabled";
const USE_VENDOR_CLIENT_INFO = "useVendorClientInfo";
const IS_PRINT_ENABLED = "isPrintEnabled";
const PRINT_TYPE = "printType";
const FORM_SECTIONS = "formSections";
const IS_PRINT_LATER_ENABLED = "isPrintLaterEnabled";
const GENERATE_IDENTIFIER_OPTIONS = "generateIdentifierOptions";
const EPC_PREFIX = "epcPrefix";
const SHOW_LAST_ENTRIES_CHECKBOX = "showSaveEntriesCheckbox";

const defaultOnboardingTabs = [
  {
    id: "add",
    label: "Add Existing Tag"
  },
  {
    id: "print",
    label: "Print New Tag"
  }
];

const initialState = {
  enableOnboardingToLocation: false,
  hideSelectOnboardingLocationInput: false,
  onboardingToLocationRequired: false,
  possibleOnboardingLocationCategories: [],
  itemTypeInfo: {},
  additionalItemProperties: [],
  selectItemTypeDisabled: false,
  itemTypePropertiesToQuery: [],
  printQuantityInfo: {},
  generateIdentiferOptions: {},

  onboardingOptions: [],
  targetLocationEnabled: false,
  defaultOnboardingTab: "print",
  isTagInputEditable: false,
  customTemplate: "",
  encodeTag: false,
  printOnly: false,
  getTagsApiUrl: "https://dvzoza0ah1.execute-api.us-west-2.amazonaws.com/v1/devices/",
  customItemIdentifierTemplate: "",
  bartenderConfig: {},
  isBulkCreateEnabled: false,
  isPrintEnabled: false,
  useVendorClientInfo: false,
  printType: PRINT_TYPES.ZPL,
  formSections: [],
  formFields: {},
  isPrintLaterEnabled: false,
  epcPrefix: "",
  showSaveEntriesCheckbox: false
};

const AddInventoryFeatureV2ConfigContext = React.createContext({
  enableOnboardingToLocation: initialState.enableOnboardingToLocation,
  hideSelectOnboardingLocationInput: initialState.hideSelectOnboardingLocationInput,
  onboardingToLocationRequired: initialState.onboardingToLocationRequired,
  possibleOnboardingLocationCategories: initialState.possibleOnboardingLocationCategories,
  itemTypeInfo: initialState.itemTypeInfo,
  additionalItemProperties: initialState.additionalItemProperties,
  selectItemTypeDisabled: initialState.selectItemTypeDisabled,
  itemTypePropertiesToQuery: initialState.itemTypePropertiesToQuery,
  printQuantity: initialState.printQuantityInfo,

  onboardingOptions: initialState.onboardingOptions,
  targetLocationEnabled: initialState.targetLocationEnabled,
  defaultOnboardingTab: initialState.defaultOnboardingTab,
  isTagInputEditable: initialState.isTagInputEditable,
  customTemplate: initialState.customTemplate,
  encodeTag: initialState.encodeTag,
  getTagsApiUrl: initialState.getTagsApiUrl,
  customItemIdentifierTemplate: initialState.customItemIdentifierTemplate,
  bartenderConfig: initialState.bartenderConfig,
  printOnly: initialState.printOnly,
  isBulkCreateEnabled: initialState.isBulkCreateEnabled,
  isPrintEnabled: initialState.isPrintEnabled,
  useVendorClientInfo: initialState.useVendorClientInfo,
  isPrintLaterEnabled: initialState.isPrintLaterEnabled,
  generateIdentiferOptions: initialState.generateIdentiferOptions,
  epcPrefix: initialState.epcPrefix,
  isLoading: true,
  showSaveEntriesCheckbox: initialState.showSaveEntriesCheckbox
});

export const useAddInventoryFeatureV2ConfigContext = () => {
  return useContext(AddInventoryFeatureV2ConfigContext);
};

export const AddInventoryFeatureV2ConfigContextProvider = ({ children }) => {
  const configProvider = useAppConfigProvider(APP_ID);
  const [isLoading, setIsLoading] = useState(true);

  const [onboardingOptions, setOnboardingOptions] = useState(initialState.onboardingOptions);
  const [targetLocationEnabled, setTargetLocationEnabled] = useState(initialState.targetLocationEnabled);
  const [defaultOnboardingTab, setDefaultOnboardingTab] = useState(initialState.defaultOnboardingTab);
  const [isTagInputEditable, setIsTagInputEditable] = useState(initialState.isTagInputEditable);
  const [customTemplate, setCustomTemplate] = useState(initialState.customTemplate);
  const [encodeTag, setEncodeTag] = useState(initialState.encodeTag);
  const [printOnly, setPrintOnly] = useState(initialState.printOnly);
  const [getTagsApiUrl, setGetTagsApiUrl] = useState(initialState.getTagsApiUrl);
  const [customItemIdentifierTemplate, setcustomItemIdentifierTemplate] = useState(
    initialState.customItemIdentifierTemplate
  );
  const [bartenderConfig, setBartenderConfig] = useState(initialState.bartenderConfig);
  const [isBulkCreateEnabled, setIsBulkCreateEnabled] = useState(initialState.isBulkCreateEnabled);
  const [isPrintEnabled, setIsPrintEnabled] = useState(initialState.isPrintEnabled);
  const [printType, setPrintType] = useState(initialState.printType);
  const [formSections, setFormSections] = useState(initialState.formSections);
  const [isPrintLaterEnabled, setIsPrintLaterEnabled] = useState(initialState.isPrintLaterEnabled);
  const [generateIdentiferOptions, setGenerateIdentiferOptions] = useState(initialState.generateIdentiferOptions);
  const [useVendorClientInfo, setUseVendorClientInfo] = useState(initialState.useVendorClientInfo);
  const [epcPrefix, setEpcPrefix] = useState(initialState.epcPrefix);
  const [showSaveEntriesCheckbox, setShowSaveEntriesCheckbox] = useState(initialState.showSaveEntriesCheckbox);

  /**
   * This function support backward compatible for the add inventory v2 page with the existing configuration to
   * avoid breaking current customers.
   * @param {*} addInventoryConfiguration
   * @returns
   */
  const convertConfigsToFormSections = (addInventoryConfiguration) => {
    const newEnableOnboardingToLocation =
      addInventoryConfiguration[ENABLE_ONBOARDING_TO_LOCATION] || initialState.enableOnboardingToLocation;
    const newHideSelectOnboardingLocationInput =
      addInventoryConfiguration[HIDE_SELECT_ONBOARDING_LOCATION_INPUT] ||
      initialState.hideSelectOnboardingLocationInput;
    const newOnboardingToLocationRequired =
      addInventoryConfiguration[ONBOARDING_TO_LOCATION_REQUIRED] || initialState.onboardingToLocationRequired;
    const newPossibleOnboardingLocationCategories =
      addInventoryConfiguration[POSSIBLE_ONBOARDING_LOCATION_CATEGORIES] ||
      initialState.possibleOnboardingLocationCategories;
    const newItemTypeInfo = addInventoryConfiguration[ITEM_TYPE_INFO] || initialState.itemTypeInfo;
    const newAdditionalItemProperties =
      addInventoryConfiguration[ADDITIONAL_ITEM_PROPERTIES] || initialState.additionalItemProperties;
    const newSelectItemTypeDisabled =
      addInventoryConfiguration[SELECT_ITEM_TYPE_DISABLED] || initialState.selectItemTypeDisabled;
    const newItemTypePropertiesToQuery =
      addInventoryConfiguration[ITEM_TYPE_PROPERTIES_TO_QUERY] || initialState.itemTypePropertiesToQuery;
    const newUseVendorClientInfo =
      addInventoryConfiguration[USE_VENDOR_CLIENT_INFO] ?? initialState.useVendorClientInfo;

    const itemTypeSection = [];
    const additionalItemPropertiesSection = [{ id: "print_quantity", label: "Number of Tags", index: 99 }];

    if (Object.keys(newItemTypeInfo).length) {
      const itemTypeField = {
        id: "item_number",
        isRequired: true,
        index: 0,
        options: [],
        type: newSelectItemTypeDisabled ? AddPageInputTypeMap.INPUT : AddPageInputTypeMap.SEARCH,
        argsForAPI: {
          fields: newItemTypePropertiesToQuery,
          queryVendorClientInfo: newUseVendorClientInfo
        },
        validateCSVInputWithOptions: !newSelectItemTypeDisabled
      };
      const { label, placeholder, propertiesMap = {} } = newItemTypeInfo;
      itemTypeField.label = label;
      itemTypeField.placeholder = placeholder;
      itemTypeSection.push(itemTypeField);
      Object.keys(propertiesMap).forEach((propertyId, index) => {
        const { label: propertyLabel } = propertiesMap[propertyId];

        // if (propertyLabel) {
        itemTypeSection.push({
          id: propertyId,
          label: propertyLabel,
          index: index + 2,
          isReadOnly: true,
          metaDataOf: "item_number"
        });
        // }
      });
    }

    if (newAdditionalItemProperties.length) {
      newAdditionalItemProperties.forEach((property, index) => {
        const { id, label, isRequired } = property;
        additionalItemPropertiesSection.push({
          id,
          label,
          index,
          isRequired
        });
      });
    }

    if (newEnableOnboardingToLocation) {
      additionalItemPropertiesSection.push({
        id: "location",
        index: additionalItemPropertiesSection.length + 1,
        label: "Onboarding Location",
        options: [],
        isRequired: newOnboardingToLocationRequired,
        type: AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API,
        isHidden: newHideSelectOnboardingLocationInput,
        argsForAPI: {
          filters: {
            categories: newPossibleOnboardingLocationCategories
          }
        },
        validateCSVInputWithOptions: true
      });
    }
    return [itemTypeSection, additionalItemPropertiesSection];
  };

  useEffect(() => {
    setIsLoading(true);
    const addInventoryConfiguration = configProvider.getValue(FEATURE_ID, "object");
    const addInventoryConfigurationV2 = addInventoryConfiguration.v2 || {};

    const { inventoryBartenderConfig = {} } = configProvider.getModelMap();

    const newOnboardingOptions = addInventoryConfiguration[ONBOARDING_OPTIONS] || initialState.onboardingOptions;
    const newTargetLocationEnabled =
      addInventoryConfiguration[TARGET_LOCATION_ENABLED] || initialState.targetLocationEnabled;
    const newDefaultOnboardingTab =
      addInventoryConfiguration[DEFAULT_ONBOARDING_TAB] || initialState.defaultOnboardingTab;
    const newIsTagInputEditable = addInventoryConfiguration[IS_TAB_INPUT_EDITABLE] || initialState.isTagInputEditable;
    const newCustomTemplate = addInventoryConfiguration[CUSTOM_TEMPLATE] || initialState.customTemplate;
    const newEncodeTag = addInventoryConfiguration[ENCODE_TAG] || initialState.encodeTag;
    const newGetTagsApiUrl = addInventoryConfiguration[GET_TAGS_API_URL] || initialState.getTagsApiUrl;
    const newCustomItemIdentifierTemplate =
      addInventoryConfiguration[CUSTOM_ITEM_IDENTIFIER_TEMPLATE] || initialState.customItemIdentifierTemplate;
    const newPrintOnly = addInventoryConfiguration[PRINT_ONLY] || initialState.printOnly;
    const newIsBulkCreateEnabled =
      addInventoryConfiguration[IS_BULK_CREATE_ENABLED] || initialState.isBulkCreateEnabled;
    const newBartenderConfig = inventoryBartenderConfig.def || initialState.bartenderConfig;
    const newIsPrintEnabled = addInventoryConfiguration[IS_PRINT_ENABLED] || initialState.isPrintEnabled;
    const newFormSections =
      addInventoryConfigurationV2[FORM_SECTIONS] ||
      convertConfigsToFormSections(addInventoryConfiguration) ||
      initialState.formSections;
    const newPrintType = addInventoryConfiguration[PRINT_TYPE] || initialState.printType;
    const onboardingModeTabs = defaultOnboardingTabs.filter((tab) => {
      return newOnboardingOptions.includes(tab.id);
    });
    const newIsPrintLaterEnabled =
      addInventoryConfigurationV2[IS_PRINT_LATER_ENABLED] || initialState.isPrintLaterEnabled;
    const newGenerateIdentifierOptions =
      addInventoryConfigurationV2[GENERATE_IDENTIFIER_OPTIONS] || initialState.generateIdentiferOptions;
    const newUseVendorClientInfo =
      addInventoryConfiguration[USE_VENDOR_CLIENT_INFO] ?? initialState.useVendorClientInfo;
    const newEpcPrefix = addInventoryConfigurationV2[EPC_PREFIX] || initialState.epcPrefix;
    const newShowSaveEntriesCheckbox =
      addInventoryConfiguration[SHOW_LAST_ENTRIES_CHECKBOX] || initialState.showSaveEntriesCheckbox;

    setFormSections(newFormSections);
    setOnboardingOptions(onboardingModeTabs);
    setTargetLocationEnabled(newTargetLocationEnabled);
    setDefaultOnboardingTab(newDefaultOnboardingTab);
    setIsTagInputEditable(newIsTagInputEditable);
    setCustomTemplate(newCustomTemplate);
    setEncodeTag(newEncodeTag);
    setGetTagsApiUrl(newGetTagsApiUrl);
    setcustomItemIdentifierTemplate(newCustomItemIdentifierTemplate);
    setBartenderConfig(newBartenderConfig || initialState.bartenderConfig);
    setPrintOnly(newPrintOnly);
    setIsBulkCreateEnabled(newIsBulkCreateEnabled);
    setIsPrintEnabled(newIsPrintEnabled);
    setPrintType(newPrintType);
    setIsPrintLaterEnabled(newIsPrintLaterEnabled);
    setGenerateIdentiferOptions(newGenerateIdentifierOptions);
    setUseVendorClientInfo(newUseVendorClientInfo);
    setEpcPrefix(newEpcPrefix);
    setShowSaveEntriesCheckbox(newShowSaveEntriesCheckbox);

    setIsLoading(false);
  }, [configProvider]);

  return (
    <AddInventoryFeatureV2ConfigContext.Provider
      value={{
        isLoading,
        onboardingOptions,
        targetLocationEnabled,
        defaultOnboardingTab,
        isTagInputEditable,
        customTemplate,
        encodeTag,
        getTagsApiUrl,
        customItemIdentifierTemplate,
        bartenderConfig,
        printOnly,
        isBulkCreateEnabled,
        isPrintEnabled,
        formSections,
        printType,
        isPrintLaterEnabled,
        generateIdentiferOptions,
        useVendorClientInfo,
        epcPrefix,
        showSaveEntriesCheckbox
      }}
    >
      {children}
    </AddInventoryFeatureV2ConfigContext.Provider>
  );
};
