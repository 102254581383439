import { ConfigClientProvider } from "xemelgo-config-client";
import CONFIGURATION from "../configuration.json";
import { LocalCacheService } from "./local-cache-service";
import { AmplifyConfigurationProvider } from "../domains/amplify-configuration-provider";
import { XemelgoService } from "./XemelgoService";

const CustomerConfigurationKey = "CustomerConfigurationKey";
const ConfigurationExpirationTimeKey = "Configuration_Exp";
const CustomerConfigurationMaxValidDuration = 43200000; // 12 hours
const CustomerConfigurationTestKey = "CustomerConfigurationTestKey";

const ClientConfigurationService = {
  getClient() {
    const configServiceApiConfig = CONFIGURATION.ConfigurationService_endpoints;
    const authConfig = CONFIGURATION.AuthConfiguration;
    const userConfig = LocalCacheService.loadUserConfig();
    const config = AmplifyConfigurationProvider.buildApiConfiguration(userConfig, authConfig, configServiceApiConfig);

    return ConfigClientProvider.getClient(config);
  },

  getConfig() {
    const ConfigurationServiceClient = ClientConfigurationService.getClient();
    const configClient = ConfigurationServiceClient.getConfigClient();
    return configClient.getConfig();
  },

  getTestClient() {
    const configServiceApiConfig = CONFIGURATION.ConfigurationService_endpoints_test;
    const authConfig = CONFIGURATION.AuthConfiguration;
    const userConfig = LocalCacheService.loadUserConfig();
    const config = AmplifyConfigurationProvider.buildApiConfiguration(userConfig, authConfig, configServiceApiConfig);

    return ConfigClientProvider.getClient(config);
  },

  getTestConfig() {
    const ConfigurationServiceClient = ClientConfigurationService.getTestClient();
    const configClient = ConfigurationServiceClient.getConfigClient();
    return configClient.getConfig();
  },

  configure() {
    return new Promise(function (resolve, reject) {
      const ConfigurationServiceClient = ClientConfigurationService.getClient();
      const configClient = ConfigurationServiceClient.getConfigClient();
      configClient
        .getConfig()
        .then((clientConfiguration) => {
          const currentDate = new Date();
          const currentTime = currentDate.getTime();
          const cacheExpirationTime = currentTime + CustomerConfigurationMaxValidDuration;
          sessionStorage.setItem(ConfigurationExpirationTimeKey, cacheExpirationTime);
          sessionStorage.setItem(CustomerConfigurationKey, JSON.stringify(clientConfiguration));
          XemelgoService.getClient().setScenario(sessionStorage.getItem("scenario") || "0");
          if (
            clientConfiguration &&
            clientConfiguration.configData &&
            clientConfiguration.configData.webClient &&
            clientConfiguration.configData.webClient.testMode
          ) {
            ClientConfigurationService.configureTestMode();
          }
          resolve(clientConfiguration);
        })
        .catch((err) => {
          console.log("Error!", err);
          reject(err);
        });
    });
  },

  configureTestMode() {
    return new Promise(function (resolve, reject) {
      const ConfigurationServiceClientTest = ClientConfigurationService.getTestClient();
      const configClientTest = ConfigurationServiceClientTest.getConfigClient();
      configClientTest
        .getConfig()
        .then((clientConfiguration) => {
          sessionStorage.setItem(CustomerConfigurationTestKey, JSON.stringify(clientConfiguration));
          resolve(clientConfiguration);
        })
        .catch((err) => {
          console.log("Error!", err);
          reject(err);
        });
    });
  }
};

export { ClientConfigurationService };
