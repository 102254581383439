import { BaseConfigurationProvider } from "./base-configuration-provider";

/**
 * TODO: This is workaround to backward compatibility support for old customer configuration
 * @param serviceId
 * @param configuration
 * @returns {string|*}
 */
const getServiceName = (serviceId, configuration) => {
  const { name } = configuration;
  if (name) {
    return name;
  }

  switch (serviceId) {
    case "xGraph":
      return "XemelgoService";
    case "xMain":
    case "apiGateway":
      return "xemelgo";
    default:
      return serviceId;
  }
};

export class ServiceConfigurationProvider extends BaseConfigurationProvider {
  constructor(serviceId, configuration) {
    super(configuration);
    this.serviceId = serviceId;
    const serviceName = getServiceName(serviceId, configuration);
    this.configuration = { ...configuration, ...{ name: serviceName } };
  }

  static parse(serviceId, configuration) {
    return new ServiceConfigurationProvider(serviceId, configuration);
  }

  getServiceId() {
    return this.serviceId;
  }

  getConfiguration() {
    return this.configuration;
  }
}
