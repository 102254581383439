import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import Overview from "../../Overview";
import Chart from "../Chart";
import InventoryOverviewComponentStyle from "./InventoryOverviewComponent.module.css";
import "react-loading-skeleton/dist/skeleton.css";

const InventoryOverviewComponent = ({ chartDataList, overviewDataList, title, itemTypeLabel }) => {
  const LeftComponent = () => {
    const location = null;
    return (
      <Chart
        title={title}
        itemTypeLabel={itemTypeLabel}
        location={location}
        data={chartDataList}
      />
    );
  };

  const RightComponent = () => {
    const dataList = overviewDataList.length ? overviewDataList : Array(4).fill({});
    return (
      <div className={InventoryOverviewComponentStyle.right_container}>
        {dataList.map(({ id, title, value }) => {
          return (
            <div
              key={id}
              className={InventoryOverviewComponentStyle.overview_item_container}
            >
              <div>
                <p className={InventoryOverviewComponentStyle.overview_title}>{title || <Skeleton />}</p>
                <p className={InventoryOverviewComponentStyle.overview_value}>{value || <Skeleton />}</p>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Overview
      leftComponent={<LeftComponent />}
      rightComponent={<RightComponent />}
    />
  );
};

export default InventoryOverviewComponent;

InventoryOverviewComponent.defaultProps = {
  chartDataList: [],
  overviewDataList: [],
  title: ""
};

InventoryOverviewComponent.propTypes = {
  title: PropTypes.string,
  chartDataList: PropTypes.arrayOf(PropTypes.array),
  overviewDataList: PropTypes.arrayOf(PropTypes.object)
};
