/* eslint-disable no-useless-escape */
import { useState } from "react";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import { useXemelgoAppsyncClient } from "../../../../services/xemelgo-appsync-service";
import { useWorkOrderPrintFileWorkflow } from "../use-work-order-print-file-workflow/useWorkOrderPrintFileWorkflow";

const ERROR_NAME = {
  order: "Work Order",
  trackerSerial: "Tracker Serial"
};

const WO_IDENTIFIER_KEY = "order_number";
const SENSOR_PROFILE_VID_KEY = "tracker_serial";

const SPECIFIC_ERROR = "Specific Error";

const throwSpecificError = (errorMessage) => {
  const error = new Error(errorMessage);
  error.name = SPECIFIC_ERROR;
  throw error;
};

const useCreateWorkOrderWorkflow = (reuseSensorProfileConfig, includePrintFileWorkFlow, printFileDataConfig) => {
  const [appsyncWorkOrderClient] = useState(useXemelgoAppsyncClient().getWorkOrderClient());
  const [taskClient] = useState(useXemelgoClient().getTaskClient());
  const [publishClient] = useState(useXemelgoClient().getPublishClient());
  const [sensorProfileClient] = useState(useXemelgoClient().getSensorProfileClient());
  const [trackingSessionClient] = useState(useXemelgoClient().getTrackingSessionClient());

  const { print: printFileWorkflow } = useWorkOrderPrintFileWorkflow(printFileDataConfig);

  const createWorkOrders = async (workOrdersPayload, allowReuseTrackerSerial) => {
    return appsyncWorkOrderClient.createWorkOrderSet(
      workOrdersPayload.map((eachWorkOrderPayload) => {
        return { ...eachWorkOrderPayload, reuse_tracker_serial: allowReuseTrackerSerial };
      })
    );
  };

  const getTaskInformationByIdentifier = async (workOrderIdentifiers, shouldIncludeTrackingSessionId) => {
    const { tasks = [] } = await taskClient.getTaskByIdentifiers(
      workOrderIdentifiers,
      shouldIncludeTrackingSessionId,
      shouldIncludeTrackingSessionId
    );
    return tasks;
  };

  const getDuplicateTrackerSerials = async (vids) => {
    return sensorProfileClient.activeSensorProfilesCheckByVid(
      vids.map((vid) => {
        return vid.toUpperCase();
      })
    );
  };

  const getDuplicationErrorMessage = (errorName, duplicatedValues) => {
    const singular = duplicatedValues.length <= 1;

    return `The following ${singular ? `${errorName}` : `${errorName}s`} entered already ${
      singular ? "exists" : "exist"
    } - ${duplicatedValues.join(", ")}`;
  };

  const getRepeatErrorMessage = (errorName, duplicatedValues) => {
    const singular = duplicatedValues.length <= 1;
    return `The following ${singular ? errorName : `${errorName}s`} entered ${
      singular ? "is" : "are"
    } repeated - ${duplicatedValues.join(", ")}`;
  };

  const getDuplicateError = (
    duplicatedWorkOrderIdentifiers,
    duplicatedSensorProfiles,
    repeatedWorkOrderIdentifiers,
    repeatedSensorProfiles
  ) => {
    return {
      error:
        !!duplicatedSensorProfiles.length ||
        !!duplicatedWorkOrderIdentifiers.length ||
        repeatedWorkOrderIdentifiers.length ||
        repeatedSensorProfiles.length,
      errorCode: repeatedWorkOrderIdentifiers.length
        ? `Repeated ${ERROR_NAME.order}`
        : repeatedSensorProfiles.length
        ? `Repeated ${ERROR_NAME.trackerSerial}`
        : duplicatedWorkOrderIdentifiers.length
        ? `Duplicate ${ERROR_NAME.order}`
        : duplicatedSensorProfiles.length
        ? `Duplicate ${ERROR_NAME.trackerSerial}`
        : "Repeat value",
      errorMessage: repeatedWorkOrderIdentifiers.length
        ? getRepeatErrorMessage(ERROR_NAME.order.toLowerCase(), repeatedWorkOrderIdentifiers)
        : repeatedSensorProfiles.length
        ? getRepeatErrorMessage(ERROR_NAME.trackerSerial.toLowerCase(), repeatedSensorProfiles)
        : duplicatedWorkOrderIdentifiers.length
        ? getDuplicationErrorMessage(ERROR_NAME.order.toLowerCase(), duplicatedWorkOrderIdentifiers)
        : duplicatedSensorProfiles.length
        ? getDuplicationErrorMessage(ERROR_NAME.trackerSerial.toLowerCase(), duplicatedSensorProfiles)
        : "",
      duplicatedValues: repeatedWorkOrderIdentifiers.length
        ? repeatedWorkOrderIdentifiers
        : repeatedSensorProfiles.length
        ? repeatedSensorProfiles
        : duplicatedWorkOrderIdentifiers.length
        ? duplicatedWorkOrderIdentifiers
        : duplicatedSensorProfiles.length
        ? duplicatedSensorProfiles
        : []
    };
  };

  const getRepeatedListHelper = (repeatCountMap) => {
    return Object.keys(repeatCountMap).reduce((accumulator, eachKey) => {
      if (repeatCountMap[eachKey] > 1) {
        return [...accumulator, eachKey];
      }
      return accumulator;
    }, []);
  };

  const getWorkOrderIdentifierAndSensorProfileVidRepeatedList = (workOrdersPayload) => {
    const { workOrderIdentifierCountMap, sensorProfileVidCountMap } = workOrdersPayload.reduce(
      (accumulator, eachWorkOrderPayload) => {
        const { [WO_IDENTIFIER_KEY]: order_number, [SENSOR_PROFILE_VID_KEY]: tracker_serial } = eachWorkOrderPayload;
        accumulator.workOrderIdentifierCountMap[order_number] =
          (accumulator.workOrderIdentifierCountMap[order_number] || 0) + 1;

        accumulator.sensorProfileVidCountMap[tracker_serial] =
          (accumulator.sensorProfileVidCountMap[tracker_serial] || 0) + 1;

        return accumulator;
      },
      {
        workOrderIdentifierCountMap: {},
        sensorProfileVidCountMap: {}
      }
    );

    const repeatedWorkOrderIdentifiers = getRepeatedListHelper(workOrderIdentifierCountMap);

    const repeatedSensorProfiles = getRepeatedListHelper(sensorProfileVidCountMap);

    return { repeatedWorkOrderIdentifiers, repeatedSensorProfiles };
  };

  const validateWorkOrderInformation = async (workOrdersPayload, reportCurrentStateMessageCallbackFn) => {
    reportCurrentStateMessageCallbackFn("Performing data validation");

    const { workOrderIdentifiers, vids } = workOrdersPayload.reduce(
      (accumulator, eachWorkOrderPayload) => {
        const { [WO_IDENTIFIER_KEY]: order_number, [SENSOR_PROFILE_VID_KEY]: tracker_serial } = eachWorkOrderPayload;
        accumulator.workOrderIdentifiers.push(order_number);
        accumulator.vids.push(tracker_serial);
        return accumulator;
      },
      { workOrderIdentifiers: [], vids: [] }
    );
    const [duplicatedWorkOrderIdentifiers, duplicatedSensorProfiles] = await Promise.all([
      getTaskInformationByIdentifier(workOrderIdentifiers),
      getDuplicateTrackerSerials(vids)
    ]);

    const { repeatedSensorProfiles, repeatedWorkOrderIdentifiers } =
      getWorkOrderIdentifierAndSensorProfileVidRepeatedList(workOrdersPayload);

    return {
      ...getDuplicateError(
        [
          ...duplicatedWorkOrderIdentifiers.map(({ identifier }) => {
            return identifier;
          })
        ],
        [
          ...duplicatedSensorProfiles.map(({ vid }) => {
            return vid;
          })
        ],
        repeatedWorkOrderIdentifiers,
        repeatedSensorProfiles
      ),
      duplicatedComplexValues: duplicatedWorkOrderIdentifiers.length
        ? duplicatedWorkOrderIdentifiers
        : duplicatedSensorProfiles
    };
  };

  const setOnboardingLocation = async (workOrderIdentifiers, locationToWorkOrderMap) => {
    const tasks = await getTaskInformationByIdentifier(workOrderIdentifiers, true);

    const taskIdentifierToTrackingSessionIdMap = tasks.reduce((accumulator, eachTask) => {
      const {
        identifier: taskIdentifier,
        isRepresentedBy: [
          {
            associatedWithSession: [{ id: trackingSessionId }]
          }
        ]
      } = eachTask;
      return { ...accumulator, [taskIdentifier]: trackingSessionId };
    }, {});

    const userEventPromises = Object.keys(locationToWorkOrderMap).map((eachLocationId) => {
      const trackingSessionIds = locationToWorkOrderMap[eachLocationId].map((eachWorkOrderIdentifier) => {
        return taskIdentifierToTrackingSessionIdMap[eachWorkOrderIdentifier];
      });
      return publishClient.userEvent(trackingSessionIds, eachLocationId);
    });
    await Promise.all(userEventPromises);
  };

  const disassociateSensorProfiles = async (duplicatedSensorProfiles) => {
    const { trackingSessionIdsToEnd, sensorProfileIdAndItemIdListToDetach } = duplicatedSensorProfiles.reduce(
      (accumulator, eachSensorProfile) => {
        const { trackingSessionId, trackingItemId, id } = eachSensorProfile;
        if (trackingSessionId) {
          accumulator.trackingSessionIdsToEnd.push(trackingSessionId);
        } else {
          accumulator.sensorProfileIdAndItemIdListToDetach.push({ id, itemId: trackingItemId });
        }
        return accumulator;
      },
      { trackingSessionIdsToEnd: [], sensorProfileIdAndItemIdListToDetach: [] }
    );

    if (trackingSessionIdsToEnd.length) {
      await trackingSessionClient.endTrackingSessions(trackingSessionIdsToEnd, true);
    }
    if (sensorProfileIdAndItemIdListToDetach.length) {
      await Promise.all(
        sensorProfileIdAndItemIdListToDetach.map(({ id: sensorProfileId, itemId }) => {
          return sensorProfileClient.detachSensorProfileFromItem(sensorProfileId, itemId);
        })
      );
    }
  };

  const handleDuplicateStep = async (
    validationResponse,
    reportCurrentStateMessageCallbackFn,
    shouldReuseSensorProfileCallbackFn
  ) => {
    const { error, errorCode, errorMessage, duplicatedValues, duplicatedComplexValues } = validationResponse;
    if (error) {
      switch (errorCode) {
        case `Duplicate ${ERROR_NAME.trackerSerial}`:
          let shouldContinue;
          if (reuseSensorProfileConfig.allow && reuseSensorProfileConfig.autoDisassociationOnReuse) {
            shouldContinue = true;
          } else {
            shouldContinue = await shouldReuseSensorProfileCallbackFn(duplicatedValues);
          }
          if (!shouldContinue) {
            throwSpecificError(errorMessage);
          }
          reportCurrentStateMessageCallbackFn("Reusing active RFID tag(s)");
          await disassociateSensorProfiles(duplicatedComplexValues);
          break;
        default:
          throwSpecificError(errorMessage);
      }
    }
  };

  const createWorkOrderStep = async (workOrdersPayload, reportCurrentStateMessageCallbackFn) => {
    reportCurrentStateMessageCallbackFn(
      `Creating ${workOrdersPayload.length} work order${workOrdersPayload.length > 1 ? "s" : ""}`
    );
    return createWorkOrders(workOrdersPayload, !!reuseSensorProfileConfig?.allow);
  };

  const setOnboardingLocationStep = async (
    createdWorkOrderIdentifiers,
    onboardingLocationToWorkOrderMap,
    reportCurrentStateMessageCallbackFn
  ) => {
    if (Object.keys(onboardingLocationToWorkOrderMap || {}).length && createdWorkOrderIdentifiers?.length) {
      reportCurrentStateMessageCallbackFn("Setting onboarding location");
      await setOnboardingLocation(createdWorkOrderIdentifiers, onboardingLocationToWorkOrderMap);
    }
  };

  const printTagStep = async (workOrdersPayload, reportCurrentStateMessageCallbackFn) => {
    if (includePrintFileWorkFlow) {
      reportCurrentStateMessageCallbackFn("Preparing for printing label(s)");
      const printFilePayload = await printFileWorkflow(workOrdersPayload);
      if (printFilePayload.error) {
        throwSpecificError(printFilePayload.errorMessage);
      }
      return printFilePayload;
    }
  };

  const submit = async (
    workOrdersPayload,
    onboardingLocationToWorkOrderMap,
    reportCurrentStateMessageCallbackFn = () => {},
    shouldReuseSensorProfileCallbackFn = () => {}
  ) => {
    try {
      reportCurrentStateMessageCallbackFn("Submitting");

      const validationResponse = await validateWorkOrderInformation(
        workOrdersPayload,
        reportCurrentStateMessageCallbackFn
      );

      await handleDuplicateStep(
        validationResponse,
        reportCurrentStateMessageCallbackFn,
        shouldReuseSensorProfileCallbackFn
      );

      const createdWorkOrderIdentifiers = await createWorkOrderStep(
        workOrdersPayload,
        reportCurrentStateMessageCallbackFn
      );

      await setOnboardingLocationStep(
        createdWorkOrderIdentifiers,
        onboardingLocationToWorkOrderMap,
        reportCurrentStateMessageCallbackFn
      );

      const printFilePayload = await printTagStep(workOrdersPayload, reportCurrentStateMessageCallbackFn);

      return { createdWorkOrderIdentifiers, printFilePayload };
    } catch (error) {
      if (error?.name === SPECIFIC_ERROR) {
        throw error;
      } else {
        throw new Error("An unexpected error occurred. Please contact Xemelgo Support for resolution.");
      }
    }
  };

  return {
    submit
  };
};

export default useCreateWorkOrderWorkflow;
