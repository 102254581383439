import React, { useContext, useState, useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import _ from "lodash";
import useInventoryTrackPageSearchParams from "../../hooks/use-inventory-track-page-search-params-hook";
import { DATA_VIEW_TYPE_MAP } from "../../../../components/change-data-view-dropdown";
import { LocalCacheService } from "../../../../services/local-cache-service";
import useInventoryTrackPageConfigContext from "../inventory-track-page-config-context";

const InventoryTrackPageStateContext = React.createContext();

const initialState = {
  selectedDataViewId: DATA_VIEW_TYPE_MAP.graphsAndMetrics,
  isSideFilterHidden: false,
  selectedTableDisplayModeId: "",
  freeTextSearchInputString: ""
};

export const useInventoryTrackPageStateContext = () => {
  return useContext(InventoryTrackPageStateContext);
};

export const InventoryTrackPageStateContextProvider = ({ children }) => {
  const { search, pathname } = useLocation();

  const {
    selectedLocationId,
    selectedViewModeId,
    selectedViewTypeId,
    setSelectedLocationId,
    setSelectedViewModeId,
    setSelectedViewTypeId,
    sideFilterValue,
    setSideFilterValue,
    isSearchParamReady,
    selectedItemGroup,
    setSelectedItemGroup
  } = useInventoryTrackPageSearchParams();

  const {
    listTableControl,
    viewModes,
    tableViewTypeControl,
    sideFilterControl,
    isLoading: isConfigLoading
  } = useInventoryTrackPageConfigContext();

  const [selectedDataViewId, setSelectedDataViewIdState] = useState(initialState.selectedDataViewId);
  const [isSideFilterHidden, setIsSideFilterHidden] = useState(initialState.isSideFilterHidden);
  const [selectedTableDisplayModeId, setSelectedTableDisplayModeId] = useState(initialState.selectedTableDisplayModeId);
  const [freeTextSearchInputString, setFreeTextSearchInputString] = useState(initialState.freeTextSearchInputString);
  const [displayedTableHeadersMap, setDisplayedTableHeadersMapState] = useState({});
  const [displayedTableHeadersMapDefault, setDisplayedTableHeadersMapDefault] = useState({});
  const [applyItemTypeFilterFn, setApplyItemTypeFilterFn] = useState(null);
  const [applyItemFilterFn, setApplyItemFilterFn] = useState(null);
  const [applyLotFilterFn, setApplyLotFilterFn] = useState(null);
  const [applyLocationFilterFn, setApplyLocationFilterFn] = useState(null);
  const [exportCsvFn, setExportCsvFn] = useState(() => {});

  // Validate and update savedDisplayedTableHeadersMapCloned object
  const validateAndDefaultDisplayedTableHeadersMapMap = (savedDisplayedTableHeadersMap, defaultValue) => {
    const savedDisplayedTableHeadersMapCloned = _.cloneDeep(savedDisplayedTableHeadersMap);
    // Iterate over defaultValue keys
    Object.keys(defaultValue).forEach((eachTableViewTypeId) => {
      // If key doesn't exist in savedDisplayedTableHeadersMapCloned, default it
      if (!savedDisplayedTableHeadersMapCloned[eachTableViewTypeId]) {
        savedDisplayedTableHeadersMapCloned[eachTableViewTypeId] = defaultValue[eachTableViewTypeId];
      } else {
        // Iterate over view modes (stock, expiration)
        Object.keys(defaultValue[eachTableViewTypeId]).forEach((eachViewModeId) => {
          // If view mode doesn't exist in savedDisplayedTableHeadersMapCloned, default it
          if (!savedDisplayedTableHeadersMapCloned[eachTableViewTypeId][eachViewModeId]) {
            savedDisplayedTableHeadersMapCloned[eachTableViewTypeId][eachViewModeId] = [
              ...defaultValue[eachTableViewTypeId][eachViewModeId]
            ];
          } else {
            // Filter out invalid IDs not in defaultValue and add missing IDs
            const validIds = new Set(defaultValue[eachTableViewTypeId][eachViewModeId]);
            savedDisplayedTableHeadersMapCloned[eachTableViewTypeId][eachViewModeId] =
              savedDisplayedTableHeadersMapCloned[eachTableViewTypeId][eachViewModeId].filter((id) => {
                return validIds.has(id);
              });

            // Add any missing IDs from defaultValue if no id
            if (!savedDisplayedTableHeadersMapCloned[eachTableViewTypeId][eachViewModeId].length) {
              defaultValue[eachTableViewTypeId][eachViewModeId].forEach((eachHeaderId) => {
                if (!savedDisplayedTableHeadersMapCloned[eachTableViewTypeId][eachViewModeId].includes(eachHeaderId)) {
                  savedDisplayedTableHeadersMapCloned[eachTableViewTypeId][eachViewModeId].push(eachHeaderId);
                }
              });
            }
          }
        });
      }
    });

    // Remove any keys from savedDisplayedTableHeadersMapCloned not present in defaultValue
    Object.keys(savedDisplayedTableHeadersMapCloned).forEach((eachTableViewTypeId) => {
      if (!defaultValue[eachTableViewTypeId]) {
        delete savedDisplayedTableHeadersMapCloned[eachTableViewTypeId];
      } else {
        Object.keys(savedDisplayedTableHeadersMapCloned[eachTableViewTypeId]).forEach((eachViewModeId) => {
          if (!defaultValue[eachTableViewTypeId][eachViewModeId]) {
            delete savedDisplayedTableHeadersMapCloned[eachTableViewTypeId][eachViewModeId];
          }
        });
      }
    });
    return savedDisplayedTableHeadersMapCloned;
  };

  useEffect(() => {
    const newSelectedDataView = LocalCacheService.getInventoryTrackPageDataView();
    setSelectedDataViewIdState(newSelectedDataView || initialState.selectedDataViewId);
  }, []);

  useEffect(() => {
    const getDefaultDisplayedTableHeadersMap = () => {
      const defaultValue = {};

      // Cache the length of viewModes array
      const viewModesLen = viewModes?.length || 1;

      Object.keys(tableViewTypeControl).forEach((eachTableViewTypeId) => {
        // Initialize an empty object for eachTableViewTypeId
        const viewModeObj = {};

        // Set up empty arrays for each viewMode id
        for (let i = 0; i < viewModesLen; i++) {
          viewModeObj[viewModes?.[i]?.id] = [];
        }

        // Retrieve the headers for the current table view type
        const { headers } = listTableControl[eachTableViewTypeId];

        headers.forEach(({ id: eachHeaderId, viewMode: eachHeaderViewMode }) => {
          const applicableViewModes = eachHeaderViewMode || ["all"];
          if (applicableViewModes.includes("all")) {
            // If 'all' is included, assign to all view modes
            for (let i = 0; i < viewModesLen; i++) {
              viewModeObj[viewModes?.[i]?.id].push(eachHeaderId);
            }
          } else {
            // Else, assign to specific view modes
            applicableViewModes.forEach((viewMode) => {
              if (viewModeObj[viewMode]) {
                viewModeObj[viewMode].push(eachHeaderId);
              }
            });
          }
        });

        // Assign the constructed viewModeObj to defaultValue
        defaultValue[eachTableViewTypeId] = viewModeObj;
      });

      return defaultValue;
    };

    if (!isConfigLoading) {
      const newDisplayedTableHeadersMapDefault = getDefaultDisplayedTableHeadersMap();
      setDisplayedTableHeadersMapDefault(newDisplayedTableHeadersMapDefault);
      const newDisplayedTableHeadersMap = validateAndDefaultDisplayedTableHeadersMapMap(
        LocalCacheService.getInventoryTrackPageDisplayedHeadersMap(),
        newDisplayedTableHeadersMapDefault
      );
      setDisplayedTableHeadersMap(newDisplayedTableHeadersMap);
    }
  }, [listTableControl, tableViewTypeControl, viewModes, isConfigLoading]);

  const setSelectedDataViewId = (value) => {
    LocalCacheService.saveInventoryTrackPageDataView(value);
    setSelectedDataViewIdState(value);
  };

  const setDisplayedTableHeadersMap = (value) => {
    LocalCacheService.saveInventoryTrackPageDisplayedHeadersMap(value);
    setDisplayedTableHeadersMapState(value);
  };

  const getURLByState = useCallback(
    ({ selectedLocationId: selectedLocationIdParam, selectedItemGroup: selectedItemGroupParam }) => {
      const newParsedString = queryString.parse(search);

      if (selectedLocationIdParam) {
        newParsedString.locationId = selectedLocationIdParam;
      }
      if (selectedItemGroupParam) {
        newParsedString.group = JSON.stringify(selectedItemGroupParam);
      }
      return `${pathname}?${queryString.stringify(newParsedString)}`;
    },
    [search]
  );

  const currentSideFilterControl = useMemo(() => {
    return (
      sideFilterControl
        .map((eachSideFilterControl) => {
          // processing the OG side filter control to includes "possibleFilterIds" by traversing "override" config.
          // "possibleFilterIds" will later be used to preserving the filter value
          const possibleFilterIds = Object.keys(eachSideFilterControl?.override || {}).reduce(
            (accumulator, eachViewModeId) => {
              Object.keys(eachSideFilterControl?.override?.[eachViewModeId] || {}).forEach((eachTableViewTypeid) => {
                const { id } = eachSideFilterControl.override?.[eachViewModeId]?.[eachTableViewTypeid] || {};
                if (id) {
                  accumulator.push(id);
                }
              });
              return accumulator;
            },
            [eachSideFilterControl.id]
          );

          return { ...eachSideFilterControl, possibleFilterIds };
        })
        .filter(({ tableViewType, viewMode }) => {
          // only showing the side filters available to the current view mode and table view type
          return (
            (!tableViewType || tableViewType.includes("all") || tableViewType.includes(selectedViewTypeId)) &&
            (!viewMode || viewMode.includes("all") || viewMode.includes(selectedViewModeId))
          );
        })
        .map((eachSideFilterControl) => {
          // also overriding the filter id and filter source by using "override" config
          const { override, id: defaultId, filterSource: defaultFilterSource } = eachSideFilterControl || {};

          const { filterSource: overrideFilterSource, id: overrideId } =
            override?.[selectedViewModeId]?.[selectedViewTypeId] || override?.all?.[selectedViewTypeId] || {};

          return {
            ...eachSideFilterControl,
            filterSource: overrideFilterSource || defaultFilterSource,
            id: overrideId || defaultId
          };
        }) || []
    );
  }, [sideFilterControl, selectedViewModeId, selectedViewTypeId]);

  return (
    <InventoryTrackPageStateContext.Provider
      value={{
        selectedLocationId,
        selectedViewModeId,
        selectedViewTypeId,
        setSelectedLocationId,
        setSelectedViewModeId,
        setSelectedViewTypeId,
        selectedDataViewId,
        setSelectedDataViewId,
        isSideFilterHidden,
        setIsSideFilterHidden,
        selectedTableDisplayModeId,
        setSelectedTableDisplayModeId,
        getURLByState,
        freeTextSearchInputString,
        setFreeTextSearchInputString,
        displayedTableHeadersMap,
        setDisplayedTableHeadersMap,
        displayedTableHeadersMapDefault,
        sideFilterValue,
        setSideFilterValue,
        applyItemTypeFilterFn,
        setApplyItemTypeFilterFn,
        applyItemFilterFn,
        setApplyItemFilterFn,
        applyLotFilterFn,
        setApplyLotFilterFn,
        applyLocationFilterFn,
        setApplyLocationFilterFn,
        exportCsvFn,
        setExportCsvFn,
        isSearchParamReady,
        selectedItemGroup,
        setSelectedItemGroup,
        currentSideFilterControl
      }}
    >
      {children}
    </InventoryTrackPageStateContext.Provider>
  );
};
