export const generateTagBorderCommand = (tagSizeInDots) => {
  const { x, y } = tagSizeInDots;

  const borderXLength = x - 100;
  const borderYLength = y - 100;

  return `^FO50,50^GB${borderXLength},5,5^FS
    ^FO50,${borderYLength + 50}^GB${borderXLength + 5},5,5^FS
    ^FO50,50^GB5,${borderYLength},5^FS
    ^FO${borderXLength + 50},50^GB5,${borderYLength + 5},5^FS`;
};
