const interpolateColor = (color1, color2, factor = 0.5) => {
  const result = color1.slice();
  for (let i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
  }
  return result;
};

export const interpolateColors = (color1, color2, steps) => {
  const stepFactor = steps <= 1 ? 1 : 1 / (steps - 1);
  const interpolatedColorArray = [];

  const newColor1 = color1.match(/\d+/g).map(Number);
  const newColor2 = color2.match(/\d+/g).map(Number);

  for (let i = 0; i < steps; i++) {
    interpolatedColorArray.push(interpolateColor(newColor1, newColor2, stepFactor * i));
  }

  return interpolatedColorArray.map((eachColor) => {
    return `rgb(${eachColor.join()})`;
  });
};
