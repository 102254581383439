import React, { createContext, useCallback, useState, useContext } from "react";

export const ScheduleManagementContext = createContext({
  registerTrackPageConfiguration: () => {},
  getTrackPageConfiguration: () => {},
  registerCustomerConfig: () => {},
  getCustomerConfiguration: () => {},
  filterInputs: {},
  setFilterInputs: () => {},
  currentWorkCenterMap: {},
  setCurrentWorkCenterMap: () => {},
  secondaryColor: "",
  setSecondaryColor: () => {}
});

export const useScheduleManagementContext = () => {
  return useContext(ScheduleManagementContext);
};

/**
 * @typedef WorkOrderTableHeaderConfig
 * @property { string } type
 * @property { string } format
 * @property { number } index
 * @property { string } label
 */

/**
 *
 * @param children
 * @return {JSX.Element}
 * @constructor
 */

export const ScheduleManagementContextProvider = ({ children }) => {
  // configuration
  const [trackPageConfiguration, setTrackPageConfiguration] = useState({});
  const [customerConfig, setCustomerConfig] = useState({});
  // filtering
  const [filterInputs, setFilterInputs] = useState({});
  const [currentWorkCenterMap, setCurrentWorkCenterMap] = useState({});
  // color
  const [secondaryColor, setSecondaryColor] = useState("");

  const registerTrackPageConfiguration = useCallback((configuration) => {
    if (!configuration) {
      return;
    }
    const { listViewConfig: newListViewConfig, metricsMap: newHeaderMetricsMap } = configuration;

    const workOrderTableHeaders = Object.entries(newListViewConfig["work-orders"])
      .sort((a, b) => {
        return a[1].index - b[1].index;
      })
      .map(([headerName, configValue]) => {
        return {
          id: headerName,
          ...configValue
        };
      });

    /**
     * @type {{workCenterMetrics: object, workOrderTableHeader: WorkOrderTableHeaderConfig }}
     */
    const trackPageConfig = {
      workOrderTableHeaders,
      workCenterMetrics: newHeaderMetricsMap["work-orders"]
    };
    setTrackPageConfiguration(trackPageConfig);
  }, []);

  const registerCustomerConfig = useCallback((configuration) => {
    setCustomerConfig(configuration);
  }, []);
  const getTrackPageConfiguration = () => {
    return { ...trackPageConfiguration };
  };

  const getCustomerConfiguration = () => {
    return { ...customerConfig };
  };

  return (
    <ScheduleManagementContext.Provider
      value={{
        secondaryColor,
        setSecondaryColor,
        filterInputs,
        setFilterInputs,
        currentWorkCenterMap,
        setCurrentWorkCenterMap,
        getTrackPageConfiguration,
        getCustomerConfiguration,
        registerCustomerConfig,
        registerTrackPageConfiguration
      }}
    >
      {children}
    </ScheduleManagementContext.Provider>
  );
};
