import React, { useEffect, useState } from "react";
import { TrendingFlat as ArrowIcon } from "@material-ui/icons";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import Style from "./ColumnSelectModal.module.css";
import { Checkbox } from "../checkbox-component/Checkbox";

export const ColumnSelectModal = ({
  columnOptions,
  currentColumns,
  maxColumns,
  minColumns,
  show,
  onConfirm,
  onCancel
}) => {
  const [ordering, setOrdering] = useState([]);

  useEffect(() => {
    if (show) {
      setOrdering(currentColumns);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={onCancel}
      centered
      dialogClassName={Style.react_bootstrap_modal_style_override}
      className={Style.modal_container}
      data-cy="column-select-modal"
    >
      <div className={Style.modal_top_container}>
        <p className={Style.title_text}>Set Displayed Columns</p>
        <p>{`You can set the columns (up to ${maxColumns}) to be displayed on the track page in a particular column ordering.`}</p>
        <div className={Style.flex_row}>
          <div className={Style.left_section}>
            <p className={Style.bold_text}>Columns</p>
            {columnOptions?.map((column) => {
              const isChecked = ordering.some((item) => {
                return item.id === column.id;
              });
              return (
                <div
                  key={`checkbox-${column.id}`}
                  onClick={() => {
                    const newOrdering = [...ordering];
                    if (isChecked) {
                      const index = newOrdering.findIndex((item) => {
                        return item.id === column.id;
                      });
                      newOrdering.splice(index, 1);
                      setOrdering(newOrdering);
                    } else if (newOrdering.length < maxColumns) {
                      newOrdering.push(column);
                      setOrdering(newOrdering);
                    }
                  }}
                  className={`${Style.checkbox_option} ${isChecked ? Style.checkbox_selected : ""}`}
                >
                  <Checkbox isChecked={isChecked} />
                  {column.label}
                </div>
              );
            })}
          </div>
          <div className={`${Style.right_section}`}>
            <p className={Style.bold_text}>Column Ordering</p>
            <div className={Style.ordering_container}>
              <p className={`${Style.position}  ${Style.ordering_header_text}`}>Position</p>
              <p className={`${Style.column_name}  ${Style.ordering_header_text}`}>Column</p>
            </div>
            {ordering.map((column, index) => {
              return (
                <div
                  key={column.id}
                  className={Style.ordering_container}
                >
                  <p className={Style.position}>{index + 1}</p>
                  <p className={Style.column_name}>{column.label}</p>
                  <div className={Style.ordering_controls}>
                    <ArrowIcon
                      onClick={() => {
                        if (index === 0) {
                          return;
                        }
                        const newOrdering = [...ordering];
                        const temp = newOrdering[index];
                        newOrdering[index] = newOrdering[index - 1];
                        newOrdering[index - 1] = temp;
                        setOrdering(newOrdering);
                      }}
                      className={`${Style.up_arrow} ${index === 0 ? Style.disabled_arrow : ""}`}
                    />
                    <ArrowIcon
                      onClick={() => {
                        if (index === ordering.length - 1) {
                          return;
                        }
                        const newOrdering = [...ordering];
                        const temp = newOrdering[index];
                        newOrdering[index] = newOrdering[index + 1];
                        newOrdering[index + 1] = temp;
                        setOrdering(newOrdering);
                      }}
                      className={`${Style.down_arrow} ${index === ordering.length - 1 ? Style.disabled_arrow : ""}`}
                    />
                    <button
                      type="button"
                      className={Style.remove_text}
                      onClick={() => {
                        const newOrdering = [...ordering];
                        newOrdering.splice(index, 1);
                        setOrdering(newOrdering);
                      }}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={Style.modal_button_container}>
        <div>
          <button
            type="button"
            onClick={onCancel}
            className={`${Style.button} ${Style.grey_button}`}
          >
            Back
          </button>
          <button
            type="button"
            onClick={() => {
              onConfirm(ordering);
            }}
            className={`${Style.button} ${Style.blue_button}`}
            disabled={ordering.length < minColumns}
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

ColumnSelectModal.defaultProps = {
  currentColumns: [],
  maxColumns: 8,
  minColumns: 1
};

ColumnSelectModal.propTypes = {
  columnOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  currentColumns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  maxColumns: PropTypes.number,
  minColumns: PropTypes.number,
  show: PropTypes.bool.isRequired
};
