import { getStatusFlags } from "../../common/Utilities";
import multipleSortComparison from "../multiple-sort-comparison";

/**
 * Get status flags with issues.
 *
 * @param {Array} issues - Array of issue objects. Each issue object should contain:
 *   - {number} issue_end_time - The end time of the issue.
 *   - {string} issue_status - The status of the issue.
 *   - {Array<string>} issue_types - Array of issue codes associated with the issue.
 * @param {Array} availableFlags - Array of available flag objects (from config).
 * @param {Array<string>} previousFlags - Array of previous flags.
 * @param {Array} possibleIssueTypes - Options for issues.
 * @returns {Array<string>} - of status flags.
 */
export const getStatusFlagsWithIssues = (issues, availableFlags, previousFlags, possibleIssueTypes = []) => {
  const activeIssues = issues.reduce((acc, issue) => {
    const { issue_end_time: issueEndTime, issue_status: issueStatus, issue_types: issueTypes = [] } = issue;
    if (issueEndTime || issueStatus === "Closed") {
      return acc;
    }

    // Add a flag to indicate that the asset has an issue for cases where issues do not have a code or
    // we only want to show a generic issue flag
    acc.add("hasIssue");

    issueTypes.forEach((issueCode) => {
      acc.add(issueCode);
    });

    return acc;
  }, new Set());

  const nonIssueFlags = previousFlags.filter((flag) => {
    const isIssueFlag = possibleIssueTypes.find((issueType) => {
      return issueType.value === flag.id;
    });

    return !isIssueFlag;
  });

  const issueStatusFlags = getStatusFlags([...activeIssues], availableFlags).sort(
    multipleSortComparison([{ id: "id" }])
  );

  return [...issueStatusFlags, ...nonIssueFlags];
};
