import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import { ReactComponent as ChevronRight } from "../../../../assets/icons/chevron-right-blue.svg";
import Style from "./TrackPageTableGroupHeader.module.css";

export const TrackPageTableGroupHeader = ({
  groupTitle,
  groupFields,
  fieldsLoading,
  isGroupClickable,
  onGroupClick,
  isExpanded,
  onExpandClick
}) => {
  return (
    <div
      className={Style.group_row}
      data-cy={`table-header-${groupTitle}`}
    >
      <div className={Style.flex_row}>
        <div className={Style.group_accent} />
        <div
          className={`${Style.chevron} ${isExpanded ? Style.chevron_down : ""}`}
          onClick={() => {
            onExpandClick(!isExpanded);
          }}
        >
          <ChevronRight height={18} />
        </div>
        <button
          type="button"
          className={Style.group_title_text}
          disabled={!isGroupClickable}
          onClick={() => {
            onGroupClick();
          }}
        >
          {groupTitle}
        </button>
      </div>
      <div className={`${Style.flex_row} ${Style.group_right_container}`}>
        {fieldsLoading ? (
          <div className={Style.group_fields_container}>
            <Skeleton className={Style.fields_skeleton} />
          </div>
        ) : (
          <div className={`${Style.group_fields_container} ${!groupFields.length ? Style.empty_fields : ""}`}>
            {groupFields.map((field) => {
              if (field.hiddenOnTable) {
                return null;
              }
              return (
                <div
                  className={Style.group_field}
                  key={`${groupTitle}-${field.label}`}
                >
                  <div className={Style.field_title}>{`${field.label}:`}</div>
                  <div
                    className={Style.field_value}
                    style={{ color: field.textColor }}
                  >
                    {field.value.toLocaleString()}
                  </div>
                </div>
              );
            })}
            <button
              type="button"
              className={Style.blue_text}
              onClick={() => {
                onExpandClick(!isExpanded);
              }}
            >
              {isExpanded ? "Collapse" : "Expand"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

TrackPageTableGroupHeader.defaultProps = {
  groupFields: [],
  isGroupClickable: false,
  onGroupClick: () => {},
  fieldsLoading: false,
  isExpanded: false,
  onExpandClick: () => {}
};

TrackPageTableGroupHeader.propTypes = {
  groupTitle: PropTypes.string.isRequired,
  groupFields: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  ),
  isExpanded: PropTypes.bool,
  onExpandClick: PropTypes.func,
  isGroupClickable: PropTypes.bool,
  onGroupClick: PropTypes.func,
  fieldsLoading: PropTypes.bool
};
