import React from "react";
import PropTypes from "prop-types";

const RenderComponent = ({ containerStyle, renderer }) => {
  return <div className={containerStyle}>{renderer}</div>;
};

export default RenderComponent;

RenderComponent.defaultProps = {
  containerStyle: null
};

RenderComponent.propTypes = {
  containerStyle: PropTypes.string,
  renderer: PropTypes.element.isRequired
};
