export const AmplifyConfigurationProvider = {
  /**
   * @param userConfig { UserConfiguration }
   * @param authConfiguration { object }
   * @param configurationServiceApiConfig { endpoints: object[] }
   */
  buildApiConfiguration(userConfig, authConfiguration, configurationServiceApiConfig = { endpoints: [] }) {
    const endpoints = [];
    let apiVersion = null;

    if (userConfig) {
      // TODO: apiGateway is the name of service from old customer configuration. Keeping it here for backward compatibility support
      const services = ["xGraph", "xMain", "apiGateway", "appSync"];
      const serviceEndpoints = services
        .filter((serviceName) => {
          return userConfig.getServiceConfiguration(serviceName);
        })
        .map((serviceName) => {
          const config = userConfig.getServiceConfiguration(serviceName);
          const { name, endpoint, region, apiVersion: version } = config;

          if (version) {
            apiVersion = version;
          }

          return {
            name,
            endpoint,
            region
          };
        });
      endpoints.push(...serviceEndpoints);
    }

    const { endpoints: configurationServiceEndpoints, apiVersion: configServiceApiVersion } =
      configurationServiceApiConfig;
    endpoints.push(...configurationServiceEndpoints);

    const clientConfig = {
      endpoints,
      apiVersion: apiVersion || configServiceApiVersion,
      auth: authConfiguration
    };

    return clientConfig;
  }
};
