export const DATA_VIEW_TYPE_MAP = {
  graphs: "graphs",
  metrics: "metrics",
  graphsAndMetrics: "graphsAndMetrics",
  none: "none"
};

export const DATA_VIEW_TYPE_TITLE_MAP = {
  graphs: "Graphs",
  metrics: "Metrics",
  graphsAndMetrics: "Graphs And Metrics",
  none: "None"
};
