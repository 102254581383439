import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { ALL_TIME_ID, QUICK_FILTER_OPTIONS } from "./data/constants";
import Calendar from "../calendar";
import { TIME_IN_MS } from "../../common/Utilities";
import TimeSelector from "../time-selector";
import Style from "./DateRangePicker.module.css";
import { roundToNextInterval } from "../time-selector/utils/round-to-next-interval/roundToNextInterval";

export const DateRangePicker = ({
  initialStartDate,
  initialEndDate,
  onClose,
  onConfirm,
  defaultDateRangeMs,
  allTimeSelected,
  quickFilterOptions
}) => {
  const now = Date.now();
  const [startDay, setStartDay] = useState(new Date(initialStartDate));
  const [endDay, setEndDay] = useState(new Date(initialEndDate));
  const [startTime, setStartTime] = useState(roundToNextInterval(new Date(initialStartDate)));
  const [endTime, setEndTime] = useState(roundToNextInterval(new Date(initialEndDate)));
  const [isAllTimeSelected, setIsAllTimeSelected] = useState(allTimeSelected);

  useEffect(() => {
    setStartDay(new Date(initialStartDate));
    setStartTime(roundToNextInterval(new Date(initialStartDate)));
  }, [initialStartDate]);

  useEffect(() => {
    setEndDay(new Date(initialEndDate));
    setEndTime(roundToNextInterval(new Date(initialEndDate)));
  }, [initialEndDate]);

  useEffect(() => {
    setIsAllTimeSelected(allTimeSelected);
  }, [allTimeSelected]);

  const dateDifference = useMemo(() => {
    const newStartTime = startDay;
    const newEndTime = endDay;

    newStartTime.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0);
    newEndTime.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0);

    return newEndTime.getTime() - newStartTime.getTime();
  }, [startDay, endDay, startTime, endTime]);

  const resetToDefault = () => {
    const nowDate = new Date(now);
    const startDate = new Date(now - defaultDateRangeMs);

    setStartDay(startDate);
    setEndDay(nowDate);

    setStartTime(roundToNextInterval(nowDate));
    setEndTime(roundToNextInterval(nowDate));
  };

  const setToStartOfDay = (date) => {
    date.setHours(0, 0, 0, 0);
    return date;
  };

  return (
    <div className={Style.container}>
      <div className={Style.content_container}>
        <div className={Style.quick_filter_section}>
          <p>Quick Filters:</p>
          <div className={Style.quick_filter_options}>
            {quickFilterOptions.map((option) => {
              const isOptionSelected =
                (isAllTimeSelected && option.id === ALL_TIME_ID) ||
                (!isAllTimeSelected && moment(endDay).isSame(moment(now), "day") && dateDifference === option.value);

              return (
                <div
                  key={option.label}
                  className={`${Style.quick_filter_button} ${isOptionSelected && Style.selected_filter}`}
                  onClick={() => {
                    if (option.id === ALL_TIME_ID) {
                      resetToDefault();
                      setIsAllTimeSelected(true);
                    } else {
                      setIsAllTimeSelected(false);

                      const newStartDate = new Date(now - option.value);
                      setStartDay(new Date(newStartDate));
                      setEndDay(new Date(now));

                      setStartTime(roundToNextInterval(new Date(newStartDate)));
                      setEndTime(roundToNextInterval(new Date(now)));
                    }
                  }}
                >
                  {option.label}
                </div>
              );
            })}
          </div>
        </div>
        <div className={Style.date_time_picker_container}>
          <p className={Style.header_text}>Start Date:</p>
          <div className={Style.calendar_container}>
            <Calendar
              date={!isAllTimeSelected ? startDay : undefined}
              onDateClick={(newDate) => {
                if (moment(endDay).isSame(moment(newDate), "day") && moment(startTime).isAfter(endTime, "minute")) {
                  setStartTime(setToStartOfDay(newDate));
                }
                if (isAllTimeSelected) {
                  setEndDay(newDate);
                  setEndTime(endTime);
                }

                setIsAllTimeSelected(false);
                setStartDay(newDate);
              }}
              maxDate={isAllTimeSelected ? new Date() : endDay}
            />
          </div>
          <div className={Style.flex_row}>
            <p>Start Time:</p>
            <div className={Style.dropdown_container}>
              <TimeSelector
                selectedTime={!isAllTimeSelected ? startTime : undefined}
                onTimeClick={(newDate) => {
                  if (isAllTimeSelected) {
                    setStartDay(new Date(now));
                    setEndDay(new Date(now));
                    setEndTime(newDate);
                  }
                  setIsAllTimeSelected(false);
                  setStartTime(newDate);
                }}
                dropdownClassName={Style.dropdown_container}
                maxTime={moment(endDay).isSame(moment(startDay), "day") ? endTime : undefined}
              />
            </div>
          </div>
        </div>
        <div className={Style.date_time_picker_container}>
          <p className={Style.header_text}>End Date:</p>
          <div className={Style.calendar_container}>
            <Calendar
              date={!isAllTimeSelected ? endDay : undefined}
              onDateClick={(newDate) => {
                if (moment(startDay).isSame(moment(newDate), "day") && moment(startTime).isAfter(endTime, "minute")) {
                  setEndTime(startTime);
                }
                if (isAllTimeSelected) {
                  setStartDay(newDate);
                  setStartTime(startTime);
                }

                setIsAllTimeSelected(false);
                setEndDay(newDate);
              }}
              minDate={isAllTimeSelected ? undefined : setToStartOfDay(startDay)}
              maxDate={new Date(now)}
            />
          </div>
          <div className={Style.flex_row}>
            <p>End Time:</p>
            <div className={Style.dropdown_container}>
              <TimeSelector
                selectedTime={!isAllTimeSelected ? endTime : undefined}
                onTimeClick={(newDate) => {
                  if (isAllTimeSelected) {
                    setEndDay(new Date(now));
                    setStartDay(new Date(now));
                    setStartTime(newDate);
                  }
                  setIsAllTimeSelected(false);
                  setEndTime(newDate);
                }}
                minTime={moment(endDay).isSame(moment(startDay), "day") ? startTime : undefined}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={Style.buttons_container}>
        <div
          className={Style.clickable_text}
          onClick={resetToDefault}
        >
          Reset to default
        </div>
        <div className={Style.flex_row}>
          <div
            onClick={onClose}
            className={Style.clickable_text}
          >
            Close
          </div>
          <div
            onClick={() => {
              const newStartTime = startDay;
              const newEndTime = endDay;

              newStartTime.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0);
              newEndTime.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0);

              onConfirm(isAllTimeSelected, newStartTime, newEndTime);
            }}
            className={Style.blue_button}
          >
            Apply
          </div>
        </div>
      </div>
    </div>
  );
};

DateRangePicker.defaultProps = {
  initialStartDate: Date.now() - TIME_IN_MS.WEEKS,
  initialEndDate: Date.now(),
  allTimeSelected: false,
  quickFilterOptions: QUICK_FILTER_OPTIONS
};

DateRangePicker.propTypes = {
  initialStartDate: PropTypes.instanceOf(Date),
  initialEndDate: PropTypes.instanceOf(Date),
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  defaultDateRangeMs: PropTypes.number.isRequired,
  allTimeSelected: PropTypes.bool,
  quickFilterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  )
};
