import React, { useContext, useState, useRef, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import useAssetMapConfigContext from "../asset-map-config-context";
import { LocalCacheService } from "../../../../services/local-cache-service";
import { SEARCH_TYPE_MAP } from "../../data/constants";

const AssetMapStateContext = React.createContext();

const initialState = {
  mapRef: null,
  isInformationHide: false,
  selectedMetricType: "",
  selectedLocationId: "",
  selectedSearchResult: {},
  isWorkOrderResultsLoading: false,
  showWorkOrderResults: false,
  isWorkOrdersFilteredByLocationAndMetricsLoading: false,
  siteLocationId: null,
  siteViewState: null,
  filterValue: null,
  isFilterActive: false,
  showAssetStatus: true,
  selectedAssetTypeSet: new Set(),
  searchAssetResults: [],
  showAssetResults: false,
  isAssetResultsLoading: false,
  searchType: SEARCH_TYPE_MAP.asset_number,
  searchString: ""
};

export const useAssetMapStateContext = () => {
  return useContext(AssetMapStateContext);
};

export const AssetMapStateContextProvider = ({ children }) => {
  const { initialLocationId, switchSiteControl } = useAssetMapConfigContext();

  const mapRef = useRef(initialState.mapRef);

  const [selectedLocationId, setSelectedLocationId] = useState(initialLocationId);
  const [selectedSearchResult, setSelectedSearchResult] = useState(initialState.selectedSearchResult);

  const [siteLocationId, setSiteLocationId] = useState(initialState.siteLocationId);
  const [siteViewState, setSiteViewState] = useState(initialState.siteViewState);
  const [filterValue, setFilterValue] = useState(initialState.filterValue);
  const [searchType, setSearchType] = useState(SEARCH_TYPE_MAP.asset_number);
  const [isAssetResultsLoading, setIsAssetResultsLoading] = useState(initialState.isAssetResultsLoading);
  const [selectedAssetTypeSet, setSelectedAssetTypeSet] = useState(initialState.selectedAssetTypeSet);
  const [searchAssetResults, setSearchAssetResults] = useState(initialState.searchAssetResults);
  const [showAssetResults, setShowAssetResults] = useState(initialState.showAssetResults);
  const [searchString, setSearchString] = useState(initialState.searchString);

  const [locationMap, setLocationMap] = useState({});

  const setSiteLocationHelper = (locationId, viewState) => {
    if (switchSiteControl?.enabled && !initialLocationId) {
      LocalCacheService.saveAssetMapSiteLocation(locationId, viewState);
    }
    setSiteViewState(viewState);
    setSiteLocationId(locationId);
  };

  useEffect(() => {
    const { locationId, viewState } =
      switchSiteControl?.enabled && !initialLocationId ? LocalCacheService.getAssetMapSiteLocation() || {} : {};
    setSiteLocationHelper(initialLocationId || locationId, viewState);
    setSelectedLocationId(initialLocationId || locationId);
  }, [initialLocationId, switchSiteControl]);

  const locationToCounts = useMemo(() => {
    return Object.values(locationMap).reduce((acc, location) => {
      if (!selectedAssetTypeSet.size) {
        return {
          ...acc,
          [location.id]: location.assetCounts
        };
      }

      const aggregateLocationTypeCounts = {
        expiredCount: 0,
        expiringSoonCount: 0,
        totalCount: 0
      };

      selectedAssetTypeSet.forEach((assetTypeIdentifier) => {
        const typeCounts =
          Object.values(location.assetTypeMap).find((assetType) => {
            return assetType.properties.identifier === assetTypeIdentifier;
          })?.counts || {};
        Object.keys(aggregateLocationTypeCounts).forEach((eachCount) => {
          aggregateLocationTypeCounts[eachCount] += typeCounts[eachCount] || 0;
        });
      });

      return {
        ...acc,
        [location.id]: aggregateLocationTypeCounts
      };
    }, {});
  }, [locationMap, selectedAssetTypeSet]);

  return (
    <AssetMapStateContext.Provider
      value={{
        mapRef,
        selectedLocationId,
        setSelectedLocationId,
        selectedSearchResult,
        setSelectedSearchResult,
        isAssetResultsLoading,
        setIsAssetResultsLoading,
        siteLocationId,
        setSiteLocation: setSiteLocationHelper,
        siteViewState,
        setSiteViewState,
        filterValue,
        setFilterValue,
        selectedAssetTypeSet,
        setSelectedAssetTypeSet,
        searchAssetResults,
        setSearchAssetResults,
        searchType,
        setSearchType,
        locationMap,
        setLocationMap,
        locationToCounts,
        showAssetResults,
        setShowAssetResults,
        searchString,
        setSearchString
      }}
    >
      {children}
    </AssetMapStateContext.Provider>
  );
};

AssetMapStateContextProvider.defaultProps = {
  children: null
};

AssetMapStateContextProvider.propTypes = {
  children: PropTypes.element
};
