import { DetectorAlertBuilder } from "./detector-alert-builder";

const RuleBuilderMap = {};
/**
 * @param ruleBuilder { import('./alert-builder.js').AlertBuilder }
 */
const registerRuleBuilder = (ruleBuilder) => {
  RuleBuilderMap[ruleBuilder.getRuleType()] = ruleBuilder;
};

// register all the rule-builders here
registerRuleBuilder(new DetectorAlertBuilder());

export const prepareRuleAndConditions = async (
  ruleClient,
  allRules,
  requestRuleType,
  ruleTypeConfiguration,
  alertDefaultTemplate
) => {
  /**
   * @type { import('./alert-builder.js').AlertBuilder }
   */
  const builder = RuleBuilderMap[requestRuleType];

  if (!builder) {
    return;
  }

  let rule = builder.filterRule(allRules, ruleTypeConfiguration);
  if (!rule) {
    rule = await builder.createRule(ruleClient, ruleTypeConfiguration, alertDefaultTemplate);
  }

  const hasConditions = builder.hasConditionsExist(rule, ruleTypeConfiguration);
  if (!hasConditions) {
    await builder.createRuleConditions(ruleClient, rule, ruleTypeConfiguration);
  }
};
