import React from "react";
import PropTypes from "prop-types";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";
import Style from "./HorizontalPaginateControl.module.css";

const HorizontalPaginateControl = ({
  disablePreviousButton,
  onClickPreviousButton,
  paginatedContent,
  disableNextButton,
  onClickNextButton
}) => {
  return (
    <div className={`${Style.flex_direction_row} ${Style.paginated_content}`}>
      <button
        className={`${Style.arrow_button} ${
          disablePreviousButton ? Style.arrow_button_disabled : Style.arrow_button_enabled
        }`}
        disabled={disablePreviousButton}
        onClick={() => {
          return onClickPreviousButton("previous");
        }}
      >
        <ChevronLeft />
      </button>

      {paginatedContent && paginatedContent()}

      <button
        className={`${Style.arrow_button} ${
          disableNextButton ? Style.arrow_button_disabled : Style.arrow_button_enabled
        }`}
        disabled={disableNextButton}
        onClick={() => {
          return onClickNextButton("next");
        }}
      >
        <ChevronRight />
      </button>
    </div>
  );
};

export default HorizontalPaginateControl;

HorizontalPaginateControl.defaultProps = {
  disablePreviousButton: true,
  onClickPreviousButton: () => {},
  paginatedContent: undefined,
  disableNextButton: true,
  onClickNextButton: () => {}
};

HorizontalPaginateControl.propTypes = {
  disablePreviousButton: PropTypes.bool,
  onClickPreviousButton: PropTypes.func,
  paginatedContent: PropTypes.func,
  disableNextButton: PropTypes.bool,
  onClickNextButton: PropTypes.func
};
