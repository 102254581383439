import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";
import { SEARCH_TYPE_MAP } from "../../data/constants";

const ManagementDashboardConfigContext = React.createContext();

const MANAGEMENT_DASHBOARD_APP_ID = "managementDashboard";
const MAP_CONFIG = "mapConfig";
const INITIAL_LOCATION_CONFIG = "initialLocationId";
const LIST_TABLE_CONTROL = "listTableControl";
const SWITCH_SITE = "switchSiteControl";
const LOCATION_CATEGORIES = "locationCategories";
const POPUP_FILTER_CONTROL = "popupFilterControl";
const SEARCH_CONTROL = "searchControl";
const WORK_ORDER_APP_ID = "order";
const WORK_ORDER_TRACK_PAGE = "trackPage";

const initialState = {
  useWorkOrderTrackPageV2: false,
  initialLocationId: "",
  mapConfig: {
    locationsSourceTilesets: [],
    floorPlanTilesets: [],
    mapStyleURL: "mapbox://",
    initialViewStates: {
      bearing: 0,
      center: [-122.24292340935142, 47.20877788177407],
      zoom: 12
    },
    markersGeojson: null
  },
  switchSiteControl: {
    locationCategory: "Facility",
    enabled: false
  },
  listTableControl: {
    workOrderTable: [
      { id: "identifier", label: "WO #", url: "/work-order/detail?itemId", urlValueKey: "id" },
      { id: "partType", label: "Part #" }
    ],
    workOrderTableNumItemsPerPage: 10
  },
  workOrderSettings: {
    options: [],
    optionControl: {}
  },
  locationCategories: ["all"],
  popupFilterControl: [],
  searchControl: Object.values(SEARCH_TYPE_MAP),
  disableDefaultPartNumberSearch: false
};

export const useManagementDashboardConfigContext = () => {
  return useContext(ManagementDashboardConfigContext);
};

export const ManagementDashboardConfigContextProvider = ({ children }) => {
  const [useWorkOrderTrackPageV2, setUseWorkOrderTrackPageV2] = useState(initialState.useTrackPageV2);
  const [initialLocationId, setInitialLocationId] = useState(initialState.initialLocationId);
  const [mapConfig, setMapConfig] = useState(initialState.mapConfig);
  const [listTableControl, setListTableControl] = useState(initialState.listTableControl);
  const [workOrderSettings, setWorkOrderSettings] = useState(initialState.workOrderSettings);
  const [switchSiteControl, setSwitchSiteControl] = useState(initialState.switchSiteControl);
  const [locationCategories, setLocationCategories] = useState(initialState.locationCategories);
  const [popupFilterControl, setPopupFilterControl] = useState(initialState.popupFilterControl);
  const [searchControl, setSearchControl] = useState(initialState.searchControl);
  const [isLoading, setIsLoading] = useState(true);

  const managementDashboardConfigProvider = useAppConfigProvider(MANAGEMENT_DASHBOARD_APP_ID);
  const workOrderConfigProvider = useAppConfigProvider(WORK_ORDER_APP_ID);

  useEffect(() => {
    setIsLoading(true);
    const { useV2: newUseTrackPageV2 = false, v2: v2TrackPageConfig = {} } = workOrderConfigProvider.getValue(
      WORK_ORDER_TRACK_PAGE,
      "object"
    );
    const { settings: newWorkOrderSettings } = v2TrackPageConfig || {};
    const newMapConfig = _.cloneDeep(
      managementDashboardConfigProvider.getValue(MAP_CONFIG, "object") || initialState.mapConfig
    );

    // backward compatibility support for old config for floorPlanTilesetURL
    if (newMapConfig.floorPlanTilesetURL) {
      newMapConfig.floorPlanTilesets = [
        ...(newMapConfig.floorPlanTilesets || []),
        { url: newMapConfig.floorPlanTilesetURL }
      ];
    }

    // backward compatibility support for old config for locationsSourceDatasetURL and locationsSourceDatasetName

    if (newMapConfig.locationsSourceDatasetName && newMapConfig.locationsSourceDatasetURL) {
      newMapConfig.locationsSourceTilesets = [
        ...(newMapConfig.locationsSourceTilesets || []),
        { url: newMapConfig.locationsSourceDatasetURL, name: newMapConfig.locationsSourceDatasetName }
      ];
    }

    const newInitialLocationId =
      managementDashboardConfigProvider.getValue(INITIAL_LOCATION_CONFIG, "string") || initialState.initialLocationId;

    const newListTableControl =
      managementDashboardConfigProvider.getValue(LIST_TABLE_CONTROL, "object") || initialState.listTableControl;

    const newSwitchSiteControl =
      managementDashboardConfigProvider.getValue(SWITCH_SITE, "object") || initialState.switchSiteControl;

    const newLocationCategories =
      managementDashboardConfigProvider.getValue(LOCATION_CATEGORIES, "array") || initialState.locationCategories;

    const newPopupFilterControl =
      managementDashboardConfigProvider.getValue(POPUP_FILTER_CONTROL, "array") || initialState.popupFilterControl;

    const newSearchControl =
      managementDashboardConfigProvider.getValue(SEARCH_CONTROL, "array") || initialState.searchControl;

    setUseWorkOrderTrackPageV2(newUseTrackPageV2);
    setMapConfig(newMapConfig);
    setInitialLocationId(newInitialLocationId);
    setListTableControl(newListTableControl);
    setWorkOrderSettings(newWorkOrderSettings || initialState.workOrderSettings);
    setSwitchSiteControl(newSwitchSiteControl);
    setLocationCategories(newLocationCategories);
    setPopupFilterControl(newPopupFilterControl);
    setSearchControl(newSearchControl);
    setIsLoading(false);
  }, [managementDashboardConfigProvider, workOrderConfigProvider]);

  return (
    <ManagementDashboardConfigContext.Provider
      value={{
        useWorkOrderTrackPageV2,
        initialLocationId,
        mapConfig,
        listTableControl,
        workOrderSettings,
        switchSiteControl,
        locationCategories,
        popupFilterControl,
        searchControl,
        isLoading
      }}
    >
      {children}
    </ManagementDashboardConfigContext.Provider>
  );
};

ManagementDashboardConfigContextProvider.defaultProps = {
  children: null
};

ManagementDashboardConfigContextProvider.propTypes = {
  children: PropTypes.element
};
