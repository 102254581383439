import React, { Fragment, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TextLink } from "../../../../../components/text-link";
import { EmptyBox } from "../../../../../components/color-box/empty-box";
import { ColorBox } from "../../../../../components/color-box/color-box";
import { ColorPicker } from "../../../../../components/color-picker";
import { Div } from "../../../../../components/div";
import "./style.css";

export const PinOperations = ({
  pin,
  inDropPinMode,
  onSetPinClicked,
  onCancelDropPinMode,
  onRemovePin,
  onColorChanged
}) => {
  const [pinColor, setPinColor] = useState("#000000");
  const [hasPin, setHasPin] = useState(false);
  const [colorPickerDropPoint, setColorPickerDropPoint] = useState(null);
  const buttonLabel = inDropPinMode ? "Cancel" : "Set Pin";

  useEffect(() => {
    let cancelled = false;

    if (!cancelled) {
      setHasPin(!!pin);
      if (pin) {
        const { color } = pin;
        setPinColor(color);
      }
    }

    return () => {
      cancelled = true;
    };
  }, [pin]);

  const onToggleSetPinCallback = useCallback(() => {
    if (inDropPinMode) {
      onCancelDropPinMode();
    } else {
      onSetPinClicked();
    }
  }, [onCancelDropPinMode, onSetPinClicked, inDropPinMode]);

  const onColorClickCallback = useCallback((event) => {
    const { target } = event;
    const { offsetLeft: x, offsetTop: y, offsetParent } = target;
    const { offsetLeft: parentOffsetLeft, offsetTop: parentOffsetTop } = offsetParent;

    setColorPickerDropPoint({ x: x + parentOffsetLeft, y: y + parentOffsetTop });
  }, []);

  const onColorPickedCallback = useCallback(
    (c) => {
      if (!hasPin) {
        return;
      }

      setPinColor(c);
      setColorPickerDropPoint(null);

      const clonedPin = { ...pin };
      clonedPin.color = c;
      onColorChanged(clonedPin);
    },
    [setColorPickerDropPoint, setPinColor, onColorChanged, hasPin, pin]
  );

  const onRemovePinCallback = useCallback(() => {
    onRemovePin(pin);
  }, [onRemovePin, pin]);

  return (
    <>
      {colorPickerDropPoint && (
        <ColorPicker
          show={!!colorPickerDropPoint}
          onSave={onColorPickedCallback}
          offsetTop={0}
          offsetLeft={0}
          color={pinColor}
          showCancel
        />
      )}
      {!hasPin && (
        <Div className="operations-group">
          <TextLink
            label={<EmptyBox />}
            className="pin-color-picker"
          />
          <TextLink
            label={buttonLabel}
            onClick={onToggleSetPinCallback}
            className="pin-operation"
          />
        </Div>
      )}
      {hasPin && (
        <Div className="operations-group">
          <TextLink
            label={<ColorBox color={pinColor} />}
            onClick={onColorClickCallback}
            className="pin-color-picker"
          />
          <TextLink
            label="Remove"
            className="pin-operation remove-pin-operation"
            onClick={onRemovePinCallback}
          />
        </Div>
      )}
    </>
  );
};

PinOperations.defaultProps = {
  pin: null,
  inDropPinMode: false,
  onSetPinClicked: () => {},
  onCancelDropPinMode: () => {},
  onRemovePin: () => {},
  onColorChanged: () => {}
};

PinOperations.propTypes = {
  pin: PropTypes.shape({
    id: PropTypes.string.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  }),
  inDropPinMode: PropTypes.bool,
  onSetPinClicked: PropTypes.func,
  onCancelDropPinMode: PropTypes.func,
  onRemovePin: PropTypes.func,
  onColorChanged: PropTypes.func
};
