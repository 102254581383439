import React, { useContext, useState, useRef, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { VIEW_MODE_MAP, TAB_OPTION_MAP } from "../../data/constants";
import useManagementDashboardConfigContext from "../management-dashboard-config-context";
import { LocalCacheService } from "../../../../services/local-cache-service";
import useManagementDashboardDataSourceContext from "../management-dashboard-data-source-context";

const ManagementDashboardStateContext = React.createContext();

const initialState = {
  mapRef: null,
  isInformationHide: false,
  viewMode: VIEW_MODE_MAP.floor_status_mode,
  selectedMetricType: "",
  selectedLocationId: "",
  selectedTab: TAB_OPTION_MAP.jobStatusTab,
  selectedSearchResult: {},
  isWorkOrderResultsLoading: false,
  showWorkOrderResults: false,
  isWorkOrdersFilteredByLocationAndMetricsLoading: false,
  siteLocationId: null,
  siteViewState: null,
  filterValue: null,
  isFilterActive: false
};

export const useManagementDashboardStateContext = () => {
  return useContext(ManagementDashboardStateContext);
};

export const ManagementDashboardStateContextProvider = ({ children }) => {
  const { initialLocationId, switchSiteControl } = useManagementDashboardConfigContext();
  const { locationTreeMap, filteredLocationTreeMap } = useManagementDashboardDataSourceContext();

  const mapRef = useRef(initialState.mapRef);

  const [isInformationHide, setIsInformationHide] = useState(initialState.isInformationHide);
  const [viewMode, setViewMode] = useState(initialState.viewMode);
  const [selectedMetricType, setSelectedMetricType] = useState(initialState.selectedMetricType);
  const [selectedLocationId, setSelectedLocationId] = useState(initialState.selectedLocationId);
  const [selectedTab, setSelectedTab] = useState(initialState.selectedTab);
  const [selectedSearchResult, setSelectedSearchResult] = useState(initialState.selectedSearchResult);
  const [isWorkOrderResultsLoading, setIsWorkOrderResultsLoading] = useState(initialState.isWorkOrderResultsLoading);
  const [showWorkOrderResults, setShowWorkOrderResults] = useState(initialState.showWorkOrderResults);
  const [isWorkOrdersFilteredByLocationAndMetricsLoading, setIsWorkOrdersFilteredByLocationAndMetricsLoading] =
    useState(initialState.isWorkOrdersFilteredByLocationAndMetricsLoading);

  const [siteLocationId, setSiteLocationId] = useState(initialState.siteLocationId);
  const [siteViewState, setSiteViewState] = useState(initialState.siteViewState);
  const [filterValue, setFilterValue] = useState(initialState.filterValue);
  const [isFilterActive, setIsFilterActive] = useState(initialState.isFilterActive);

  const activeLocationTreeMap = useMemo(() => {
    return isFilterActive ? filteredLocationTreeMap : locationTreeMap;
  }, [locationTreeMap, filteredLocationTreeMap, isFilterActive]);

  const setSiteLocationHelper = (locationId, viewState) => {
    if (switchSiteControl?.enabled && !initialLocationId) {
      LocalCacheService.saveManagementDashboardSiteLocation(locationId, viewState);
    }
    setSiteViewState(viewState);
    setSiteLocationId(locationId);
  };

  useEffect(() => {
    const { locationId, viewState } =
      switchSiteControl?.enabled && !initialLocationId
        ? LocalCacheService.getManagementDashboardSiteLocation() || {}
        : {};

    setSiteLocationHelper(initialLocationId || locationId, viewState);
    setSelectedLocationId(initialLocationId || locationId);
  }, [initialLocationId, switchSiteControl]);

  return (
    <ManagementDashboardStateContext.Provider
      value={{
        mapRef,
        isInformationHide,
        setIsInformationHide,
        viewMode,
        setViewMode,
        selectedMetricType,
        setSelectedMetricType,
        selectedLocationId,
        setSelectedLocationId,
        selectedTab,
        setSelectedTab,
        selectedSearchResult,
        setSelectedSearchResult,
        isWorkOrderResultsLoading,
        setIsWorkOrderResultsLoading,
        showWorkOrderResults,
        setShowWorkOrderResults,
        isWorkOrdersFilteredByLocationAndMetricsLoading,
        setIsWorkOrdersFilteredByLocationAndMetricsLoading,
        siteLocationId,
        setSiteLocationId,
        setSiteLocation: setSiteLocationHelper,
        siteViewState,
        setSiteViewState,
        filterValue,
        setFilterValue,
        isFilterActive,
        setIsFilterActive,
        activeLocationTreeMap
      }}
    >
      {children}
    </ManagementDashboardStateContext.Provider>
  );
};

ManagementDashboardStateContextProvider.defaultProps = {
  children: null
};

ManagementDashboardStateContextProvider.propTypes = {
  children: PropTypes.element
};
