import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import SearchDropdown from "../../../../../../components/SearchDropdown/SearchDropdown";
import KioskStyle from "../../../../Kiosk.module.css";
import Style from "./PicklistVerificationSetup.module.css";
import TransferOrderDropdown from "./features/transfer-order-dropdown";
import useKioskConfigContext from "../../../../contexts/kiosk-config-context";

export const PicklistVerificationSetup = ({ stageOptions, onConfirm }) => {
  const { transferOrderDisplayName } = useKioskConfigContext();

  const [stage, setStage] = useState();
  const [selectedOptionsMap, setSelectedOptionsMap] = useState({});

  useEffect(() => {
    if (stageOptions.length === 1) {
      setStage(stageOptions[0]);
    }
  }, []);

  const selectedOptions = useMemo(() => {
    return Object.values(selectedOptionsMap);
  }, [selectedOptionsMap]);

  return (
    <>
      <div className={Style.container}>
        <div className={Style.inputs_container}>
          {stageOptions.length > 1 && (
            <div>
              <div className={Style.text}>
                Stage:
                <p className={Style.required_asterisk}>*</p>
              </div>
              <SearchDropdown
                selectedItem={stage}
                options={stageOptions}
                onItemSelected={(newStage) => {
                  setStage(newStage);
                }}
                showIcon
                showAllOptions
              />
            </div>
          )}
          <div>
            <div className={Style.text}>
              {`${transferOrderDisplayName}:`}
              <p className={Style.required_asterisk}>*</p>
            </div>
            <TransferOrderDropdown
              selectedOptionsMap={selectedOptionsMap}
              onSelectedOptionsMapChange={setSelectedOptionsMap}
            />
          </div>
        </div>
      </div>
      <div className={KioskStyle.footer}>
        <button
          type="button"
          className={KioskStyle.footer_button}
          disabled={selectedOptions.length === 0 || !stage}
          onClick={() => {
            const selectedTransferOrders = selectedOptions.map((option) => {
              return option.value;
            });

            onConfirm(selectedTransferOrders, stage);
          }}
        >
          Next
        </button>
      </div>
    </>
  );
};

PicklistVerificationSetup.defaultProps = {
  stageOptions: []
};

PicklistVerificationSetup.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  stageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  )
};
