import React from "react";
import PropTypes from "prop-types";

import GeneralizedModal from "../../../../../../components/generalized-modal/GeneralizedModal";
import Style from "./ApproveConsumptionModal.module.css";

export const ApproveConsumptionModal = ({ show, onCancel, onConfirm }) => {
  const approveConsumptionModalButtons = () => {
    return [
      {
        id: "cancel-button",
        title: "Cancel",
        onClick: onCancel,
        className: "cancel-button"
      },
      {
        id: "submit-button",
        title: "Confirm",
        onClick: onConfirm,
        className: "default-button"
      }
    ];
  };

  return (
    <GeneralizedModal
      title="Approve Consumption"
      modalBodyComponent={
        <div>You are about approve consumption of all non-removed SKUs. Note, you cannot undo this action</div>
      }
      modalFooterButtons={approveConsumptionModalButtons()}
      showModal={show}
      classNameOverrides={{
        content: Style.modal_content,
        header: Style.modal_header,
        body: Style.modal_body
      }}
    />
  );
};

ApproveConsumptionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};
