import React, { useCallback, useEffect, useState } from "react";
import { Input, FormGroup, Label } from "reactstrap";
import PropTypes from "prop-types";
import { v4 } from "uuid";

const EmptyOptionKey = "__empty";
export const DropDownInput = ({
  label,
  dropdownOptions,
  includeEmptyOption,
  onSelect,
  onUnselect,
  defaultSelectedKey,
  dropdownClassName,
  value,
  autoPopulate
}) => {
  const [componentId] = useState(v4());
  const [options, setOptions] = useState([]);
  const [selectedKey, setSelectedKey] = useState("");
  const [lastHandledDefaultKey, setLastHandleDefaultKey] = useState(null);

  useEffect(() => {
    if (value !== selectedKey) {
      setSelectedKey(value);
    }
  }, [value, selectedKey]);

  const onSelectCallback = useCallback(
    (event) => {
      // the value in the target is the option id
      const { value: key } = event.target;
      if (key === EmptyOptionKey) {
        onUnselect();
      } else {
        onSelect(key);
      }
      setSelectedKey(key);
    },
    [onSelect, onUnselect]
  );

  useEffect(() => {
    let isCancelled = false;

    const optionList = [...dropdownOptions];
    if (includeEmptyOption) {
      optionList.unshift({ key: EmptyOptionKey, value: "" });
    }

    if (!isCancelled) {
      setOptions(optionList);
    }

    return () => {
      isCancelled = true;
    };
  }, [dropdownOptions, includeEmptyOption]);

  /**
   * Handle default select value
   */
  useEffect(() => {
    let isCancelled = false;

    const unsubscribeEffectCallback = () => {
      isCancelled = true;
    };

    if (!defaultSelectedKey) {
      return unsubscribeEffectCallback;
    }

    // this step is important since the caller of this component may keep on re-render upon listening to
    //  value changes from this component. This may result in the defaultSelectedKey or onSelect callback
    //  to be refreshed and constantly triggering this effect.
    if (lastHandledDefaultKey && lastHandledDefaultKey === defaultSelectedKey) {
      return unsubscribeEffectCallback;
    }

    const filteredOption = options.find((option) => {
      const { key } = option;
      return defaultSelectedKey === key;
    });

    if (!filteredOption) {
      return unsubscribeEffectCallback;
    }

    if (!isCancelled) {
      const { key } = filteredOption;
      setSelectedKey(key);
      setLastHandleDefaultKey(defaultSelectedKey);
    }

    return unsubscribeEffectCallback;
  }, [options, defaultSelectedKey, onSelect, lastHandledDefaultKey]);

  return (
    <FormGroup>
      <Label
        className="form-label"
        for={componentId}
      >
        {label}
      </Label>
      <Input
        type="select"
        value={selectedKey}
        onChange={onSelectCallback}
        id={componentId}
        className={dropdownClassName}
        disabled={autoPopulate}
      >
        {options.map((option) => {
          const { key, value } = option;
          return (
            <option
              value={key}
              key={key}
            >
              {value}
            </option>
          );
        })}
      </Input>
    </FormGroup>
  );
};

DropDownInput.defaultProps = {
  includeEmptyOption: false,
  onSelect: () => {},
  onUnselect: () => {},
  defaultSelectedKey: EmptyOptionKey,
  dropdownClassName: ""
};

DropDownInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  dropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string
    })
  ).isRequired,
  includeEmptyOption: PropTypes.bool,
  onSelect: PropTypes.func,
  onUnselect: PropTypes.func,
  defaultSelectedKey: PropTypes.string,
  dropdownClassName: PropTypes.string
};
