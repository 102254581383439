import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const XButton = ({ label, onClick, className }) => {
  return (
    <button
      onClick={onClick}
      className={`xemelgo-button ${className}`}
      type="button"
    >
      {label}
    </button>
  );
};

XButton.defaultProps = {
  onClick: () => {},
  className: ""
};

XButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string
};
