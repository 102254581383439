/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect, useMemo } from "react";
import { GetApp as ExportIcon } from "@material-ui/icons";
import { useLocation, useHistory } from "react-router-dom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import CreateWorkOrderModal from "../order-track-page-feature/CreateWorkOrderModal";
import ScreenFrame from "../../components/ScreenFrame/ScreenFrame";
import Style from "./OrderTrackPageFeature.module.css";
import { SideFilters } from "./features/side-filters/SideFilters";
import Breadcrumb from "../../components/breadcrumb";
import FilterOverviewControl from "./features/filter-overview-control";
import WorkOrderListTable from "./features/work-order-list-table";
import LocationListTable from "./features/location-list-table";
import { useXemelgoClient } from "../../services/xemelgo-service";
import { useXemelgoAppsyncClient } from "../../services/xemelgo-appsync-service";
import useWorkOrderTrackPageDataSourceContext, {
  WorkOrderTrackPageDataSourceContextProvider
} from "./contexts/work-order-track-page-data-source-context";
import useBreadcrumbBuilder from "../../components/breadcrumb/hooks/use-breadcrumb-builder";
import useWorkOrderTrackPageStateContext, {
  WorkOrderTrackPageStateContextProvider
} from "./contexts/work-order-track-page-state-context";
import useWorkOrderTrackPageConfigContext, {
  WorkOrderTrackPageConfigContextProvider
} from "./contexts/work-order-track-page-config-context";
import FreeTextSearchInput from "../../components/free-text-search-input";
import { getFormattedDate } from "../../common/utils";
import { TAB_OPTION_MAP } from "./data/constants";
import CreateWorkOrderButton from "./features/create-work-order-button";
import DataViewDropdown from "./features/data-view-dropdown";
import LocationOverview from "./features/location-overview";
import MultiSelectActionsBar from "./features/multi-select-actions-bar";
import useMultiSelectActionFactory from "./hooks/use-multi-select-action-factory";
import DisplayBanner from "../../components/display-banner/DisplayBanner";
import useMixpanelContext from "../../context/mixpanel-context";
import {
  WORK_ORDER_TRACK_PAGE_V2_EVENT,
  WORK_ORDER_TRACK_PAGE_V2_STEPS
} from "../../constants/mixpanel-constant/workOrderTrackpageV2";
import WOSettingsMenu from "./features/wo-settings-menu";
import { SUPPORTED_SETTING_ID_MAP } from "./features/wo-settings-menu/hooks/use-settings-builder/data/constants";
import { ReactComponent as OrderIcon } from "../../assets/icons/order.svg";
import xemelgoStyle from "../../styles/variable";
import BulkUpdateWorkOrderButton from "./features/bulk-update-work-order-button";
import { ReactComponent as FullScreenIcon } from "../../assets/icons/fullscreen.svg";
import FullScreenOrderTrackPage from "./features/full-screen-order-track-page";

const REFRESH_INTERVAL = 1000 * 60 * 15;
const mainColor = xemelgoStyle.theme.ORDER_PRIMARY;
const secondaryColor = xemelgoStyle.theme.ORDER_SECONDARY;

export const OrderTrackPageFeature = () => {
  const [showAddOrderModal, setShowAddOrderModal] = useState(false);
  const { sendMixPanelEvent } = useMixpanelContext();
  const fullScreenHandle = useFullScreenHandle();

  const client = useXemelgoClient();
  const appSyncClient = useXemelgoAppsyncClient();

  const history = useHistory();
  const { search, pathname } = useLocation();

  const {
    locationTreeMap,
    setWorkOrderDataList,
    updateLocationMetrics,
    setChildrenLocationMetricMap,
    lastUpdatedTime,
    backlogThresholdToLocationIdMap,
    setBacklogThresholdToLocationIdMap,
    workOrderDataList
  } = useWorkOrderTrackPageDataSourceContext();

  const {
    addOrderEnabled,
    bulkUpdateEnabled,
    useAddOrderV2,
    initialURLParameters,
    isLoading: isConfigLoading,
    settings: settingsConfig
  } = useWorkOrderTrackPageConfigContext();

  const {
    selectedLocationId,
    setSelectedTab,
    selectedTab,
    setIsMetricsLoading,
    setIsLocationListLoading,
    setIsWorkOrderDataListLoading,
    freeTextSearchInputText,
    setFreeTextSearchInputText,
    exportCsvFn,
    selectedOrders,
    setSelectedOrders,
    selectedMultiSelectAction,
    setSelectedMultiSelectAction,
    showActionStatusBanner,
    setShowActionStatusBanner,
    actionStatusBannerError,
    setActionStatusBannerError,
    actionStatusBannerMessage,
    setActionStatusBannerMessage,
    fetchLocationTreeFn,
    enabledMultiSelectOptions
  } = useWorkOrderTrackPageStateContext();

  const selectedAction = useMemo(() => {
    return enabledMultiSelectOptions?.find((option) => {
      return option.id === selectedMultiSelectAction;
    });
  }, [selectedMultiSelectAction, enabledMultiSelectOptions]);

  const onModalClose = (actionAttempted, success, message) => {
    setSelectedMultiSelectAction("");
    setSelectedOrders({});

    if (actionAttempted) {
      setShowActionStatusBanner(true);
      setActionStatusBannerError(!success);
      setActionStatusBannerMessage(message);
    }
  };

  const { MultiSelectActionComponent } = useMultiSelectActionFactory(
    workOrderDataList,
    selectedOrders,
    selectedAction || {},
    onModalClose
  );

  const percentageThresholdKeyMap = useMemo(() => {
    return (
      settingsConfig?.optionControl?.[SUPPORTED_SETTING_ID_MAP.locationThresholdSettingModal]
        ?.percentageThresholdKeyMap || {}
    );
  }, [settingsConfig]);

  const { breadcrumbDataList } = useBreadcrumbBuilder({ selectedLocationId, locationTreeMap });

  const fetchBackThresholdToLocationIdMap = async () => {
    const workOrderClient = await client.getWorkOrderClient();
    const data = await workOrderClient.getBacklogRuleLocationThreshold();
    setBacklogThresholdToLocationIdMap(data);
  };

  const configureInitialURLParameters = () => {
    history.replace(`${pathname}${initialURLParameters}`);
  };

  useEffect(() => {
    sendMixPanelEvent(WORK_ORDER_TRACK_PAGE_V2_EVENT, WORK_ORDER_TRACK_PAGE_V2_STEPS.ENTRY);
  }, []);

  useEffect(() => {
    if (showAddOrderModal) {
      window.fcWidget.hide();
    } else {
      window.fcWidget.show();
      fetchDataOnLocationChanged();
    }
  }, [showAddOrderModal]);

  useEffect(() => {
    if (!isConfigLoading) {
      fetchLocationTreeFn();
      fetchBackThresholdToLocationIdMap();
    }
  }, [isConfigLoading]);

  useEffect(() => {
    if (!search && !isConfigLoading) {
      configureInitialURLParameters();
    }
  }, [search, initialURLParameters, isConfigLoading]);

  const getChildrenLocations = () => {
    return (locationTreeMap[selectedLocationId]?.childLocations || []).length === 1
      ? [locationTreeMap[selectedLocationId]]
      : Object.values(locationTreeMap).filter((eachLocation) => {
          return eachLocation.directParentId === selectedLocationId;
        });
  };

  const fetchChildrenLocationMetrics = async () => {
    setIsMetricsLoading(true);
    setIsLocationListLoading(true);
    const workOrderAppSyncClient = await appSyncClient.getWorkOrderClient();

    const childrenLocations = getChildrenLocations();

    const metricsPromise = [];
    childrenLocations.forEach((eachLocation) => {
      metricsPromise.push(workOrderAppSyncClient.getTrackPageMetricsByLocationIds(eachLocation.childLocations));
    });

    const responses = await Promise.all(metricsPromise);

    setChildrenLocationMetricMap({
      responses,
      childrenLocations,
      backlogThresholdToLocationIdMap,
      locationTreeMap,
      percentageThresholdKeyMap
    });
    setIsMetricsLoading(false);
    setIsLocationListLoading(false);
  };

  const fetchWorkOrders = async () => {
    setIsWorkOrderDataListLoading(true);
    setWorkOrderDataList([]);
    const workOrderAppSyncClient = await appSyncClient.getWorkOrderClient();
    const childrenLocations = getChildrenLocations();

    const childrenLocationIds = childrenLocations.reduce((accumulator, eachLocation) => {
      return accumulator.concat(eachLocation.childLocations);
    }, []);

    const results = childrenLocationIds.length
      ? await workOrderAppSyncClient.getWorkOrdersByLocationIds(childrenLocationIds)
      : [];

    setWorkOrderDataList(results);

    setIsWorkOrderDataListLoading(false);
  };

  const showLocationTab = useMemo(() => {
    const childrenLocations = getChildrenLocations();
    return childrenLocations.length > 1 || childrenLocations[0]?.id !== selectedLocationId;
  }, [selectedLocationId, locationTreeMap]);

  const fetchDataOnLocationChanged = async () => {
    if (Object.keys(locationTreeMap).length && Object.keys(backlogThresholdToLocationIdMap).length) {
      const promiseList = [fetchChildrenLocationMetrics(), fetchWorkOrders()];
      await Promise.all(promiseList);
    }
  };

  useEffect(() => {
    if (fullScreenHandle.active) {
      return;
    }
    fetchDataOnLocationChanged();
    const interval = setInterval(fetchDataOnLocationChanged, REFRESH_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, [selectedLocationId, locationTreeMap, backlogThresholdToLocationIdMap, fullScreenHandle.active]);

  const subscribeToLocationMetrics = async () => {
    const workOrderAppSyncClient = await appSyncClient.getWorkOrderClient();
    const onNextFn = (metrics) => {
      updateLocationMetrics({
        metrics,
        childrenLocations: getChildrenLocations(),
        backlogThresholdToLocationIdMap,
        locationTreeMap,
        percentageThresholdKeyMap
      });
    };
    const onError = (error) => {
      console.log("err", error);
    };

    return workOrderAppSyncClient.subscribeToLocationMetrics(onNextFn, onError);
  };

  useEffect(() => {
    if (Object.keys(locationTreeMap).length > 0) {
      const subscriptionPromise = subscribeToLocationMetrics();
      return () => {
        subscriptionPromise.then((subscription) => {
          subscription.unsubscribe && subscription.unsubscribe();
        });
      };
    }
  }, [locationTreeMap, selectedLocationId]);

  return (
    <>
      <FullScreen handle={fullScreenHandle}>
        {fullScreenHandle.active && (
          <FullScreenOrderTrackPage
            fullScreenHandle={fullScreenHandle}
            refreshInterval={REFRESH_INTERVAL}
            refreshFn={fetchDataOnLocationChanged}
          />
        )}
      </FullScreen>

      {showActionStatusBanner && (
        <DisplayBanner
          bannerError={actionStatusBannerError}
          bannerTitle={actionStatusBannerError ? "Error" : "Success"}
          bannerMessage={actionStatusBannerMessage}
          onCloseBanner={() => {
            setShowActionStatusBanner(false);
          }}
        />
      )}
      <ScreenFrame
        title="Work Orders"
        color={mainColor}
        secondaryColor={secondaryColor}
        titleIconComponent={
          <OrderIcon
            width={25}
            height={25}
            style={{ color: mainColor }}
          />
        }
        titleRightComponent={
          <div className={`${Style.flex_row} ${Style.title_right_container}`}>
            <DataViewDropdown />
            <WOSettingsMenu />
            <div className={`${Style.flex_column} ${Style.last_updated_time_container}`}>
              <p className={Style.last_updated_time_text}>Last Updated:</p>
              <p className={Style.last_updated_time_text}>
                {lastUpdatedTime ? getFormattedDate(lastUpdatedTime) : "-"}
              </p>
            </div>
          </div>
        }
      >
        <div className={`${Style.flex_row} ${Style.container}`}>
          <div className={`${Style.side_column}`}>
            {(addOrderEnabled || bulkUpdateEnabled) && (
              <div className={`${Style.flex_column} ${Style.buttons_container}`}>
                {addOrderEnabled && (
                  <CreateWorkOrderButton
                    useAddOrderV2={useAddOrderV2}
                    onClick={() => {
                      setShowAddOrderModal(true);
                    }}
                  />
                )}
                {bulkUpdateEnabled && <BulkUpdateWorkOrderButton />}
              </div>
            )}
            <div className={Style.side_filter_container}>
              <SideFilters />
            </div>
          </div>
          <div className={`${Style.flex_column} ${Style.content}`}>
            <Breadcrumb dataList={breadcrumbDataList} />
            {selectedTab === TAB_OPTION_MAP.orderTab && <FilterOverviewControl />}
            <LocationOverview />
            <div className={`${Style.flex_row} ${Style.filter_bar_group}`}>
              <div className={Style.free_text_search_container}>
                <FreeTextSearchInput
                  value={freeTextSearchInputText}
                  onChangeText={setFreeTextSearchInputText}
                />
              </div>
              {selectedTab === TAB_OPTION_MAP.orderTab && (
                <FullScreenIcon
                  className={Style.full_screen_button}
                  onClick={async () => {
                    await fullScreenHandle.enter();
                  }}
                />
              )}

              <button
                type="button"
                className={`${Style.flex_row} ${Style.export_button}`}
                onClick={() => {
                  exportCsvFn();
                }}
              >
                <ExportIcon />
                Export as CSV
              </button>
            </div>
            <div className={`${Style.flex_row} ${Style.tabs_group}`}>
              {showLocationTab && (
                <button
                  type="button"
                  className={`${Style.tab} ${selectedTab === TAB_OPTION_MAP.locationTab && Style.tab_active}`}
                  onClick={() => {
                    setSelectedTab(TAB_OPTION_MAP.locationTab);
                  }}
                >
                  Locations
                </button>
              )}
              <button
                type="button"
                className={`${Style.tab} ${selectedTab === TAB_OPTION_MAP.orderTab && Style.tab_active}`}
                onClick={() => {
                  setSelectedTab(TAB_OPTION_MAP.orderTab);
                }}
              >
                All Orders
              </button>
            </div>
            {Object.values(selectedOrders).filter((value) => {
              return value;
            }).length !== 0 && <MultiSelectActionsBar />}
            {selectedTab === TAB_OPTION_MAP.orderTab ? <WorkOrderListTable /> : <LocationListTable />}
          </div>
        </div>
        {showAddOrderModal && (
          <CreateWorkOrderModal
            onCloseClick={async () => {
              setShowAddOrderModal(false);
            }}
          />
        )}
        <MultiSelectActionComponent />
      </ScreenFrame>
    </>
  );
};

export default () => {
  return (
    <WorkOrderTrackPageConfigContextProvider>
      <WorkOrderTrackPageDataSourceContextProvider>
        <WorkOrderTrackPageStateContextProvider>
          <OrderTrackPageFeature />
        </WorkOrderTrackPageStateContextProvider>
      </WorkOrderTrackPageDataSourceContextProvider>
    </WorkOrderTrackPageConfigContextProvider>
  );
};
