export const TITLE = "Goods Receipt";

export const TABLE_COLUMN_SIZE_MAP = {
  small: 2,
  medium: 5,
  large: 8,
  default: 1
};

export const ROW_COUNT_HEADER_LABEL = "#";

export const ROW_COUNT_ATTRIBUTE = "rowCount";
