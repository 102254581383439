import React, { useState, useEffect } from "react";
import { MDBInput } from "mdbreact";
import PropTypes from "prop-types";
import { ReactComponent as RequirementDefaultIcon } from "../../assets/icons/requirement_default.svg";
import { ReactComponent as RequirementMetIcon } from "../../assets/icons/requirement_met.svg";
import { ReactComponent as RequirementNotMetIcon } from "../../assets/icons/requirement_not_met.svg";
import { PASSWORD_REQUIREMENTS, CONFRIM_PASSWORD_REQUIREMENT, CLASS_OVERRIDE_SHAPE } from "./data/constant";
import PasswordRequirementStrip from "./components/password-requirement-strip";
import ShowPasswordToggleIcon from "./components/show-password-toggle-icon";
import Style from "./PasswordInputFormWithValidation.module.css";

const PasswordInputFormWithValidation = ({
  onSubmit,
  submitButtonText,
  onCancel = null,
  cancelButtonText = null,
  classOverrides = null
}) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordRequirementStatus, setPasswordRequirementStatus] = useState({});
  const [showPasswordRequirementStatus, setShowPasswordRequirementStatus] = useState(false);
  const [showPasswordNotMatchWarning, setShowPasswordNotMatchWarning] = useState(false);
  const [showPassowrd, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const initValidationState = (requirements) => {
    const validationState = {};
    requirements.forEach((req) => {
      validationState[req.name] = null;
    });
    return validationState;
  };

  useEffect(() => {
    setPasswordRequirementStatus(initValidationState(PASSWORD_REQUIREMENTS));
  }, []);

  const onPasswordInputChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    updateValidationState(PASSWORD_REQUIREMENTS, newPassword, true, event);

    // this is for the case when user type the confirm password first first,
    //  and then type the password field that match the confirm password field.
    //  we need to hide the warning if the password is matched as user type this field.
    if (newPassword === confirmPassword) {
      setShowPasswordNotMatchWarning(false);
    }
  };

  const onnPasswordInputFocus = () => {
    if (!showPasswordRequirementStatus) {
      setShowPasswordRequirementStatus(true);
    }
  };

  const onPasswordInputBlur = (event) => {
    updateValidationState(PASSWORD_REQUIREMENTS, event.target.value, false, event);

    // if user already entered confirm password, and enter a passowrd that doesn't match, show warning on blur
    if (confirmPassword !== "") {
      setShowPasswordNotMatchWarning(!checkIfPasswordsMatched());
    }
  };

  const onConfirmPasswordInputChange = (event) => {
    setShowPasswordNotMatchWarning(false);
    setConfirmPassword(event.target.value);
  };

  const onConfirmPasswordInputBlur = () => {
    setShowPasswordNotMatchWarning(!checkIfPasswordsMatched());
  };

  const updateValidationState = (requirements, passwordToValidate, isUpdatePassedStateOnly, event = {}) => {
    // only set the requirement status to initial state when user clear the password field.
    if (passwordToValidate === "" && event.type !== "blur" && event.type !== "click") {
      setPasswordRequirementStatus(initValidationState(requirements));
      return;
    }

    const validationState = { ...passwordRequirementStatus };
    requirements.forEach((requirement) => {
      const { name } = requirement;
      const isValid = requirement.isValid(passwordToValidate);

      // if prev state is green, then it can only go to gray
      if (isUpdatePassedStateOnly && !isValid) {
        if (validationState[name] === true) {
          validationState[name] = null;
        }

        return;
      }
      validationState[name] = isValid;
    });
    setPasswordRequirementStatus(validationState);
  };

  const checkIsFormValid = (event) => {
    if (!showPasswordRequirementStatus) {
      setShowPasswordRequirementStatus(true);
    }

    const isPasswordValid = Object.values(passwordRequirementStatus).every((value) => {
      return value === true;
    });

    const isPasswordMatched = checkIfPasswordsMatched();

    if (!isPasswordValid || !isPasswordMatched) {
      updateValidationState(PASSWORD_REQUIREMENTS, password, false, event);
      setShowPasswordNotMatchWarning(!isPasswordMatched);
    }
    return isPasswordValid && isPasswordMatched;
  };

  const getInputType = (showPassword_) => {
    return showPassword_ ? "text" : "password";
  };

  const checkIfPasswordsMatched = () => {
    return confirmPassword === password;
  };

  const passwordRequirementDisplay = (requirements) => {
    return (
      <div>
        {requirements.map((requirement) => {
          const { name, label } = requirement;
          const state = passwordRequirementStatus[name];
          const icon =
            state === null ? (
              <RequirementDefaultIcon title="requirement-default" />
            ) : state ? (
              <RequirementMetIcon title="requirement-met" />
            ) : (
              <RequirementNotMetIcon title="requirement-not-met" />
            );
          return (
            <PasswordRequirementStrip
              label={label}
              icon={icon}
              key={label}
              classOverrides={classOverrides}
            />
          );
        })}
      </div>
    );
  };

  const passwordNotMatchedWarningDisplay = () => {
    const { label } = CONFRIM_PASSWORD_REQUIREMENT;
    const icon = <RequirementNotMetIcon title="password-not-matched" />;
    return (
      <PasswordRequirementStrip
        label={label}
        icon={icon}
        classOverrides={classOverrides}
      />
    );
  };

  return (
    <div className={Style.login_form}>
      <form>
        <div className={Style.form_group}>
          <p
            style={{
              textAlign: "center",
              color: "white"
            }}
          />
          <div className={Style.input_field_container}>
            <MDBInput
              className={`${classOverrides?.passwordInput || Style.password_input}`}
              label="New Password"
              id="newPassword"
              value={password}
              onInput={onPasswordInputChange}
              onFocus={onnPasswordInputFocus}
              onBlur={onPasswordInputBlur}
              type={getInputType(showPassowrd)}
            />
            <button
              aria-label="toggle-show-password"
              type="button"
              onClick={() => {
                setShowPassword(!showPassowrd);
              }}
              className={Style.show_password_button}
            >
              <ShowPasswordToggleIcon
                showPassword={showPassowrd}
                classOverrides={classOverrides}
              />
            </button>
          </div>

          {showPasswordRequirementStatus && passwordRequirementDisplay(PASSWORD_REQUIREMENTS)}
          <div className={Style.input_field_container}>
            <MDBInput
              className={`${classOverrides?.passwordInput || Style.password_input}`}
              label="Confirm Password"
              id="confirmPassword"
              value={confirmPassword}
              onInput={onConfirmPasswordInputChange}
              onBlur={onConfirmPasswordInputBlur}
              type={getInputType(showConfirmPassword)}
            />
            <button
              aria-label="toggle-show-confirm-password"
              type="button"
              onClick={() => {
                setShowConfirmPassword(!showConfirmPassword);
              }}
              className={Style.show_password_button}
            >
              <ShowPasswordToggleIcon
                showPassword={showConfirmPassword}
                classOverrides={classOverrides}
              />
            </button>
          </div>

          {showPasswordNotMatchWarning && passwordNotMatchedWarningDisplay()}
        </div>
        <div className={Style.password_form_button_container}>
          {onCancel && (
            <button
              className={`cancel-button ${Style.change_password_button}`}
              type="button"
              onClick={onCancel}
            >
              {cancelButtonText}
            </button>
          )}
          <button
            aria-label="submit"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              if (checkIsFormValid(e)) {
                e.preventDefault();
                onSubmit(password);
              }
            }}
            className={`default-button ${Style.change_password_button}`}
          >
            {submitButtonText}
          </button>
        </div>
      </form>
    </div>
  );
};

PasswordInputFormWithValidation.defaultProps = {
  onCancel: null,
  cancelButtonText: null,
  classOverrides: null
};

PasswordInputFormWithValidation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  cancelButtonText: PropTypes.string,
  classOverrides: CLASS_OVERRIDE_SHAPE
};

export default PasswordInputFormWithValidation;
