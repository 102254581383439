import React, { useMemo } from "react";
import PropTypes from "prop-types";
import SearchDropdown from "../SearchDropdown/SearchDropdown";
import { roundToNextInterval } from "./utils/round-to-next-interval/roundToNextInterval";
import { TIME_IN_MS } from "../../common/Utilities";
import Style from "./TimeSelector.module.css";

export const TimeSelector = ({ selectedTime, onTimeClick, minuteInterval, maxTime, minTime }) => {
  const roundedTime = roundToNextInterval(new Date(selectedTime), minuteInterval);
  const selectedLabel = roundedTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  const selectedItem = { id: selectedLabel, label: selectedLabel, value: roundedTime };

  const times = useMemo(() => {
    const newTimes = [];
    const startOfDay = minTime || new Date();
    if (!minTime) {
      startOfDay.setHours(0, 0, 0, 0);
    }

    for (let i = 0; i < 24 * 60; i += minuteInterval) {
      const time = new Date(startOfDay.getTime() + i * TIME_IN_MS.MINUTES);

      if (maxTime && time.getTime() > maxTime.getTime()) {
        break;
      }

      const label = time.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
      newTimes.push({ id: label, label, value: time });
    }

    return newTimes;
  }, [maxTime, minTime]);

  return (
    <SearchDropdown
      selectedItem={selectedTime ? selectedItem : undefined}
      options={times}
      onItemSelected={(newItem) => {
        onTimeClick(newItem.value);
      }}
      disableDropDownChevIcon
      showAllOptions
      textareaClassName={Style.dropdown_textarea}
    />
  );
};

TimeSelector.defaultProps = {
  minuteInterval: 15,
  maxTime: undefined,
  minTime: undefined
};

TimeSelector.propTypes = {
  selectedTime: PropTypes.instanceOf(Date).isRequired,
  onTimeClick: PropTypes.func.isRequired,
  minuteInterval: PropTypes.number,
  maxTime: PropTypes.instanceOf(Date),
  minTime: PropTypes.instanceOf(Date)
};
