import { FeatureConfigurationProvider } from "./feature-configuration-provider";
import { ModelConfigurationProvider } from "./model-configuration-provider";
import { BaseConfigurationProvider } from "./base-configuration-provider";

export class AppConfigurationProvider extends BaseConfigurationProvider {
  constructor(appId, configObj, baseModelDomainMap) {
    super(configObj);
    this.appId = appId;

    const { modelMap, featureMap = {} } = configObj;
    const modelConfigurationProvider = ModelConfigurationProvider.parse(modelMap, baseModelDomainMap);
    const mergedModelConfigurationMap = modelConfigurationProvider.getModelDefinitionDomainMap();
    this.featureConfigurationProviderMap = Object.keys(featureMap).reduce((map, featureId) => {
      const newMap = { ...map };
      const featureConfigObj = featureMap[featureId];
      newMap[featureId] = FeatureConfigurationProvider.parse(featureId, featureConfigObj, mergedModelConfigurationMap);
      return newMap;
    }, {});

    this.domainMap = modelConfigurationProvider.getModelDefinitionDomainMap();
  }

  static parse(appId, configuration, baseModelDomainMap) {
    let configObj = configuration || {};
    if (typeof configuration === "string") {
      configObj = JSON.parse(configuration);
    }

    return new AppConfigurationProvider(appId, configObj, baseModelDomainMap);
  }

  getAppId() {
    return this.appId;
  }

  getModelMap() {
    return this.domainMap;
  }

  getModel(modelId) {
    return this.domainMap[modelId];
  }

  getSupportedFeatureIds() {
    const featureNames = Object.keys(this.featureConfigurationProviderMap);
    return featureNames;
  }

  getFeatureConfigurationProvider(featureId, defaultConfiguration = {}) {
    const featureConfigProvider = this.featureConfigurationProviderMap[featureId];
    if (featureConfigProvider) {
      return featureConfigProvider;
    }

    // this happens when the feature [raw] configuration is not provided but within feature component, it asks for the
    //  configuration provider. When this happens, we inject an empty [raw] configuration for the feature and register
    //  the feature config provider.
    const provider = FeatureConfigurationProvider.parse(featureId, defaultConfiguration);
    this.featureConfigurationProviderMap[featureId] = provider;
    return provider;
  }
}
