import React from "react";
import PropTypes from "prop-types";

export const Div = ({ children, style, className }) => {
  return (
    <div
      style={style}
      className={className}
    >
      {children}
    </div>
  );
};

Div.defaultProps = {
  style: {},
  className: "",
  children: ""
};

Div.propTypes = {
  children: PropTypes.node,
  style: PropTypes.shape(),
  className: PropTypes.string
};
