import React, { useContext, useState } from "react";

const BOMManagementContext = React.createContext();

const initialState = {
  additionInformation: {},
  inputPartGroupInfo: [],
  operationInfo: [],
  itemTypeList: []
};

export const useBOMManagementContext = () => {
  return useContext(BOMManagementContext);
};

export const BOMManagementContextProvider = ({ children }) => {
  const [additionalInformation, setAdditionalInformation] = useState(initialState.additionInformation);
  const [inputPartGroupInfo, setInputPartGroupInfo] = useState(initialState.inputPartGroupInfo);
  const [operationInfo, setOperationInfo] = useState(initialState.operationInfo);
  const [itemTypeList, setItemTypeList] = useState(initialState.itemTypeList);

  return (
    <BOMManagementContext.Provider
      value={{
        additionalInformation,
        setAdditionalInformation,
        inputPartGroupInfo,
        setInputPartGroupInfo,
        operationInfo,
        setOperationInfo,
        itemTypeList,
        setItemTypeList
      }}
    >
      {children}
    </BOMManagementContext.Provider>
  );
};
