import React, { Fragment } from "react";
import PropTypes from "prop-types";

const ViewTabComponent = ({ current, data, mainLabel, mainLabelClassname, buttonClassname, buttonActiveClassname }) => {
  if (!data) {
    return null;
  }

  return (
    <>
      {mainLabel && <p className={mainLabelClassname}>{mainLabel}</p>}
      {data.map((eachTab) => {
        const { id, label, onClick } = eachTab;
        if (!id) {
          return;
        }
        return (
          <div
            data-cy-track-page-view-tab
            className={current === id ? buttonActiveClassname : buttonClassname}
            key={id}
            onClick={onClick}
          >
            {label}
          </div>
        );
      })}
    </>
  );
};

export default ViewTabComponent;

ViewTabComponent.defaultProps = {
  current: "",
  data: [],
  mainLabel: "",
  mainLabelClassname: "",
  buttonClassname: "",
  buttonActiveClassname: ""
};

ViewTabComponent.propTypes = {
  current: PropTypes.string,
  data: PropTypes.array,
  mainLabel: PropTypes.string,
  mainLabelClassname: PropTypes.string,
  buttonClassname: PropTypes.string,
  buttonActiveClassname: PropTypes.string
};
