import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as PrintIcon } from "../../assets/icons/printer.svg";
import { ReactComponent as LocationIcon } from "../../assets/icons/location.svg";
import { ReactComponent as CheckMarkIcon } from "../../assets/icons/checkmark.svg";
import Style from "./MultiSelectActionsBar.module.css";

const multiSelectOptionIcons = {
  printTags: PrintIcon,
  setLocation: LocationIcon,
  markAsComplete: CheckMarkIcon
};

export const MultiSelectActionsBar = ({ numOfSelectedItem, multiSelectOptions, onOptionClick, onDeselectAllClick }) => {
  return (
    <div className={Style.action_bar}>
      <div className={Style.selection_info}>
        <div className={Style.selected_amount}>{numOfSelectedItem}</div>
        <div className={Style.selected_text}>selected</div>
        <div
          className={`${Style.action_bar_button} ${Style.deselect_all}`}
          onClick={onDeselectAllClick}
        >
          Deselect All
        </div>
      </div>
      <div className={Style.multi_select_options}>
        {multiSelectOptions.map((option) => {
          const { id, label } = option;

          if (!id || !label) {
            return null;
          }

          const IconComponent = multiSelectOptionIcons[id];

          return (
            <div
              className={`${Style.multi_select_option} ${Style.action_bar_button}`}
              key={id}
              onClick={() => {
                onOptionClick(option);
              }}
            >
              <IconComponent
                data-cy-multi-select-action-button
                width="23px"
                height="23px"
                className={Style.action_icon}
              />
              <div className={`${Style.multi_select_option_text}`}>{label}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

MultiSelectActionsBar.propTypes = {
  multiSelectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  numOfSelectedItem: PropTypes.number.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  onDeselectAllClick: PropTypes.func.isRequired
};
