import React from "react";
import PropTypes from "prop-types";
import AutoSizeTextArea from "../AutoSizeTextArea/AutoSizeTextArea";
import SearchDropdown from "../SearchDropdown/SearchDropdown";
import MyFacilityV2Style from "./MyFacilityV2Components.module.css";

const InputGroup = ({ title, properties, onChange, values, placeholders }) => {
  const resolveTextValue = (propertyName) => {
    if (values[propertyName] !== undefined) {
      return values[propertyName];
    }

    return placeholders && placeholders[propertyName] ? placeholders[propertyName] : undefined;
  };

  const renderInput = (property) => {
    if (property.options) {
      return (
        <SearchDropdown
          options={property.options}
          selectedItem={values[property.name]}
          onItemSelected={(newItem) => {
            onChange(property.name, newItem);
          }}
          error={property.error}
          disabled={property.disabled}
          placeholder={placeholders ? placeholders[property.name] : null}
        />
      );
    }
    return (
      <AutoSizeTextArea
        value={resolveTextValue(property.name)}
        onChangeText={(text) => {
          onChange(property.name, text);
        }}
        error={property.error}
        disabled={property.disabled}
      />
    );
  };

  return (
    <div className={MyFacilityV2Style.body_container}>
      {title}
      <div className={MyFacilityV2Style.input_group}>
        {properties.map((property) => {
          return (
            <div
              key={property.name}
              className={MyFacilityV2Style.input_container}
            >
              {`${property.displayName}${property.optional ? "" : "*"}`}
              {renderInput(property)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

InputGroup.defaultProps = {
  title: "",
  properties: [],
  onChange: () => {},
  values: {},
  placeholders: {}
};

InputGroup.propTypes = {
  title: PropTypes.string,
  properties: PropTypes.array,
  onChange: PropTypes.func,
  values: PropTypes.object,
  placeholders: PropTypes.object
};

export default InputGroup;
