import React from "react";
import PropTypes from "prop-types";
import Style from "../../PaginatedListTableWithTypes.module.css";

export const Status = ({ statusList }) => {
  return (
    <div className={`${Style.flex_row} ${Style.status_container}`}>
      {statusList?.length
        ? statusList.map((eachStatus) => {
            const { title, label, textColor = "white", backgroundColor, color } = eachStatus;
            const displayText = label || title;

            return (
              <div
                key={displayText}
                className={Style.status}
                style={{ backgroundColor: backgroundColor || color, color: textColor }}
              >
                {displayText}
              </div>
            );
          })
        : "-"}
    </div>
  );
};

Status.defaultProps = {
  statusList: []
};

Status.propTypes = {
  statusList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string
    })
  )
};
