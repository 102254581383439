import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import UsersPageFeature from "../../features/users-page-feature/UsersPageFeature";
import { useConfigurationProvider } from "../../services/soft-cache-service";
import { useRoleAuthorization } from "../hooks/useRoleAuthorization";

const UsersApp = () => {
  const { config } = useConfigurationProvider();
  const { settingOptions } = config.webClient;

  const { isAuthorized } = useRoleAuthorization({
    config: settingOptions
  });

  return (
    <>
      {isAuthorized("users") && (
        <Route
          exact
          path="/users"
          component={UsersPageFeature}
        />
      )}
    </>
  );
};

export default UsersApp;
