/**
 * @typedef PropertyDef
 * @property { boolean } [optional=false]
 * @property { string } name
 * @property { string[] } [optionallyDependsOn=null]
 */

/**
 * Validate payload against specific property. This method validate if payload input is required,
 *  and if the input is optionally, does it depends on other input if value is provided.
 * @param payload { object }
 * @param currentProperty { PropertyDef }
 * @returns {{errorFields: [], hasError: boolean}}
 */
const validatePayloadAgainstProperty = (payload, currentProperty, resources = [], editedSections = []) => {
  const { optional, name, optionallyDependsOn, unique } = currentProperty;
  const errorFields = [];

  if (unique) {
    const duplicate = resources.find((resource) => {
      return resource[name] === payload[name];
    });
    const editedProperties = editedSections.filter((element) => {
      return element[name] === payload[name];
    });
    if (duplicate) {
      errorFields.push(name);
      return {
        hasError: !!duplicate,
        errorFields
      };
    }
    if (editedProperties.length > 1) {
      errorFields.push(name);
      return {
        hasError: true,
        errorFields
      };
    }
  }

  // doubly negate will cast value to boolean
  // introduce hasValue variable for code easy readability purpose.
  const hasValue = !!payload[name];
  // case: is required
  if (!optional) {
    const hasError = !hasValue;
    if (hasError) {
      errorFields.push(name);
    }

    return {
      hasError,
      errorFields
    };
  }

  // case: is optional and has no value;
  if (!hasValue) {
    return {
      hasError: false,
      errorFields: []
    };
  }

  // The cases below is when optional property has input value
  // case: with no optional dependencies
  if (!optionallyDependsOn || optionallyDependsOn.length === 0) {
    return {
      hasError: false,
      errorFields: []
    };
  }

  let hasError = false;
  optionallyDependsOn.forEach((field) => {
    const dependencyHasValue = !!payload[field];
    if (!dependencyHasValue) {
      errorFields.push(field);
    }

    hasError = hasError || !dependencyHasValue;
  });

  return {
    hasError,
    errorFields
  };
};

/**
 * Validate payload against all property definitions.
 * @param payload { object }
 * @param properties { PropertyDef[] }
 * @returns {{errorResultMap: {}, hasError: boolean}}
 */
const checkPayloadAgainstPropertiesForError = (payload, properties, resources, editedSections) => {
  let hasError = false;
  const errorResultMap = {};
  properties.forEach((prop) => {
    const result = validatePayloadAgainstProperty(payload, prop, resources, editedSections);
    const { hasError: propertyHasError, errorFields } = result;
    hasError = hasError || propertyHasError;

    errorFields.forEach((errField) => {
      errorResultMap[errField] = true;
    });
  });

  return {
    hasError,
    errorResultMap
  };
};

export { checkPayloadAgainstPropertiesForError };
