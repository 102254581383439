import { useMemo } from "react";
import { useSearchParams } from "react-router-dom-v5-compat";
import { SEARCH_PARAM_NAME } from "./data/constants";
import { TAB_OPTION_MAP } from "../../data/constants";

export const useWorkOrderTrackPageSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedLocationId = searchParams.get(SEARCH_PARAM_NAME.selectedLocationId) || undefined;
  const selectedTab = searchParams.get(SEARCH_PARAM_NAME.selectedTab) || TAB_OPTION_MAP.locationTab;
  const sideFilterValueString = searchParams.get(SEARCH_PARAM_NAME.sideFilterValue);

  const sideFilterValue = useMemo(() => {
    return JSON.parse(sideFilterValueString || "{}");
  }, [sideFilterValueString]);

  const setSelectedLocationId = (newSelectedLocationId) => {
    setSearchParams((prev) => {
      if (!newSelectedLocationId) {
        prev.delete(SEARCH_PARAM_NAME.selectedLocationId);
      } else {
        prev.set(SEARCH_PARAM_NAME.selectedLocationId, newSelectedLocationId);
      }
      return prev;
    });
  };
  const setSelectedTab = (newSelectedTab) => {
    setSearchParams(
      (prev) => {
        if (!newSelectedTab) {
          prev.delete(SEARCH_PARAM_NAME.selectedTab);
        } else {
          prev.set(SEARCH_PARAM_NAME.selectedTab, newSelectedTab);
        }
        return prev;
      },
      { replace: true }
    );
  };

  const setSideFilterValue = (newSideFilterValue) => {
    setSearchParams(
      (prev) => {
        if (!newSideFilterValue || !Object.keys(newSideFilterValue || {}).length) {
          prev.delete(SEARCH_PARAM_NAME.sideFilterValue);
        } else {
          prev.set(SEARCH_PARAM_NAME.sideFilterValue, JSON.stringify(newSideFilterValue));
        }
        return prev;
      },
      {
        replace: true
      }
    );
  };

  return {
    selectedLocationId,
    setSelectedLocationId,
    selectedTab,
    setSelectedTab,
    sideFilterValue,
    setSideFilterValue
  };
};
