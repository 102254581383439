import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import useAssetTrackPageDataSourceContext from "../../contexts/asset-track-page-data-source-context";
import useAssetTrackPageStateContext from "../../contexts/asset-track-page-state-context";
import { TAB_OPTION_MAP } from "../../data/constants";
import Style from "./AssetTrackPageTable.module.css";
import AssetViewTable from "./features/asset-view-table";
import AssetTypeViewTable from "./features/asset-type-view-table";

export const AssetTrackPageTable = () => {
  const { locationTreeMap } = useAssetTrackPageDataSourceContext();
  const {
    areLocationsLoading,
    locationHierarchy,
    selectedLocationId,
    selectedTab,
    sideFilterValue,
    setLocationsToDisplay
  } = useAssetTrackPageStateContext();

  useEffect(() => {
    if (!Object.keys(locationHierarchy).length || !Object.keys(locationTreeMap).length) {
      return;
    }

    const selectedLocationIds = [];
    let tempLocationId = selectedLocationId;
    while (tempLocationId) {
      const { id, directParentId } = locationTreeMap[tempLocationId] || {};
      selectedLocationIds.unshift(id);
      tempLocationId = directParentId;
    }

    let locations = locationHierarchy;
    for (const locationId of selectedLocationIds) {
      locations = locations[locationId] || {};
    }

    let useSideFilterLocations = false;
    if (sideFilterValue.locationId && Array.isArray(sideFilterValue.locationId)) {
      useSideFilterLocations = sideFilterValue.locationId.some((location) => {
        return location.value && locations[location.id];
      });
    }

    const locationsToDisplay = Object.keys(locations).filter((locationId) => {
      const sideFilterHasLocation = useSideFilterLocations
        ? sideFilterValue.locationId.find((location) => {
            return location.value && location.id === locationId;
          })
        : true;
      return (
        sideFilterHasLocation &&
        (selectedLocationId !== locationId || locationTreeMap[locationId].childLocations.length === 1)
      );
    });

    setLocationsToDisplay(locationsToDisplay);
  }, [selectedLocationId, locationHierarchy, locationTreeMap, sideFilterValue]);

  if (areLocationsLoading) {
    return (
      <div className={Style.groups_container}>
        <Skeleton height={30} />
        <Skeleton height={30} />
        <Skeleton height={30} />
      </div>
    );
  }

  return selectedTab === TAB_OPTION_MAP.assetTab ? <AssetViewTable /> : <AssetTypeViewTable />;
};
