/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "mdbreact";
import { withRouter } from "react-router-dom";
import {
  FormatListBulleted,
  ViewModule,
  ChevronRight,
  ChevronLeft,
  Close,
  OpenInBrowser,
  KeyboardArrowDown,
  MoreVert
} from "@material-ui/icons";
import LoadingCircle from "components/loading/LoadingCircle";
import FlashingDots from "components/flashing-dots";
import Skeleton from "react-loading-skeleton";
import TextFilterDropDown from "../../text-filter-dropdown/TextFilterDropDown";
import { getFormattedDate, exportCsv, getValueOrDefault } from "../../../common/Utilities";
import TrackPageComponentStyle from "./TrackPageComponentV2.module.css";
import TabListComponent from "../../tab-list-component/TabListComponent";
import SortIconDropDown from "../../sort-icon-dropdown/SortIconDropDown";
import GridViewComponent from "../../grid-view-component/GridViewComponent";
import ListView from "../ListView";
import ViewTabComponent from "../../ViewTabComponent";
import "react-loading-skeleton/dist/skeleton.css";
import OptionalLink from "../../optional-link/OptionalLink";
import xemelgoStyle from "../../../styles/variable";
import ScreenFrame from "../../ScreenFrame/ScreenFrame";
import MultiSelectActionsBar from "../../multi-select-actions-bar";

const UNKNOWN_LOCATION_ID = "unknown-location-112358";

const TrackPageComponent = ({
  listViewFilterFunc,
  filterFunc,
  defaultFilterInputType,
  mainColor,
  buttonColor, // mainColor is used to control the color of the page, i.e. Inventory: blue, Asset: pink
  secondaryColor, // secondaryCollor is used to control the color paired with the main color of the page, i.e: Inventory: lighter blue, Asset, lighter pink
  statusTab,
  statusViewTabStructure,
  title,
  subTitle,
  backComponentProps,
  buttonTitle,
  buttonFunction,
  disableAddButton,
  titleIcon,
  filterComponent,
  overviewComponent,
  renderDataList,
  defaultNumExpandedHeaders,
  headerBasedContentList,
  shouldShowSeeMore,
  seeMoreCallback,
  shouldSortHeaders,
  preferCollapse,
  getNumSubContentItems,
  getLastPage,
  shouldUseDashForNullListViewContent,
  shouldResetSubContentPageOnRefresh,
  headerMetricsList,
  headerMetricsData,
  headerType,
  headerSortCallback,
  disableHeaderSortButton,
  contentLoading,
  gridCardComponent,
  listViewMode,
  setListViewMode,
  canSwitchListViewMode,
  viewTabStructure,
  viewTabLabel,
  viewTabButtonClassname,
  viewTabButtonActiveClassname,
  currentViewTab,
  hideViewButtons,
  breadcrumbsComponent,
  history,
  listViewStructure,
  contentSortSchema,
  handleListViewClick,
  onHeaderClick,
  currentFilterList,
  removeFilterClick,
  sortSchema,
  flipSortOrder,
  onExportCSV,
  updateFilterFunction,
  getNumRowsPerPage,
  generatePaginationUpdateFunc,
  generateColumnSortUpdateFunc,
  generateExpansionUpdateFunc,
  getCurrentSorterFunction,
  sidebarFeatureButtons,
  additionalExportCsvHeaders,
  additionalButtonsStructureList,
  moreButtonsList,
  numOfItemsPerPage,
  getContentLengthForListView,
  getEnabledStatuses,
  shouldAutoExpandFirstRow,
  useSkeleton,
  exportCsvButtonReady,
  getListViewLinkDetails,
  getHeaderLinkDetails,
  addTransferOrderButton,
  addTransferOrderButtonTitle,
  addTransferOrderButtonFunction,
  multiSelectEnabled,
  multiSelectOptions,
  onMultiSelectOptionClick,
  selectedItemMap,
  onSelectItem
}) => {
  const [showFilter, setShowFilter] = useState(true);
  const [showMoreFilterTabs, setShowMoreFilterTabs] = useState(false);
  const [headerShowDataMap, setHeaderShowDataMap] = useState({});
  const [filterInput, setFilterInput] = useState("");
  const [filterInputType, setFilterInputType] = useState(null);

  const [sortCompareFunc, setSortCompareFunc] = useState(undefined);
  const [sortHeaderDec, setSortHeaderDec] = useState(false);

  const [_, forceUpdate] = useReducer((x) => {
    return x + 1;
  }, 0);

  useEffect(() => {
    if (!filterInputType?.length) {
      setFilterInputType(defaultFilterInputType);
    }
  }, []);

  useEffect(() => {
    updateFilterFunction(filterInput, filterInputType);
  }, [filterInput, filterInputType]);

  useEffect(() => {
    setShowMoreFilterTabs(false);
  }, [currentFilterList]);

  useEffect(() => {
    forceUpdate();
  }, [headerBasedContentList]);

  useEffect(() => {
    const calculateHeaderShowDataMapHelper = (headerList) => {
      return headerList.reduce((accumulator, each) => {
        const { id, subHeaderList } = each;
        accumulator[id] = false;
        if (headerShowDataMap[id] && !preferCollapse) {
          accumulator[id] = headerShowDataMap[id];
        }
        if (subHeaderList?.length) {
          const subMap = calculateHeaderShowDataMapHelper(subHeaderList);
          accumulator = { ...accumulator, ...subMap };
        }
        return accumulator;
      }, {});
    };

    let headerShowDataMapCopy = { ...headerShowDataMap };
    renderDataList
      .sort((a, b) => {
        if (!shouldSortHeaders) {
          return 0;
        }
        if (a.id === UNKNOWN_LOCATION_ID || b.id === UNKNOWN_LOCATION_ID) {
          return a.id === UNKNOWN_LOCATION_ID ? 1 : -1;
        }
        return sortHeaderDec
          ? b.headerTitle.localeCompare(a.headerTitle, undefined, {
              numeric: true,
              sensitivity: "base"
            })
          : a.headerTitle.localeCompare(b.headerTitle, undefined, {
              numeric: true,
              sensitivity: "base"
            });
      })
      .map((headerDataGroup, i) => {
        const { id } = headerDataGroup;
        if (i === 0 && shouldAutoExpandFirstRow) {
          // we need to move this buried check
          headerShowDataMapCopy = {
            ...headerShowDataMapCopy,
            [id]: true
          };
          generateExpansionUpdateFunc(id, true);
        } else if (headerShowDataMapCopy?.[id] === true) {
          generateExpansionUpdateFunc(id, true);
        }
      });

    // calculating the new headerShowDataMap which is used to control which of the header should be expanded or collapsed
    // this includes adding the parent id to the current when there is a tree structure
    const newHeaderShowDataMap = renderDataList?.reduce((accumulator, each) => {
      const { id, subHeaderList } = each;
      accumulator[id] = false;
      if (headerShowDataMapCopy[id]) {
        accumulator[id] = headerShowDataMapCopy[id];
      }
      if (subHeaderList?.length) {
        const subMap = calculateHeaderShowDataMapHelper(subHeaderList);
        accumulator = { ...accumulator, ...subMap };
      }
      return accumulator;
    }, {});

    setHeaderShowDataMap(newHeaderShowDataMap);
  }, [renderDataList]);

  const onExportCSVDefault = async () => {
    const exportCsvListViewHeaderStructure =
      additionalExportCsvHeaders && additionalExportCsvHeaders.length > 0
        ? [...listViewStructure, ...additionalExportCsvHeaders]
        : [...listViewStructure];
    const getDataHelper = (headerGroupList) => {
      const allData = headerGroupList.reduce((accumulator, eachHeaderGroup) => {
        const { dataList = [], subHeaderList = [], headerTitle, hide } = eachHeaderGroup;
        if (!hide) {
          dataList
            .filter((eachData) => {
              return listViewFilterFunc(filterInput, eachData);
            })
            .forEach((eachData) => {
              const one = {};
              if (headerTitle) {
                one["Group By"] = headerTitle;
              }
              exportCsvListViewHeaderStructure.forEach((eachHeader) => {
                if (!String(eachHeader?.id).length) {
                  // e.g. PO's set identifier to empty string as indicator that the
                  // column should be excluded at various points
                  return;
                }
                if (Array.isArray(eachData[eachHeader.id])) {
                  if (eachData[eachHeader.id].length === 0) {
                    one[eachHeader.label] = "-";
                  } else {
                    let flag = "";
                    eachData[eachHeader.id].forEach((eachFlag, index) => {
                      if (eachFlag.displayText) {
                        flag += eachFlag.displayText;
                      } else {
                        flag += eachFlag;
                      }
                      if (index < eachData[eachHeader.id].length - 1) {
                        flag += ", ";
                      }
                    });
                    one[eachHeader.label] = flag;
                  }
                } else if (typeof eachData[eachHeader.id] === "object" && eachData[eachHeader.id]) {
                  one[eachHeader.label] = eachData[eachHeader.id].displayText;
                } else if (
                  typeof eachData[eachHeader.id] !== "string" &&
                  new Date(eachData[eachHeader.id]).getTime() > 0 &&
                  eachData[eachHeader.id].toString().length > 8
                ) {
                  one[eachHeader.label] = getFormattedDate(eachData[eachHeader.id], "hh:mm A MMM D YYYY");
                } else if (eachData[eachHeader.id] || typeof eachData[eachHeader.id] === "number") {
                  one[eachHeader.label] = eachData[eachHeader.id];
                } else {
                  one[eachHeader.label] = "-";
                }
              });
              accumulator.push(one);
            });
        }
        if (subHeaderList?.length) {
          accumulator = accumulator.concat(getDataHelper(subHeaderList));
        }
        return accumulator;
      }, []);
      return allData;
    };

    const fileExtension = ".csv";
    const headerStructureList = [...exportCsvListViewHeaderStructure]; // listViewHeaderStructureListControl(currentViewTab);
    const dataJson = getDataHelper(onExportCSV ? await onExportCSV() : renderDataList);
    const headers = headerStructureList.map((eachHeader) => {
      return eachHeader.label;
    });
    exportCsv(dataJson, headers, `DataSheet${fileExtension}`);
  };

  const renderGridView = (filteredDataList) => {
    if (filteredDataList[0] === "LOADING") {
      if (useSkeleton) {
        return (
          <Skeleton
            className={TrackPageComponentStyle.grid_card_container_style}
            count={4}
            width={280}
            height={120}
            inline
          />
        );
      }
      return <LoadingCircle shouldCenter />;
    }

    return (
      <GridViewComponent>
        {filteredDataList.sort(sortCompareFunc).map((eachData) => {
          return gridCardComponent(eachData, eachData.id, TrackPageComponentStyle.grid_card_container_style);
        })}
      </GridViewComponent>
    );
  };

  const renderListView = (filteredListViewDataList, headerKey) => {
    // headerKey should be something unique to the content row
    // (i.e. header for listview) that allows the fetch function
    // to distinguish which subcontent it's fetching
    // TODO: Listview needs to be refactor
    if (filteredListViewDataList[0] === "LOADING") {
      if (useSkeleton) {
        return (
          <div>
            {[...Array(5)].map((i) => {
              return (
                <div className={TrackPageComponentStyle.skeleton_row}>
                  <Skeleton
                    className={TrackPageComponentStyle.skeleton_cols}
                    count={8}
                    width={100}
                    height={20}
                    borderRadius={10}
                    inline
                  />
                </div>
              );
            })}
          </div>
        );
      }
      return <LoadingCircle shouldCenter />;
    }

    return (
      <ListView
        flipSort={flipSortOrder}
        numOfItemsPerPage={getNumRowsPerPage ? getNumRowsPerPage(headerKey) : numOfItemsPerPage}
        headerStructureList={listViewStructure}
        dataList={filteredListViewDataList}
        shouldUseDash={shouldUseDashForNullListViewContent}
        shouldResetPageOnLoad={shouldResetSubContentPageOnRefresh}
        handleClick={handleListViewClick}
        getLinkDetails={getListViewLinkDetails}
        aPrioriLength={getNumSubContentItems && getNumSubContentItems(headerKey)}
        getLengthObject={
          getContentLengthForListView
            ? {
                function: getContentLengthForListView,
                param0: headerKey,
                param1: getEnabledStatuses()
              }
            : {}
        }
        aPrioriPage={getLastPage && getLastPage(headerKey)}
        paginationUpdateFunc={generatePaginationUpdateFunc(headerKey)}
        columnSortUpdateFunc={generateColumnSortUpdateFunc(headerKey)}
        defaultColumnSort={getCurrentSorterFunction(headerKey)}
        multiSelectEnabled={multiSelectEnabled}
        onMultiClick={(clickedItemIds) => {
          const newSelectedMap = { ...selectedItemMap };

          const allSelected = clickedItemIds.reduce((accumulator, each) => {
            return accumulator && selectedItemMap[each];
          }, true);

          clickedItemIds.forEach((itemId) => {
            if (clickedItemIds.length > 1 ? allSelected : selectedItemMap[itemId]) {
              delete newSelectedMap[itemId];
            } else {
              const item = filteredListViewDataList.find((data) => {
                return data.id === itemId;
              });
              newSelectedMap[itemId] = item;
            }
          });
          onSelectItem(newSelectedMap);
        }}
      />
    );
  };

  // this is the calculate if it should show "Collapse All Location" or "Expand All Location"
  const allCollapsed = Object.keys(headerShowDataMap || {}).reduce((accumulator, eachKey) => {
    if (headerShowDataMap[eachKey]) {
      accumulator = false;
    }
    return accumulator;
  }, defaultNumExpandedHeaders === 0);

  const allExpanded = Object.keys(headerShowDataMap || {}).reduce((accumulator, eachKey) => {
    return accumulator && headerShowDataMap[eachKey];
  }, true);

  const getHeaderTemplate = (headerTitle, id) => {
    const linkDetails = getHeaderLinkDetails ? getHeaderLinkDetails(id) : null;
    const isLinkActive = !!linkDetails;
    return (
      <OptionalLink
        active={isLinkActive}
        route={isLinkActive ? linkDetails.route : null}
        isReplace={isLinkActive ? linkDetails.isReplace : null}
        className={TrackPageComponentStyle.header_title}
        style={isLinkActive ? null : { pointerEvents: "none" }}
        onClick={onHeaderClick}
      >
        {headerTitle}
      </OptionalLink>
    );
  };

  const renderEachHeaderTable = (headerDataList, parentIdList = []) => {
    return headerDataList
      .sort((a, b) => {
        if (!shouldSortHeaders) {
          return 0;
        }
        if (a.id === UNKNOWN_LOCATION_ID || b.id === UNKNOWN_LOCATION_ID) {
          return a.id === UNKNOWN_LOCATION_ID ? 1 : -1;
        }
        return sortHeaderDec
          ? b.headerTitle.localeCompare(a.headerTitle, undefined, {
              numeric: true,
              sensitivity: "base"
            })
          : a.headerTitle.localeCompare(b.headerTitle, undefined, {
              numeric: true,
              sensitivity: "base"
            });
      })
      .map((headerDataGroup) => {
        const { id, headerTitle, metricsList, dataList, subHeaderList, healthColor, hide } = headerDataGroup;

        // if data and metadata are decoupled, use decoupled data
        // to find most-recently-updated data by checking 'time'
        const headerBasedContentListCopy = (headerBasedContentList || []).reverse();
        let contentIndex = -1;
        headerBasedContentListCopy.forEach((item, i) => {
          if (Object.keys(item)[0] === id) {
            if (contentIndex === -1 || item.time > headerBasedContentListCopy[contentIndex].time) {
              contentIndex = i;
            }
          }
        });

        let contentIsLoading = false;
        if (
          contentIndex !== -1 &&
          Object.values(headerBasedContentListCopy[contentIndex] || {}).indexOf("LOADING") !== -1
        ) {
          contentIsLoading = true;
        }

        let filteredDataList = contentIsLoading
          ? ["LOADING"]
          : (contentIndex !== -1 ? Object.values(headerBasedContentListCopy[contentIndex])[0] : dataList || []).filter(
              (eachData) => {
                if (listViewMode) {
                  return listViewFilterFunc(filterInput, eachData);
                }
                return filterFunc(filterInput, eachData);
              }
            );

        const headerHealthColor = getValueOrDefault(() => {
          return headerMetricsData[id].healthColor;
        }, healthColor);

        if (Object.keys(selectedItemMap).length > 0) {
          filteredDataList = filteredDataList.map((eachData) => {
            return {
              ...eachData,
              isChecked: !!selectedItemMap[eachData.id]
            };
          });
        }

        if (!hide) {
          return (
            <div
              key={id}
              className={TrackPageComponentStyle.content_container}
            >
              {!!headerTitle && (
                <div
                  className={TrackPageComponentStyle.header_container}
                  style={{
                    backgroundColor: xemelgoStyle.theme.XEMELGO_LIGHTBLUE,
                    borderLeft: headerHealthColor && `10px solid ${headerHealthColor}`
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      flex: 1
                    }}
                  >
                    <div
                      className={TrackPageComponentStyle.header_group}
                      data-cy-track-page-v2-header-group
                    >
                      <div
                        data-cy-track-page-v2-header-group-button
                        onClick={() => {
                          setHeaderShowDataMap({
                            ...headerShowDataMap,
                            [id]: !headerShowDataMap[id]
                          });
                          generateExpansionUpdateFunc(id, !headerShowDataMap[id]);
                        }}
                      >
                        {headerShowDataMap[id] ? (
                          <KeyboardArrowDown
                            className={TrackPageComponentStyle.header_title_icon}
                            style={{ color: xemelgoStyle.theme.APP_WHITE }}
                          />
                        ) : (
                          <ChevronRight
                            className={TrackPageComponentStyle.header_title_icon}
                            style={{
                              color: xemelgoStyle.theme.APP_WHITE
                            }}
                          />
                        )}
                      </div>
                      {getHeaderTemplate(headerTitle, id)}
                    </div>
                    <div className={TrackPageComponentStyle.metrics_group}>
                      {(headerMetricsList || metricsList).map((eachMetric) => {
                        const { id, label } = eachMetric;
                        const value =
                          headerMetricsData &&
                          headerDataGroup?.id &&
                          headerMetricsData[headerDataGroup.id] &&
                          id !== undefined
                            ? headerMetricsData[headerDataGroup.id][id]
                            : eachMetric?.value;
                        return (
                          <div
                            key={label}
                            className={TrackPageComponentStyle.header_data_group}
                          >
                            <p className={TrackPageComponentStyle.header_data_label}>{`${label}:`}</p>
                            {value === "..." ? (
                              <FlashingDots />
                            ) : (
                              <p
                                className={TrackPageComponentStyle.header_data_value}
                                data-cy-track-page-v2-header-group-data-value
                              >
                                {value}
                              </p>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <div
                      className={TrackPageComponentStyle.header_show_more_btn}
                      onClick={() => {
                        setHeaderShowDataMap({
                          ...headerShowDataMap,
                          [id]: !headerShowDataMap[id]
                        });
                        generateExpansionUpdateFunc(id, !headerShowDataMap[id]);
                      }}
                    >
                      {headerShowDataMap[id] ? "Collapse" : "Expand"}
                    </div>
                  </div>
                </div>
              )}
              {(!headerTitle || headerShowDataMap[id]) && (
                <div className={TrackPageComponentStyle.table_container}>
                  {(!headerTitle || headerShowDataMap[id]) &&
                    (filteredDataList?.length
                      ? listViewMode
                        ? renderListView(filteredDataList, id)
                        : renderGridView(filteredDataList)
                      : !subHeaderList?.length && <div className={TrackPageComponentStyle.table_empty}>No Items</div>)}
                </div>
              )}
              <div className={TrackPageComponentStyle.sub_header_group}>
                {headerShowDataMap[id] &&
                  subHeaderList !== undefined &&
                  renderEachHeaderTable(subHeaderList, parentIdList.concat(id))}
              </div>
            </div>
          );
        }
        return null;
      });
  };

  const renderFilterList = () => {
    return (
      <div className={TrackPageComponentStyle.filter_container}>
        <div className={TrackPageComponentStyle.filter_title_container}>
          <p className={TrackPageComponentStyle.groupby_label}>Filter By</p>
          <div
            className={TrackPageComponentStyle.hide_filter}
            onClick={() => {
              setShowFilter(!showFilter);
            }}
          >
            {`${!showFilter ? "Show Filters" : "Hide Filters"}`}
          </div>
        </div>
        {showFilter && filterComponent}
      </div>
    );
  };

  const renderBackButton = () => {
    if (backComponentProps?.enabled) {
      return (
        <div
          className={TrackPageComponentStyle.back_btn_container}
          onClick={backComponentProps.onClick || (() => {})}
        >
          <ChevronLeft className={TrackPageComponentStyle.back_btn_icon} />
          <p className={TrackPageComponentStyle.back_btn_text}>{`Back to ${backComponentProps.title}`}</p>
        </div>
      );
    }
  };

  const renderFilterTabs = () => {
    if (currentFilterList?.length) {
      return (
        <div className={TrackPageComponentStyle.filter_tab_container}>
          <p className={TrackPageComponentStyle.filter_tab_label}>Filters:</p>
          <div className={TrackPageComponentStyle.filter_tab_list}>
            {currentFilterList
              .sort((a, b) => {
                return a < b;
              })
              .map((each, index) => {
                const { id, label } = each;
                if (index < 4 || showMoreFilterTabs) {
                  return (
                    <div
                      key={`${id}_${label}`}
                      className={TrackPageComponentStyle.filter_tab_item_group}
                      style={{
                        backgroundColor: xemelgoStyle.theme.XEMELGO_LIGHTBLUE
                      }}
                    >
                      <p className={TrackPageComponentStyle.filter_tab_item_title}>{label}</p>
                      <Close
                        onClick={() => {
                          removeFilterClick([id]);
                        }}
                        className={TrackPageComponentStyle.filter_tab_item_close_icon}
                      />
                    </div>
                  );
                }
                return null;
              })}
            {currentFilterList.length > 4 && !showMoreFilterTabs && (
              <div className={TrackPageComponentStyle.filter_tab_more_count}>
                {`+ ${currentFilterList.length - 4} filters`}
              </div>
            )}
            {currentFilterList.length > 4 && (
              <div
                onClick={() => {
                  setShowMoreFilterTabs(!showMoreFilterTabs);
                }}
                className={TrackPageComponentStyle.filter_action_btn}
              >
                {`Show ${showMoreFilterTabs ? "less" : "more"}`}
              </div>
            )}
            <div
              onClick={() => {
                removeFilterClick(
                  currentFilterList.map((each) => {
                    return each.id;
                  })
                );
              }}
              className={TrackPageComponentStyle.filter_action_btn}
            >
              Clear Filters
            </div>
          </div>
        </div>
      );
    }
  };

  const renderMainData = () => {
    if (
      headerMetricsList?.length ||
      additionalButtonsStructureList.length ||
      renderDataList?.reduce((accumulator, each) => {
        const hasData = (subHeaderList) => {
          return subHeaderList.reduce((subHeaderHasData, eachSubHeader) => {
            if (eachSubHeader.dataList.length) {
              subHeaderHasData = true;
            } else if (eachSubHeader.subHeaderList?.length) {
              const result = hasData(eachSubHeader.subHeaderList);
              if (result) {
                subHeaderHasData = result;
              }
            }
            return subHeaderHasData;
          }, false);
        };

        if (each.dataList.length) {
          accumulator = true;
        } else if (each.subHeaderList?.length) {
          const result = hasData(each.subHeaderList);
          if (result) {
            accumulator = result;
          }
        }
        return accumulator;
      }, false)
    ) {
      return (
        <>
          <div>{overviewComponent}</div>
          <div className={TrackPageComponentStyle.filter_and_buttons_group}>
            {contentSortSchema && (
              <div>
                <TextFilterDropDown
                  sortSchema={contentSortSchema}
                  onClick={(it) => {
                    setFilterInputType(it?.id);
                  }}
                />
              </div>
            )}
            <div className={TrackPageComponentStyle.filter_bar_container}>
              <span
                className={`fa fa-search ${TrackPageComponentStyle.filter_bar_icon} ${TrackPageComponentStyle.icon_offset}`}
              />
              <input
                onChange={({ currentTarget }) => {
                  setFilterInput(currentTarget.value || "");
                  updateFilterFunction(currentTarget.value || "", filterInputType);
                }}
                className={TrackPageComponentStyle.filter_bar}
                title="Filter Input Field"
                placeholder={`Type to Filter ${
                  (
                    viewTabStructure?.find((each) => {
                      return each.id === currentViewTab;
                    }) || { label: "" }
                  )?.label
                }`}
              />
            </div>
            <div className={TrackPageComponentStyle.buttons_container}>
              <div className={listViewMode && TrackPageComponentStyle.hide_sort_icon}>
                <SortIconDropDown
                  sortSchema={sortSchema}
                  onClick={(compareFunc) => {
                    setSortCompareFunc(() => {
                      return compareFunc;
                    });
                  }}
                />
              </div>
              {canSwitchListViewMode &&
                (listViewMode ? (
                  <ViewModule
                    className={TrackPageComponentStyle.icon_button}
                    onClick={() => {
                      setListViewMode(false);
                    }}
                  />
                ) : (
                  <FormatListBulleted
                    className={TrackPageComponentStyle.icon_button}
                    onClick={() => {
                      setListViewMode(true);
                    }}
                  />
                ))}
              {additionalButtonsStructureList.map(({ id, onClick, title, iconComponent, linkRoute }) => {
                return (
                  <OptionalLink
                    key={id}
                    active={!!linkRoute}
                    route={linkRoute}
                  >
                    <div
                      id={id}
                      onClick={onClick}
                      className={TrackPageComponentStyle.csv_btn}
                      style={{ backgroundColor: buttonColor || mainColor }}
                    >
                      {iconComponent}
                      {title}
                    </div>
                  </OptionalLink>
                );
              })}
              {exportCsvButtonReady ? (
                <div
                  onClick={onExportCSVDefault}
                  className={TrackPageComponentStyle.csv_btn}
                >
                  <OpenInBrowser className={TrackPageComponentStyle.csv_icon} />
                  Export as CSV
                </div>
              ) : (
                <Skeleton
                  width={147}
                  height={40}
                />
              )}
            </div>
          </div>
          {Object.keys(selectedItemMap).length > 0 && (
            <MultiSelectActionsBar
              numOfSelectedItem={Object.keys(selectedItemMap).length}
              multiSelectOptions={multiSelectOptions}
              onOptionClick={(option) => {
                onMultiSelectOptionClick(option);
              }}
              onDeselectAllClick={() => {
                onSelectItem(new Set());
              }}
            />
          )}
          {!!viewTabStructure?.length && shouldRenderSortAndCollapseHeader && (
            <div className={TrackPageComponentStyle.view_container}>
              {!hideViewButtons && !!viewTabStructure?.length && (
                <ViewTabComponent
                  current={currentViewTab}
                  data={viewTabStructure}
                  mainLabel={viewTabLabel}
                  mainLabelClassname={TrackPageComponentStyle.view_label}
                  buttonClassname={viewTabButtonClassname || TrackPageComponentStyle.view_btn}
                  buttonActiveClassname={viewTabButtonActiveClassname || TrackPageComponentStyle.view_btn_active}
                />
              )}

              {shouldRenderSortAndCollapseHeader && (
                <div className={TrackPageComponentStyle.small_action_container}>
                  {!disableHeaderSortButton && (
                    <div
                      onClick={() => {
                        setSortHeaderDec(!sortHeaderDec);
                        if (headerSortCallback) {
                          // if caller queries content and headers dynamically, need to use the callback
                          // to alert caller that header sort has changed
                          headerSortCallback(!sortHeaderDec);
                        }
                      }}
                      className={TrackPageComponentStyle.sort_header_btn}
                    >
                      {`Sorting ${headerType} By: ${sortHeaderDec ? "Z-A" : "A-Z"}`}
                    </div>
                  )}
                  <div
                    className={TrackPageComponentStyle.header_collapse_btn}
                    onClick={() => {
                      const newHeaderShowDataMap = {};
                      Object.keys(headerShowDataMap).forEach((eachKey) => {
                        const newState = preferCollapse ? allCollapsed : !allExpanded;
                        newHeaderShowDataMap[eachKey] = newState;
                        generateExpansionUpdateFunc(eachKey, newState);
                      });
                      setHeaderShowDataMap(newHeaderShowDataMap);
                    }}
                  >
                    {preferCollapse && `${allCollapsed ? "Expand" : "Collapse"} all ${headerType.toLowerCase()}`}
                    {!preferCollapse && `${allExpanded ? "Collapse" : "Expand"} all ${headerType.toLowerCase()}`}
                  </div>
                </div>
              )}
            </div>
          )}
          <div className={TrackPageComponentStyle.datalist_container}>
            {contentLoading ? (
              useSkeleton ? (
                <Skeleton
                  className={TrackPageComponentStyle.skeleton_header}
                  count={5}
                  height={34}
                />
              ) : (
                <LoadingCircle shouldCenter />
              )
            ) : renderDataList.length ? (
              <>
                {renderEachHeaderTable(renderDataList)}
                {shouldShowSeeMore && (
                  <div
                    onClick={seeMoreCallback}
                    style={{
                      textDecorationLine: "underline",
                      cursor: "pointer",
                      textAlign: "center"
                    }}
                  >
                    See More
                  </div>
                )}
              </>
            ) : (
              <div className={TrackPageComponentStyle.no_data}>No data available to show</div>
            )}
          </div>
        </>
      );
    }

    return <div className={TrackPageComponentStyle.no_data}>No data available to show</div>;
  };

  const shouldRenderSortAndCollapseHeader = (renderDataList || []).reduce((accumulator, eachData) => {
    if (eachData.headerTitle) {
      accumulator = true;
    }
    return accumulator;
  }, false);

  const renderAddButton = () => {
    const isLinkActive = !(buttonFunction && buttonTitle);
    return (
      <OptionalLink
        active={isLinkActive}
        route={`${history.location.pathname}/create`}
        className={TrackPageComponentStyle.create_button}
        onClick={isLinkActive ? null : buttonFunction}
        data-cy-track-page-v2__create-button
      >
        {buttonTitle}
      </OptionalLink>
    );
  };

  const renderTrackingOrderButton = () => {
    const isLinkActive = !(addTransferOrderButtonFunction && addTransferOrderButtonTitle);
    return (
      <OptionalLink
        active={isLinkActive}
        route={`${history.location.pathname}/bulk-create-transfer-order`}
        className={TrackPageComponentStyle.create_button}
        onClick={isLinkActive ? null : addTransferOrderButtonFunction}
      >
        {addTransferOrderButtonTitle}
      </OptionalLink>
    );
  };

  return (
    <ScreenFrame
      title={title}
      subTitle={subTitle}
      color={mainColor}
      secondaryColor={secondaryColor}
      titleIconComponent={titleIcon}
      titleRightComponent={
        <div className={TrackPageComponentStyle.title_container}>
          {Boolean(moreButtonsList.length) && (
            <div className={TrackPageComponentStyle.button_group}>
              <Dropdown>
                <DropdownToggle nav>
                  <MoreVert style={{ color: buttonColor || mainColor }} />
                </DropdownToggle>
                <DropdownMenu>
                  {moreButtonsList.map((each) => {
                    const { id, title: buttonTitle, onClick } = each;
                    return (
                      <OptionalLink
                        key={id}
                        active={each.isLinkActive}
                        route={each.linkRoute}
                        isReplace={each.linkIsReplace}
                      >
                        <DropdownItem
                          id={id}
                          onClick={onClick}
                        >
                          {buttonTitle}
                        </DropdownItem>
                      </OptionalLink>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>
          )}
        </div>
      }
    >
      <div className={TrackPageComponentStyle.main_container}>
        {(filterComponent || buttonTitle || sidebarFeatureButtons || statusViewTabStructure) && (
          <div className={TrackPageComponentStyle.left_main_container}>
            {(buttonTitle || sidebarFeatureButtons || statusViewTabStructure) && (
              <div className={TrackPageComponentStyle.buttons_group}>
                {!disableAddButton && renderAddButton()}
                {addTransferOrderButton && renderTrackingOrderButton()}
                {sidebarFeatureButtons}
                {statusViewTabStructure && (
                  <TabListComponent
                    tabStructure={statusViewTabStructure}
                    tabStyle={TrackPageComponentStyle.tab_style}
                    tabListStyle={TrackPageComponentStyle.tab_list_style}
                    focusedTabStyle={TrackPageComponentStyle.focused_tab_style}
                    disabledTabStyle={TrackPageComponentStyle.disabled_tab_style}
                    tabListHeaderStyle={TrackPageComponentStyle.groupby_label}
                    focusedTab={statusTab}
                  />
                )}
              </div>
            )}
            {renderFilterList()}
          </div>
        )}
        <div className={TrackPageComponentStyle.navigation_group}>
          {(breadcrumbsComponent || currentFilterList) && (
            <div className={TrackPageComponentStyle.page_info_container}>
              {renderBackButton()}
              <div className={TrackPageComponentStyle.breadcrumb_container}>{breadcrumbsComponent}</div>
              {renderFilterTabs()}
            </div>
          )}
          <div
            className={TrackPageComponentStyle.data_container}
            style={!backComponentProps && !breadcrumbsComponent ? { borderTop: 0 } : {}}
          >
            {renderMainData()}
          </div>
        </div>
      </div>
    </ScreenFrame>
  );
};

export default withRouter(TrackPageComponent);

TrackPageComponent.defaultProps = {
  headerType: "Locations",
  viewTabLabel: "View: ",
  defaultNumExpandedHeaders: 0,
  shouldShowSeeMore: false,
  shouldSortHeaders: true,
  preferCollapse: true,
  defaultFilterInputType: null,
  shouldUseDashForNullListViewContent: false,
  shouldResetSubContentPageOnRefresh: true,
  updateFilterFunction: () => {},
  getNumRowsPerPage: null,
  generateColumnSortUpdateFunc: () => {},
  generatePaginationUpdateFunc: () => {},
  generateExpansionUpdateFunc: () => {},
  getCurrentSorterFunction: () => {},
  viewTabButtonClassname: TrackPageComponentStyle.view_btn,
  viewTabButtonActiveClassname: TrackPageComponentStyle.view_btn_active,
  additionalButtonsStructureList: [],
  moreButtonsList: [],
  getContentLengthForListView: null,
  shouldAutoExpandFirstRow: true,
  useSkeleton: false,
  exportCsvButtonReady: true,
  selectedItemMap: {},
  multiSelectEnabled: false,
  multiSelectOptions: [],
  onSelectItem: () => {},
  onMultiSelectOptionClick: () => {}
};

TrackPageComponent.propTypes = {
  mainColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  statusTab: PropTypes.string,
  statusViewTabStructure: PropTypes.array,
  title: PropTypes.string.isRequired,
  backComponentProps: PropTypes.object,
  buttonTitle: PropTypes.string,
  buttonFunction: PropTypes.func,
  disableAddButton: PropTypes.bool,
  titleIcon: PropTypes.object,
  filterComponent: PropTypes.object,
  overviewComponent: PropTypes.object,
  renderDataList: PropTypes.array,
  defaultNumExpandedHeaders: PropTypes.number,
  headerBasedContentList: PropTypes.array,
  shouldShowSeeMore: PropTypes.bool,
  seeMoreCallback: PropTypes.func,
  shouldSortHeaders: PropTypes.bool,
  preferCollapse: PropTypes.bool,
  generateFetchFunction: PropTypes.func,
  getNumSubContentItems: PropTypes.func,
  getLastPage: PropTypes.func,
  shouldUseDashForNullListViewContent: PropTypes.bool,
  shouldResetSubContentPageOnRefresh: PropTypes.bool,
  headerMetricsList: PropTypes.array,
  headerMetricsData: PropTypes.object,
  headerType: PropTypes.string,
  disableHeaderSortButton: PropTypes.bool,
  headerSortCallback: PropTypes.func,
  contentLoading: PropTypes.bool,
  gridCardComponent: PropTypes.func,
  listViewMode: PropTypes.bool,
  setListViewMode: PropTypes.func,
  canSwitchListViewMode: PropTypes.bool,
  viewTabStructure: PropTypes.array,
  viewTabLabel: PropTypes.string,
  viewTabButtonClassname: PropTypes.string,
  viewTabButtonActiveClassname: PropTypes.string,
  currentViewTab: PropTypes.string,
  hideViewButtons: PropTypes.bool,
  breadcrumbsComponent: PropTypes.object,
  history: PropTypes.object,
  listViewStructure: PropTypes.array,
  contentSortSchema: PropTypes.array,
  handleListViewClick: PropTypes.func,
  onHeaderClick: PropTypes.func,
  currentFilterList: PropTypes.array,
  removeFilterClick: PropTypes.func,
  onExportCSV: PropTypes.func,
  sortSchema: PropTypes.array,
  flipSortOrder: PropTypes.bool,
  sidebarFeatureButtons: PropTypes.array,
  listViewFilterFunc: PropTypes.func,
  filterFunc: PropTypes.func,
  defaultFilterInputType: PropTypes.string,
  updateFilterFunction: PropTypes.func,
  getNumRowsPerPage: PropTypes.func,
  generatePaginationUpdateFunc: PropTypes.func,
  generateColumnSortUpdateFunc: PropTypes.func,
  generateExpansionUpdateFunc: PropTypes.func,
  getCurrentSorterFunction: PropTypes.func,
  buttonColor: PropTypes.string,
  additionalButtonsStructureList: PropTypes.array,
  subTitle: PropTypes.string,
  moreButtonsList: PropTypes.array,
  numOfItemsPerPage: PropTypes.number,
  getContentLengthForListView: PropTypes.func,
  getEnabledStatuses: PropTypes.func,
  shouldAutoExpandFirstRow: PropTypes.bool,
  useSkeleton: PropTypes.bool,
  exportCsvButtonReady: PropTypes.bool,
  selectedItemMap: PropTypes.objectOf(PropTypes.any),
  multiSelectEnabled: PropTypes.bool,
  multiSelectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string
    })
  ),
  onSelectItem: PropTypes.func,
  onMultiSelectOptionClick: PropTypes.func
};
