import React from "react";
import PropTypes from "prop-types";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import ExitFullscreenIcon from "@material-ui/icons/FullscreenExit";
import Style from "./HeaderRightComponent.module.css";

export const HeaderRightComponent = ({ fullscreenMode, onChangeFullscreenMode, status }) => {
  return (
    <div className={Style.flex_row}>
      <div
        className={Style.status_text}
        style={{ color: status.statusMessageColor }}
      >
        {status.statusMessage}
      </div>
      <div
        onClick={() => {
          onChangeFullscreenMode(!fullscreenMode);
        }}
        className={Style.fullscreen_button}
      >
        {fullscreenMode ? (
          <ExitFullscreenIcon className={Style.fullscreen_icon} />
        ) : (
          <FullscreenIcon className={Style.fullscreen_icon} />
        )}
        {fullscreenMode ? "Exit Full Screen" : "Full Screen"}
      </div>
    </div>
  );
};

HeaderRightComponent.defaultProps = {
  status: {}
};

HeaderRightComponent.propTypes = {
  fullscreenMode: PropTypes.bool.isRequired,
  onChangeFullscreenMode: PropTypes.func.isRequired,
  status: PropTypes.shape({
    statusMessage: PropTypes.string,
    statusMessageColor: PropTypes.string
  })
};
