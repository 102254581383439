import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "mdbreact";
import { KeyboardArrowDown as ExpandIcon } from "@material-ui/icons";
import useAssetTrackPageStateContext from "../../../../contexts/asset-track-page-state-context";
import useAssetTrackPageConfigContext from "../../../../contexts/asset-track-page-config-context";
import Style from "./TableOptions.module.css";

export const TableOptions = ({ onColumnSelectClick }) => {
  const { locationSortOptions } = useAssetTrackPageConfigContext();
  const { locationSortOrder, setLocationSortOrder, setExpandedLocations, locationsToDisplay, expandedLocations } =
    useAssetTrackPageStateContext();

  const isAnyLocationExpanded = useMemo(() => {
    return locationsToDisplay.some((locationId) => {
      return expandedLocations[locationId] === true;
    });
  }, [expandedLocations, locationsToDisplay]);

  return (
    <div className={Style.table_controls_container}>
      <button
        type="button"
        onClick={onColumnSelectClick}
        className={Style.blue_text}
      >
        Columns
      </button>
      <Dropdown>
        <DropdownToggle
          nav
          className={`${Style.blue_text} ${Style.flex_row} ${Style.sort_button}`}
        >
          {`Sort Locations By: ${locationSortOptions[locationSortOrder]}`}
          <ExpandIcon className={Style.expand_icon} />
        </DropdownToggle>
        <DropdownMenu data-cy="sort-by-dropdown">
          {Object.keys(locationSortOptions).map((key) => {
            return (
              <DropdownItem
                key={key}
                value="Type"
                onClick={() => {
                  setLocationSortOrder(key);
                }}
              >
                {locationSortOptions[key]}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
      <div className={Style.expand_button_area}>
        <button
          type="button"
          onClick={() => {
            const newExpandedLocations = { ...expandedLocations };
            locationsToDisplay.forEach((locationId) => {
              newExpandedLocations[locationId] = !isAnyLocationExpanded;
            });
            setExpandedLocations(newExpandedLocations);
          }}
          className={`${Style.blue_text} ${Style.expand_button}`}
        >
          {`${isAnyLocationExpanded ? "Collapse" : "Expand"} all locations`}
        </button>
      </div>
    </div>
  );
};

TableOptions.propTypes = {
  onColumnSelectClick: PropTypes.func.isRequired
};
