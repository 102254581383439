import xemelgoStyle from "../../../../styles/variable";
import { ISSUE_TABLE_OPTIONS } from "../../data/constants";

export const parseItemIssues = (issues, issueTableOptions = []) => {
  const resolvedIssueOptions = issueTableOptions?.filter((option) => {
    return option.id !== ISSUE_TABLE_OPTIONS.resolve;
  });

  return issues.map((issue) => {
    const issueResolved = !!issue.issue_end_time;

    const locations = issue.occurredAt
      .map((location) => {
        return location.identifier || location.name;
      })
      .sort((a, b) => {
        return a.localeCompare(b);
      })
      .join(", ");

    const issueTypes = issue.issue_types
      ?.sort((a, b) => {
        return a.localeCompare(b);
      })
      .join(", ");

    return {
      ...issue,
      locationsString: locations,
      issueTypesString: issueTypes,
      statusAccentColor: issueResolved ? xemelgoStyle.theme.STATUS_GREEN : xemelgoStyle.theme.STATUS_RED,
      rowOptions: issueResolved ? resolvedIssueOptions : issueTableOptions
    };
  });
};
