import React from "react";
import PropTypes from "prop-types";

import Style from "./TableActionBar.module.css";

export const TableActionBar = ({ visible, summary, actions }) => {
  const renderAction = (action, index) => {
    return (
      <button
        key={action.id || index}
        type="button"
        onClick={action.onClick}
        style={action.color ? { color: action.color } : null}
      >
        <div className={`${Style.icon_container}`}>{action.iconComponent && action.iconComponent}</div>
        <p>{action.title}</p>
      </button>
    );
  };

  return (
    <div
      className={`${Style.action_bar_container}`}
      style={{ visibility: visible ? "visible" : "hidden" }}
    >
      {summary && <p>{summary}</p>}
      {actions.map(renderAction)}
    </div>
  );
};

TableActionBar.defaultProps = {
  summary: null
};

TableActionBar.propTypes = {
  visible: PropTypes.bool.isRequired,
  summary: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      onClick: PropTypes.func,
      color: PropTypes.string,
      iconComponent: PropTypes.node
    })
  ).isRequired
};
