import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "../modal";
import MultiInputForm from "../multi-input-form";
import Style from "./ReportIssueModal.module.css";
import { useXemelgoClient } from "../../services/xemelgo-service";
import { getFieldValue } from "../multi-input-form/utils/get-field-value/getFieldValue";
import { validateFormFields } from "../multi-input-form/utils/validate-form-fields/validateFormFields";
import { DEFAULT_REPORT_ISSUE_FORM_FIELDS } from "./data/constants";
import { STATUS_OPTIONS } from "../status-popup-component";
import useMixpanelContext from "../../context/mixpanel-context";
import { ISSUE_REPORTING, ISSUE_REPORTING_STEPS } from "../../constants/mixpanel-constant/issueReporting";

export const ReportIssueModal = ({ itemId, formFields, onClose, locations, defaultLocationId, possibleIssueTypes }) => {
  const { sendMixPanelEvent } = useMixpanelContext();
  const [issueClient] = useState(useXemelgoClient().getIssueClient());
  const [fields, setFields] = useState(formFields);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const newFields = formFields.map((field) => {
      const newField = { ...field };
      if (newField.id === "location") {
        newField.options = locations;
      } else if (field.id === "issue_types" && !field.options) {
        newField.options = possibleIssueTypes;
      }
      return newField;
    });

    setFields(newFields);
  }, [formFields, locations, possibleIssueTypes]);

  const onConfirm = async () => {
    let locationId = defaultLocationId;

    setIsSubmitting(true);
    const issueFields = fields.reduce((acc, field) => {
      if (field.id === "location") {
        locationId = getFieldValue(field);
      } else if (field.value) {
        acc[field.id] = getFieldValue(field);
      }

      return acc;
    }, {});

    try {
      await issueClient.reportIssue(itemId, issueFields, locationId);
      sendMixPanelEvent(ISSUE_REPORTING, ISSUE_REPORTING_STEPS.REPORT_ISSUE_SUCCEEDED);

      setIsSubmitting(false);
      onClose(STATUS_OPTIONS.SUCCESS, "The issue has been reported.");
    } catch (error) {
      sendMixPanelEvent(ISSUE_REPORTING, ISSUE_REPORTING_STEPS.REPORT_ISSUE_SUCCEEDED, {
        error_message: typeof err === "string" ? error : error.message
      });

      onClose(STATUS_OPTIONS.ERROR, "Failed to report issue.");
    }
  };

  return (
    <Modal
      title="Report an Issue"
      onConfirm={onConfirm}
      onCancel={() => {
        onClose(STATUS_OPTIONS.NONE);
      }}
      confirmDisabled={!validateFormFields(fields) || isSubmitting}
      confirmButtonText="Submit"
      modalDialogClassName={Style.modal_dialog}
      closeOnClickOutside={false}
    >
      <MultiInputForm
        isFormDisabled={isSubmitting}
        formFields={fields}
        onChange={(fieldId, newValue) => {
          const newFields = fields.map((field) => {
            if (field.id === fieldId) {
              return { ...field, value: newValue };
            }
            return field;
          });
          setFields(newFields);
        }}
        containerClassName={Style.multi_input_container}
        fieldContainerClassName={Style.multi_input_field_container}
      />
    </Modal>
  );
};

ReportIssueModal.defaultProps = {
  formFields: DEFAULT_REPORT_ISSUE_FORM_FIELDS,
  locations: [],
  defaultLocationId: "",
  possibleIssueTypes: []
};

ReportIssueModal.propTypes = {
  itemId: PropTypes.string.isRequired,
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string,
      isRequired: PropTypes.bool,
      value: PropTypes.any
    })
  ),
  onClose: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  defaultLocationId: PropTypes.string,
  possibleIssueTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  )
};
