/* eslint-disable no-param-reassign */
import { mergeWith } from "lodash";

export const getCombinedConfiguration = (configurations) => {
  const combinedConfiguration = { categories: [] };
  const categoryGroups = configurations.reduce((categoryGroupsMap, config) => {
    for (const category of config.categories || []) {
      if (!category.id) {
        if (!categoryGroupsMap.none) {
          categoryGroupsMap.none = [];
        }

        categoryGroupsMap.none.push(category);
      } else {
        if (!categoryGroupsMap[category.id]) {
          categoryGroupsMap[category.id] = [];
        }

        categoryGroupsMap[category.id].push(category);
      }
    }

    return categoryGroupsMap;
  }, {});

  const cagetoryIdsExclusdingNone = Object.keys(categoryGroups).filter((categoryKey) => {
    return categoryKey !== "none";
  });

  for (const categoryId of cagetoryIdsExclusdingNone) {
    const combinedCategory = mergeWith({}, ...categoryGroups[categoryId], (objValue, srcValue) => {
      if (Array.isArray(objValue)) {
        if (!(objValue || [])[0].id) {
          return undefined;
        }

        return mergeArrayWithIdsViaReplacement(objValue, srcValue);
      }

      return undefined;
    });

    combinedConfiguration.categories.push(combinedCategory);
  }

  combinedConfiguration.categories = combinedConfiguration.categories.concat(categoryGroups.none || []);
  return combinedConfiguration;
};

export const applySideFilters = (configuration, sideFilterValues, item) => {
  if (!configuration || !configuration.categories) {
    return true;
  }

  const allFiltersToApply = configuration.categories.reduce((filters, categoryConfig) => {
    return filters.concat(
      (categoryConfig.filters || []).map((filter) => {
        return filter.filterToApply;
      })
    );
  }, []);

  const passesAllFilters = allFiltersToApply.reduce((passesFilter, filter) => {
    return passesFilter && filter(sideFilterValues, item);
  }, true);

  return passesAllFilters;
};

const mergeArrayWithIdsViaReplacement = (source, target) => {
  const mergedArray = [];
  const addedIds = [];

  for (const sourceItem of source) {
    const matchingTargetItem = target.find((targetItem) => {
      return sourceItem.id === targetItem.id;
    });

    mergedArray.push(matchingTargetItem || sourceItem);
    addedIds.push(matchingTargetItem ? matchingTargetItem.id : sourceItem.id);
  }

  const targetItemsNotCaputured = target.filter((targetItem) => {
    return !addedIds.includes(targetItem.id);
  });

  return mergedArray.concat(targetItemsNotCaputured);
};
