import { v4 } from "uuid";

const TimeoutIdRegistry = {};

export const ClearScheduledInterval = (syncIntervalId) => {
  if (!syncIntervalId) {
    return;
  }

  const timeoutId = TimeoutIdRegistry[syncIntervalId];
  clearTimeout(timeoutId);
  delete TimeoutIdRegistry[syncIntervalId];
};

export const ScheduledSyncWorkflowInterval = (asyncFn, intervalInMs) => {
  const id = v4();

  const workflow = async () => {
    await asyncFn();

    // if the registry still have the scheduledId registered, then we can start the
    //  next cycle, otherwise, stop scheduling for the next interval
    if (TimeoutIdRegistry[id]) {
      TimeoutIdRegistry[id] = setTimeout(() => {
        return workflow();
      }, intervalInMs);
    }
  };

  TimeoutIdRegistry[id] = setTimeout(() => {
    return workflow();
  }, intervalInMs);
  return id;
};
