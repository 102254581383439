import _ from "lodash";
import { v4 } from "uuid";

// This function return mock data as a placeholder for an API
export const getScheduledWorkOrders = async (workCenterGroupId) => {
  await new Promise((resolve) => {
    return setTimeout(resolve, 500);
  });
  return Promise.resolve([
    {
      id: v4(),
      task_identifier: "100000",
      task_scheduledDate: 1659631054000,
      mustStartTimestamp: 1613170864397,
      task_dueDate: 1613170864397,
      outputPart_identifier: "S019BRNBRN48120",
      outputPart_description: "",
      outputPart_gauge_ts: "26GA",
      outputPart_color_ts: "BLK/BRN",
      outputPart_strechout_ts: 1
    },
    {
      id: v4(),
      task_identifier: "100001",
      task_scheduledDate: 1659371854000,
      mustStartTimestamp: 1613170864397,
      task_dueDate: 1613170864397,
      outputPart_identifier: "C019BLKPRM24",
      outputPart_description: "",
      outputPart_gauge_ts: "27GA",
      outputPart_color_ts: "BLK/PRM",
      outputPart_strechout_ts: 1
    },
    {
      id: v4(),
      task_identifier: "100002",
      task_scheduledDate: 1659803854000,
      mustStartTimestamp: 1613170864397,
      task_dueDate: 1613170864397,
      outputPart_identifier: "S019BLKBLK48120",
      outputPart_description: "",
      outputPart_gauge_ts: "30GA",
      outputPart_color_ts: "WHT/PR",
      outputPart_strechout_ts: 4
    },
    {
      id: v4(),
      task_identifier: "100002",
      task_scheduledDate: 1659803854000,
      mustStartTimestamp: 1613170864397,
      task_dueDate: 1613170864397,
      outputPart_identifier: "S019BLKBLK48120",
      outputPart_description: "",
      outputPart_gauge_ts: "30GA",
      outputPart_color_ts: "WHT/PR",
      outputPart_strechout_ts: 4
    },
    {
      id: v4(),
      task_identifier: "100002",
      task_scheduledDate: 1659803854000,
      mustStartTimestamp: 1613170864397,
      task_dueDate: 1613170864397,
      outputPart_identifier: "S019BLKBLK48120",
      outputPart_description: "",
      outputPart_gauge_ts: "30GA",
      outputPart_color_ts: "WHT/PR",
      outputPart_strechout_ts: 4
    },
    {
      id: v4(),
      task_identifier: "100002",
      task_scheduledDate: 1659803854000,
      mustStartTimestamp: 1613170864397,
      task_dueDate: 1613170864397,
      outputPart_identifier: "S019BLKBLK48120",
      outputPart_description: "",
      outputPart_gauge_ts: "30GA",
      outputPart_color_ts: "WHT/PR",
      outputPart_strechout_ts: 4
    },
    {
      id: v4(),
      task_identifier: "100002",
      task_scheduledDate: 1659803854000,
      mustStartTimestamp: 1613170864397,
      task_dueDate: 1613170864397,
      outputPart_identifier: "S019BLKBLK48120",
      outputPart_description: "",
      outputPart_gauge_ts: "30GA",
      outputPart_color_ts: "WHT/PR",
      outputPart_strechout_ts: 4
    },
    {
      id: v4(),
      task_identifier: "100002",
      task_scheduledDate: 1659803854000,
      mustStartTimestamp: 1613170864397,
      task_dueDate: 1613170864397,
      outputPart_identifier: "S019BLKBLK48120",
      outputPart_description: "",
      outputPart_gauge_ts: "30GA",
      outputPart_color_ts: "WHT/PR",
      outputPart_strechout_ts: 4
    },
    {
      id: v4(),
      task_identifier: "100002",
      task_scheduledDate: 1659803854000,
      mustStartTimestamp: 1613170864397,
      task_dueDate: 1613170864397,
      outputPart_identifier: "S019BLKBLK48120",
      outputPart_description: "",
      outputPart_gauge_ts: "30GA",
      outputPart_color_ts: "WHT/PR",
      outputPart_strechout_ts: 4
    },
    {
      id: v4(),
      task_identifier: "100002",
      task_scheduledDate: 1659803854000,
      mustStartTimestamp: 1613170864397,
      task_dueDate: 1613170864397,
      outputPart_identifier: "S019BLKBLK48120",
      outputPart_description: "",
      outputPart_gauge_ts: "30GA",
      outputPart_color_ts: "WHT/PR",
      outputPart_strechout_ts: 4
    },
    {
      id: v4(),
      task_identifier: "100002",
      task_scheduledDate: 1659803854000,
      mustStartTimestamp: 1613170864397,
      task_dueDate: 1613170864397,
      outputPart_identifier: "S019BLKBLK48120",
      outputPart_description: "",
      outputPart_gauge_ts: "30GA",
      outputPart_color_ts: "WHT/PR",
      outputPart_strechout_ts: 4
    },
    {
      id: v4(),
      task_identifier: "100002",
      task_scheduledDate: 1659803854000,
      mustStartTimestamp: 1613170864397,
      task_dueDate: 1613170864397,
      outputPart_identifier: "S019BLKBLK48120",
      outputPart_description: "",
      outputPart_gauge_ts: "30GA",
      outputPart_color_ts: "WHT/PR",
      outputPart_strechout_ts: 4
    },
    {
      id: v4(),
      task_identifier: "100002",
      task_scheduledDate: 1659803854000,
      mustStartTimestamp: 1613170864397,
      task_dueDate: 1613170864397,
      outputPart_identifier: "S019BLKBLK48120",
      outputPart_description: "",
      outputPart_gauge_ts: "30GA",
      outputPart_color_ts: "WHT/PR",
      outputPart_strechout_ts: 4
    },
    {
      id: v4(),
      task_identifier: "100002",
      task_scheduledDate: 1659803854000,
      mustStartTimestamp: 1613170864397,
      task_dueDate: 1613170864397,
      outputPart_identifier: "S019BLKBLK48120",
      outputPart_description: "",
      outputPart_gauge_ts: "30GA",
      outputPart_color_ts: "WHT/PR",
      outputPart_strechout_ts: 4
    },
    {
      id: v4(),
      task_identifier: "100002",
      task_scheduledDate: 1659803854000,
      mustStartTimestamp: 1613170864397,
      task_dueDate: 1613170864397,
      outputPart_identifier: "S019BLKBLK48120",
      outputPart_description: "",
      outputPart_gauge_ts: "30GA",
      outputPart_color_ts: "WHT/PR",
      outputPart_strechout_ts: 4
    },
    {
      id: v4(),
      task_identifier: "100002",
      task_scheduledDate: 1659803854000,
      mustStartTimestamp: 1613170864397,
      task_dueDate: 1613170864397,
      outputPart_identifier: "S019BLKBLK48120",
      outputPart_description: "",
      outputPart_gauge_ts: "30GA",
      outputPart_color_ts: "WHT/PR",
      outputPart_strechout_ts: 4
    },
    {
      id: v4(),
      task_identifier: "100002",
      task_scheduledDate: 1659803854000,
      mustStartTimestamp: 1613170864397,
      task_dueDate: 1613170864397,
      outputPart_identifier: "S019BLKBLK48120",
      outputPart_description: "",
      outputPart_gauge_ts: "30GA",
      outputPart_color_ts: "WHT/PR",
      outputPart_strechout_ts: 4
    },
    {
      id: v4(),
      task_identifier: "100002",
      task_scheduledDate: 1659803854000,
      mustStartTimestamp: 1613170864397,
      task_dueDate: 1613170864397,
      outputPart_identifier: "S019BLKBLK48120",
      outputPart_description: "",
      outputPart_gauge_ts: "30GA",
      outputPart_color_ts: "WHT/PR",
      outputPart_strechout_ts: 4
    },
    {
      id: v4(),
      task_identifier: "100002",
      task_scheduledDate: 1659803854000,
      mustStartTimestamp: 1613170864397,
      task_dueDate: 1613170864397,
      outputPart_identifier: "S019BLKBLK48120",
      outputPart_description: "",
      outputPart_gauge_ts: "30GA",
      outputPart_color_ts: "WHT/PR",
      outputPart_strechout_ts: 4
    },
    {
      id: v4(),
      task_identifier: "100002",
      task_scheduledDate: 1659803854000,
      mustStartTimestamp: 1613170864397,
      task_dueDate: 1613170864397,
      outputPart_identifier: "S019BLKBLK48120",
      outputPart_description: "",
      outputPart_gauge_ts: "30GA",
      outputPart_color_ts: "WHT/PR",
      outputPart_strechout_ts: 4
    },
    {
      id: v4(),
      task_identifier: "100002",
      task_scheduledDate: 1659803854000,
      mustStartTimestamp: 1613170864397,
      task_dueDate: 1613170864397,
      outputPart_identifier: "S019BLKBLK48120",
      outputPart_description: "",
      outputPart_gauge_ts: "30GA",
      outputPart_color_ts: "WHT/PR",
      outputPart_strechout_ts: 4
    }
  ]);
};
