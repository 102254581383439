import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import Overview from "../../../components/TrackPageComponents/Overview";
import AssetOverviewComponentStyle from "./AssetOverviewComponent.module.css";
import "react-loading-skeleton/dist/skeleton.css";

const AssetOverviewComponent = ({ dataList }) => {
  const RightComponent = () => {
    return (
      <div className={AssetOverviewComponentStyle.right_container}>
        {dataList.map(({ id, title, value }) => {
          return (
            <div
              key={id}
              className={AssetOverviewComponentStyle.overview_item_container}
            >
              <div>
                <p className={AssetOverviewComponentStyle.overview_title}>{title || <Skeleton />}</p>
                <p className={AssetOverviewComponentStyle.overview_value}>{value ?? <Skeleton />}</p>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Overview
      rightComponent={<RightComponent />}
      rightComponentClassName={AssetOverviewComponentStyle.overview_right_component}
    />
  );
};

AssetOverviewComponent.defaultProps = {
  dataList: []
};

AssetOverviewComponent.propTypes = {
  dataList: PropTypes.array
};

export default AssetOverviewComponent;
