import React from "react";
import PropTypes from "prop-types";
import "./style.css";

export const TwoColumnsPaneView = ({ leftPane, rightPane, className }) => {
  const rootClassName = `two-columns-pane-view ${className}`;
  return (
    <div className={rootClassName}>
      <div className="two-columns-pane-view-container">
        <div className="two-columns-pane-view-left-pane">{leftPane}</div>
        <div className="two-columns-pane-view-right-pane">{rightPane}</div>
      </div>
    </div>
  );
};

TwoColumnsPaneView.defaultProps = {
  className: "",
};

TwoColumnsPaneView.propTypes = {
  className: PropTypes.string,
  leftPane: PropTypes.node.isRequired,
  rightPane: PropTypes.node.isRequired
};
