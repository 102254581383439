import React, { useState } from "react";
import Style from "./LocationOverview.module.css";
import useWorkOrderTrackPageStateContext from "../../contexts/work-order-track-page-state-context";
import Metrics from "../metrics";
import { DATA_VIEW_TYPE_MAP } from "../data-view-dropdown/data/constants";
import Charts from "../charts";

export const LocationOverview = () => {
  const { selectedDataView } = useWorkOrderTrackPageStateContext();

  const [isSingleMode, setIsSingleMode] = useState(false);
  if (selectedDataView === DATA_VIEW_TYPE_MAP.none) {
    return null;
  }

  return (
    <div
      ref={(element) => {
        if (element) {
          setIsSingleMode(element.childElementCount === 1);
        }
      }}
      className={Style.container}
    >
      {(selectedDataView === DATA_VIEW_TYPE_MAP.graphs || selectedDataView === DATA_VIEW_TYPE_MAP.graphsAndMetrics) && (
        <Charts slidesPerPage={isSingleMode ? 2 : 1} />
      )}
      {(selectedDataView === DATA_VIEW_TYPE_MAP.metrics ||
        selectedDataView === DATA_VIEW_TYPE_MAP.graphsAndMetrics) && <Metrics horizontalMode={isSingleMode} />}
    </div>
  );
};
