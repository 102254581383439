import { makeStyles, createTheme } from "@material-ui/core/styles";
import xemelgoStyle from "../../../styles/variable";

export const AddPageTheme = createTheme({
  palette: {
    primary: { 500: xemelgoStyle.theme.APP_BLUE }
  }
});

export const useFormControlStyle = makeStyles(() => {
  return {
    root: {
      color: "#92929D",
      width: 250
    }
  };
});
export const useFormGroupStyle = makeStyles(() => {
  return {
    root: {
      marginLeft: 25
    }
  };
});

export const useFormLabelStyle = makeStyles(() => {
  return {
    root: {
      color: xemelgoStyle.theme.TEXT_PRIMARY,
      fontSize: "1rem",
      textTransform: "capitalize",
      "&$focused:not($error)": {
        color: xemelgoStyle.theme.TEXT_PRIMARY
      }
    },
    focused: {},
    error: {}
  };
});

export const useInputIconStyle = makeStyles(() => {
  return {
    root: {
      fontSize: 12,
      padding: 3,
      color: "#92929D"
    }
  };
});

export const useInputStyle = makeStyles((theme) => {
  return {
    root: {
      width: ({ width }) => {
        return width || 250;
      },
      minWidth: 250,
      maxWidth: ({ width }) => {
        return width || 250;
      },
      color: xemelgoStyle.theme.TEXT_PRIMARY,
      "&:hover:not($disabled):not($error):not($focused) $notchedOutline": {
        borderColor: xemelgoStyle.theme.TEXT_PRIMARY
      },
      "&$focused $notchedOutline": {
        borderColor: theme.palette.primary,
        borderWidth: 2
      },
      "&$disabled $notchedOutline": {
        borderColor: "rgba(0, 0, 0, 0.23)",
        color: xemelgoStyle.theme.TEXT_PRIMARY
      },
      "&$disabled": {
        color: xemelgoStyle.theme.TEXT_PRIMARY
      }
    },
    error: {},
    disabled: {},
    notchedOutline: {
      borderColor: "#92929D"
    },
    focused: {
      color: xemelgoStyle.theme.TEXT_PRIMARY
    },
    adornedEnd: {
      paddingRight: 5
    }
  };
});

export const usePhoneInputStyle = makeStyles((theme) => {
  return {
    root: {
      width: ({ width }) => {
        return width || 190;
      },
      minWidth: 190,
      maxWidth: 340,
      color: xemelgoStyle.theme.TEXT_PRIMARY,
      "&:hover:not($disabled):not($error):not($focused) $notchedOutline": {
        borderColor: xemelgoStyle.theme.TEXT_PRIMARY
      },
      "&$focused $notchedOutline": {
        borderColor: theme.palette.primary,
        borderWidth: 2
      },
      "&$disabled $notchedOutline": {
        borderColor: "rgba(0, 0, 0, 0.23)",
        color: xemelgoStyle.theme.TEXT_PRIMARY
      },
      "&$disabled": {
        color: xemelgoStyle.theme.TEXT_PRIMARY
      }
    },
    error: {},
    disabled: {},
    notchedOutline: {
      borderColor: "#92929D"
    },
    focused: {
      color: xemelgoStyle.theme.TEXT_PRIMARY
    },
    adornedEnd: {
      paddingRight: 5
    }
  };
});

export const userInputLabelStyle = makeStyles((theme) => {
  return {
    root: {
      color: "#92929D",
      zIndex: 0,
      "&$disabled": {
        color: "#92929D"
      },
      "&$disabled $asterisk": {
        color: "#92929D"
      },
      "&$shrink:not($disabled)": {
        color: xemelgoStyle.theme.TEXT_PRIMARY
      },
      "&$shrink $asterisk": {
        color: xemelgoStyle.theme.TEXT_PRIMARY
      }
    },
    asterisk: {},
    disabled: {},
    focused: {
      color: theme.palette.primary
    },
    shrink: {}
  };
});
