import React from "react";
import discard_icon from "img/discard.png";
import PropTypes from "prop-types";
import green_check_icon from "../../img/success.png";
import Style from "./EditButton.module.css";

const EditButton = ({ show, editMode, onSaveClick, onDiscardClick, onEditClick, textButtonClassName }) => {
  if (!show) {
    return null;
  }
  if (editMode) {
    return (
      <div className={`${Style.edit_buttons_container} ${Style.flex_row}`}>
        <img
          className={Style.edit_button}
          onClick={onSaveClick}
          src={green_check_icon}
          alt="Save"
        />
        <img
          className={Style.edit_button}
          onClick={onDiscardClick}
          src={discard_icon}
          alt="Discard"
        />
      </div>
    );
  }
  return (
    <button
      className={textButtonClassName || Style.text_button}
      onClick={onEditClick}
    >
      Edit
    </button>
  );
};

EditButton.defaultProps = {
  show: true,
  editMode: false,
  onSaveClick: () => {},
  onDiscardClick: () => {},
  onEditClick: () => {},
  textButtonClassName: ""
};

EditButton.propTypes = {
  show: PropTypes.bool,
  editMode: PropTypes.bool,
  onSaveClick: PropTypes.func,
  onDiscardClick: PropTypes.func,
  onEditClick: PropTypes.func,
  textButtonClassName: PropTypes.string
};

export default EditButton;
