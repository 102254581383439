export const getLabelByItemTypeClass = (itemClass) => {
  switch (itemClass) {
    case "Part":
      return "Order Part";
    case "Asset":
      return "Asset Item Type";
    case "Inventory":
      return "Item Type";
    default:
      return "";
  }
};
