import React, { Component } from "react";

export default class MissingRuleContent extends Component {
  render() {
    return (
      <div>
        <p className="tabbedSectionComponent-contentTitle">Missing Item</p>
        <p style={{ color: "#343434" }}>
          Sends a notification when an item is marked as missing or a missing item is marked as found.
        </p>
      </div>
    );
  }
}
