import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useXemelgoAppsyncClient } from "../../../../../services/xemelgo-appsync-service";
import useMixpanelContext from "../../../../../context/mixpanel-context/";

import queryString from "query-string";

const OrderDetailSubscriptionContext = React.createContext();

export const useOrderDetailSubscriptionContext = () => {
  return OrderDetailSubscriptionContext.Consumer;
};

export const OrderDetailSubscriptionContextProvider = ({ children }) => {
  const mixpanelContext = useMixpanelContext();
  const values = queryString.parse(window.location.search);
  const { itemId } = values;
  const appSyncClient = useXemelgoAppsyncClient();
  const [data, setData] = useState(null);
  const [callbackFn, setCallbackFn] = useState(null);

  useEffect(() => {
    if (callbackFn && data) {
      callbackFn(data.id);
    }
  }, [data, callbackFn]);

  const subscribeToLocationItemMetrics = async () => {
    const workOrderAppSyncClient = await appSyncClient.getWorkOrderClient();
    const onNextFn = (metrics) => {
      const {
        value: {
          data: {
            onUpdateMetric: {
              results: [{ properties }]
            }
          }
        }
      } = metrics;

      const metricsResult = JSON.parse(properties);
      if (metricsResult.id === itemId) {
        setData(metricsResult);
      }
    };
    const onError = (error) => {
      console.log("err", error);
    };

    return workOrderAppSyncClient.subscribeToLocationItemMetrics(onNextFn, onError);
  };

  useEffect(() => {
    const subscriptionPromise = subscribeToLocationItemMetrics();
    return () => {
      subscriptionPromise.then((subscription) => {
        subscription.unsubscribe && subscription.unsubscribe();
      });
    };
  }, []); // Empty dependency array means this effect runs once, similar to componentDidMount

  return (
    <OrderDetailSubscriptionContext.Provider
      value={{
        mixpanelContext,
        setCallbackFn
      }}
    >
      {children}
    </OrderDetailSubscriptionContext.Provider>
  );
};
