import { getFormattedDate } from "../../../../common/Utilities";

export const getValueByType = (data, type) => {
  switch (type) {
    case "dateTime":
      return data ? getFormattedDate(data, "hh:mm A MMM Do YYYY") : "-";

    case "date":
      return data ? getFormattedDate(data, "MMM Do YYYY") : "-";

    case "array":
      return data
        ?.map((eachData) => {
          return eachData.label;
        })
        .join(", ");

    case "string":
    default:
      return data;
  }
};
