import { useMemo } from "react";
import { useStore } from "react-hookstore";
import { userProfileStore } from "../../state-managements/stores/user-profile-store";

export const ADMIN_ROLE = "admin";
export const USER_ROLE = "user";
export const DEFAULT_ROLES = [ADMIN_ROLE, USER_ROLE];

export const getDefaultRoles = (adminOnly) => {
  return adminOnly ? [ADMIN_ROLE] : DEFAULT_ROLES;
};

export const useRoleAuthorization = ({ config }) => {
  const [userProfile] = useStore(userProfileStore);

  const operationIdToRolesMap = useMemo(() => {
    return (
      config?.reduce((accum, option) => {
        accum[option.id] = option.roles || getDefaultRoles(option.adminOnly);
        return accum;
      }, {}) || {}
    );
  }, [config]);

  const getRoles = (id) => {
    return operationIdToRolesMap[id] || [];
  };

  const isAuthorized = (id) => {
    return getRoles(id).includes(userProfile.getRole());
  };

  const isUserInRoles = (roles) => {
    return roles.includes(userProfile.getRole());
  };

  return { isAuthorized, getRoles, isUserInRoles };
};
