/**
 * @typedef HeaderType
 * @property {boolean} filterEnabled
 * @property {string} format
 * @property {number} index
 * @property {string} label
 * @property {string} type
 *
 */

/**
 * @typedef workOrder
 * @property {string} id
 * @property {number} mustStartTimestamp
 * @property {string} outputPart_color_ts
 * @property {string} outputPart_description
 * @property {string} outputPart_gauge_ts
 * @property {string} outputPart_identifier
 * @property {number} outputPart_strechout_ts
 * @property {number} task_dueDate
 * @property {string} task_identifier
 *
 */

/**
 * @typedef OptionType
 * @property {string} id
 * @property {string} label
 * @property {string | number} value
 */

/**
 * @typedef FilterType
 * @property {string} id
 * @property {OptionType[] | undefined} options
 * @property {string} label
 * @property {string | undefined} type
 */

/**
 * getFilterMap function get the filterable properties from the headerList, then collect each filter's
 * options from the workOrders data.
 * @param {HeaderType[]} headerList
 * @param {workOrder[]} workOrders
 * @returns {}
 */
export const getFilterMap = (headerList, workOrders) => {
  const filterableProperties = headerList
    .filter((header) => {
      return header.filterEnabled === true;
    })
    .reduce((acc, curr) => {
      const { id, label, type, format } = curr;
      const options = [];
      // get all available options
      workOrders.forEach((workOrder) => {
        let option = workOrder[id];
        if (option) {
          if (typeof option !== "String") {
            option = option.toString();
          }
          options.push({ id: option, label: option, value: option });
        }
      });
      const uniqueOptionList = [
        ...new Map(
          options.map((item) => {
            return [item.id, item];
          })
        ).values()
      ];

      return {
        ...acc,
        [id]: {
          id,
          label,
          type,
          options: type != "date" ? uniqueOptionList : undefined,
          format
        }
      };
    }, {});
  return filterableProperties;
};
