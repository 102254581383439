import React from "react";
import { Chart } from "react-google-charts";
import Skeleton from "react-loading-skeleton";
import { isAbsolute } from "path";
import PropTypes from "prop-types";

import Style from "./PieChart.module.css";

export const PieChart = ({ isLoading, title, subTitle, data }) => {
  return (
    <div className={Style.chart_container}>
      <div className={Style.title_text}>
        <p className={`${Style.title_separation} ${Style.title_text}`}>
          {isLoading ? (
            <Skeleton
              height={27}
              width={100}
            />
          ) : (
            title
          )}
        </p>
        <p className={Style.sub_title_text}>
          {isLoading ? (
            <Skeleton
              height={27}
              width={100}
            />
          ) : (
            subTitle
          )}
        </p>
      </div>
      <div className={Style.my_pretty_chart_container}>
        {isLoading ? (
          <div className={Style.skeleton}>
            <Skeleton
              circle
              height={130}
              width={130}
            />
          </div>
        ) : (
          <Chart
            position={isAbsolute}
            width={200}
            height={240}
            chartType="PieChart"
            loader={
              <Skeleton
                circle="true"
                height={130}
                width={130}
              />
            }
            data={data}
            options={{
              titlePosition: "none",
              legend: { position: "top", alignment: "center" },
              chartArea: { left: 0, top: 55, right: 0, bottom: 55 },
              colors: ["#61D438", "#F5B052", "#F74446", "lightgrey"],
              pieSliceText: "none",
              pieHole: 0.5,
              backgroundColor: { fill: "transparent" }
            }}
            rootProps={{ data_testid: "1" }}
            chartWrapperParams={{ view: { columns: [0, 1] } }}
            chartPackages={["corechart", "controls"]}
          />
        )}
      </div>
    </div>
  );
};

PieChart.defaultProps = {
  isLoading: false,
  title: "",
  subTitle: "",
  data: []
};

PieChart.propTypes = {
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.array)
};
