import React, { useState, useMemo } from "react";
import { PropTypes } from "prop-types";
import Style from "./CollapsibleSectionList.module.css";
import SectionList from "../section-list";
import { ReactComponent as ChevUp } from "../../assets/icons/chev-up.svg";
import { ReactComponent as ChevDown } from "../../assets/icons/chev-down.svg";

export const CollapsibleSectionList = ({ sections, collapsible, renderSectionHeader, ...sectionProps }) => {
  const [collapsedControlMap, setCollapsedControlMap] = useState({});

  const filteredSections = useMemo(() => {
    return sections.map((eachSection) => {
      return { ...eachSection, data: collapsedControlMap[eachSection.id] ? [] : eachSection.data };
    });
  }, [sections, collapsedControlMap]);

  const renderSectionHeaderHelper = (section) => {
    return (
      <div
        className={`${Style.header_container} ${collapsible && Style.header_container_active}`}
        onClick={() => {
          setCollapsedControlMap({ ...collapsedControlMap, [section.id]: !collapsedControlMap[section.id] });
        }}
      >
        {renderSectionHeader?.(section) || <p className={Style.header_text}>{section.label}</p>}
        {collapsible && (
          <div className={Style.icon_button}>
            {collapsedControlMap[section.id] ? (
              <ChevDown
                width={12}
                height={12}
              />
            ) : (
              <ChevUp
                width={12}
                height={12}
              />
            )}
          </div>
        )}
      </div>
    );
  };
  return (
    <SectionList
      sections={filteredSections}
      renderSectionHeader={renderSectionHeaderHelper}
      {...sectionProps}
    />
  );
};

CollapsibleSectionList.defaultProps = {
  collapsible: true,
  sections: [],
  renderItem: null,
  renderSectionHeader: null,
  sectionContainerClassName: ""
};

CollapsibleSectionList.propTypes = {
  collapsible: PropTypes.bool,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired }))
    })
  ),
  renderItem: PropTypes.func,
  renderSectionHeader: PropTypes.func,
  sectionContainerClassName: PropTypes.string
};
