export const generateConsumePayload = (activeItems) => {
  const tagsToConsume = [];
  const consumePayload = activeItems.map((item) => {
    if (item.vid) {
      tagsToConsume.push(item.vid);
    }
    return item.trackingSessionId;
  });
  return { consumePayload, tagsToConsume };
};
