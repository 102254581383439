export const INVENTORY_BULK_UPDATE = "Inventory Bulk Update";
export const INVENTORY_BULK_UPDATE_STEPS = {
  ENTRY: "Entry",
  INPUT_VALIDATION_SUCCESS: "Input Validation Success",
  INPUT_VALIDATION_FAILURE: "Input Validation Failure",
  BULK_RESET_ENTRY: "Bulk Reset Entry",
  BULK_UPDATE_SUCCESS: "Bulk Update Success",
  BULK_UPDATE_FAILURE: "Bulk Update Failure",
  BULK_RESET_SUCCESS: "Bulk Reset Success",
  BULK_RESET_FAILURE: "Bulk Reset Failure"
};
