import React, { Fragment } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "mdbreact";
import { MoreVert } from "@material-ui/icons";
import Style from "./css/PartIssueRouteHistoryTable.module.css";

const KeyToInlineStyleObjectMap = {
  issueCode: { alignSelf: "auto", marginTop: "0.5em", marginRight: "5em", width: "20%" },
  lastUpdatedTime: { alignSelf: "auto", marginTop: "0.5em", marginRight: "5.6em", minWidth: "6em" },
  commentsList: { marginTop: "0.5em", marginRight: "1.8em", marginLeft: "0.8em", flex: 1 },
  "more-button": { flex: "none", alignSelf: "auto" },
  "issueCode-header": { alignSelf: "auto", marginTop: "0.5em", marginRight: "5em", width: "20%" },
  "lastUpdatedTime-header": {
    alignSelf: "auto",
    marginTop: "0.5em",
    marginRight: "5.4em",
    minWidth: "6em"
  },
  "commentsList-header": { marginTop: "0.5em", marginRight: "1.8em", flex: 1 },
  "more-button-header": { flex: "none", alignSelf: "auto" }
};

const renderHeader = (titleList, secondary) => {
  return (
    <div className={secondary ? Style.secondary_header_row : Style.header_row}>
      {titleList.map((eachTitle) => {
        return (
          <div
            style={KeyToInlineStyleObjectMap[`${eachTitle.key}-header`] || {}}
            className={secondary ? Style.secondary_header_text : Style.header_text}
            key={eachTitle.key}
          >
            {eachTitle.value}
          </div>
        );
      })}
    </div>
  );
};

const renderStatus = (statusList = []) => {
  return (
    <div>
      {statusList.map((status = {}) => {
        return (
          <div
            key={status.id}
            className={Style.status}
            style={{ color: "#fff", width: "fit-content", backgroundColor: status.color }}
          >
            {status.displayText}
          </div>
        );
      })}
    </div>
  );
};

const renderLink = (data = {}) => {
  return (
    <a
      className={Style.underline_text}
      key={data.id}
      title="Click to view details"
      href={data.link}
    >
      {data.displayText}
    </a>
  );
};

const renderList = (data = []) => {
  return data.map((item = "") => {
    return <div style={{ marginBottom: data.length === 1 ? "0em" : "0.5em" }}>{item}</div>;
  });
};

const renderMoreButton = (options = []) => {
  return (
    <Dropdown>
      <DropdownToggle
        nav
        className={Style.dropdown_toggle}
      >
        <MoreVert />
      </DropdownToggle>
      <DropdownMenu>
        {options?.map((option = {}) => {
          return (
            <DropdownItem
              id={option.id}
              className={option.className || "dropdown-item"}
              onClick={option.onClick}
            >
              <div
                id={option.id}
                key={option.id}
              >
                {option.label}
              </div>
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

const renderColumnData = (type = "text", data) => {
  switch (type) {
    case "status":
      return renderStatus(data || []);
    case "link":
      return renderLink(data || {});
    case "list":
      return renderList(data || []);
    case "more-button":
      return renderMoreButton(data || {});
    default:
      return data || "-";
  }
};

const renderSecondaryRowData = (rowData, titleList = [], id) => {
  return (
    <div className={Style.secondary_row}>
      {titleList.map((eachTitle = {}) => {
        const { type = "text", key } = eachTitle;
        return (
          <div
            key={id}
            style={KeyToInlineStyleObjectMap[key] || {}}
            className={Style.secondary_row_text}
          >
            {renderColumnData(type, rowData[key])}
          </div>
        );
      })}
    </div>
  );
};

const renderRowData = (rowData, titleList, index) => {
  return (
    <div key={index}>
      {rowData.id !== "issues-before-detection" ? (
        <div className={Style.row}>
          {titleList.map((eachTitle = {}) => {
            const { type = "text", key } = eachTitle;
            return (
              <div
                className={Style.row_text}
                key={key}
              >
                {renderColumnData(type, rowData[key])}
              </div>
            );
          })}
        </div>
      ) : null}
      {rowData.issues && rowData.issues.titleList.length > 0 && rowData.issues.dataList.length > 0 && (
        <div style={{ paddingBottom: "0.4em", paddingLeft: "1em" }}>
          {renderHeader(rowData.issues.titleList || [], true)}
          {rowData.issues.dataList.map((issue) => {
            return renderSecondaryRowData(issue || {}, rowData.issues.titleList || [], issue.id);
          })}
        </div>
      )}
    </div>
  );
};

const renderEmptyRow = () => {
  return (
    <div key={1}>
      <div className={Style.empty_row}>No data to show</div>
    </div>
  );
};

const PartIssueRouteHistoryTable = ({ dataList = [], titleList = [] }) => {
  return (
    <div className={Style.header_container}>
      {renderHeader(titleList)}
      <div className={Style.rows_container}>
        {dataList.length > 0
          ? dataList.map((rowData, index) => {
              return renderRowData(rowData, titleList, index);
            })
          : renderEmptyRow()}
      </div>
    </div>
  );
};

export default PartIssueRouteHistoryTable;
