export const STATUS_COLOR_MAP = {
  Healthy: "#4B9910",
  Warning: "#F6B044",
  Critical: "#FF3D46",
  Expedited: "#E56201"
};
export const STATUS_SEVERITY_MAP = {
  warning: "Warning",
  critical: "Critical",
  expedited: "Expedited",
  healthy: "Healthy"
};

export const VIEW_MODE_MAP = {
  floor_status_mode: {
    id: "floor_status_mode",
    label: "Floor Status View",
    value: "floor_status_mode"
  },
  expedited_parts_mode: {
    id: "expedited_parts_mode",
    label: "Expedited View",
    value: "expedited_parts_mode"
  }
};

export const SEARCH_TYPE_MAP = {
  work_order: { id: "work_order", label: "WO #" },
  item_type: { id: "item_type", label: "Part #" }
};

export const TAB_OPTION_MAP = {
  jobStatusTab: "jobStatus",
  locationListTab: "locationListTab"
};

export const STATUS_TITLE_MAP = {
  lateToStartWarning: "Warning - Late To Start",
  lateToStartCritical: "Critical - Late To Start",
  idleWarning: "Warning - Time Exceeded",
  idleCritical: "Critical - Time Exceeded",
  healthy: "Healthy"
};

export const FRONTEND_STATUS_MAP = {
  timeExceeded: "Time Exceeded",
  lateToStart: "Late To Start"
};
export const BACKEND_STATUS_MAP = {
  timeExceeded: "Time Exceeded",
  lateToStart: "Delayed"
};

export const EXPEDITED_VALUES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
