import React, { useState } from "react";
import PhoneInput, { formatPhoneNumber } from "react-phone-number-input";
import PropTypes from "prop-types";
import TextInput from "./TextInput";

const PhoneNumberInput = ({
  id,
  value,
  formattedValue,
  label,
  onChange,
  error,
  onBlur,
  required,
  disabled,
  InputProps
}) => {
  const [countryCode, setCountryCode] = useState("US");
  const ref = React.createRef();

  const formattedNumber = formatPhoneNumber(formattedValue);

  return (
    <PhoneInput
      id={id}
      ref={ref}
      defaultCountry="US"
      onChange={onChange}
      label={label}
      onBlur={() => {
        return onBlur({ target: { id } });
      }}
      required={required}
      disabled={disabled}
      onCountryChange={(code) => {
        return setCountryCode(code);
      }}
      country={countryCode}
      limitMaxLength
      displayInitialValueAsLocalNumber
      InputProps={{
        ...InputProps,
        id,
        value: formattedNumber || value,
        label,
        required,
        disabled,
        error,
        country: "US",
        type: "tel",
        onChange: (event) => {
          onChange(id, event.target.value);
        },
        onBlur: (value) => {
          return onBlur(value, countryCode);
        }
      }}
      inputComponent={TextInput}
    />
  );
};
export default PhoneNumberInput;

PhoneNumberInput.defaultProps = {
  id: "",
  label: "",
  value: "",
  formattedValue: "",
  onChange: () => {},
  error: false,
  required: false,
  onBlur: () => {},
  disabled: false,
  numberOnly: false
};

PhoneNumberInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  formattedValue: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  required: PropTypes.bool,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  numberOnly: PropTypes.bool,
  InputProps: PropTypes.object
};
