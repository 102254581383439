import React from "react";
import PropTypes from "prop-types";
import Switch from "react-switch";
import Style from "./SwitchWithLabel.module.css";

const SwitchWithLabel = ({ label, onChange, checked, disabled }) => {
  return (
    <div className={Style.switch_container}>
      {label && <span className={Style.switch_text}>{label}</span>}
      <Switch
        onChange={(value) => {
          onChange(value);
        }}
        disabled={disabled}
        checked={checked}
        className={Style.switch}
        uncheckedIcon={false}
        checkedIcon={false}
        onColor="#4d8605"
      />
    </div>
  );
};

export default SwitchWithLabel;

SwitchWithLabel.defaultProps = {
  label: "",
  onChange: () => {},
  checked: false,
  disabled: false
};

SwitchWithLabel.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool
};
