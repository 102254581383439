import React, { useState, useMemo } from "react";
import {
  VictoryChart,
  VictoryZoomContainer,
  VictoryAxis,
  VictoryBrushContainer,
  VictoryBar,
  VictoryTooltip,
  VictoryTheme,
  VictoryLabel
} from "victory";

import PropTypes from "prop-types";
import { STATUS_COLOR_MAP, STATUS_SEVERITY_MAP } from "../../data/constants";
import Style from "./BacklogChart.module.css";
import StatusColorLegend from "../status-color-legend";

const MAX_NUMBER_PER_VIEW = 9;

export const BacklogChart = ({ dataList }) => {
  const legendList = useMemo(() => {
    return [
      { label: STATUS_SEVERITY_MAP.healthy, color: STATUS_COLOR_MAP[STATUS_SEVERITY_MAP.healthy] },
      { label: STATUS_SEVERITY_MAP.warning, color: STATUS_COLOR_MAP[STATUS_SEVERITY_MAP.warning] },
      { label: STATUS_SEVERITY_MAP.critical, color: STATUS_COLOR_MAP[STATUS_SEVERITY_MAP.critical] }
    ];
  }, []);

  const [zoomDomain, setZoomDomain] = useState({
    x: [0.5, dataList.length <= MAX_NUMBER_PER_VIEW ? dataList.length + 0.5 : 9.5]
  });

  const handleZoom = (domain) => {
    setZoomDomain(domain);
  };

  const [dataWithMinBarSize, newYKey] = useMemo(() => {
    const key = "_minY";
    let maxY = 0;

    dataList.forEach((eachData) => {
      if (maxY < eachData.y) {
        maxY = eachData.y;
      }
    });

    maxY = Math.ceil(maxY + maxY * 0.1) || 1;

    return [
      dataList.map((eachData) => {
        return { ...eachData, [key]: eachData.y, maxY };
      }),
      key,
      maxY
    ];
  }, [dataList]);

  return (
    <div className={Style.outer_container}>
      <div className={Style.width}>
        {dataList.length > MAX_NUMBER_PER_VIEW && (
          <VictoryChart
            domainPadding={25}
            padding={{ top: 0, left: 50, right: 50, bottom: 10 }}
            width={600}
            height={75}
            containerComponent={
              <VictoryBrushContainer
                allowDraw={false}
                allowResize={false}
                brushDimension="x"
                brushDomain={dataList.length <= MAX_NUMBER_PER_VIEW ? null : { x: [0, 9.5] }}
                onBrushDomainChange={handleZoom}
              />
            }
          >
            <VictoryAxis
              tickFormat={(x) => {
                return x;
              }}
            />
            <VictoryBar
              data={dataWithMinBarSize}
              style={{
                data: {
                  fill: ({ datum }) => {
                    return STATUS_COLOR_MAP[datum.status] || "#E2E2EA";
                  }
                }
              }}
              x="x"
              y={newYKey}
            />
          </VictoryChart>
        )}
        <VictoryChart
          padding={{ top: 20, left: 50, right: 50, bottom: 30 }}
          theme={VictoryTheme.material}
          width={600}
          height={325}
          domainPadding={{ x: [15, 15], y: [30, 30] }}
          containerComponent={
            <VictoryZoomContainer
              zoomDomain={zoomDomain}
              disable
            />
          }
        >
          <VictoryAxis
            dependentAxis
            tickFormat={(t) => {
              return Number.isInteger(t) ? t : null;
            }}
            style={{
              tickLabels: { fill: "#979799", fontWeight: "bold" },
              ticks: { strokeWidth: 1.25, stroke: "#979799" },
              axis: { strokeWidth: 1, stroke: "#E2E2EA" }
            }}
          />
          <VictoryAxis
            tickFormat={(x) => {
              return x.length > 5 ? `${x.substring(0, 5)}...` : x;
            }}
            style={{
              tickLabels: {
                fill: "#979799",
                fontWeight: "500",
                fontSize: 14,
                verticalAnchor: "middle"
              },
              ticks: { strokeWidth: 1.25, stroke: "#979799" },
              axis: { strokeWidth: 1, stroke: "#E2E2EA" }
            }}
          />
          <VictoryBar
            barWidth={40}
            cornerRadius={2}
            data={dataWithMinBarSize}
            style={{
              data: {
                fill: ({ datum }) => {
                  return STATUS_COLOR_MAP[datum.status] || "#E2E2EA";
                }
              }
            }}
            x="x"
            y={newYKey}
          />

          <VictoryBar
            events={[
              {
                eventHandlers: {
                  onMouseEnter: () => {
                    return [
                      {
                        eventKey: "all",
                        target: "data",
                        mutation: () => {
                          return {
                            style: {
                              fill: "transparent"
                            }
                          };
                        }
                      },
                      {
                        target: "data",
                        mutation: () => {
                          return {
                            style: {
                              fill: "#979799",
                              opacity: 0.3
                            }
                          };
                        }
                      }
                    ];
                  },
                  onMouseLeave: () => {
                    return [
                      {
                        eventKey: "all",
                        target: "data",
                        mutation: () => {
                          return {
                            style: {
                              fill: "transparent"
                            }
                          };
                        }
                      },
                      {
                        target: "data",
                        mutation: () => {
                          return {
                            style: {
                              fill: "transparent"
                            }
                          };
                        }
                      }
                    ];
                  }
                }
              }
            ]}
            barWidth={50}
            cornerRadius={2}
            data={dataWithMinBarSize}
            style={{
              data: {
                fill: "transparent"
              }
            }}
            x="x"
            y="maxY"
            labels={({ datum }) => {
              return [
                ["Location Name: ", datum.x],
                ["Current Backlog: ", datum.y]
              ];
            }}
            labelComponent={
              <VictoryTooltip
                cornerRadius={5}
                constrainToVisibleArea
                centerOffset={{ y: -10 }}
                flyoutPadding={10}
                pointerLength={0}
                flyoutStyle={{
                  stroke: "#979799",
                  strokeWidth: 0.5,
                  fill: "white"
                }}
                style={{ fill: "#343434", fontWeight: 400 }}
                labelComponent={<VictoryLabel lineHeight={1.5} />}
              />
            }
          />
        </VictoryChart>
      </div>
      <StatusColorLegend legendList={legendList} />
    </div>
  );
};

BacklogChart.defaultProps = {
  dataList: []
};

BacklogChart.propTypes = {
  dataList: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      status: PropTypes.string
    })
  )
};
