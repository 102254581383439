import React, { useState, useEffect, useMemo, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { EditOutlined } from "@material-ui/icons";
import useSortableHeader from "../../../../hooks/use-sortable-header";
import PaginatedListTable from "../../../../components/PaginatedList/PaginatedList";
import Style from "./WorkOrderTable.module.css";
import { useScheduleManagementContext } from "../../context/ScheduleManagementContext.context";
import { WorkOrderRecord } from "../work-order-record/WorkOrderRecord";
import { EditScheduleModal } from "./features/EditScheduleModal";
import { isWorkOrderMatchFilterInput } from "../../services/isWorkOrderMatchFilterInput";
import { useXemelgoClient } from "../../../../services/xemelgo-service";

export const WorkOrderTable = ({ workOrderMap, workCenterId, onReload }) => {
  // useState variables
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentWorkOrderMap, setCurrentWorkOrderMap] = useState({});

  // context variables
  const configContext = useScheduleManagementContext();
  const { filterInputs } = configContext;
  const { workOrderTableHeaders: workOrderTableHeaderConfigMap } = configContext.getTrackPageConfiguration();

  const { getSortedData, getSortableHeader, order, orderBy } = useSortableHeader();

  // services
  const xemelgoClient = useXemelgoClient();
  const [scheduleOrderClient] = useState(xemelgoClient.getScheduleOrderClient());

  useEffect(() => {
    setCurrentWorkOrderMap(workOrderMap);
  }, [workOrderMap]);

  const headerList = useMemo(() => {
    return (
      Object.entries(workOrderTableHeaderConfigMap)
        // sort header by index
        .sort((a, b) => {
          return a[1].index - b[1].index;
        })
        .map(([headerName, configValue]) => {
          return {
            id: headerName,
            ...configValue
          };
        })
    );
  }, [configContext]);

  const reducedHeaderList = useMemo(() => {
    return headerList.map((header) => {
      const { id, type } = header;
      return { id, type };
    });
  }, [headerList]);

  const getSelectedWorkOrders = () => {
    return Object.values(workOrdersToDisplay).filter((workOrder) => {
      return workOrder.isChecked === true;
    });
  };

  // Edit Schedule Modal Functions
  const handleDiscardClick = () => {
    setShowEditModal(false);
  };

  const handleSubmitClick = async (newScheduledDate, newWorkCenter) => {
    const updatePayload = {
      newScheduledDate,
      newWorkCenter: newWorkCenter.id,
      currentWorkCenter: workCenterId
    };
    const selectedWorkOrders = getSelectedWorkOrders();
    const promises = selectedWorkOrders.map((workOrder) => {
      return scheduleOrderClient.updateScheduledWorkOrder(workOrder, updatePayload);
    });

    Promise.all(promises).then((result) => {
      setTimeout(() => {
        onReload(newWorkCenter?.id);
        setShowEditModal(false);
      }, 1000);
    });
  };

  const onSelectClick = (ids, newState) => {
    const newWorkOrderMap = _.cloneDeep(currentWorkOrderMap);
    ids.forEach((id) => {
      newWorkOrderMap[id].isChecked = newState;
    });
    setCurrentWorkOrderMap(newWorkOrderMap);
  };

  const workOrdersToDisplay = useMemo(() => {
    let filteredWorkOrders = [...Object.values(currentWorkOrderMap)];

    // TODO: refactor this filter block
    Object.keys(filterInputs).forEach((filterProperty) => {
      filteredWorkOrders = Object.values(filteredWorkOrders).filter((workOrder) => {
        const { value } = filterInputs[filterProperty];
        return isWorkOrderMatchFilterInput(reducedHeaderList, value, workOrder, filterProperty);
      });
    });

    const sortedDataList = getSortedData(filteredWorkOrders).reduce((obj, item) => {
      return Object.assign(obj, { [item.id]: item });
    }, {});

    return sortedDataList;
  }, [currentWorkOrderMap, configContext, filterInputs, order, orderBy]);

  const checkedCount = useMemo(() => {
    let currentCheckedCount = 0;
    Object.values(workOrdersToDisplay).forEach((element) => {
      if (element.isChecked) {
        currentCheckedCount++;
      }
    });
    return currentCheckedCount;
  }, [workOrdersToDisplay]);

  return (
    <div className={Style.flex_column}>
      {checkedCount > 0 && (
        <div className={`${Style.selection_container} ${Style.flex_row}`}>
          <div className={Style.selection_count_container}>{checkedCount} selected</div>
          <div className={Style.selection_buttons_container}>
            <button
              onClick={() => {
                onSelectClick(
                  Object.values(workOrdersToDisplay).map((element) => {
                    return element.id;
                  }),
                  false
                );
              }}
              className={Style.selection_button}
            >
              Deselect All
            </button>
            <button
              className={Style.selection_button}
              onClick={() => {
                setShowEditModal(true);
              }}
            >
              <EditOutlined /> Bulk Edit
            </button>
          </div>
        </div>
      )}
      <EditScheduleModal
        selectedWorkOrders={getSelectedWorkOrders()}
        showModal={showEditModal}
        onDiscardClick={handleDiscardClick}
        onSubmitClick={handleSubmitClick}
      />
      <div className={`${Style.table_container}`}>
        {Object.keys(workOrdersToDisplay).length ? (
          <PaginatedListTable
            header={headerList}
            data={Object.values(workOrdersToDisplay)}
            headerContainerClassName={`${Style.table_header}`}
            selectable
            onSelectClick={onSelectClick}
            renderHeader={(eachHeader) => {
              return getSortableHeader(eachHeader, { style: { fontWeight: 600, fontSize: "14px" } });
            }}
            renderItem={(rowData) => {
              return (
                <WorkOrderRecord
                  recordName={rowData.id}
                  data={rowData}
                  headerList={headerList}
                />
              );
            }}
          />
        ) : (
          <div className={Style.table_empty}> No data to show</div>
        )}
      </div>
    </div>
  );
};

WorkOrderTable.defaultProps = {
  workOrderMap: {}
};

WorkOrderTable.propTypes = {
  workOrderMap: PropTypes.shape({
    workOrder: PropTypes.shape({
      task_identifier: PropTypes.string,
      outputPart_identifier: PropTypes.string,
      mustStartTimestamp: PropTypes.number,
      task_scheduledDate: PropTypes.number,
      task_dueDate: PropTypes.number
    })
  })
};
