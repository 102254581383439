import React from "react";
import { Route } from "react-router-dom";
import ManagementDashboardScreen from "../../features/management-dashboard-feature";

const ManagementDashboardApp = () => {
  return (
    <>
      <Route
        exact
        path="/management-dashboard"
        component={ManagementDashboardScreen}
      />
    </>
  );
};

export default ManagementDashboardApp;
