import React from "react";
import { Route } from "react-router-dom";
import ReportsPageFeature from "../../features/report-feature/ReportsPageFeature";
import ReportsPageFeatureV2 from "../../features/reports-page-feature";
import ReportDetailFeature from "../../features/report-detail-feature";
import { useAppConfigProvider } from "../../services/soft-cache-service";

const APP_ID = "reports";
const ReportsApp = () => {
  const configProvider = useAppConfigProvider(APP_ID);
  const useV2 = configProvider.getValue("useV2", "boolean") || false;

  if (useV2) {
    return (
      <>
        <Route
          exact
          path="/reports"
          component={ReportsPageFeatureV2}
        />
        <Route
          exact
          path="/reports/detail"
          component={ReportDetailFeature}
        />
      </>
    );
  }
  return (
    <>
      <Route
        exact
        path="/reports"
        component={ReportsPageFeature}
      />
    </>
  );
};

export default ReportsApp;
