import React from "react";
import TabbedSectionComponent from "../../components/vertical-tab-with-contents/TabbedSectionComponent";
import TabWithSwitchControlComponent from "../../components/vertical-tab-with-contents/TabWithSwitchControlComponent";
import "./RuleConfigurationSection.css";
import AlertService, { ruleInfoMap, temporaryComponent } from "../../services/AlertService";
import { XemelgoService } from "../../services/XemelgoService";
import Spinner from "react-bootstrap/Spinner";

function generateTabName(tabContent, className, setActiveComponentFn) {
  const tabName = tabContent.name;
  const { component } = tabContent;

  const id = `${tabContent.name}-subscription-switch`;
  return (
    <TabWithSwitchControlComponent
      key={id}
      tabName={tabName}
      toggleSwitchFn={tabContent.notification.toggleSubscribe}
      isOn={tabContent.notification.subscribed}
      enableRuleSubscription={tabContent.enableRuleSubscription}
      className={className}
      handleOnClick={() => setActiveComponentFn(tabName, component)}
    />
  );
}

export default class RuleConfigurationSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rules: props.rules,
      ruleNotificationMap: {}
    };
  }

  componentDidMount() {
    this.onLoad();
  }

  onLoad = () => {
    AlertService.fetchRules().then((result) => {
      const { ruleNotificationMap } = result;
      const { notificationFeatureEnabled } = result;

      const tabs = [];
      Object.keys(ruleNotificationMap)
        .sort((ruleA, ruleB) => ruleA.localeCompare(ruleB))
        .forEach((ruleName) => {
          const rule = ruleNotificationMap[ruleName];

          // Don't render any rules that doesn't exist
          if (!ruleInfoMap[ruleName]) return;

          const renderComponent = ruleInfoMap[ruleName].component;
          const { enableRuleSubscription } = ruleInfoMap[ruleName];
          const tabContent = {
            name: ruleName,
            rule,
            result,
            onLoad: this.onLoad,
            component: renderComponent || temporaryComponent,
            enableRuleSubscription,
            notification: {
              ruleId: rule.ruleId,
              available: notificationFeatureEnabled,
              subscribed: rule.enabled,
              eventId: rule.eventId,
              async toggleSubscribe(on) {
                const { ruleId } = rule;
                const RulePageClient = XemelgoService.getClient().getRulePageClient();

                if (!(typeof ruleId === "object")) {
                  if (on) await RulePageClient.subscribeForRule(ruleId);
                  else await RulePageClient.unsubscribeForRule(ruleId);
                } else {
                  const keys = Object.keys(ruleId);
                  for (const key of keys) {
                    if (on && !rule.subscriptions[key]) {
                      await RulePageClient.subscribeForRule(ruleId[key]);
                    } else if (!on && rule.subscriptions[key]) {
                      await RulePageClient.unsubscribeForRule(ruleId[key]);
                    }
                  }
                }
                this.subscribed = rule.enabled;
              }
            }
          };
          tabs.push(tabContent);
        });
      this.setState({
        tabContents: tabs,
        notificationFeatureEnabled
      });
    });
  };

  render() {
    const { tabContents } = this.state;
    return (
      <div className="rule-configuration-section">
        <div className="title">
          <h2>Rules Configuration</h2>
          <div>Set up rules to send to notifications when an event has been triggered</div>
        </div>
        {!tabContents ? (
          <div className="loading-circle">
            <Spinner animation="border" />
          </div>
        ) : (
          <TabbedSectionComponent
            tabContents={tabContents}
            className="rule-detail-tab"
            generateTabNameFn={generateTabName}
          />
        )}
      </div>
    );
  }
}
