import React from "react";
import PropTypes from "prop-types";
import Style from "./ListEmpty.module.css";

export const ListEmpty = ({ title }) => {
  return (
    <div className={Style.container}>
      <p className={Style.text}>{title}</p>
    </div>
  );
};

ListEmpty.defaultProps = {
  title: "No items"
};

ListEmpty.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.number])
};
