import React, { PureComponent } from "react";
import { Chart as DonutChart } from "react-google-charts";
import { isAbsolute } from "path";
import PropTypes from "prop-types";
import "./css/Chart.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

/*
 * Chart takes props from InventoryComponent and displays Plant/Location name and DonutChart
 * Reference Google Charts documentation for DonutChart details
 * https://developers.google.com/chart/interactive/docs/gallery/piechart#making-a-donut-chart
 */
export default class Chart extends PureComponent {
  render() {
    const { data, title, itemTypeLabel = "Item Type" } = this.props;

    return (
      <div className="chart_container">
        <div className="title-text">
          <p className="title-separation title-text">
            {data.length ? (
              `${title} Health`
            ) : (
              <Skeleton
                height={27}
                width={100}
              />
            )}
          </p>
          <p className="sub-title-text">
            {data.length ? (
              title === "Stock" ? (
                itemTypeLabel
              ) : (
                "Item"
              )
            ) : (
              <Skeleton
                height={27}
                width={100}
              />
            )}
          </p>
        </div>
        <div className="my-pretty-chart-container">
          {!data.length ? (
            <div className="skeleton">
              <Skeleton
                circle
                height={130}
                width={130}
              />
            </div>
          ) : (
            <DonutChart
              position={isAbsolute}
              width={200}
              height={240}
              chartType="PieChart"
              loader={
                <Skeleton
                  circle="true"
                  height={130}
                  width={130}
                />
              }
              data={data}
              options={{
                titlePosition: "none",
                legend: { position: "top", alignment: "center" },
                chartArea: { left: 0, top: 55, right: 0, bottom: 55 },
                colors: ["#F74446", "#F5B052", "#61D438", "lightgrey"],
                pieSliceText: "none",
                pieHole: 0.5,
                backgroundColor: { fill: "transparent" }
              }}
              rootProps={{ "data-testid": "1" }}
              chartWrapperParams={{ view: { columns: [0, 1] } }}
              chartPackages={["corechart", "controls"]}
            />
          )}
        </div>
      </div>
    );
  }
}

Chart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array).isRequired
};
