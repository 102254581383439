import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getFormattedDate } from "../../../../common/Utilities";
import Style from "./WorkOrderRecord.module.css";

const ORDER_IDENTIFIER_KEY = "identifier";
const ORDER_TRAVELLER_ID_KEY = "travellerId";
const OUTPUT_PART_IDENTIFIER_KEY = "outputPart_identifier";
const OUTPUT_PART_ID_KEY = "outputPart_id";

/**
 * @typedef CellFormat
 * @property { string } id
 * @property { string } type
 * @property { string } format
 * @property { boolean } title
 * @property { boolean }  default
 * @property { number } index
 * @property { string } label
 */

/**
 *
 * @param { any } value
 * @param { string } defaultValue
 */
const getValueOrDefault = (value, defaultValue) => {
  if (value === undefined || value === null) {
    return defaultValue;
  }

  return value;
};

/**
 *
 * @param value
 * @param { CellFormat } cellFormat
 */
const getFormattedData = (value, cellFormat, data) => {
  const { id, type, format = "MM/DD/YYYY" } = cellFormat;
  if (type === "date") {
    return value ? getFormattedDate(value, format) : "-";
  }

  if (value && type === "link") {
    if (id === ORDER_IDENTIFIER_KEY) {
      return {
        value,
        link: `/work-order/detail?itemId=${data[ORDER_TRAVELLER_ID_KEY]}`
      };
    }

    if (id === OUTPUT_PART_IDENTIFIER_KEY) {
      return {
        value,
        link: `/item-types-management/detail?id=${data[OUTPUT_PART_ID_KEY]}`
      };
    }
  }

  if (value) {
    return value;
  }

  if (typeof value === "number") {
    return 0;
  }

  return "-";
};

/**
 *
 * @param data
 * @param { CellFormat[] } headerList
 * @param { string } key
 * @return {JSX.Element}
 * @constructor
 */
export const WorkOrderRecord = ({ recordName, data, headerList }) => {
  const [formattedData, setFormattedData] = useState({});

  useEffect(() => {
    if (!data || !headerList) {
      return;
    }

    const formatted = {};
    headerList.forEach((header) => {
      const propertyName = header.id;
      const rawValue = getValueOrDefault(data[propertyName], "");
      const formattedValue = getFormattedData(rawValue, header, data);
      formatted[propertyName] = formattedValue;
    });

    setFormattedData(formatted);
  }, [data, headerList]);

  return (
    <div className={Style.table_row}>
      {headerList.map((header) => {
        const { id: propertyName, type } = header;
        if (type === "link") {
          return (
            <a
              className={`${Style.flex_row} ${Style.table_item} ${Style.link_text}`}
              key={`row ${recordName} col ${propertyName}`}
              title="Click to view details"
              href={formattedData[header.id]?.link}
            >
              {formattedData[header.id]?.value}
            </a>
          );
        }
        return (
          <div
            key={`row ${recordName} col ${propertyName}`}
            className={`${Style.flex_row} ${Style.table_item}`}
          >
            {formattedData[header.id]}
          </div>
        );
      })}
    </div>
  );
};

WorkOrderRecord.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    task_identifier: PropTypes.string,
    outputPart_identifier: PropTypes.string,
    mustStartTimestamp: PropTypes.number,
    task_scheduleDate: PropTypes.number,
    task_dueDate: PropTypes.number
  }).isRequired,

  headerList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string,
      format: PropTypes.string,
      index: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  key: PropTypes.string
};

WorkOrderRecord.defaultProps = {
  key: `workOrder-record-${Date.now().toString()}`
};
