import { useMemo, useEffect, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom-v5-compat";
import { SEARCH_PARAM_NAME } from "./data/constants";

export const useInventoryTrackPageSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();

  const selectedLocationId = searchParams.get(SEARCH_PARAM_NAME.selectedLocationId) || undefined;

  const selectedViewModeId = searchParams.get(SEARCH_PARAM_NAME.selectedViewModeId) || undefined;

  const selectedViewTypeId = searchParams.get(SEARCH_PARAM_NAME.selectedViewTypeId) || "";

  const sideFilterValueString = searchParams.get(SEARCH_PARAM_NAME.sideFilterValue);

  const selectedItemGroupString = searchParams.get(SEARCH_PARAM_NAME.selectedItemGroup);

  const setSelectedLocationId = (newSelectedLocationId) => {
    setSearchParams((prev) => {
      if (!newSelectedLocationId) {
        prev.delete(SEARCH_PARAM_NAME.selectedLocationId);
      } else {
        prev.set(SEARCH_PARAM_NAME.selectedLocationId, newSelectedLocationId);
      }
      return prev;
    });
  };

  const setSelectedViewModeId = (newSelectedViewModeId) => {
    setSearchParams(
      (prev) => {
        if (!newSelectedViewModeId) {
          prev.delete(SEARCH_PARAM_NAME.selectedViewModeId);
        } else {
          prev.set(SEARCH_PARAM_NAME.selectedViewModeId, newSelectedViewModeId);
        }
        return prev;
      },
      { replace: true }
    );
  };

  const setSelectedViewTypeId = (newSelectedViewTypeId) => {
    setSearchParams(
      (prev) => {
        if (!newSelectedViewTypeId) {
          prev.delete(SEARCH_PARAM_NAME.selectedViewTypeId);
        } else {
          prev.set(SEARCH_PARAM_NAME.selectedViewTypeId, newSelectedViewTypeId);
        }
        return prev;
      },
      { replace: true }
    );
  };
  const sideFilterValue = useMemo(() => {
    return JSON.parse(sideFilterValueString || "{}");
  }, [sideFilterValueString]);

  const setSideFilterValue = (newSideFilterValue) => {
    setSearchParams(
      (prev) => {
        if (!newSideFilterValue || !Object.keys(newSideFilterValue || {}).length) {
          prev.delete(SEARCH_PARAM_NAME.sideFilterValue);
        } else {
          prev.set(SEARCH_PARAM_NAME.sideFilterValue, JSON.stringify(newSideFilterValue));
        }
        return prev;
      },
      {
        replace: true
      }
    );
  };

  const [isSearchParamReady, setIsSearchParamReady] = useState(false);

  const selectedItemGroup = useMemo(() => {
    return JSON.parse(selectedItemGroupString || "{}");
  }, [selectedItemGroupString]);

  const setSelectedItemGroup = (newSelectedItemGroup) => {
    setSearchParams((prev) => {
      if (!newSelectedItemGroup || !Object.keys(newSelectedItemGroup || {}).length) {
        prev.delete(SEARCH_PARAM_NAME.selectedItemGroup);
      } else {
        prev.set(SEARCH_PARAM_NAME.selectedItemGroup, JSON.stringify(newSelectedItemGroup));
      }
    });
  };

  useEffect(() => {
    if (location.pathname === "/inventory") {
      setIsSearchParamReady(true);
    }
  }, [location.pathname]);

  return {
    selectedLocationId,
    selectedViewModeId,
    selectedViewTypeId,
    setSelectedLocationId,
    setSelectedViewModeId,
    setSelectedViewTypeId,
    sideFilterValue,
    setSideFilterValue,
    isSearchParamReady,
    selectedItemGroup,
    setSelectedItemGroup
  };
};
