import React from "react";
import PropTypes from "prop-types";
import Style from "../../SelectedLocationContent.module.css";
import OptionalLink from "../../../../../../components/optional-link/OptionalLink";
import { STATUS_COLOR_MAP } from "../../../../data/constants";
import getValueByType from "./utils/get-value-by-type";

export const renderStatusHelper = (workOrder) => {
  return (
    <div className={`${Style.flex_column} ${Style.table_column} ${Style.table_column_status}`}>
      {workOrder.operationStatus === "Time Exceeded" && (
        <div
          className={Style.information_title_status}
          style={{
            marginLeft: 0,
            backgroundColor: STATUS_COLOR_MAP[workOrder.operationSeverity]
          }}
        >
          {`${workOrder.operationSeverity} - Time Exceeded`}
        </div>
      )}
      {workOrder.operationStartStatus === "Delayed" && (
        <div
          className={Style.information_title_status}
          style={{
            marginLeft: 0,
            backgroundColor: STATUS_COLOR_MAP[workOrder.operationDelayedStartSeverity]
          }}
        >
          {`${workOrder.operationDelayedStartSeverity} - Late To Start`}
        </div>
      )}
      {workOrder.priority >= 1 && workOrder.priority <= 9 && (
        <div
          className={Style.information_title_status}
          style={{
            marginLeft: 0,
            backgroundColor: STATUS_COLOR_MAP.Expedited
          }}
        >
          Expedited
        </div>
      )}
    </div>
  );
};

export const WorkOrderTableRow = ({ workOrderTableConfig, workOrderData, onMouseEnter, onMouseLeave }) => {
  return (
    <div
      key={workOrderData.id}
      className={`${Style.flex_row} ${Style.table_item}`}
    >
      {workOrderTableConfig.map(({ id, url, urlValueKey, type, timeFormat }) => {
        if (id === "status") {
          return renderStatusHelper(workOrderData);
        }
        return (
          <div
            key={id}
            className={`${Style.flex_row} ${Style.table_column}`}
          >
            <OptionalLink
              active={!!url && !!workOrderData[urlValueKey]}
              route={`${url}=${workOrderData[urlValueKey]}`}
              className={`${Style.table_item_text} ${
                !!url && !!workOrderData[urlValueKey] && Style.table_item_text_active
              }`}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              {getValueByType(workOrderData[id], type, timeFormat) || "-"}
            </OptionalLink>
          </div>
        );
      })}
    </div>
  );
};

WorkOrderTableRow.defaultProp = {
  workOrderTableConfig: [],
  workOrderData: {},
  onMouseEnter: () => {},
  onMouseLeave: () => {}
};

WorkOrderTableRow.propTypes = {
  workOrderTableConfig: PropTypes.array.isRequired,
  workOrderData: PropTypes.object.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
};
