import { useMemo } from "react";
import CONFIGURATION from "../../configuration.json";

export const useConfigurationInfo = () => {
  const configurationInfo = useMemo(() => {
    const tempConfigurationInfo = {
      stage: "dev",
      bucketName: CONFIGURATION.s3_report_configuration.bucket_name
    };

    if (CONFIGURATION.production) {
      tempConfigurationInfo.stage = "prod";
    } else if (CONFIGURATION.demo) {
      tempConfigurationInfo.stage = "demo";
    }

    return tempConfigurationInfo;
  }, [CONFIGURATION]);

  return configurationInfo;
};
