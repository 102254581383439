import React, { useMemo, useState } from "react";
import { getFormattedDate } from "../../../../common/utils";
import { DateTimePicker } from "../../../../components/DateTimePicker/DateTimePicker";
import SearchDropdown from "../../../../components/SearchDropdown/SearchDropdown";
import Style from "./DetectorCoverageReportForm.module.css";

const DetectorCoverageReportForm = React.forwardRef((_, ref) => {
  const [selectedType, setSelectedType] = useState({});
  const [typeError, setTypeError] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [startDateError, setStartDateError] = useState(false);

  const [endDate, setEndDate] = useState(null);
  const [endDateError, setEndDateError] = useState(false);

  const typeOptions = useMemo(() => {
    return [
      { id: "heatmap", label: "Heatmap" },
      { id: "events", label: "Events" }
    ];
  }, []);

  const validateForm = () => {
    let isValid = true;
    if (!selectedType.id) {
      setTypeError(true);
      isValid = false;
    }

    if (!startDate) {
      setStartDateError(true);
      isValid = false;
    }
    if (!endDate) {
      setEndDateError(true);
      isValid = false;
    }
    if (endDate < startDate) {
      setStartDateError(true);
      setEndDateError(true);
      isValid = false;
    }

    return isValid;
  };

  const buildRefFunctions = () => {
    return {
      buildPayload: () => {
        const isValid = validateForm();
        if (!isValid) {
          return ["", { error: true }];
        }
        const retPayload = { startDate, endDate, type: selectedType.id };
        return [
          `Detection Report - ${getFormattedDate(startDate, "YYYY_MM_DD")} - ${getFormattedDate(
            endDate,
            "YYYY_MM_DD"
          )}`,
          retPayload
        ];
      }
    };
  };

  if (ref) {
    ref.current = buildRefFunctions();
  }

  return (
    <>
      <div className={`${Style.grid} ${Style.input_group_list}`}>
        <div className={Style.flex_column}>
          <div className={Style.flex_row}>
            <p className={`${Style.attribute_label}`}>Type</p>
            <p className={`${Style.attribute_label} ${Style.label_asterisk}`}>*</p>
          </div>
          <div className={Style.input_container}>
            <SearchDropdown
              color="#4A90FF"
              error={typeError}
              placeholder="Select a type"
              options={typeOptions}
              showIcon
              withoutOptionFilter
              selectedItem={selectedType}
              onItemSelected={(item) => {
                setTypeError(false);
                setSelectedType(item);
              }}
            />
          </div>
        </div>
        <div />
      </div>
      <div className={`${Style.flex_column} ${Style.run_type_container}`}>
        <div className={Style.flex_row}>
          <p className={`${Style.attribute_label}`}>Please Select the Start Date and End Date</p>
          <p className={`${Style.attribute_label} ${Style.label_asterisk}`}>*</p>
        </div>
        <div className={`${Style.flex_row} ${Style.date_picker_list}`}>
          <div className={`${Style.flex_row} ${Style.date_picker}`}>
            <p>Start Date:</p>
            <DateTimePicker
              color="#4A90FF"
              error={startDateError}
              placeholder="Select date & time"
              value={startDate}
              onTimeChange={(timestamp) => {
                setStartDateError(false);
                setStartDate(timestamp);
              }}
            />
          </div>
          <div className={`${Style.flex_row} ${Style.date_picker}`}>
            <p>End Date:</p>
            <DateTimePicker
              color="#4A90FF"
              error={endDateError}
              placeholder="Select date & time"
              value={endDate}
              onTimeChange={(timestamp) => {
                setEndDateError(false);
                setEndDate(timestamp);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
});

export default DetectorCoverageReportForm;
