/* eslint-disable no-param-reassign */
import { uniq } from "lodash";

import { APPROVAL_STATE } from "../../data/constants";

export const getItemTypeRows = (reportResult, statusesToInclude) => {
  return Object.keys(reportResult.itemTypes || [])
    .map((itemTypeId) => {
      const itemType = reportResult.itemTypes[itemTypeId];
      const relevantItems = (itemType.items || []).filter((item) => {
        return !statusesToInclude || statusesToInclude.includes(item.state);
      });

      if (relevantItems.length === 0) {
        return null;
      }

      const itemStats = getItemTypeMetrics(relevantItems);
      const distinctLotsForItems = uniq(
        relevantItems.map((item) => {
          return item.lotNumber;
        })
      );
      distinctLotsForItems.sort((a, b) => {
        return a - b;
      });

      return {
        id: itemTypeId,
        identifier: itemType.identifier,
        lotNumber: distinctLotsForItems.join(", "),
        pending: itemStats[APPROVAL_STATE.pending],
        approved: itemStats[APPROVAL_STATE.approved],
        removed: itemStats[APPROVAL_STATE.standby],
        consumed: itemStats[APPROVAL_STATE.pending] + itemStats[APPROVAL_STATE.approved],
        isAutoApproved: itemStats.isAutoApproved,
        isChecked: false,
        isExpanded: false,
        items: relevantItems.map((item) => {
          item.isChecked = false;
          item.isAutoApproved = item.state === APPROVAL_STATE.autoApproved;
          return item;
        })
      };
    })
    .filter((row) => {
      return row != null;
    });
};

const getItemTypeMetrics = (itemsForItemType) => {
  return (itemsForItemType || []).reduce(
    (stats, item) => {
      if (item.state === APPROVAL_STATE.autoApproved) {
        stats[APPROVAL_STATE.approved]++;
      } else {
        stats[item.state]++;
        stats.isAutoApproved = false;
      }

      return stats;
    },
    {
      [APPROVAL_STATE.pending]: 0,
      [APPROVAL_STATE.approved]: 0,
      [APPROVAL_STATE.standby]: 0,
      isAutoApproved: true
    }
  );
};
