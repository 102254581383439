import React, { Component, Fragment } from "react";
import Switch from "react-switch";
import EditableTableComponent from "../../components/editable-table/EditableTableComponent";
import { XemelgoService } from "../../services/XemelgoService";
import { CollapsibleListView } from "../../components/collapsible-list-view";
import "./style.css";

export default class StockLevelRuleContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locationDropdownOptions: [],
      itemTypeData: [],
      loading: false
    };

    this.handleRuleConditionRequests = this.handleRuleConditionRequests.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
  }

  componentDidMount() {
    const { ruleConditionsList = [], locationDataList = [], itemTypeData = [] } = this.props;
    this.onLoad(ruleConditionsList, locationDataList, itemTypeData);
  }

  componentWillReceiveProps(newProps) {
    let ruleConditionsList;
    let oldRuleConditionsList;
    if (Array.isArray(newProps.ruleConditionsList)) {
      ruleConditionsList = [...newProps.ruleConditionsList];
      oldRuleConditionsList = [...this.props.ruleConditionsList];
    }
    if (ruleConditionsList !== oldRuleConditionsList) {
      const { locationDataList = [], itemTypeData = [] } = newProps;
      this.onLoad(ruleConditionsList, locationDataList, itemTypeData);
    }
  }

  onLoad = (ruleConditionsList, locationDataList, itemTypeData) => {
    let conditions;
    const { ruleConfig } = this.props;
    // if the user has optimal stock, then it will show based off of the optimal condition
    // since optimal condition has both min stock and optimal stock
    if (ruleConfig.optimalStock) {
      conditions = ruleConditionsList.optimalConditions.map((ruleCondition) => {
        return this.parseConditionData(ruleCondition);
      });
    } else {
      conditions = ruleConditionsList.map((ruleCondition) => {
        return this.parseConditionData(ruleCondition);
      });
    }
    conditions.sort((a, b) => {
      if (a.locationName.localeCompare(b.locationName) === 0) {
        return a.itemType.localeCompare(b.itemType);
      }
      return a.locationName.localeCompare(b.locationName);
    });
    const locationDropdownOptions = locationDataList.map((eachLocation) => {
      return { objectTypeName: eachLocation.name, objectTypeId: eachLocation.id };
    });

    this.setState({
      conditions,
      locationDropdownOptions,
      itemTypeData
    });
  };

  parseConditionData = (ruleCondition) => {
    const { tags } = ruleCondition;
    const { ruleConfig, ruleConditionsList } = this.props;
    const conditionId = ruleCondition.id;

    let itemType = "";
    let threshold = "";
    let locationName = "";
    let locationId = "";
    let optimalThreshold = "";
    const ruleName = ruleCondition.name;
    const subscribed = ruleCondition.hasSubscriptions;

    if (tags) {
      itemType = tags.itemType;
      threshold = tags.threshold;
      locationName = tags.locationName;
      locationId = tags.locationId;
      optimalThreshold = tags.optimalThreshold;
    } else {
      itemType = ruleCondition.conditions.find((condition) => condition.property === "itemType").value;
      threshold = ruleCondition.conditions.find((condition) => condition.property === "itemTypeCount").value;
      // locationName = ruleCondition.conditions.find(condition => condition.property === 'locationId')
      // .value;
    }

    let minStockCondition;
    let outOfStockCondition;
    if (ruleConfig.optimalStock) {
      minStockCondition = ruleConditionsList.minStockConditions.find(
        (condition) => condition.tags.itemType === itemType && condition.tags.locationId === locationId
      );
      outOfStockCondition = ruleConditionsList.outOfStockConditions.find(
        (condition) => condition.tags.itemType === itemType && condition.tags.locationId === locationId
      );
    }

    return {
      // ruleId,
      id: conditionId, // this is for component to keep track
      conditionId,
      itemType,
      threshold,
      locationName,
      subscribed,
      ruleName,
      locationId,
      minStockId: minStockCondition ? minStockCondition.id : "",
      optimalThreshold,
      minStockName: minStockCondition ? minStockCondition.name : "",
      outOfStockId: outOfStockCondition ? outOfStockCondition.id : ""
    };
  };

  buildConditions = (payload) => {
    const { ruleConfig } = this.props;
    const { locationName, locationId, itemType, threshold, optimalThreshold } = payload;
    let optimalConditions = [];
    let outOfStockConditions = [];
    const commonConditions = [
      {
        property: "itemType",
        op: "=",
        value: itemType
      },
      {
        property: "locationName",
        op: "=",
        value: locationName
      },
      {
        property: "locationId",
        op: "=",
        value: locationId
      }
    ];

    const lowStockConditions = [
      ...commonConditions,
      {
        property: "itemTypeCount",
        op: "<",
        value: Math.round(threshold)
      }
    ];

    if (ruleConfig.optimalStock) {
      lowStockConditions.push({
        property: "itemTypeCount",
        op: ">",
        value: 0
      });

      optimalConditions = [
        ...commonConditions,
        {
          property: "itemTypeCount",
          op: ">=",
          value: Math.round(threshold)
        },
        {
          property: "itemTypeCount",
          op: "<",
          value: Math.round(optimalThreshold)
        }
      ];

      outOfStockConditions = [
        ...commonConditions,
        {
          property: "itemTypeCount",
          op: "=",
          value: 0
        }
      ];
    }

    return {
      lowStockConditions,
      optimalConditions,
      outOfStockConditions
    };
  };

  buildUpdateRuleConditionPayloadFromRequest = (requestPayload) => {
    const { ruleConfig } = this.props;
    const id = requestPayload.conditionId;
    const { ruleName, minStockName, minStockId, locationName, locationId, itemType, threshold, optimalThreshold } =
      requestPayload;

    const { lowStockConditions, optimalConditions } = this.buildConditions({
      threshold,
      locationId,
      itemType,
      optimalThreshold,
      locationName
    });

    const tags = {
      locationId,
      locationName,
      itemType,
      threshold: Math.round(threshold)
    };
    const minStockTags = {
      locationName,
      itemType,
      threshold: Math.round(threshold),
      locationId
    };

    if (ruleConfig.optimalStock) {
      tags.optimalThreshold = Math.round(optimalThreshold);
      return {
        id,
        ruleName,
        tags,
        conditions: optimalConditions,
        minStockName,
        minStockTags,
        minStockConditions: lowStockConditions,
        minStockId
      };
    } else {
      return {
        id,
        ruleName,
        tags,
        conditions: lowStockConditions
      };
    }
  };

  buildCreateRuleConditionPayloadFromRequest = (requestPayload) => {
    // const { locationName, itemType, threshold, locationId } = requestPayload;
    const { locationDataList, ruleConfig } = this.props;
    const { itemType } = requestPayload;
    let { locationName, threshold, optimalThreshold } = requestPayload;
    const selectedLocation = locationDataList.find((location) => location.name.includes(locationName));
    const locationId = selectedLocation.id;
    locationName = selectedLocation.name;
    threshold = Math.round(threshold);

    const { lowStockConditions, optimalConditions, outOfStockConditions } = this.buildConditions({
      threshold,
      locationId,
      itemType,
      optimalThreshold,
      locationName
    });

    const createPayload = {
      name: `lowStock_${itemType}_${locationName}`,
      tags: {
        locationId,
        locationName,
        itemType,
        threshold
      },
      conditions: lowStockConditions
    };

    if (ruleConfig.optimalStock) {
      optimalThreshold = Math.round(optimalThreshold);
      createPayload.optimalName = `optimalStock_${itemType}_${locationName}`;
      createPayload.optimalConditions = optimalConditions;
      createPayload.optimalTags = {
        locationId,
        locationName,
        itemType,
        threshold,
        optimalThreshold
      };

      // assume that if optimal is available, then out of stock is available too
      createPayload.outOfStockName = `outOfStock_${itemType}_${locationName}`;
      createPayload.outOfStockConditions = outOfStockConditions;
      createPayload.outOfStockTags = {
        locationId,
        locationName,
        itemType
      };
    }
    return createPayload;
  };

  handleValidation = (payloads) => {
    const { conditions } = this.state;
    const { ruleConfig } = this.props;

    const inputErrorMap = {};

    const validatedObject = {
      errorExists: false,
      errorMap: inputErrorMap
    };
    let errorMessage = "Please enter valid values in the highlighted field(s).";

    payloads.forEach((payloadItem) => {
      const inputError = {
        threshold: false,
        locationName: false,
        itemType: false,
        optimalThreshold: false
      };

      if (payloadItem._event !== "pending_delete") {
        // const { threshold, locationName, locationId, itemType } = payloadItem;
        const { itemType, locationName } = payloadItem;
        let { threshold, optimalThreshold } = payloadItem;
        const currentRowId = payloadItem.id;

        if (!locationName || locationName === "") {
          inputError.locationName = true;
        }
        //
        // if (!locationId || locationId === '') {
        //   inputError.locationName = true;
        // }

        if (!itemType || itemType === "") {
          inputError.itemType = true;
        }

        /**
         * (+threshold === +threshold) is called a 'self check' that is used to check if it is a valid number
         * '+variable' converts the value into a number.
         */
        const maxValue = 10000;
        threshold = Math.round(threshold);
        if (
          threshold === "" ||
          !threshold ||
          /* eslint-disable-next-line no-self-compare */
          !(+threshold === +threshold) ||
          +threshold <= 0 ||
          +threshold >= maxValue ||
          isNaN(+threshold)
        ) {
          inputError.threshold = true;
          if (+threshold <= 0) {
            errorMessage = "Threshold cannot be less than 0!";
          } else if (+threshold >= maxValue) {
            errorMessage = `Threshold cannot be greater than ${maxValue}!`;
          } else if (isNaN(+threshold)) {
            errorMessage = "Threshold should be a number!";
          }
        }

        if (ruleConfig.optimalStock) {
          optimalThreshold = Math.round(optimalThreshold);
          if (
            optimalThreshold === "" ||
            !optimalThreshold ||
            /* eslint-disable-next-line no-self-compare */
            !(+optimalThreshold === +optimalThreshold) ||
            +optimalThreshold <= 0 ||
            +optimalThreshold >= maxValue ||
            optimalThreshold < threshold ||
            isNaN(+optimalThreshold)
          ) {
            inputError.optimalThreshold = true;
            if (optimalThreshold <= 0) {
              errorMessage = "Optimal Threshold cannot be less than 0!";
            } else if (+threshold >= maxValue) {
              errorMessage = `Optimal Threshold cannot be greater than ${maxValue}!`;
            } else if (optimalThreshold < threshold) {
              errorMessage = "Optimal Threshold cannot be less than Minimum Threshold!";
            } else if (isNaN(+optimalThreshold)) {
              errorMessage = "Optimal Threshold should be a number!";
            }
          }
        }

        if (payloadItem._event === "pending_new") {
          conditions.forEach((condition) => {
            if (condition.locationName === locationName && condition.itemType === itemType) {
              errorMessage = "No duplicate rules allowed";
              inputError.locationName = true;
              inputError.itemType = true;
            }
          });
        }
        inputErrorMap[currentRowId] = inputError;
      }
    });

    const BreakForEachLoop = { exception: "Error exists." };

    try {
      Object.keys(inputErrorMap).forEach((key) => {
        if (
          inputErrorMap[key].threshold ||
          inputErrorMap[key].itemType ||
          inputErrorMap[key].locationName ||
          inputErrorMap[key].optimalThreshold
        ) {
          validatedObject.errorExists = true;
          throw BreakForEachLoop;
        } else {
          validatedObject.errorExists = false;
        }
      });
    } catch (e) {
      alert(errorMessage);
      if (e !== BreakForEachLoop) throw e;
    }

    validatedObject.errorMap = inputErrorMap;

    return validatedObject;
  };

  handleAutoFill = (headerId, newValue, data) => {
    const { locationDropdownOptions } = this.state;

    const autoFillObject = {
      data,
      changed: true
    };

    if (headerId === "itemType") {
      const location = locationDropdownOptions.find((location) => location.objectTypeName === "Stock Room 1");
      const locationId = location ? location.objectTypeId : "";
      // const itemIndex = locationDropdownOptions
      //   .map(i => {
      //     return i.objectTypeName;
      //   })
      //   .indexOf(newValue);
      // const itemId = locationDropdownOptions[itemIndex].objectTypeId;
      data.locationId = locationId;
      data.locationName = location.objectTypeName;
    }
    autoFillObject.data = data;

    if (data.itemType === "" && data.threshold === "") {
      autoFillObject.changed = false;
    }

    return autoFillObject;
  };

  handleRuleConditionRequests = async (requests) => {
    const { ruleId, ruleConfig } = this.props;
    if (!requests || requests.length === 0) {
      return;
    }
    const RulePageClient = XemelgoService.getClient().getRulePageClient();
    this.setState({ loading: true });
    await Promise.all(
      requests.map(async (request) => {
        const event = request._event;
        let payload;
        switch (event) {
          case "pending_new":
            payload = this.buildCreateRuleConditionPayloadFromRequest(request);
            if (ruleConfig.optimalStock) {
              await RulePageClient.createRuleCondition(payload.name, payload.tags, ruleId.minStock, payload.conditions);
              await RulePageClient.createRuleCondition(
                payload.optimalName,
                payload.optimalTags,
                ruleId.optimalStock,
                payload.optimalConditions
              );
              await RulePageClient.createRuleCondition(
                payload.outOfStockName,
                payload.outOfStockTags,
                ruleId.outOfStock,
                payload.outOfStockConditions
              );
            } else {
              await RulePageClient.createRuleCondition(payload.name, payload.tags, ruleId, payload.conditions);
            }
            break;
          case "pending_update":
            payload = this.buildUpdateRuleConditionPayloadFromRequest(request);
            await RulePageClient.updateRuleCondition(payload.id, payload.ruleName, payload.tags, payload.conditions);

            if (ruleConfig.optimalStock) {
              await RulePageClient.updateRuleCondition(
                payload.minStockId,
                payload.minStockName,
                payload.minStockTags,
                payload.minStockConditions
              );
              // out of stock never needs to get updated
            }
            break;
          case "pending_delete":
            await RulePageClient.removeRuleCondition(request.conditionId, false);

            if (ruleConfig.optimalStock) {
              await RulePageClient.removeRuleCondition(request.minStockId, false);
              await RulePageClient.removeRuleCondition(request.outOfStockId, false);
            }
            break;
          default:
            console.log(`Unsupported Event[Name:${event}]`);
            break;
        }
      })
    );
    this.setState({ loading: false });
    await this.props.onLoad();
    const { ruleConditionsList = [] } = this.props;
    let conditions;
    if (ruleConfig.optimalStock) {
      conditions = ruleConditionsList.optimalConditions.map((ruleCondition) => {
        return this.parseConditionData(ruleCondition);
      });
    } else {
      conditions = ruleConditionsList.map((ruleCondition) => {
        return this.parseConditionData(ruleCondition);
      });
    }

    return conditions.map((condition) => condition.id);
  };

  handleSubscriptionChange = async (ruleConditionId, subscribed) => {
    // const { ruleConditionsList } = this.props;

    const subscribedRuleConditionIdList = [];
    const unsubscribedRuleConditionIdList = [];
    if (subscribed) {
      subscribedRuleConditionIdList.push(ruleConditionId);
    } else {
      unsubscribedRuleConditionIdList.push(ruleConditionId);
    }

    const RulePageClient = XemelgoService.getClient().getRulePageClient();
    await RulePageClient.updateSubscriptionForRuleConditions(
      subscribedRuleConditionIdList,
      unsubscribedRuleConditionIdList
    );
    await this.props.onLoad();
  };

  toggleSubscription = async (ruleIdentifier, subscribed) => {
    const { ruleId } = this.props;
    const RulePageClient = XemelgoService.getClient().getRulePageClient();
    if (!subscribed) {
      await RulePageClient.subscribeForRule(ruleId[ruleIdentifier]);
    } else {
      await RulePageClient.unsubscribeForRule(ruleId[ruleIdentifier]);
    }

    await this.props.onLoad();
  };

  renderSwitches() {
    const { subscriptions } = this.props;
    // p tags are used for bolding because the <b> and <strong> tags aren't working for some reason
    return (
      <Fragment>
        <div className="alerts-settings-container">
          <div>
            Receive <p className="inline-bold">Out of Stock</p> Alerts
            <div className="switch-helper-text">
              Receive alerts when stock levels of an item reach 0 (<p className="inline-bold">Out of Stock</p>)
            </div>
          </div>
          <Switch
            checked={subscriptions.outOfStock}
            id="outOfStock"
            className="react_switch"
            width={40}
            height={21}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor="#4d8605"
            onChange={() => {
              this.toggleSubscription("outOfStock", subscriptions.outOfStock);
            }}
          />
        </div>
        <div className="alerts-settings-container">
          <div>
            Receive <p className="inline-bold">Min Stock</p> Alerts
            <div className="switch-helper-text">
              Receive alerts when stock levels of an item reach the <p className="inline-bold">Min Stock</p> level
            </div>
          </div>
          <Switch
            checked={subscriptions.minStock}
            id="minStock"
            className="react_switch"
            width={40}
            height={21}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor="#4d8605"
            onChange={() => {
              this.toggleSubscription("minStock", subscriptions.minStock);
            }}
          />
        </div>
        <div className="alerts-settings-container last-alert">
          <div>
            Receive <p className="inline-bold">Optimal Stock</p> Alerts
            <div className="switch-helper-text">
              Receive alerts when stock levels of an item reach the <p className="inline-bold">Optimal Stock</p> level
            </div>
          </div>
          <Switch
            checked={subscriptions.optimalStock}
            id="optimalStock"
            className="react_switch"
            width={40}
            height={21}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor="#4d8605"
            onChange={() => {
              this.toggleSubscription("optimalStock", subscriptions.optimalStock);
            }}
          />
        </div>
      </Fragment>
    );
  }

  render() {
    const { conditions, itemTypeData, locationDropdownOptions, loading } = this.state;
    const { ruleConfig } = this.props;
    const headers = [
      {
        displayName: "Location",
        id: "locationName",
        cell: {
          input: "dropdown",
          data: locationDropdownOptions,
          display: "text",
          modifiable: false // whether it can be edited after being added
        }
      },
      {
        displayName: "Item",
        id: "itemType",
        cell: {
          input: "dropdown",
          data: itemTypeData,
          display: "text",
          modifiable: false // whether it can be edited after being added
        }
      },
      {
        displayName: ruleConfig.optimalStock ? "Min Stock" : "Threshold",
        id: "threshold",
        cell: {
          input: "text",
          display: "text",
          modifiable: true // whether it can be edited after being added
        }
      }
    ];
    if (ruleConfig.optimalStock) {
      headers.push({
        displayName: "Optimal Stock",
        id: "optimalThreshold",
        cell: {
          input: "text",
          display: "text",
          modifiable: true
        }
      });
    }
    return (
      <div>
        <div>
          <p className="tabbedSectionComponent-contentTitle">
            {ruleConfig.optimalStock ? "Stock Alerts" : "Low Stock"}
          </p>
          <p style={{ color: "#343434" }}>
            Sends a notification if the count of items at any location falls below the set threshold.
          </p>
        </div>
        {ruleConfig.optimalStock && (
          <CollapsibleListView
            className="alert-switches"
            key="stock"
            title="Alert Settings"
            content={this.renderSwitches()}
            openAtStart
          />
        )}
        <EditableTableComponent
          headers={headers}
          dataList={conditions}
          handleChangesFn={this.handleRuleConditionRequests}
          handleValidationFn={this.handleValidation}
          handleSubscriptionChange={this.handleSubscriptionChange}
          loading={loading}
          // handleAutoFillFn={this.handleAutoFill}
        />
      </div>
    );
  }
}
