import React from "react";
import { ModalForm } from "../modal-form";
import "./UserModal.css";
import DetailsForm from "./DetailsForm";

const UserModal = ({ data, updateDataFn, currentAction, currentPartner, updateCurrentActionFn, confirmFns }) => {
  const { action = "", isVendorClientUser, user: { displayName = "" } = {} } = currentAction;

  let title;
  let confirmText;
  const show = !!(action.length && Object.keys(data).length);

  switch (action) {
    case "create":
      title = `New ${currentPartner.identifier} user`;
      confirmText = "Invite";
      break;
    case "edit":
      title = `Edit ${displayName}`;
      confirmText = "Save";
      break;
    case "delete":
      title = `Delete ${displayName}?`;
      confirmText = "Delete User";
      break;
    default:
      break;
  }

  const confirmFn = confirmFns[currentAction.action];

  return (
    <ModalForm
      className={action === "delete" ? "delete-modal-container" : "modal-container"}
      show={show}
      title={<div className="modal-header">{title}</div>}
      body={
        action === "delete" ? (
          <div>If you delete this user account, data associated with this account will also be removed.</div>
        ) : (
          <DetailsForm
            data={data}
            onChangeFn={updateDataFn}
            isVendorClientUser={isVendorClientUser}
          />
        )
      }
      footer={
        <ModalFooter
          cancelText="Cancel"
          cancelOnClick={() => {
            updateCurrentActionFn({});
            updateDataFn({});
          }}
          confirmText={confirmText}
          confirmOnClick={async () => {
            await confirmFn();
          }}
          confirmDanger={action === "delete"}
        />
      }
    />
  );
};

export default UserModal;

const ModalFooter = ({ cancelText, cancelOnClick, confirmText, confirmOnClick, confirmDanger = false }) => {
  return (
    <div className="modal-buttons">
      <div
        onClick={cancelOnClick}
        className="cancelButton"
      >
        {cancelText}
      </div>
      <div
        onClick={confirmOnClick}
        className={confirmDanger ? "confirmDangerButton" : "confirmButton"}
      >
        {confirmText}
      </div>
    </div>
  );
};
