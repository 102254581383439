import { VALUE_TYPE_MAP } from "../../data/constants";
import { getFormattedDate } from "../../../../../../../../common/utils";

export const getValueByType = (value, type, timeFormat) => {
  switch (type) {
    case VALUE_TYPE_MAP.boolean:
      return typeof value !== "undefined" ? (value ? "Yes" : "No") : "-";
    case VALUE_TYPE_MAP.dateTime:
      return value ? getFormattedDate(value, timeFormat || "hh:mm A MMM Do") : "-";
    case VALUE_TYPE_MAP.date:
      return value ? getFormattedDate(value, timeFormat || "MMM Do") : "-";
    default:
      return value || "-";
  }
};
