import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import _ from "lodash";

import { ThemeProvider } from "@material-ui/core/styles";

import theme from "./MaterialTheme";

import FormComponentsStyle from "./css/FormComponents.module.css";

/*
This component displays one or multiple checkboxes

Inputs
  * Data - Data object containing checkbox relate data
  Data {
    id: Identifier used to refer to group of checkboxes
    label: Title for this group of checkboxes
    value: Key / Value pair of each checkbox
    {
      key - Identifier for this checkbox: value - display name to the right of a checkbox
    }
  }

Checkbox Properties
  * color - Changes the theme of the checkbox based on palette primary color
*/
export default class CheckboxGroupField extends PureComponent {
  changeCheckBox = (name, checked) => {
    const { data, changeHandler } = this.props;

    let updatedData = _.cloneDeep(data.value);

    if (checked) {
      updatedData.push(name);
    } else {
      updatedData = updatedData.filter((element) => {
        return element !== name;
      });
    }

    changeHandler(data.id, updatedData);
  };

  render() {
    const { data } = this.props;
    return (
      <div
        className={FormComponentsStyle.form_content_normal}
        key={data.id}
      >
        <div className={FormComponentsStyle.form_label_text}>{data.label}</div>
        <ThemeProvider theme={theme}>
          <FormGroup column>
            {data.possibleValues.map((entry) => {
              return (
                <FormControlLabel
                  key={entry}
                  control={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Checkbox
                      tabIndex={data.tabIndex || -1}
                      key={entry}
                      checked={data.value.includes(entry)}
                      onChange={(event) => this.changeCheckBox(entry, event.target.checked)}
                      color="primary"
                    />
                  }
                  label={entry}
                />
              );
            })}
          </FormGroup>
        </ThemeProvider>
      </div>
    );
  }
}

CheckboxGroupField.propTypes = {
  data: PropTypes.array.isRequired,
  changeHandler: PropTypes.func.isRequired
};
