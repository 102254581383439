import React from "react";
import PropTypes from "prop-types";

import { XButton } from "../../../../../components/xemelgo-button";
import { Div } from "../../../../../components/div";

import "./style.css";

export const DeletePartnerFormFooter = ({ onCancel, onSubmit, className }) => {
  return (
    <Div className={`delete-partner-form-footer ${className}`}>
      <XButton
        className="cancel-button"
        label="Cancel"
        onClick={onCancel}
      />
      <XButton
        className="confirm-button"
        label="Confirm Deletion"
        onClick={onSubmit}
      />
    </Div>
  );
};

DeletePartnerFormFooter.defaultProps = {
  className: null
};

DeletePartnerFormFooter.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string
};
