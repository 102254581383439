import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Spinner from "react-bootstrap/Spinner";

import { useDisplayBannerContext } from "context/DisplayBannerContext/DisplayBannerContext";
import { useXemelgoClient } from "../../services/xemelgo-service";
import { useFeatureConfigProvider } from "../../services/soft-cache-service";
import { TwoColumnsPaneView } from "../../components/two-columns-pane-view";
import { ListPartnerGroupsPanel } from "./features/list-partner-groups-panel/ListPartnerGroupsPanel";
import { PartnerDetailPane } from "./features/partner-detail-pane/PartnerDetailPane";
import { FeatureConfigurationProvider } from "../../domains/feature-configuration-provider";
import { DeletePartnerForm } from "./features/delete-partner-form/DeletePartnerForm";
import { AddEditForm } from "./features/partner-add-edit-form/PartnerAddEditForm";
import { getPartnerDisplayValue } from "./ListPartnersUtil";

import "./style.css";

const FEATURE_ID = "listPartners";
const MODEL_ID = "partner";
export const ListPartners = ({ appId }) => {
  const [xemelgoClient] = useState(useXemelgoClient());
  const [providedConfigProvider] = useState(useFeatureConfigProvider(appId, FEATURE_ID));
  const [configProvider, setConfigProvider] = useState(null);
  const { setShowBanner } = useDisplayBannerContext();
  const [loading, setLoading] = useState(false);
  const [needRefreshData, setNeedRefreshData] = useState(true);
  const [partnerMap, setPartnerMap] = useState({});
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [openDeleteForm, setOpenDeleteForm] = useState(false);
  const [openAddForm, setOpenAddForm] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [partnerDisplayProperty, setPartnerDisplayProperty] = useState("name");

  useEffect(() => {
    return () => {
      setShowBanner(false);
    };
  }, []);

  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!providedConfigProvider) {
      return cancelCallback;
    }

    const providedConfig = providedConfigProvider.getConfiguration();
    const featureConfigProvider = FeatureConfigurationProvider.parse(FEATURE_ID, providedConfig);

    if (!cancelled) {
      setConfigProvider(featureConfigProvider);
      const modelConfigProvider = featureConfigProvider.getModel(MODEL_ID);
      const instanceDisplayProperty = modelConfigProvider.getValue("displayProperty", "string", MODEL_ID);
      setPartnerDisplayProperty(instanceDisplayProperty);
    }

    return cancelCallback;
  }, [providedConfigProvider]);

  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!configProvider || !xemelgoClient || !needRefreshData) {
      return cancelCallback;
    }

    setLoading(true);
    populatePartnerData(cancelled).then(() => {
      return setLoading(false);
    });

    return cancelCallback;
  }, [xemelgoClient, configProvider, needRefreshData]);

  const populatePartnerData = async (cancelled) => {
    const partnerClient = xemelgoClient.getPartnerClient();
    const listResults = await partnerClient.listPartners();
    const sorted = listResults
      .sort((partner1, partner2) => {
        const partner1DisplayValue = getPartnerDisplayValue(partner1, partnerDisplayProperty, false);
        const partner2DisplayValue = getPartnerDisplayValue(partner2, partnerDisplayProperty, false);

        if (!partner1DisplayValue) {
          return 1;
        }
        if (!partner2DisplayValue) {
          return -1;
        }

        return partner1DisplayValue.localeCompare(partner2DisplayValue);
      })
      .map((partner) => {
        const mappedPartner = {
          id: partner.id,
          name: partner.name,
          identifier: partner.identifier,
          description: partner.description
        };

        return mappedPartner;
      });

    if (!cancelled) {
      setPartnerMap(
        /* eslint-disable no-param-reassign */
        sorted.reduce((map, partner) => {
          map[partner.id] = partner;
          return map;
        }, {})
      );
      setNeedRefreshData(false);
    }
  };

  const refreshData = useCallback(() => {
    setSelectedPartner(null);
    setNeedRefreshData(true);
  }, []);

  const onAddButtonClicked = useCallback(() => {
    window.fcWidget.hide();
    setOpenAddForm(true);
  }, []);

  const onAddCancel = useCallback((shouldRefresh) => {
    window.fcWidget.show();
    setOpenAddForm(false);

    if (shouldRefresh) {
      setNeedRefreshData(true);
    }
  }, []);

  const onAddSave = useCallback(() => {
    window.fcWidget.show();
    setOpenAddForm(false);
    refreshData();
  }, [refreshData]);

  const onEditButtonClicked = useCallback(() => {
    window.fcWidget.hide();
    setOpenEditForm(true);
  }, []);

  const onEditCancel = useCallback(() => {
    window.fcWidget.show();
    setOpenEditForm(false);
  }, []);

  const onEditSave = useCallback(() => {
    setOpenEditForm(false);
    refreshData();
  }, [refreshData]);

  const onDeleteButtonClicked = useCallback(() => {
    setOpenDeleteForm(true);
  }, []);

  const onCancelDeleteFormCallback = useCallback(() => {
    setOpenDeleteForm(false);
  }, []);

  const onSubmitDeleteForm = useCallback(
    (id) => {
      const partnerClient = xemelgoClient.getPartnerClient();
      partnerClient.removePartner(id).then(() => {
        setOpenDeleteForm(false);
        refreshData();
      });
    },
    [xemelgoClient, refreshData]
  );

  const onPartnerSelected = useCallback(
    (id) => {
      const partner = partnerMap[id];
      setSelectedPartner(partner);
    },
    [partnerMap]
  );

  const handleCreatePartner = async (xClient, partnerValue) => {
    const partnerClient = xClient.getPartnerClient();

    try {
      await partnerClient.createPartner(partnerValue.identifier, partnerValue.name, partnerValue.description);
    } catch {
      return {
        isSuccess: false,
        failureMessage: `Unable to add ${partnerValue[partnerDisplayProperty] || partnerValue.name}`
      };
    }

    return { isSuccess: true };
  };

  const handleEditPartner = async (xClient, partnerValue) => {
    const partnerClient = xClient.getPartnerClient();

    try {
      await partnerClient.updatePartner(partnerValue.id, {
        name: partnerValue.name,
        identifier: partnerValue.identifier,
        description: partnerValue.description
      });
    } catch {
      return {
        isSuccess: false,
        failureMessage: `Unable to update ${partnerValue[partnerDisplayProperty] || partnerValue.name}`
      };
    }

    return { isSuccess: true };
  };

  const renderAddForm = () => {
    return (
      <AddEditForm
        isCreateMode
        featureId="addPartner"
        modelId={MODEL_ID}
        configuration={configProvider.getFeatureConfiguration("addResource")}
        show={openAddForm}
        onCancel={onAddCancel}
        onSubmit={onAddSave}
        persistEntityAction={handleCreatePartner}
      />
    );
  };

  const renderEditForm = () => {
    return (
      <AddEditForm
        isCreateMode={false}
        featureId="editPartner"
        modelId={MODEL_ID}
        configuration={configProvider.getFeatureConfiguration("editResource")}
        show={openEditForm}
        onCancel={onEditCancel}
        onSubmit={onEditSave}
        persistEntityAction={handleEditPartner}
        entity={selectedPartner}
      />
    );
  };

  return (
    <>
      {!configProvider || loading ? (
        <div className="loading-circle-container">
          <div className="loading-circle">
            <Spinner animation="border" />
          </div>
        </div>
      ) : (
        <>
          {openEditForm && renderEditForm()}
          {openAddForm && renderAddForm()}
          {openDeleteForm && (
            <DeletePartnerForm
              partner={selectedPartner}
              show={openDeleteForm}
              onSubmit={onSubmitDeleteForm}
              onCancel={onCancelDeleteFormCallback}
              displayProperty={partnerDisplayProperty}
            />
          )}
          <TwoColumnsPaneView
            className="list-partners-feature"
            leftPane={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <ListPartnerGroupsPanel
                partners={Object.values(partnerMap)}
                onPartnerSelected={onPartnerSelected}
                onAddClicked={onAddButtonClicked}
                focus={!needRefreshData}
                displayProperty={partnerDisplayProperty}
              />
            }
            rightPane={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <PartnerDetailPane
                modelId={MODEL_ID}
                configuration={configProvider.getFeatureConfiguration("partnerDetailView")}
                partner={selectedPartner}
                onDeleteClicked={onDeleteButtonClicked}
                onEditClicked={onEditButtonClicked}
                displayProperty={partnerDisplayProperty}
              />
            }
          />
        </>
      )}
    </>
  );
};

ListPartners.propTypes = {
  appId: PropTypes.string.isRequired
};
