import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import ImageUploading from "react-images-uploading";
import { ReactComponent as UploadImageIcon } from "../../../../assets/icons/upload-images.svg";
import { ReactComponent as AddImageIcon } from "../../../../assets/icons/add-images.svg";
import { ReactComponent as RemoveImageIcon } from "../../../../assets/icons/cancel.svg";
import Style from "./ImageUploadComponentStyle.module.css";
import ImageWithEnlargedModalView from '../../../image-with-enlarged-modal-view';

const ImageUploadComponent = ({
  existingImagePaths,
  onNewImageChange,
  onExistingImageRemove,
  maxNumberOfFiles,
  acceptedFileTypes
}) => {
  const [uploadedImageList, setUploadedImageList] = useState([]);

  const existingImageCount = useMemo(() => {
    if (maxNumberOfFiles === 1) {
      return 0;
    }
    return existingImagePaths.length;
  }, [existingImagePaths]);

  return (
    <ImageUploading
      multiple={!!(maxNumberOfFiles > 1)}
      value={uploadedImageList}
      onChange={(imageList) => {
        setUploadedImageList(imageList);
        onNewImageChange(imageList);
      }}
      maxNumber={maxNumberOfFiles - existingImageCount}
      dataURLKey="data_url"
      acceptType={acceptedFileTypes}
    >
      {({ imageList, onImageUpload, onImageRemove, dragProps, errors }) => {
        return (
          <>
            {imageList?.length + existingImageCount === 0 ? (
              <div
                onClick={onImageUpload}
                {...dragProps}
                className={Style.image_upload_component}
              >
                <UploadImageIcon className={Style.upload_icon} />
                <p className={Style.image_upload_component_text}>Drag and Drop Images Here to Upload</p>
                <p className={Style.image_upload_component_text}>or</p>
                <button
                  type="button"
                  className={Style.button}
                >
                  Browse
                </button>
              </div>
            ) : (
              <div className={maxNumberOfFiles === 1 ? Style.centered_preview_container : ""}>
                <div className={Style.previews_container}>
                  {maxNumberOfFiles > 1 && (
                    <div
                      onClick={onImageUpload}
                      className={`${Style.image_container} ${Style.add_images_container}`}
                      {...dragProps}
                    >
                      <AddImageIcon className={Style.add_image_icon} />
                    </div>
                  )}
                  {maxNumberOfFiles > 1 &&
                    existingImagePaths.map((imagePath) => {
                      return (
                        <div
                          key={`existing-image-${imagePath}`}
                          className={Style.preview_box}
                        >
                          <ImageWithEnlargedModalView
                            classOverrides={{
                              imageStyle: Style.preview_image,
                              imageContainerStyle: Style.image_container
                            }}
                            imageUri={imagePath}
                          />
                          <RemoveImageIcon
                            className={Style.delete_icon}
                            onClick={() => {
                              onExistingImageRemove(imagePath);
                            }}
                          />
                        </div>
                      );
                    })}
                  {imageList.map((image, index) => {
                    return (
                      <div
                        key={`${image.data_url}-${index}`} // if multiple images have same data_url, need index for remove to work
                        className={Style.preview_box}
                      >
                        <ImageWithEnlargedModalView
                          classOverrides={{
                            imageStyle: Style.preview_image,
                            imageContainerStyle: `${Style.image_container} ${
                              maxNumberOfFiles === 1 ? Style.single_image : ""
                            }`
                          }}
                          imageUri={image.data_url}
                        />
                        <RemoveImageIcon
                          className={Style.delete_icon}
                          onClick={() => {
                            onImageRemove(index);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {errors && (
              <div className={Style.error_container}>
                {errors.maxNumber && <span>{`Number of images exceed allowed limit of ${maxNumberOfFiles}.`}</span>}
                {errors.acceptType && (
                  <span>Your selected file type is not allowed. Please use a .jpg / .png file.</span>
                )}
              </div>
            )}
          </>
        );
      }}
    </ImageUploading>
  );
};

ImageUploadComponent.defaultProps = {
  maxNumberOfFiles: 1,
  acceptedFileTypes: ["jpg", "png", "jpeg"],
  existingImagePaths: [],
  onExistingImageRemove: () => {}
};

ImageUploadComponent.propTypes = {
  onNewImageChange: PropTypes.func.isRequired,
  onExistingImageRemove: PropTypes.func,
  maxNumberOfFiles: PropTypes.number,
  acceptedFileTypes: PropTypes.arrayOf(PropTypes.string),
  existingImagePaths: PropTypes.arrayOf(PropTypes.string)
};

export default ImageUploadComponent;
