import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import ShipmentsTrackPageFeature from "../../features/shipments-track-page-feature/ShipmentsTrackPageFeature";
import ShipmentsTrackPageFeatureV2 from "../../features/shipments-track-page-feature/ShipmentsTrackPageFeatureV2";
import { useAppConfigProvider } from "../../services/soft-cache-service";

const APP_ID = "shipments";

const ShipmentsApp = () => {
  const configProvider = useAppConfigProvider(APP_ID);
  const TrackPageConfig = configProvider.getValue("trackPage", "object") || {};
  const VERSION = TrackPageConfig.version;

  return (
    <Route
      exact
      path="/shipments"
      component={VERSION === "v2" ? ShipmentsTrackPageFeatureV2 : ShipmentsTrackPageFeature}
    />
  );
};

export default ShipmentsApp;
