import { useSearchParams } from "react-router-dom-v5-compat";
import { useStore } from "react-hookstore";
import { SEARCH_PARAM_NAME } from "./data/constants";
import { fullScreenModeStore } from "../../../../state-managements/stores/full-screen-mode-store";

export const useKioskSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [, setFullscreenModeStore] = useStore(fullScreenModeStore);

  const setActionId = (newActionId) => {
    setSearchParams((prev) => {
      if (!newActionId) {
        prev.delete(SEARCH_PARAM_NAME.actionId);
      } else {
        prev.set(SEARCH_PARAM_NAME.actionId, newActionId);
      }
      return prev;
    });
  };

  const setFullscreenMode = (newFullscreenMode) => {
    setSearchParams((prev) => {
      if (!newFullscreenMode) {
        setFullscreenModeStore(false);
        prev.delete(SEARCH_PARAM_NAME.fullscreenMode);
      } else {
        setFullscreenModeStore(true);
        prev.set(SEARCH_PARAM_NAME.fullscreenMode, newFullscreenMode);
      }
      return prev;
    });
  };

  return {
    actionId: searchParams.get(SEARCH_PARAM_NAME.actionId) || "",
    fullscreenMode: searchParams.get(SEARCH_PARAM_NAME.fullscreenMode) === "true",
    setActionId,
    setFullscreenMode
  };
};
