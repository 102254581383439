import React from "react";
import Tippy from "@tippyjs/react";
import PropTypes from "prop-types";
import Style from "./Tooltip.module.css";

export const Tooltip = ({ children, content, placement, containerClassName, ...restProps }) => {
  return (
    <Tippy
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
      placement={placement}
      className={`${Style.tooltip} ${containerClassName}`}
      delay={0}
      content={content}
    >
      {children}
    </Tippy>
  );
};

Tooltip.defaultProps = {
  placement: "auto",
  content: "",
  containerClassName: ""
};

Tooltip.propTypes = {
  children: PropTypes.element.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
  containerClassName: PropTypes.string,
  placement: PropTypes.oneOf([
    "top",
    "top-start",
    "top-end",
    "right",
    "right-start",
    "right-end",
    "bottom",
    "bottom-start",
    "bottom-end",
    "left",
    "left-start",
    "left-end",
    "auto",
    "auto-start",
    "auto-end"
  ])
};
