/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./styles.css";

/**
 * A wrapper around React Router's {@link Link} component which allows
 * for falling back to a non-link element for display if a link
 * is not desired
 * @param {Object} props - component props
 * @param {boolean} [props.active=true] - Controls whether or not the link element is enabled
 * @param {string=} [props.route] - The route to navigate to when the card is clicked
 * @param {boolean=} [props.isReplace] - If true, will replace the url in the browsing history instead
 * of pushing onto the stack
 * @param {any=} props.children - The element/value to wrap with a link if linking is active, or the default
 * to display when linking is not active
 * @returns JSX.element
 */

const OptionalLink = ({ active, route, isReplace, children, ...elementProps }) => {
  if (!active) {
    return <div {...elementProps}>{children}</div>;
  }

  return (
    <Link
      to={route}
      replace={isReplace}
      {...elementProps}
    >
      {children}
    </Link>
  );
};

OptionalLink.defaultProps = {
  active: true,
  route: null,
  isReplace: false,
  children: null
};

OptionalLink.propTypes = {
  active: PropTypes.bool,
  route: PropTypes.string,
  isReplace: PropTypes.bool,
  children: PropTypes.node
};

export default OptionalLink;
