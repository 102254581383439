import React from "react";

import { FilterConfigurationBase } from "../../../side-filter/data/FilterConfigurationBase";
import { DateTimePicker } from "../../../../components/DateTimePicker/DateTimePicker";
import { SIDE_FILTER_CATEGORIES } from "../../../side-filter/data/constants";

export class BaseReportFilters extends FilterConfigurationBase {
  getConfiguration() {
    return {
      id: "BaseReportSideFilter",
      categories: [
        {
          id: SIDE_FILTER_CATEGORIES.dateTime,
          title: "Date & Time",
          filters: [
            {
              id: "fromDateTime",
              title: "From:",
              renderComponent: (sideFilterValues, updateFilterValues) => {
                return (
                  <DateTimePicker
                    color="#4A90FF"
                    placeholder="Select start date & time"
                    value={sideFilterValues.startTime}
                    onTimeChange={(timestamp) => {
                      updateFilterValues({ startTime: timestamp });
                    }}
                  />
                );
              },
              filterToApply: (sideFilterValues, { node_start_time: nodeStartTime }) => {
                return !sideFilterValues.startTime || sideFilterValues.startTime <= nodeStartTime;
              }
            },
            {
              id: "toDateTime",
              title: "To:",
              renderComponent: (sideFilterValues, updateFilterValues) => {
                return (
                  <DateTimePicker
                    color="#4A90FF"
                    minDate={sideFilterValues.startTime}
                    placeholder="Select end date & time"
                    value={sideFilterValues.endTime}
                    onTimeChange={(timestamp) => {
                      updateFilterValues({ endTime: timestamp });
                    }}
                  />
                );
              },
              filterToApply: (sideFilterValues, { node_start_time: nodeStartTime }) => {
                return !sideFilterValues.endTime || sideFilterValues.endTime >= nodeStartTime;
              }
            }
          ]
        }
      ]
    };
  }
}
