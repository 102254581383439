import React from "react";
import PropTypes from "prop-types";
import { FormFieldsType } from "../../types/onboardingPageTypes";
import MultiInputForm from "../multi-input-form";
import Style from "./SingleCreateForm.module.css";

/**
 * This component support the default onboarding form with one or two sections
 */
export const SingleCreateForm = ({
  title,
  formSections,
  onFormChange,
  additionalOptionsComponent: AdditionalOptionsComponent
}) => {
  const isLoading = !formSections.length;

  return (
    <div className={Style.single_create_form}>
      <p className={Style.form_title}>{title}</p>
      <div className={Style.form_container}>
        <div>
          <MultiInputForm
            formFields={formSections[0]}
            onChange={onFormChange}
            isLoading={isLoading}
            containerClassName={
              formSections.length > 1 ? Style.first_section_container : Style.second_section_container
            }
            fieldContainerClassName={Style.field_container}
          />
          {!isLoading && AdditionalOptionsComponent}
        </div>
        {formSections.length > 1 && (
          <>
            <div className={Style.section_seperator} />
            <MultiInputForm
              formFields={formSections[1]}
              onChange={onFormChange}
              containerClassName={Style.second_section_container}
              fieldContainerClassName={Style.field_container}
            />
          </>
        )}
      </div>
    </div>
  );
};

SingleCreateForm.defaultProps = {
  title: "",
  formSections: [],
  onFormChange: () => {},
  additionalOptionsComponent: null
};

SingleCreateForm.propTypes = {
  title: PropTypes.string,
  formSections: PropTypes.arrayOf(FormFieldsType),
  onFormChange: PropTypes.func,
  additionalOptionsComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.oneOf([null, false, undefined])])
};
