import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import ListTable from "../ListTable/ListTable";
import Style from "./PaginatedList.module.css";

const PaginatedList = ({ data, numItemsPerPage, ...restProps }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(numItemsPerPage);
  const { pagedItemList, pageStart, pageEnd } = useMemo(() => {
    const pageStart = pageNumber * itemsPerPage - itemsPerPage;
    const pageEnd = pageNumber * itemsPerPage < data.length ? pageNumber * itemsPerPage : data.length;
    return { pagedItemList: data.slice(pageStart, pageEnd), pageStart, pageEnd };
  }, [data, pageNumber, itemsPerPage]);

  // reset page to 1 after filter
  const [prevDataLength, setPrevDataLength] = useState(0);
  useEffect(() => {
    if (data.length !== prevDataLength) {
      setPrevDataLength(data.length);
      setPageNumber(1);
    }
  }, [data]);

  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber >= Math.ceil(data.length / itemsPerPage);

  const leftButton = isFirstPage
    ? `${Style.table_pager_button} ${Style.disabledButton}`
    : `${Style.table_pager_button} ${Style.enabledButton}`;
  const rightButton = isLastPage
    ? `${Style.table_pager_button} ${Style.disabledButton}`
    : `${Style.table_pager_button} ${Style.enabledButton}`;

  const Paginator = () => {
    const fontWeight = restProps.paginatorFontWeight ?? "normal";
    return (
      <div
        className={`${Style.flex_row} ${Style.table_pager} ${restProps.paginatorClassName}`}
        style={{ fontWeight }}
      >
        <div>
          {restProps.showRowsPerPage && (
            <div className={`${Style.flex_row}`}>
              <p
                className={Style.table_pager_size_text}
                style={{ fontWeight }}
              >
                {"Rows per page: "}
              </p>
              <select
                className={Style.table_pager_size_selector}
                style={{ fontWeight }}
                value={itemsPerPage}
                onChange={(e) => {
                  setPageNumber(1);
                  setItemsPerPage(e.target.value);
                }}
              >
                <option
                  className={Style.table_pager_size_item}
                  value={10}
                >
                  10
                </option>
                <option
                  className={Style.table_pager_size_item}
                  value={20}
                >
                  20
                </option>
                <option
                  className={Style.table_pager_size_item}
                  value={30}
                >
                  30
                </option>
                <option
                  className={Style.table_pager_size_item}
                  value={40}
                >
                  40
                </option>
                <option
                  className={Style.table_pager_size_item}
                  value={50}
                >
                  50
                </option>
              </select>
            </div>
          )}
        </div>
        <button
          type="button"
          className={leftButton}
          onClick={() => {
            if (!isFirstPage) {
              setPageNumber(pageNumber - 1);
            }
          }}
          disabled={isFirstPage}
        >
          <ChevronLeft />
        </button>
        <p
          className={Style.table_pager_number}
          style={{ fontWeight }}
        >
          {`${data.length ? pageStart + 1 : 0} - ${pageEnd} of ${data.length}`}
        </p>
        <button
          type="button"
          className={rightButton}
          onClick={() => {
            if (!isLastPage) {
              setPageNumber(pageNumber + 1);
            }
          }}
          disabled={isLastPage}
        >
          <ChevronRight />
        </button>
      </div>
    );
  };

  return (
    <div className={Style.flex_column}>
      {restProps.paginatorLocation === "top" && <Paginator />}
      <ListTable
        data={pagedItemList}
        {...restProps}
      />
      {restProps.paginatorLocation === "bottom" && <Paginator />}
    </div>
  );
};

PaginatedList.defaultProps = {
  data: [],
  numItemsPerPage: 10,
  paginatorLocation: "bottom"
};

PaginatedList.propTypes = {
  paginatorLocation: PropTypes.string,
  data: PropTypes.array,
  numItemsPerPage: PropTypes.number
};

export default PaginatedList;
