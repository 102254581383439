import xemelgoStyle from "../../../../../styles/variable";
import { VALUE_TYPE_MAP } from "../../../../package-track-page-feature/data/constants";

export const PICKLIST_STATUS_MAP = {
  complete: {
    label: "Complete",
    backgroundColor: xemelgoStyle.theme.STATUS_GREEN,
    sortIndex: 3
  },
  partial: {
    label: "Partial",
    backgroundColor: xemelgoStyle.theme.STATUS_YELLOW,
    textColor: xemelgoStyle.theme.TEXT_PRIMARY,
    sortIndex: 1
  },
  extraScanned: {
    label: "Extra Scanned",
    backgroundColor: xemelgoStyle.theme.STATUS_RED,
    sortIndex: 0
  },
  notScanned: {
    label: "Not Scanned",
    backgroundColor: xemelgoStyle.theme.NEUTRAL_PRIMARY,
    sortIndex: 2
  }
};

export const LOCATION_ROLE_ACTION = {
  STAGE: "stage",
  SHIP: "ship",
  RECEIVE: "receive",
  VERIFY: "verify"
};

export const DEFAULT_STAGE_OPTIONS = [
  {
    label: "Stage",
    value: LOCATION_ROLE_ACTION.STAGE,
    quantityName: "quantityStaged"
  },
  {
    label: "Ship",
    value: LOCATION_ROLE_ACTION.SHIP,
    quantityName: "quantityInProgress"
  },
  {
    label: "Receive",
    value: LOCATION_ROLE_ACTION.RECEIVE,
    quantityName: "quantityReceived"
  },
  {
    label: "Verify",
    value: LOCATION_ROLE_ACTION.VERIFY,
    quantityName: "quantityVerified"
  }
];

const TRANSFER_ORDER_ITEM_STATUS = {
  ADDED: "Added",
  STAGED: "Staged",
  SHIPPED: "InProgress",
  RECEIVED: "Received",
  VERIFIED: "Verified"
};

export const STAGE_TO_SHOWN_ITEM_STATUSES = {
  [LOCATION_ROLE_ACTION.STAGE]: [TRANSFER_ORDER_ITEM_STATUS.ADDED],
  [LOCATION_ROLE_ACTION.SHIP]: [TRANSFER_ORDER_ITEM_STATUS.ADDED, TRANSFER_ORDER_ITEM_STATUS.STAGED],
  [LOCATION_ROLE_ACTION.RECEIVE]: [
    TRANSFER_ORDER_ITEM_STATUS.ADDED,
    TRANSFER_ORDER_ITEM_STATUS.STAGED,
    TRANSFER_ORDER_ITEM_STATUS.SHIPPED
  ],
  [LOCATION_ROLE_ACTION.VERIFY]: [
    TRANSFER_ORDER_ITEM_STATUS.ADDED,
    TRANSFER_ORDER_ITEM_STATUS.STAGED,
    TRANSFER_ORDER_ITEM_STATUS.SHIPPED,
    TRANSFER_ORDER_ITEM_STATUS.RECEIVED
  ]
};

export const DEFAULT_ITEM_TYPE_DISPLAY_NAME = "Item Type";

export const DEFAULT_EPC_TABLE_HEADERS = [
  { id: "vid", label: "EPC", isNavLink: true, openLinkInNewTab: true },
  { id: "status", label: "Status", type: VALUE_TYPE_MAP.status, defaultSort: true }
];

export const QR_CODE_COLUMN_TYPE = "QRCode";

export const DEFAULT_UNEXPECTED_ITEMS_TABLE_HEADERS = [
  {
    id: "itemType_identifier",
    label: DEFAULT_ITEM_TYPE_DISPLAY_NAME
  },
  {
    id: "identifier",
    label: "Item"
  },
  {
    id: "vid",
    label: "",
    type: QR_CODE_COLUMN_TYPE
  }
];

export const STAGE_TO_STATUS_MAP = {
  Stage: "Staging in Progress",
  Ship: "Shipping in Progress",
  Receive: "Receiving in Progress",
  Verify: "Verifying in Progress"
};
