import React from "react";
import { Route } from "react-router-dom";
import GoodsReceiptPageFeature from "../../features/goods-receipt-page-feature/GoodsReceiptPageFeature";

const GoodsReceiptApp = () => {
  return (
    <Route
      exact
      path="/goods-receipt"
      component={GoodsReceiptPageFeature}
    />
  );
};
export default GoodsReceiptApp;
