import React, { useState, useRef, useEffect, Fragment } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { FormatListBulleted, ViewModule, KeyboardArrowDown } from "@material-ui/icons";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import ExitFullscreenIcon from "@material-ui/icons/FullscreenExit";
import { getFormattedDate, exportCsv } from "common/Utilities";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Popper from "@material-ui/core/Popper";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "mdbreact";
import Skeleton from "react-loading-skeleton";
import ListView from "../ListView";
import TrackPageDataViewStyle from "./TrackPageDataViewComponent.module.css";
import GridViewComponent from "../../grid-view-component/GridViewComponent";
import SortIconDropDown from "../../sort-icon-dropdown/SortIconDropDown";
import "react-loading-skeleton/dist/skeleton.css";
import xemelgoStyle from "../../../styles/variable";

const TrackPageDataViewComponent = ({
  gridCardComponent,
  dataList,
  listViewStructure,
  listViewDataList,
  handleListViewClick,
  filterFunc,
  listViewFilterFunc,
  sortSchema,
  fullScreenMode,
  setFullScreenModeFn,
  fullScreenModeRefreshFn,
  enableAutoRefresh,
  fullScreenModeRefreshInterval,
  defaultViewMode,
  onlyListViewMode,
  belowActionBarComponent,
  multiSelectEnabled,
  onMultiClick,
  resetPageOnLoad,
  batchUpdateOptions,
  setFormComponent,
  formComponent,
  disablePagination,
  loading,
  getListViewLinkDetails
}) => {
  const [listViewMode, setListViewMode] = useState(defaultViewMode === "listview");
  const [filterInput, setFilterInput] = useState("");
  const [sortCompareFunc, setSortCompareFunc] = useState(undefined);
  const [targetElRef, setTargetElRef] = useState(null);

  const prevDataListRef = useRef();
  const prevListViewModeRef = useRef(listViewMode);

  useEffect(() => {
    setListViewMode(defaultViewMode === "listview");
    prevListViewModeRef.current = defaultViewMode;
  }, [defaultViewMode]);

  useEffect(() => {
    if (onlyListViewMode) {
      prevListViewModeRef.current = listViewMode;
      setListViewMode(true);
    } else {
      setListViewMode(prevListViewModeRef.current);
    }
    // eslint-disable-next-line
  }, [onlyListViewMode]);

  const filteredDataList = dataList.filter((each) => {
    return filterFunc ? filterFunc(filterInput, each) : true;
  });
  if (dataList !== prevDataListRef.current) {
    setSortCompareFunc(undefined);
    prevDataListRef.current = dataList;
  } else {
    filteredDataList.sort(sortCompareFunc || undefined);
  }
  const filteredListViewDataList = listViewDataList.filter((each) => {
    return listViewFilterFunc ? listViewFilterFunc(filterInput, each) : true;
  });

  // TODO: this function also needs to be change after refactor listview component
  const onExportCsv = () => {
    const fileExtension = ".csv";
    const headerStructureList = listViewStructure;
    const data = filteredListViewDataList;
    const dataJson = data.map((each) => {
      const one = {};
      headerStructureList.forEach((eachHeader) => {
        if (Array.isArray(each[eachHeader.id])) {
          if (each[eachHeader.id].length === 0) {
            one[eachHeader.label] = "-";
          } else {
            let flag = "";
            each[eachHeader.id].forEach((eachFlag, index) => {
              flag += eachFlag.displayText;
              if (index < each[eachHeader.id].length - 1) {
                flag += ", ";
              }
            });
            one[eachHeader.label] = flag;
          }
        } else if (typeof each[eachHeader.id] === "object" && each[eachHeader.id]) {
          one[eachHeader.label] = each[eachHeader.id].displayText;
        } else if (
          typeof each[eachHeader.id] !== "string" &&
          new Date(each[eachHeader.id]).getTime() > 0 &&
          each[eachHeader.id].toString().length > 8
        ) {
          one[eachHeader.label] = getFormattedDate(each[eachHeader.id], "hh:mm A MMM D YYYY");
        } else if (each[eachHeader.id]) {
          one[eachHeader.label] = each[eachHeader.id];
        } else {
          one[eachHeader.label] = "-";
        }
      });
      return one;
    });
    const headers = headerStructureList.map((eachHeader) => {
      return eachHeader.label;
    });
    exportCsv(dataJson, headers, `DataSheet${fileExtension}`);
  };

  const renderGridView = () => {
    if (loading) {
      return (
        <Skeleton
          className={TrackPageDataViewStyle.grid_card_container_style}
          count={3}
          width={400}
          inline
        />
      );
    }

    return (
      <GridViewComponent>
        {filteredDataList.map((eachData) => {
          return gridCardComponent(eachData, eachData.id, TrackPageDataViewStyle.grid_card_container_style);
        })}
      </GridViewComponent>
    );
  };

  const renderFullScreenModeButton = () => {
    if (setFullScreenModeFn) {
      if (fullScreenMode) {
        return (
          <ExitFullscreenIcon
            className={TrackPageDataViewStyle.icon_button}
            onClick={() => {
              setFullScreenModeFn(false);
            }}
          />
        );
      }
      return (
        <FullscreenIcon
          className={TrackPageDataViewStyle.icon_button}
          onClick={() => {
            setFullScreenModeFn(true);
          }}
        />
      );
    }
    return null;
  };

  let checkedCount = 0;
  filteredListViewDataList.forEach((element) => {
    if (element.isChecked) {
      checkedCount++;
    }
  });

  const renderListView = () => {
    // TODO: Listview needs to be refactor
    if (loading) {
      return (
        <div className={TrackPageDataViewStyle.skeleton_group}>
          {[...Array(5)].map((i) => {
            return (
              <div className={TrackPageDataViewStyle.skeleton_row}>
                <Skeleton
                  className={TrackPageDataViewStyle.skeleton_cols}
                  count={8}
                  width={95}
                  height={20}
                  borderRadius={10}
                  inline
                />
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <>
        {checkedCount > 0 && (
          <div className={TrackPageDataViewStyle.multi_select_container}>
            <div className={TrackPageDataViewStyle.multi_select_text_container}>
              <div className={TrackPageDataViewStyle.multi_select_container}>
                <span className={TrackPageDataViewStyle.multi_select_count_text}>{checkedCount}</span>
                selected
              </div>
              <div
                onClick={() => {
                  onMultiClick(
                    filteredListViewDataList.map((element) => {
                      return element.id;
                    }),
                    false
                  );
                }}
                className={TrackPageDataViewStyle.multi_select_deselect_text}
              >
                Deselect All
              </div>
            </div>
            <Dropdown>
              <DropdownToggle
                data-cy-drop-down-button
                nav
                onClick={(event) => {
                  setFormComponent(undefined);
                  setTargetElRef(event.currentTarget);
                }}
              >
                <div className={TrackPageDataViewStyle.multi_select_edit}>
                  Actions <KeyboardArrowDown />
                </div>
              </DropdownToggle>
              <DropdownMenu right>
                {batchUpdateOptions.map((option) => {
                  return (
                    <DropdownItem
                      data-cy-drop-down-menu-option
                      key={option.label}
                      onClick={option.onClick}
                    >
                      <div>{option.label}</div>
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </div>
        )}
        <ListView
          headerStructureList={listViewStructure}
          dataList={filteredListViewDataList}
          handleClick={handleListViewClick}
          getLinkDetails={getListViewLinkDetails}
          onMultiClick={onMultiClick}
          shouldResetPageOnLoad={resetPageOnLoad}
          multiSelectEnabled={multiSelectEnabled}
          enablePagination={!disablePagination}
        />
      </>
    );
  };

  return (
    <div data-cy-work-order-track-page-data-view>
      <div className={TrackPageDataViewStyle.actions_container}>
        {filterFunc && (
          <input
            className={TrackPageDataViewStyle.filter_bar}
            title="Filter Input Field"
            value={filterInput}
            placeholder="Type to Filter Results"
            onChange={({ currentTarget }) => {
              if (multiSelectEnabled) {
                onMultiClick(
                  filteredListViewDataList.map((element) => {
                    return element.id;
                  }),
                  false
                );
              }
              setFilterInput(currentTarget.value);
            }}
            data-cy-work-order-track-page-data-view-component__filter-input
          />
        )}
        {loading ? (
          <Skeleton className={TrackPageDataViewStyle.icon_button} />
        ) : (
          <GetAppIcon
            className={TrackPageDataViewStyle.icon_button}
            onClick={onExportCsv}
          />
        )}
        {listViewMode ? (
          !onlyListViewMode && (
            <ViewModule
              className={TrackPageDataViewStyle.icon_button}
              onClick={() => {
                setListViewMode(false);
              }}
            />
          )
        ) : (
          <>
            <SortIconDropDown
              sortSchema={sortSchema}
              onClick={(compareFunc) => {
                setSortCompareFunc(() => {
                  return compareFunc;
                });
              }}
            />
            <FormatListBulleted
              className={TrackPageDataViewStyle.icon_button}
              onClick={() => {
                setListViewMode(true);
              }}
            />
          </>
        )}
        {renderFullScreenModeButton()}
        {enableAutoRefresh && (
          <div className={TrackPageDataViewStyle.display_timer}>
            <CountdownCircleTimer
              size={24}
              strokeWidth={3.5}
              isPlaying={enableAutoRefresh && checkedCount == 0}
              duration={fullScreenModeRefreshInterval / 1000}
              colors={xemelgoStyle.theme.APP_BLUE}
              onComplete={() => {
                fullScreenModeRefreshFn();
                return { shouldRepeat: true };
              }}
            />
          </div>
        )}
      </div>
      {belowActionBarComponent}
      {listViewMode ? renderListView() : renderGridView()}
      <Popper
        id="track page popper"
        open={!!formComponent}
        anchorEl={targetElRef}
        placement="bottom-end"
        modifiers={{
          preventOverflow: {
            boundariesElement: "viewport",
            padding: { top: 60 }
          }
        }}
      >
        <>{formComponent}</>
      </Popper>
    </div>
  );
};

export default TrackPageDataViewComponent;
