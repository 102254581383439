import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as HidePasswordIcon } from "../../../../assets/icons/hide_password.svg";
import { ReactComponent as ShowPasswordIcon } from "../../../../assets/icons/show_password.svg";
import { CLASS_OVERRIDE_SHAPE } from "../../data/constant";
import Style from "./ShowPasswordToggleIcon.module.css";

export const ShowPasswordToggleIcon = ({ showPassword, classOverrides }) => {
  const iconStyle = classOverrides?.showPasswordToggleIcon || Style.show_password_toggle_icon;
  return showPassword ? (
    <div className={Style.show_password_toggle_icon_container}>
      <HidePasswordIcon className={iconStyle} />
      <span className={classOverrides?.showPasswordToggleLabel || Style.show_password_icon_label}>Hide</span>
    </div>
  ) : (
    <div className={Style.show_password_toggle_icon_container}>
      <ShowPasswordIcon className={iconStyle} />
      <span className={classOverrides?.showPasswordToggleLabel || Style.show_password_icon_label}>Show</span>
    </div>
  );
};
ShowPasswordToggleIcon.defaultProps = {
  classOverrides: null
};

ShowPasswordToggleIcon.propTypes = {
  showPassword: PropTypes.bool.isRequired,
  classOverrides: CLASS_OVERRIDE_SHAPE
};
