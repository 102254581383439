export const EDGE_OPERATION_MAP = {
  START: {
    operationName: "start",
    isTenantSpecific: false
  },
  STOP: {
    operationName: "stop",
    isTenantSpecific: false
  },
  ENABLE_INGESTION_RULE: {
    operationName: "enable-ingestion-rule",
    isTenantSpecific: true
  },
  DISABLE_INGESTION_RULE: {
    operationName: "disable-ingestion-rule",
    isTenantSpecific: true
  }
};

export const callEdgeOperation = (edgeApiUrl, detectorSerial, operation, abortSignal, tenantId) => {
  const requestOptions = {
    method: "POST",
    headers: {},
    body: "",
    signal: abortSignal
  };

  let url = edgeApiUrl;

  if (operation.isTenantSpecific) {
    url += `/poc/tenants/${tenantId}`;
  }

  url += `/devices/${detectorSerial}/operations/${operation.operationName}`;

  return fetch(url, requestOptions);
};
