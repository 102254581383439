export const onboardConsumedItems = async (xemelgoClient, items, locationId) => {
  const trackingSessionClient = xemelgoClient.getTrackingSessionClient();
  const publishClient = xemelgoClient.getPublishClient();
  const consumeClient = xemelgoClient.getConsumeClient();
  const trackingSessionIds = [];
  const consumedItemIds = [];

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    const { id, trackingSessionId } = item;

    // start tracking session
    if (!trackingSessionId) {
      consumedItemIds.push(id);
    } else {
      trackingSessionIds.push(trackingSessionId);
    }
  }

  if (consumedItemIds.length) {
    const newTrackingSessionIds = await trackingSessionClient.startTrackingSessions(consumedItemIds);
    trackingSessionIds.push(...newTrackingSessionIds);
  }

  // reactivate item
  if (trackingSessionIds.length) {
    await consumeClient.reactivateConsumedItem(trackingSessionIds, locationId);

    // hack to switch processState from incoming to onhand
    await publishClient.userEvent(trackingSessionIds, locationId, {
      actions: {
        flipHasExitState: true
      }
    });
    await publishClient.userEvent(trackingSessionIds, locationId, {
      actions: {
        flipHasExitState: false
      }
    });
  }
};
