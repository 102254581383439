import { useState } from "react";
import { useHistory } from "react-router-dom";
import { APPROVAL_STATE } from "../../data/constants";
import { useDisplayBannerContext } from "../../../../../../context/DisplayBannerContext/DisplayBannerContext";
import { useXemelgoClient } from "../../../../../../services/xemelgo-service";

export const useApprovalReportUpdater = (reportDetail) => {
  const history = useHistory();
  const { setShowBanner, setBannerHasError, setBannerTitle } = useDisplayBannerContext();
  const [xemelgoClient] = useState(useXemelgoClient());

  const removeFromConsumption = async (rowsToUpdate) => {
    await updateReport(
      rowsToUpdate,
      (item) => {
        return item.isChecked;
      },
      (item) => {
        // eslint-disable-next-line no-param-reassign
        item.state = APPROVAL_STATE.standby;
      },
      () => {
        return { result: reportDetail.result };
      },
      "Your consumption of the selected items was deleted successfully! The selected items have been moved under 'Standby Items'",
      "Could not remove selected items from consumption"
    );
  };

  const approveConsumption = async (rowsToUpdate) => {
    await updateReport(
      rowsToUpdate,
      () => {},
      () => {},
      (rowIdsToUpdate) => {
        if (rowIdsToUpdate.length === 0) {
          return { state: APPROVAL_STATE.approved };
        }

        return { state: APPROVAL_STATE.approved, result: reportDetail.result };
      },
      "Your consumption was approved successfully!",
      "Could not approve consumption",
      () => {
        history.goBack();
      },
      true
    );
  };

  const updateReport = async (
    rowsToUpdate,
    rowFilter,
    updateResult,
    getReportUpdate,
    successMessage,
    errorMessage,
    successAction,
    alwaysUpdate = false
  ) => {
    const rowIdsToUpdate = getFilteredItemIds(rowsToUpdate, rowFilter);

    if (alwaysUpdate || rowIdsToUpdate.length > 0) {
      updateResultWithNewState(rowIdsToUpdate, updateResult);

      const reportClient = xemelgoClient.getReportClient();
      try {
        await reportClient.updateReport(reportDetail.id, getReportUpdate(rowIdsToUpdate));
        setBannerHasError(false);
        setBannerTitle(successMessage);
        setShowBanner(true);
        if (successAction) {
          successAction();
        }
      } catch (e) {
        setBannerHasError(true);
        setBannerTitle(errorMessage);
        setShowBanner(true);
      }
    }
  };

  const getFilteredItemIds = (itemTypeRows, filterFunc) => {
    return itemTypeRows
      .reduce((items, itemTypeRow) => {
        return items.concat(itemTypeRow.items);
      }, [])
      .filter(filterFunc)
      .map((item) => {
        return item.id;
      });
  };

  const updateResultWithNewState = (itemIdsToInclude, updateStateFunc) => {
    Object.values(reportDetail.result.itemTypes)
      .reduce((items, itemType) => {
        return items.concat(itemType.items);
      }, [])
      .filter((item) => {
        return itemIdsToInclude.includes(item.id);
      })
      .forEach(updateStateFunc);
  };

  return {
    approveConsumption,
    removeFromConsumption
  };
};
