import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import Style from "./GeneralizedModal.module.css";

const GeneralizedModal = ({
  showModal,
  title,
  modalBodyComponent,
  modalFooterButtons,
  modalFooterComponent,
  classNameOverrides
}) => {
  return (
    <Modal
      centered
      backdrop="static"
      backdropClassName={Style.backdrop}
      dialogClassName={`${Style.dialog_base} ${classNameOverrides.dialog}`}
      show={showModal}
      contentClassName={`${Style.content_base} ${classNameOverrides.content}`}
    >
      <Modal.Header className={`${Style.header_base} ${classNameOverrides.header}`}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={`${Style.body_base} ${classNameOverrides.body}`}>{modalBodyComponent}</Modal.Body>

      <Modal.Footer className={`${Style.footer_base} ${classNameOverrides.footer}`}>
        {modalFooterComponent ||
          (modalFooterButtons &&
            modalFooterButtons.map((footerButton) => {
              return (
                <button
                  key={footerButton.id}
                  type="button"
                  className={`${Style.button_base} ${footerButton.className}`}
                  onClick={footerButton.onClick}
                  disabled={footerButton.disabled}
                >
                  {footerButton.title}
                </button>
              );
            }))}
      </Modal.Footer>
    </Modal>
  );
};

export default GeneralizedModal;

GeneralizedModal.defaultProps = {
  title: "",
  showModal: false,
  modalBodyComponent: null,
  modalFooterComponent: null,
  modalFooterButtons: [
    {
      id: "cancel",
      title: "Cancel",
      onClick: () => {},
      className: null
    }
  ],
  classNameOverrides: {}
};

GeneralizedModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showModal: PropTypes.bool,
  modalBodyComponent: PropTypes.element,
  modalFooterComponent: PropTypes.element,
  modalFooterButtons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
      className: PropTypes.string,
      onClick: PropTypes.func.isRequired,
      disabled: PropTypes.bool
    })
  ),
  classNameOverrides: PropTypes.shape({
    content: PropTypes.string,
    body: PropTypes.string,
    footer: PropTypes.string,
    dialog: PropTypes.string
  })
};
