export const SOLUTION_ID = {
  ORDER: "order",
  ORDER_TRACKING: "order-tracking",
  INVENTORY: "inventory",
  INVENTORY_TRACKING: "inventory-tracking",
  INVENTORY_APPROVAL_MANAGEMENT: "inventory-approval-management",
  CHECK_OUT: "check-out",
  GOODS_RECEIPT: "goods-receipt",
  ASSET: "asset",
  ASSET_TRACKING: "asset-tracking",
  ASSET_MAP: "asset-map",
  CREATE_ASSET: "create-asset",
  SHIPMENTS: "shipments",
  PURCHASE_ORDER: "purchase-order",
  MANAGEMENT_DASHBOARD: "management-dashboard",
  SCHEDULING_MANAGEMENT: "scheduling-management",
  PACKAGE: "package",
  INVENTORY_DASHBOARD: "inventory-dashboard"
};

export const SETTING_ID = {
  REPORT: "reports",
  ITEM_TYPES_MANAGEMENT: "item-types-management",
  MY_FACILITY: "my-facility",
  USERS: "users",
  ALERTS: "alerts"
};

export const APP_ID_TO_FEATURE_ID = {
  asset: "addAsset",
  inventory: "addInventory",
  order: "addOrder"
};

export const APP_ID_TO_SOLUTION = {
  asset: "asset",
  inventory: "inventory",
  order: "workOrder"
};
