import React, { useState, useEffect, useRef } from "react";
import { AddRounded, RemoveRounded, GpsFixedRounded } from "@material-ui/icons";
import "./FloorMap.css";
import PropTypes from "prop-types";
import ReactDOMServer from "react-dom/server";
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import mapboxgl from "!mapbox-gl";
import useConfirmation from "../../hooks/use-confirmation";
import {
  getMapFeatureCenter,
  getMapLayerFilter,
  getMapLayerOpacity,
  getStatusForLocation,
  getTargetFeatureHelper
} from "./utils";

import { MAP_LAYER_NAME } from "./data/constants";
import mapMarker from "../../assets/images/map-marker.png";

mapboxgl.accessToken = "pk.eyJ1IjoieGVtZWxnbyIsImEiOiJjbDNvdjZ4ZnEwNng1M2NwbXR1OWJ2aWNiIn0.isF9cAXWgzw0yLEJ6U15bA";

const FloorMap = React.forwardRef(
  (
    {
      initialViewStates,
      colorMap,
      mapStyleURL,
      floorPlanTilesets,
      locationsSourceTilesets,
      markersGeojson,
      onLocationClicked,
      showColorIndicator,
      showNavigationControl,
      mapContainerClassName,
      mapNavigationControlClassName,
      mapColorIndicatorClassName,
      children,
      mapCameraPadding,
      popupRenderer,
      popupEnabled,
      hideIds,
      mapInitializedCallback
    },
    ref
  ) => {
    const mapContainerComponentRef = useRef(null);
    const mapRef = useRef(null);
    const hoverStateIdRef = useRef(null);
    const primarySelectedStateIdRef = useRef(null);
    const secondarySelectedStateIdRef = useRef(null);
    const selectedMarkerRef = useRef(null);
    const popupRef = useRef(null);
    const [zoom, setZoom] = useState(initialViewStates.zoom || 0);

    const [loading, setLoading] = useState(true);
    const [map, setMap] = useState(null);
    const [popup, setPopup] = useState(null);
    const [confirmFlyToDone, getFlyToConfirmation] = useConfirmation();

    // component did mount: starting the map initialing process
    useEffect(() => {
      // initialize map only once
      mapRef.current = new mapboxgl.Map({
        ...initialViewStates,
        container: mapContainerComponentRef.current,
        style: mapStyleURL,
        maxPitch: 0,
        padding: mapCameraPadding
      });

      popupRef.current = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
      });

      return () => {
        mapRef.current.remove(); // unmount the map properly
      };
    }, []);

    useEffect(() => {
      setMap(mapRef.current);
    }, [mapRef.current]);

    useEffect(() => {
      setPopup(popupRef.current);
    }, [popupRef.current]);

    // initializing map
    useEffect(() => {
      // wait for map to initialize
      if (!map) {
        return;
      }
      map.on("idle", () => {
        map.resize();
      });

      map.on("load", () => {
        // adding floor plan to the map

        map.loadImage(mapMarker, (error, image) => {
          if (error) {
            throw error;
          }
          map.addImage("custom-marker", image);

          floorPlanTilesets.forEach(({ url }, index) => {
            map
              .addSource(`${MAP_LAYER_NAME.floorPlanSource}${index}`, {
                url,
                type: "raster"
              })
              .addLayer({
                id: `${MAP_LAYER_NAME.floorPlanLayer}${index}`,
                type: "raster",
                source: `${MAP_LAYER_NAME.floorPlanSource}${index}`
              });
          });

          locationsSourceTilesets.forEach(({ url, name }, index) => {
            map
              .addSource(`${MAP_LAYER_NAME.locationsSource}${index}`, {
                url,
                type: "vector",
                promoteId: "id"
              })
              .addLayer({
                id: `${MAP_LAYER_NAME.locationColorLayer}${index}`,
                type: "fill",
                source: `${MAP_LAYER_NAME.locationsSource}${index}`,
                "source-layer": name,
                filter: getMapLayerFilter(zoom, hideIds),
                layout: {
                  "fill-sort-key": ["get", "zoom"]
                },
                paint: {
                  "fill-color": getStatusForLocation(colorMap),
                  "fill-opacity": getMapLayerOpacity(zoom, primarySelectedStateIdRef.current)
                }
              })
              .addLayer({
                id: `${MAP_LAYER_NAME.locationOutlineLayer}${index}`,
                type: "line",
                source: `${MAP_LAYER_NAME.locationsSource}${index}`,
                "source-layer": name,
                filter: getMapLayerFilter(zoom, hideIds),
                layout: {
                  "line-join": "round",
                  "line-sort-key": ["get", "zoom"]
                },
                paint: {
                  "line-color": "#343434",
                  "line-width": 2
                }
              })
              .addLayer({
                id: `${MAP_LAYER_NAME.locationSelectedOutlineShadowLayer}${index}`,
                type: "line",
                source: `${MAP_LAYER_NAME.locationsSource}${index}`,
                "source-layer": name,
                layout: {
                  "line-join": "round"
                },
                paint: {
                  "line-width": 5,
                  "line-gap-width": 5,
                  "line-blur": 10,
                  "line-color": ["case", ["boolean", ["feature-state", "selected"], false], "white", "transparent"],
                  "line-opacity": 0.5
                }
              })
              .addLayer({
                id: `${MAP_LAYER_NAME.locationSelectedOutlineLayer}${index}`,
                type: "line",
                source: `${MAP_LAYER_NAME.locationsSource}${index}`,
                "source-layer": name,
                layout: {
                  "line-join": "round"
                },
                paint: {
                  "line-width": 5,
                  "line-color": [
                    "case",
                    ["boolean", ["feature-state", "selected"], false],
                    getStatusForLocation(colorMap),
                    "transparent"
                  ]
                }
              })
              .addLayer({
                id: `${MAP_LAYER_NAME.locationHoveredOutlineLayer}${index}`,
                type: "line",
                source: `${MAP_LAYER_NAME.locationsSource}${index}`,
                "source-layer": name,
                layout: {
                  "line-join": "round"
                },
                paint: {
                  "line-width": 5,
                  "line-color": [
                    "case",
                    ["boolean", ["feature-state", "selected"], false],
                    "transparent",
                    ["boolean", ["feature-state", "hover"], false],
                    "#4a90ff",
                    "transparent"
                  ]
                }
              });
          });

          if (markersGeojson) {
            map
              .addSource(MAP_LAYER_NAME.markerSource, {
                type: "geojson",
                data: markersGeojson,
                promoteId: "id",
                cluster: true,
                clusterRadius: 100 // Radius of each cluster when clustering points (defaults to 50)
              })
              .addLayer({
                id: MAP_LAYER_NAME.markerGroupLayer,
                type: "circle",
                source: MAP_LAYER_NAME.markerSource,
                filter: ["has", "point_count"],
                paint: {
                  "circle-color": "#4a90ff",
                  "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40]
                }
              })
              .addLayer({
                id: MAP_LAYER_NAME.markerGroupCountLayer,
                type: "symbol",
                source: MAP_LAYER_NAME.markerSource,

                filter: ["has", "point_count"],
                layout: {
                  "text-field": ["get", "point_count_abbreviated"],
                  "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                  "text-size": 12
                },
                paint: {
                  "text-color": "white"
                }
              })
              .addLayer({
                id: MAP_LAYER_NAME.markersLayer,
                type: "symbol",
                source: MAP_LAYER_NAME.markerSource,
                filter: [
                  "all",
                  ["!", ["has", "point_count"]],
                  ["<=", zoom, ["get", "maxzoom"]],
                  [">=", zoom, ["get", "minzoom"]]
                ],
                layout: {
                  "icon-image": "custom-marker",
                  "icon-allow-overlap": true
                }
              });

            map.on("click", MAP_LAYER_NAME.markerGroupLayer, (e) => {
              const features = map.queryRenderedFeatures(e.point, {
                layers: [MAP_LAYER_NAME.markerGroupLayer]
              });
              const clusterId = features[0].properties.cluster_id;
              map.getSource(MAP_LAYER_NAME.markerSource).getClusterExpansionZoom(clusterId, (err, clusterZoom) => {
                if (err) {
                  return;
                }

                map.flyTo({
                  center: features[0].geometry.coordinates,
                  zoom: clusterZoom
                });
              });
            });

            map.on("click", MAP_LAYER_NAME.markersLayer, (event) => {
              const currentZoom = map.getZoom();
              const [currentFeature, approximateCenter] = getTargetFeatureHelper(event.features, currentZoom);
              if (currentFeature) {
                const center = (markersGeojson?.features || []).find((eachMarker) => {
                  const { properties } = eachMarker || {};
                  const { id } = properties || {};
                  return id === currentFeature?.id;
                })?.geometry?.coordinates;
                selectFeature(currentFeature, center || approximateCenter);
              }
            });
          }

          map.on("zoom", () => {
            setZoom(map.getZoom());
            const currentZoom = map.getZoom();
            setZoom(currentZoom);
          });

          map.on("flystart", () => {
            map.on("moveend", () => {
              map.on("idle", () => {
                confirmFlyToDone(true);
              });
            });
          });

          locationsSourceTilesets.forEach((_, index) => {
            // "mouseleave" event for map when hovered location changed
            map.on("mouseleave", `${MAP_LAYER_NAME.locationColorLayer}${index}`, () => {
              resetHover();
            });

            // the "click" even for map when selected location changed
            map.on("click", `${MAP_LAYER_NAME.locationColorLayer}${index}`, (event) => {
              const currentZoom = map.getZoom();
              const [currentFeature, center] = getTargetFeatureHelper(event.features, currentZoom);

              if (currentFeature) {
                selectFeature(currentFeature, center);
              }
            });
          });
          setLoading(false);

          resetMap({ shouldTriggerCallback: false }).then(() => {
            mapInitializedCallback();
          });
        });
      });
    }, [map, markersGeojson]);

    // update mouseHover when popupRenderer changes
    useEffect(() => {
      if (map) {
        locationsSourceTilesets.forEach((_, index) => {
          map.on("mousemove", `${MAP_LAYER_NAME.locationColorLayer}${index}`, (event) => {
            const currentZoom = map.getZoom();
            const [currentFeature, center] = getTargetFeatureHelper(event.features, currentZoom);
            if (currentFeature) {
              hoverLocation(currentFeature, center);
            }
          });
        });
      }
    }, [map, popupRenderer, locationsSourceTilesets]);

    // update color_status & color_status_outline map layer filter when zoom level changed
    // update the color_status map layer fill paint when zoom & selected location changed
    useEffect(() => {
      if (!map || loading) {
        return;
      }

      locationsSourceTilesets.forEach((_, index) => {
        map.setFilter(`${MAP_LAYER_NAME.locationColorLayer}${index}`, getMapLayerFilter(zoom, hideIds));
        map.setFilter(`${MAP_LAYER_NAME.locationOutlineLayer}${index}`, getMapLayerFilter(zoom, hideIds));
        map.setPaintProperty(
          `${MAP_LAYER_NAME.locationColorLayer}${index}`,
          "fill-opacity",
          getMapLayerOpacity(zoom, primarySelectedStateIdRef.current)
        );
        map.setPaintProperty(
          `${MAP_LAYER_NAME.locationColorLayer}${index}`,
          "fill-color",
          getStatusForLocation(colorMap)
        );
        map.setPaintProperty(`${MAP_LAYER_NAME.locationSelectedOutlineLayer}${index}`, "line-color", [
          "case",
          ["boolean", ["feature-state", "selected"], false],
          getStatusForLocation(colorMap),
          "transparent"
        ]);
      });
      if (markersGeojson) {
        map.setFilter(MAP_LAYER_NAME.markersLayer, [
          "all",
          ["!", ["has", "point_count"]],
          ["<=", zoom, ["get", "maxzoom"]],
          [">=", zoom, ["get", "minzoom"]]
        ]);
      }
    }, [zoom, map, primarySelectedStateIdRef.current, colorMap, loading, hideIds, markersGeojson]);

    // update padding to the map camera view when mapCameraPadding props is changed
    useEffect(() => {
      // when map isn't ready or mapCameraPadding Props isn't there or not positive numbers, skip the function
      if (
        !map ||
        !Object.keys(mapCameraPadding).every((eachKey) => {
          return mapCameraPadding[eachKey] > 0;
        })
      ) {
        return;
      }

      map.flyTo({
        padding: {
          top: mapCameraPadding.top || 0,
          bottom: mapCameraPadding.bottom || 0,
          left: mapCameraPadding.left || 0,
          right: mapCameraPadding.right || 0
        }
      });
    }, [map, mapCameraPadding?.top, mapCameraPadding?.bottom, mapCameraPadding?.left, mapCameraPadding?.right]);

    // triggered "onLocationClicked" callback from props when a location is selected
    useEffect(() => {
      if (!map) {
        return;
      }
      const primarySelectedStateId = primarySelectedStateIdRef.current;
      const secondarySelectedStateId = secondarySelectedStateIdRef.current;

      const selectedLocationFeature = locationsSourceTilesets.reduce((accumulator, { name }, index) => {
        if (accumulator) {
          return accumulator;
        }
        return (
          map.querySourceFeatures(MAP_LAYER_NAME.markerSource).find((eachFeature) => {
            return eachFeature.properties.id === selectedMarkerRef.current;
          }) ||
          map
            .querySourceFeatures(`${MAP_LAYER_NAME.locationsSource}${index}`, { sourceLayer: name })
            .find((eachFeature) => {
              return eachFeature.id === (primarySelectedStateId || secondarySelectedStateId);
            })
        );
      }, null);

      if (selectedLocationFeature?.properties?.id) {
        onLocationClicked(selectedLocationFeature?.properties?.id, {
          properties: selectedLocationFeature.properties,
          center: selectedMarkerRef.current
            ? markersGeojson?.features?.find((eachMarker) => {
                const { properties } = eachMarker || {};
                const { id } = properties || {};
                return id === selectedLocationFeature.properties.id;
              })?.geometry?.coordinates
            : getMapFeatureCenter(selectedLocationFeature)
        });
      }
    }, [map, primarySelectedStateIdRef.current, secondarySelectedStateIdRef.current, selectedMarkerRef.current]);

    // set the map camera to be the initialViewStates
    const resetMap = async ({
      shouldTriggerCallback = true,
      shouldResetLocation = true,
      viewState: newInitialViewStates
    }) => {
      selectedMarkerRef.current = null;

      if (!map) {
        return null;
      }

      const flyCompletedPromise = getFlyToConfirmation();

      map.fire("flystart");

      const viewState = newInitialViewStates || initialViewStates;

      if (viewState.bounds) {
        map.fitBounds(viewState.bounds, {
          bearing: viewState.bearing,
          padding: viewState
        });
      } else {
        map.flyTo({ ...viewState, padding: mapCameraPadding, speed: 2, curve: 2 });
      }

      if (shouldResetLocation) {
        selectFeature({ id: null }, null);
        if (shouldTriggerCallback) {
          onLocationClicked(null, {});
        }
      }
      await flyCompletedPromise;
      return viewState;
    };

    const selectFeature = async (currentFeature, center) => {
      // reset select markerRef to be null when feature is click
      selectedMarkerRef.current = null;

      const primarySelectedStateId = primarySelectedStateIdRef.current;
      const secondarySelectedStateId = secondarySelectedStateIdRef.current;

      let flyCompletedPromise = null;

      if (currentFeature.id) {
        const newZoom =
          primarySelectedStateId === currentFeature.id
            ? currentFeature.properties.maxzoom === 24 && currentFeature.properties.zoom + 0.5 <= 24
              ? currentFeature.properties.zoom + 0.5
              : currentFeature.properties.maxzoom
            : currentFeature.properties.zoom;

        flyCompletedPromise = getFlyToConfirmation();

        map.flyTo({
          ...JSON.parse(currentFeature.properties.viewStates || "{}"),
          center,
          zoom: newZoom
        });
        map.fire("flyStart");
      }

      if (
        (primarySelectedStateId !== null && primarySelectedStateId !== currentFeature.id) ||
        secondarySelectedStateId !== null
      ) {
        locationsSourceTilesets.forEach(({ name }, index) => {
          map.setFeatureState(
            {
              source: `${MAP_LAYER_NAME.locationsSource}${index}`,
              sourceLayer: name,
              id:
                primarySelectedStateId !== null && primarySelectedStateId !== currentFeature.id
                  ? primarySelectedStateId
                  : secondarySelectedStateId
            },
            {
              selected: false
            }
          );
        });
      }
      if (currentFeature?.geometry?.type !== "Point") {
        locationsSourceTilesets.forEach(({ name }, index) => {
          map.setFeatureState(
            {
              source: `${MAP_LAYER_NAME.locationsSource}${index}`,
              sourceLayer: name,
              id: currentFeature.id
            },
            {
              selected: true
            }
          );
        });
      }

      if (currentFeature.id === primarySelectedStateId) {
        secondarySelectedStateIdRef.current = primarySelectedStateId;
      } else if (!currentFeature.id) {
        secondarySelectedStateIdRef.current = null;
      }

      popup.remove();

      if (currentFeature?.geometry?.type !== "Point") {
        primarySelectedStateIdRef.current =
          currentFeature.id === primarySelectedStateId
            ? currentFeature?.properties?.maxzoom === 24
              ? currentFeature.id
              : null
            : currentFeature.id;
      } else {
        selectedMarkerRef.current = currentFeature.id;
      }

      await flyCompletedPromise;
      return {
        properties: currentFeature?.properties,
        center: currentFeature?.id && center
      };
    };

    const hoverLocation = (currentFeature, center) => {
      const hoverStateId = hoverStateIdRef.current;
      if (hoverStateId !== null) {
        locationsSourceTilesets.forEach(({ name }, index) => {
          map.setFeatureState(
            {
              source: `${MAP_LAYER_NAME.locationsSource}${index}`,
              sourceLayer: name,

              id: hoverStateId
            },
            { hover: false }
          );
        });
      }
      popup.remove();
      locationsSourceTilesets.forEach(({ name }, index) => {
        map.setFeatureState(
          {
            source: `${MAP_LAYER_NAME.locationsSource}${index}`,
            sourceLayer: name,
            id: currentFeature.id
          },
          { hover: true }
        );
      });
      hoverStateIdRef.current = currentFeature.id;

      if (!currentFeature.state?.selected && popupEnabled) {
        popup
          .setLngLat(center)
          .setHTML(ReactDOMServer.renderToString(popupRenderer(currentFeature.properties.id)))
          .addTo(map);
      }
    };

    const resetHover = () => {
      const hoverStateId = hoverStateIdRef.current;
      if (hoverStateId !== null) {
        locationsSourceTilesets.forEach(({ name }, index) => {
          map.setFeatureState(
            {
              source: `${MAP_LAYER_NAME.locationsSource}${index}`,
              sourceLayer: name,
              id: hoverStateId
            },
            { hover: false }
          );
        });
      }
      hoverStateIdRef.current = null;
      popup.remove();
    };

    const buildMapRefFunctions = () => {
      const getMapFeatureWithCenterByLocationId = (locationId) => {
        if (!map || !locationId) {
          return new Array(2);
        }
        const feature = locationsSourceTilesets.reduce((accumulator, { name }, index) => {
          let targetFeature;
          const targetFeatures = map
            .querySourceFeatures(`${MAP_LAYER_NAME.locationsSource}${index}`, { sourceLayer: name })
            .filter((eachFeature) => {
              return eachFeature.properties.id === locationId;
            });
          targetFeature = targetFeatures.find((eachFeature) => {
            return eachFeature.geometry.type === "Polygon";
          });
          if (!targetFeature) {
            targetFeature = targetFeatures?.[0] || null;
          }
          return accumulator || targetFeature;
        }, null);

        return getTargetFeatureHelper([feature], map.getZoom());
      };

      const getMapMarkerWithCenterByLocationId = (locationId) => {
        if (!map || !locationId) {
          // eslint-disable-next-line no-sparse-arrays
          return [,];
        }
        const targetFeature = map.querySourceFeatures(MAP_LAYER_NAME.markerSource).find((eachFeature) => {
          return eachFeature.properties.id === locationId;
        });
        const [, approximateCenter] = getTargetFeatureHelper([targetFeature], map.getZoom());
        const center = (markersGeojson?.features || []).find((eachMarker) => {
          const { properties } = eachMarker || {};
          const { id } = properties || {};
          return id === targetFeature?.id;
        })?.geometry?.coordinates;
        return [targetFeature, center || approximateCenter];
      };

      return {
        selectLocation: async (locationId, autoResetToFind) => {
          let [currentFeature, center] = getMapFeatureWithCenterByLocationId(locationId);
          if (!currentFeature && autoResetToFind) {
            await resetMap({ shouldResetLocation: false, shouldTriggerCallback: false });
            [currentFeature, center] = getMapFeatureWithCenterByLocationId(locationId);
          }
          return selectFeature(currentFeature || {}, center);
        },
        hoverLocation: (locationId) => {
          const [currentFeature, center] = getMapFeatureWithCenterByLocationId(locationId);
          if (currentFeature) {
            hoverLocation(currentFeature, center);
          }
        },
        selectMarker: async (locationId, autoResetToFind) => {
          let [currentFeature, center] = getMapMarkerWithCenterByLocationId(locationId);
          if (!currentFeature && autoResetToFind) {
            await resetMap({ shouldResetLocation: false, shouldTriggerCallback: false });
            [currentFeature, center] = getMapMarkerWithCenterByLocationId(locationId);
          }
          return selectFeature(currentFeature || {}, center);
        },
        resetHover,
        resetMap
      };
    };

    if (ref) {
      ref.current = buildMapRefFunctions();
    }

    return (
      <div
        ref={mapContainerComponentRef}
        className={`map_container ${mapContainerClassName}`}
      >
        {showNavigationControl && (
          <div className={`map_tool_container map_control ${mapNavigationControlClassName} `}>
            <button
              type="button"
              className="map_control_button"
              onClick={resetMap}
            >
              <GpsFixedRounded />
            </button>
            <button
              type="button"
              className="map_control_button"
              onClick={() => {
                map.zoomIn();
              }}
            >
              <AddRounded />
            </button>
            <button
              type="button"
              className="map_control_button"
              onClick={() => {
                map.zoomOut();
              }}
            >
              <RemoveRounded />
            </button>
          </div>
        )}
        {showColorIndicator && (
          <ul className={`map_tool_container color_indicator_container ${mapColorIndicatorClassName} `}>
            {Object.keys(colorMap).map((eachColor) => {
              const { label } = colorMap[eachColor];
              return (
                <li
                  className="color_indicator"
                  key={`${label}_${eachColor}`}
                >
                  <div
                    className="color_icon"
                    style={{
                      backgroundColor: eachColor
                    }}
                  />
                  <p className="color_label">{label}</p>
                </li>
              );
            })}
          </ul>
        )}
        {children}
      </div>
    );
  }
);

FloorMap.defaultProps = {
  initialViewStates: {},
  colorMap: {},
  markersGeojson: null,
  onLocationClicked: () => {},
  showColorIndicator: true,
  showNavigationControl: true,
  mapContainerClassName: "",
  mapNavigationControlClassName: "",
  mapColorIndicatorClassName: "",
  mapCameraPadding: { top: 0, bottom: 0, left: 0, right: 0 },
  popupRenderer: () => {},
  popupEnabled: false,
  hideIds: [],
  mapInitializedCallback: () => {},
  children: null
};

FloorMap.propTypes = {
  initialViewStates: PropTypes.shape({
    center: (props, propName, componentName) => {
      if (
        !Array.isArray(props[propName]) ||
        props[propName].length !== 2 ||
        !props[propName].every((each) => {
          return each % 1 !== 0;
        })
      ) {
        return new Error(`Invalid prop \`${propName}\` supplied to \`initialViewState\` of \`${componentName}\`.`);
      }
    },
    zoom: PropTypes.number,
    bearing: PropTypes.number,
    bounds: (props, propName, componentName) => {
      if (
        props[propName] &&
        (!Array.isArray(props[propName]) ||
          props[propName].length !== 2 ||
          !props[propName].every((each) => {
            return (
              Array.isArray(each) &&
              each.length === 2 &&
              each.every((inner) => {
                return inner % 1 !== 0;
              })
            );
          }))
      ) {
        return new Error(`Invalid prop \`${propName}\` supplied to \`initialViewState\` of \`${componentName}\`.`);
      }
    },
    maxBounds: (props, propName, componentName) => {
      if (
        props[propName] &&
        (!Array.isArray(props[propName]) ||
          props[propName].length !== 2 ||
          !props[propName].every((each) => {
            return (
              Array.isArray(each) &&
              each.length === 2 &&
              each.every((inner) => {
                return inner % 1 !== 0;
              })
            );
          }))
      ) {
        return new Error(`Invalid prop \`${propName}\` supplied to \`initialViewState\` of \`${componentName}\`.`);
      }
    }
  }),
  colorMap: PropTypes.objectOf(
    PropTypes.shape({ label: PropTypes.string, locations: PropTypes.arrayOf(PropTypes.string) })
  ),
  mapStyleURL: PropTypes.string.isRequired,
  floorPlanTilesets: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string.isRequired })).isRequired,
  locationsSourceTilesets: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string.isRequired, url: PropTypes.string.isRequired })
  ).isRequired,
  markersGeojson: PropTypes.shape({
    features: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        properties: PropTypes.shape({
          zoom: PropTypes.number,
          maxzoom: PropTypes.number,
          minzoom: PropTypes.number,
          id: PropTypes.string.isRequired,
          viewState: PropTypes.object
        }),
        geometry: PropTypes.shape({ coordinates: PropTypes.arrayOf(PropTypes.number), type: PropTypes.string })
      })
    )
  }),
  onLocationClicked: PropTypes.func,
  showColorIndicator: PropTypes.bool,
  showNavigationControl: PropTypes.bool,
  mapContainerClassName: PropTypes.string,
  mapNavigationControlClassName: PropTypes.string,
  mapColorIndicatorClassName: PropTypes.string,
  mapCameraPadding: (props, propName, componentName) => {
    if (
      props[propName] &&
      (typeof props[propName] !== "object" ||
        props[propName].top < 0 ||
        props[propName].bottom < 0 ||
        props[propName].left < 0 ||
        props[propName].right < 0)
    ) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`mapComeraPadding\` of \`${componentName}\`. top/bottom/left/right must be positive number`
      );
    }
  },
  popupRenderer: PropTypes.func,
  popupEnabled: PropTypes.bool,
  hideIds: PropTypes.arrayOf(PropTypes.string),
  mapInitializedCallback: PropTypes.func,
  children: PropTypes.any
};

export default FloorMap;
