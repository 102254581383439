/**
 * @typedef {Object} TemplateType
 * @property {string} id
 * @property {string} printerIP
 */

/**
 * @typedef {Object} PrinterType
 * @property {string} printerName
 * @property {string} printerLocation
 */

/**
 * @typedef {Object.<string, PrinterType>} PrinterMapType
 */

/**
 * This function determines list of printers and templates for users to select thar filter by the available
 * printers in the network
 * @param {TemplateType[]} templatesList
 * @param {PrinterMapType} availablePrinterMap
 * @returns
 */
export const generateTemplatePrinterMaps = (templatesList, availablePrinterMap) => {
  return templatesList.reduce(
    (acc, template) => {
      const { id, printerIP } = template;
      if (!acc.templateToPrinterMap[id]) {
        acc.templateToPrinterMap[id] = { printers: [], ...template };
      }

      if (id === "default") {
        Object.keys(availablePrinterMap).forEach((printerId) => {
          acc.templateToPrinterMap[id].printers.push(availablePrinterMap[printerId]);

          if (!acc.printersToTemplateMap[printerId]) {
            acc.printersToTemplateMap[printerId] = { ...availablePrinterMap[printerId], templates: [] };
          }
          acc.printersToTemplateMap[printerId].templates.push(template);
        });
      } else if (!printerIP) {
        acc.templateToPrinterMap[id].printers.push(...Object.values(availablePrinterMap));

        Object.keys(availablePrinterMap).forEach((printerId) => {
          if (!acc.printersToTemplateMap[printerId]) {
            acc.printersToTemplateMap[printerId] = { ...availablePrinterMap[printerId], templates: [] };
          }
          acc.printersToTemplateMap[printerId].templates.push(template);
        });
      } else if (availablePrinterMap[printerIP]) {
        acc.templateToPrinterMap[id].printers.push(availablePrinterMap[printerIP]);
        if (!acc.printersToTemplateMap[printerIP]) {
          acc.printersToTemplateMap[printerIP] = { ...availablePrinterMap[printerIP], templates: [] };
        }
        acc.printersToTemplateMap[printerIP].templates.push(template);
      }

      return acc;
    },
    {
      printersToTemplateMap: {},
      templateToPrinterMap: {}
    }
  );
};
