/* eslint-disable react/display-name */
import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import Skeleton from "react-loading-skeleton";
import uuid from "uuid";
import TrackPageComponentStyle from "../../components/TrackPageComponents/track-page-component/TrackPageComponent.module.css";
import AssetOverViewComponent from "../asset-details-feature/components/AssetOverviewComponent";
import TrackPageComponent from "../../components/TrackPageComponents/track-page-component/TrackPageComponent";
import GroupbySideBarComponent from "../../components/TrackPageComponents/groupby-sidebar-component/GroupbySidebarComponent";
import { getCustomerLogo, getFormattedDate, getStackedXemelgoLogo, getStatusFlags } from "../../common/Utilities";
import TrackPageDataViewComponent from "../../components/TrackPageComponents/track-page-data-view-component/TrackPageDataViewComponent";
import GridCardContentDefault from "../asset-details-feature/components/grid-card-content-default/GridCardContentDefault";
import GridCardContentGroupby from "../asset-details-feature/components/grid-card-content-groupby/GridCardContentGroupby";
import GridCardComponent from "../../components/grid-card-component/GridCardComponent";
import OptionalLink from "../../components/optional-link/OptionalLink";
import Style from "../../components/TrackPageComponents/Order/css/TrackPageGridViewComponent.module.css";
import { useXemelgoClient } from "../../services/xemelgo-service";
import BreadcrumbsComponent from "../../components/breadcrumbs-component/BreadcrumbsComponent";
import "./style.css";
import { useAppConfigProvider, useConfigurationProvider } from "../../services/soft-cache-service";
import PrintTagsModal from "../print-tags-modal";
import DisplayBanner from "../../components/display-banner/DisplayBanner";
import "react-loading-skeleton/dist/skeleton.css";
import { ReactComponent as AssetIcon } from "../../assets/icons/asset.svg";
import xemelgoStyle from "../../styles/variable";
import AddAssetFeature from "../add-asset-feature-v2";

const APP_ID = "asset";
const TRACK_PAGE_CONFIG = "trackPage";
const STATUS_MAP = "statusFlagMap";
const POSSIBLE_DETECTOR_LOCATIONS = "possibleDetectorLocations";
const mainColor = xemelgoStyle.theme.ASSET_PRIMARY;
const secondaryColor = xemelgoStyle.theme.ASSET_SECONDARY;

const AssetTrackPageFeature = ({ history }) => {
  const bulkUpdateItemsButton = {
    id: "bulk-update-items",
    display: "Bulk Update",
    linkRoute: `${history.location.pathname}/bulk-update`
  };

  const sidebarFeatureButtonMap = {
    "bulk-update-items": bulkUpdateItemsButton
  };

  const breadcrumbsDataListRef = useRef();

  const handleBreadCrumbsClick = (id) => {
    const { current } = breadcrumbsDataListRef;
    let currentSearch = history.location.search;
    while (current.length && current[current.length - 1].id !== id) {
      currentSearch = currentSearch.replace(`&id=${current[current.length - 1].id}`, "");
      current.pop();
    }
    // Need to clean up url when following breadcrumbs back
    history.replace(`${history.location.pathname}${currentSearch}`);
    setBreadcrumbsDataList(current);
  };

  const viewAllAssetsTab = {
    id: "all-assets",
    display: "All Assets",
    action: () => {
      handleTabClick("all-assets", overallDataRef.current.tracksItem);
      setFocusedTab(null);
      setBreadcrumbsDataList([]);
      history.replace(`${history.location.pathname}`);
    }
  };

  const groupByAssetTypeTab = {
    id: "type",
    display: "Type",
    action: () => {
      handleTabClick("type", overallDataRef.current.types);
      setFocusedTab(null);
      setBreadcrumbsDataList([
        {
          id: "type",
          value: "Type",
          onClick: () => {
            handleBreadCrumbsClick("type");
            handleTabClick("type", overallDataRef.current.types);
            setFocusedTab(null);
          }
        }
      ]);
      history.replace(`${history.location.pathname}?group=type`);
    }
  };

  const groupByLocationTab = {
    id: "location",
    display: "Location",
    action: () => {
      handleTabClick("location", overallDataRef.current.locations);
      setFocusedTab(null);
      setBreadcrumbsDataList([
        {
          id: "location",
          value: "Location",
          onClick: () => {
            handleBreadCrumbsClick("location");
            handleTabClick("location", overallDataRef.current.locations);
            setFocusedTab(null);
          }
        }
      ]);
      history.replace(`${history.location.pathname}?group=location`);
    }
  };

  const viewTabsMap = {
    "all-assets": viewAllAssetsTab
  };

  const groupByTabsMap = {
    location: groupByLocationTab,
    type: groupByAssetTypeTab
  };

  const viewHeaderAndTabs = {
    id: "view",
    display: "View",
    tabStructure: []
  };

  const groupByHeaderAndTabs = {
    id: "groupby",
    display: "Group By",
    tabStructure: []
  };

  const SolutionConfigProvider = useAppConfigProvider(APP_ID);
  const TrackPageConfig = SolutionConfigProvider.getValue(TRACK_PAGE_CONFIG, "object");
  const useAddAssetV2 = !!TrackPageConfig.useAddAssetV2;
  const LocationRoleConfig = useConfigurationProvider().config.features.locationRole || {};
  const SolutionStatuses = SolutionConfigProvider.getValue(STATUS_MAP, "object");
  const [currentTab, setCurrentTab] = useState("all-assets");
  const [focusedTab, setFocusedTab] = useState("all-assets"); // This is used only when currentTab is null but you want the side bar to focused on specific id
  const overallDataRef = useRef({});
  const [currentDataList, setCurrentDataList] = useState([]);
  const [logoData, setLogoData] = useState(null);
  const [listLoaded, setListLoaded] = useState(false);
  const [client] = useState(useXemelgoClient());
  const [breadcrumbsDataList, setBreadcrumbsDataList] = useState([]);
  const [defaultViewMode, setDefaultViewMode] = useState(null);
  const [overviewData, setOverviewData] = useState({
    topMetricsDataList: [],
    bottomMetricsDataList: []
  });
  const [overallListData, setOverallListData] = useState({});
  const [gridViewConfig, setGridViewConfig] = useState({});
  const [listViewConfig, setListViewConfig] = useState({});
  const [groupbyStructure, setGroupByStructure] = useState(null);
  const [gridViewSortSchemaConfig, setGridViewSortSchemaConfig] = useState([]);
  const [printEnabled, setPrintEnabled] = useState(false);
  const [printTagsOptions, setPrintTagsOptions] = useState("");
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [showDisplayBanner, setShowDisplayBanner] = useState(false);
  const [displayBannerError, setDisplayBannerError] = useState(false);
  const [displayBannerMessage, setDisplayBannerMessage] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);

  const handleTabClick = (tabId, dataList) => {
    setCurrentTab(tabId);
    setCurrentDataList(dataList);
  };

  const processQueryPayload = (payload, data) => {
    let tabId = null;
    let dataList = [];
    const newBreadcrumbsDataList = [];
    switch (payload.group) {
      case "location":
        tabId = "location";
        dataList = data.locations;
        newBreadcrumbsDataList.push({
          id: "location",
          value: "Location",
          onClick: () => {
            handleBreadCrumbsClick("location");
            handleTabClick("location", data.locations);
            setFocusedTab(null);
          }
        });
        break;
      case "type":
        tabId = "type";
        dataList = data.types;
        newBreadcrumbsDataList.push({
          id: "type",
          value: "Type",
          onClick: () => {
            handleBreadCrumbsClick("type");
            handleTabClick("type", data.types);
            setFocusedTab(null);
          }
        });
        break;
      default:
        tabId = null;
        dataList = data.tracksItem;
        break;
    }
    if (payload.id) {
      setFocusedTab(tabId);
      const result = dataList.find((each) => {
        return each.id === payload.id;
      });
      if (result) {
        tabId = null;
        dataList = result.tracksItem;
        const breadcrumbItem = {
          id: payload.id,
          value: result.name
        };
        if (history.location.search.includes("type")) {
          breadcrumbItem.linkIconTitle = "Click to view item type details";
          breadcrumbItem.linkIconClickFn = () => {
            history.push(`${history.location.pathname}/itemType/detail?id=${payload.id}`);
          };
        }
        newBreadcrumbsDataList.push(breadcrumbItem);
      } else {
        history.replace(`${history.location.pathname}?group=${payload.group}`);
      }
    }
    setBreadcrumbsDataList(newBreadcrumbsDataList);
    handleTabClick(tabId, dataList);
  };

  useEffect(() => {
    breadcrumbsDataListRef.current = breadcrumbsDataList;
  });

  useEffect(() => {
    const onLoad = async () => {
      const {
        groupbyEnabled,
        possibleGroupbyTabs,
        defaultTab,
        possibleViewTabs,
        batchUpdateOptions = [],
        defaultViewMode: newDefaultViewMode,
        gridViewConfig: newGridViewConfig,
        listViewConfig: newListViewConfig
      } = TrackPageConfig;

      const gridViewSortSchema = newGridViewConfig.sortSchema || [];

      if (defaultTab && defaultTab !== "all-assets") {
        history.replace(`${history.location.pathname}?group=${defaultTab}`);
        setFocusedTab(defaultTab);
        setCurrentTab(defaultTab);
      }

      const tempGroupByStructure = [];
      const tempGroupByTabStructure = [];
      const tempViewTabStructure = [];

      for (const tabId of possibleViewTabs) {
        tempViewTabStructure.push(viewTabsMap[tabId]);
      }
      viewHeaderAndTabs.tabStructure = tempViewTabStructure;
      tempGroupByStructure.push(viewHeaderAndTabs);

      if (groupbyEnabled && possibleGroupbyTabs.length) {
        for (const grouByTabId of possibleGroupbyTabs) {
          tempGroupByTabStructure.push(groupByTabsMap[grouByTabId]);
        }
        groupByHeaderAndTabs.tabStructure = tempGroupByTabStructure;
        tempGroupByStructure.push(groupByHeaderAndTabs);
      }

      if (!groupbyStructure) {
        setGroupByStructure(tempGroupByStructure);
      }

      const newPrintTagsOptions = batchUpdateOptions.find((option) => {
        return option.id === "printTags";
      });
      if (newPrintTagsOptions) {
        setPrintEnabled(true);
        setPrintTagsOptions(newPrintTagsOptions);
      }

      setDefaultViewMode(newDefaultViewMode);
      setGridViewConfig(newGridViewConfig);
      setListViewConfig(newListViewConfig);
      setGridViewSortSchemaConfig(gridViewSortSchema);

      getCustomerLogo().then((logo) => {
        if (!logo) {
          logo = getStackedXemelgoLogo("dark");
        }
        setLogoData(logo);
      });

      loadContent();
    };

    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  const loadContent = async () => {
    const possibleLocationCategories = SolutionConfigProvider.getValue(POSSIBLE_DETECTOR_LOCATIONS, "array");

    const { additionalAttributeMap, validProcessStates = [] } = TrackPageConfig;

    const TrackPageClient = await client.getTrackPageClient();
    const data = await TrackPageClient.getAssetPageData(
      ["location", "type"],
      possibleLocationCategories,
      additionalAttributeMap,
      LocationRoleConfig.enabled,
      validProcessStates
    );
    const tempOverview = {
      numOfMissing: 0,
      numOfAsset: 0,
      numOfWeeklyMissing: "N/A",
      outOfCalibrationCount: 0
    };
    tempOverview.numOfAsset = data.tracksItem.length;
    data.tracksItem.forEach((each) => {
      if (each.isMissing) {
        tempOverview.numOfMissing += 1;
      }
      each.statusFlags = getStatusFlags(each.statusFlags, SolutionStatuses);
    });

    setOverviewData(tempOverview);
    setOverallListData(data);
    setListLoaded(true);
    overallDataRef.current = data;
  };

  useEffect(() => {
    if (Object.keys(overallListData).length) {
      const queryPaylod = queryString.parse(history.location.search);
      if (Object.entries(queryPaylod).length) {
        processQueryPayload(queryPaylod, overallListData);
      } else {
        setCurrentDataList(overallListData.tracksItem);
        setCurrentTab(null);
      }
    }
  }, [overallListData]);

  const getSortSchema = () => {
    const currentSortSchema =
      (currentTab ? gridViewSortSchemaConfig[currentTab] : gridViewSortSchemaConfig["all-assets"]) || [];
    const processedSortSchema = [...currentSortSchema];

    if (currentSortSchema && currentSortSchema.length > 0) {
      currentSortSchema.forEach((item, i) => {
        const { type, id } = item;

        switch (type) {
          case "number":
            processedSortSchema[i].compareFunc = (a, b, reverse) => {
              if (a[id] === b[id]) {
                return 0;
              }
              if (!a[id]) {
                return reverse ? -1 : 1;
              }
              if (!b[id]) {
                return reverse ? 1 : -1;
              }
              return a[id] < b[id] ? -1 : 1;
            };
            break;
          case "character":
            processedSortSchema[i].compareFunc = (a, b, reverse) => {
              // If sorting by last seen location in grid view, we want Unknown location to be
              // either at the end or beginning.
              if (id === "lastKnownLocationName") {
                if (!a[id] && !b[id]) {
                  return 0;
                }
                if (!a[id]) {
                  return 1;
                }
                if (!b[id]) {
                  return -1;
                }
              }
              if (!a[id]) {
                return reverse ? -1 : 1;
              }
              if (!b[id]) {
                return reverse ? 1 : -1;
              }
              if (a[id].toLowerCase() === b[id].toLowerCase()) {
                return 0;
              }
              return a[id].toLowerCase() < b[id].toLowerCase() ? -1 : 1;
            };
            break;
          case "status":
            processedSortSchema[i].compareFunc = (a, b) => {
              const statusLevelCalculator = (statusId) => {
                switch (statusId) {
                  case "missing":
                    return 256;
                  case "usageCritical":
                    return 128;
                  case "eolCritical":
                    return 64;
                  case "refreshCritical":
                    return 32;
                  case "critical":
                    return 16;
                  case "refreshWarning":
                    return 8;
                  case "eolWarning":
                    return 4;
                  case "warning":
                    return 2;
                  case "healthy":
                    return 1;
                  default:
                    return 0;
                }
              };
              let aLevel = 0;
              let bLevel = 0;
              a &&
                a.statusFlags.forEach((each) => {
                  aLevel += statusLevelCalculator(each.id);
                });
              b &&
                b.statusFlags.forEach((each) => {
                  bLevel += statusLevelCalculator(each.id);
                });
              return aLevel < bLevel ? 1 : -1;
            };
            break;
          default:
            break;
        }
      });
    }

    return processedSortSchema || [];
  };

  const getSidebarFeatureButtons = () => {
    const { sidebarFeatureButtons } = TrackPageConfig;

    return (
      sidebarFeatureButtons &&
      sidebarFeatureButtons.map((featureButton) => {
        const featureButtonDetail = sidebarFeatureButtonMap[featureButton.id];

        return (
          featureButtonDetail && (
            <OptionalLink
              key={featureButton.id}
              active={!!featureButtonDetail.linkRoute}
              route={featureButtonDetail.linkRoute}
            >
              <div
                name={featureButton.display}
                className={TrackPageComponentStyle.create_button}
                onClick={featureButtonDetail.onClick}
              >
                {featureButton.display}
              </div>
            </OptionalLink>
          )
        );
      })
    );
  };

  const filterFunction = (input, each) => {
    let match = false;
    const lowerCaseInput = input.toLowerCase();
    const keywords = lowerCaseInput.trim().split(" ");
    const { name } = each;
    switch (currentTab) {
      case "type":
      case "location":
        if (name && name.toLowerCase().includes(lowerCaseInput)) {
          match = true;
        }
        break;
      default:
        match = true;
        const searchString = getGridViewSearchString(each, gridViewConfig[currentTab || "all-assets"]);
        for (const keyword of keywords) {
          if (!searchString.toLowerCase().includes(keyword)) {
            match = false;
            break;
          }
        }

        break;
    }
    return match;
  };

  // TODO: this function needs to be change when ListView get refactored
  const listViewFilterFunction = (input, each) => {
    let match = true;
    const lowerCaseInput = input && input.toLowerCase();
    if (input) {
      const keywords = lowerCaseInput.trim().split(" ");
      for (const keyword of keywords) {
        if (!each.searchString.toLowerCase().includes(keyword)) {
          match = false;
          break;
        }
      }
    }
    return match;
  };

  const renderGridCardComponentDefault = (eachItemData, id, containerStyle) => {
    const currentGridViewConfig = gridViewConfig[currentTab || "all-assets"] || {};
    const { title = {}, subTitle = {}, bottomContentList = [], showStatusFlags, image } = currentGridViewConfig || {};
    return (
      <GridCardComponent
        key={id}
        containerStyle={containerStyle}
        color={
          eachItemData.statusFlags && eachItemData.statusFlags.length ? eachItemData.statusFlags[0].color : "#00B200"
        }
      >
        <OptionalLink route={`${history.location.pathname}/detail?itemId=${eachItemData.id}`}>
          <GridCardContentDefault
            image={(image && eachItemData[image.id]) ?? logoData}
            title={{ ...title, value: eachItemData[title.id] }}
            subTitle={{ ...subTitle, value: eachItemData[subTitle.id] }}
            bottomContentList={bottomContentList?.map((each) => {
              const value =
                (each.displayMapping || []).find((displayMap) => {
                  return displayMap.id === eachItemData[each.id];
                })?.display || eachItemData[each.id];
              return { ...each, value };
            })}
            showStatusFlags={showStatusFlags}
            statusFlags={eachItemData.statusFlags}
          />
        </OptionalLink>
      </GridCardComponent>
    );
  };

  const renderGridCardComponentGroupby = (eachData, id, containerStyle) => {
    const currentGridViewConfig = gridViewConfig[currentTab] || {};
    const { title = {}, contentList = [] } = currentGridViewConfig || {};
    const {
      missingCount,
      eolCriticalCount,
      eolWarningCount,
      refreshWarningCount,
      refreshCriticalCount,
      usageCriticalCount,
      tracksItem,
      image_path
    } = eachData;
    let healthColor = "#00B200";
    if (eolWarningCount > 0 || refreshWarningCount > 0) {
      healthColor = "orange";
    }
    if (missingCount > 0 || eolCriticalCount > 0 || refreshCriticalCount > 0 || usageCriticalCount > 0) {
      healthColor = "#F62227";
    }

    return (
      <GridCardComponent
        onClick={() => {
          handleTabClick(null, tracksItem);
          const breadcrumbItem = {
            id,
            value: eachData.name
          };
          if (history.location.search.includes("type")) {
            breadcrumbItem.linkIconTitle = "Click to view item type details";
            breadcrumbItem.linkIconClickFn = () => {
              history.push(`${history.location.pathname}/itemType/detail?id=${id}`);
            };
          }
          breadcrumbsDataList.push(breadcrumbItem);
          setBreadcrumbsDataList(breadcrumbsDataList);
        }}
        key={id}
        color={healthColor}
        containerStyle={containerStyle}
      >
        <OptionalLink
          route={`${history.location.pathname}${history.location.search}&id=${id}`}
          isReplace
        >
          <GridCardContentGroupby
            image={history.location.search.includes("type") ? image_path || logoData : null}
            title={{ ...title, value: eachData[title.id] }}
            contentList={contentList?.map((each) => {
              return { ...each, value: eachData[each.id] };
            })}
          />
        </OptionalLink>
      </GridCardComponent>
    );
  };

  const renderGridCard = () => {
    switch (currentTab) {
      case "location":
      case "type":
        return renderGridCardComponentGroupby;
      default:
        return renderGridCardComponentDefault;
    }
  };

  const listViewHeaderStructureListControl = (groupby) => {
    const currentListViewHeaderConfig = listViewConfig[groupby || "all-assets"];
    if (!currentListViewHeaderConfig) {
      return [];
    }

    currentListViewHeaderConfig
      .sort((a, b) => {
        return a.index - b.index;
      })
      .forEach((eachHeader) => {
        const { type } = eachHeader;
        switch (type) {
          case "statusText":
            eachHeader.renderComponent = (statusList) => {
              return (
                statusList &&
                Array.isArray(statusList) &&
                statusList.length > 0 &&
                statusList.map((eachStatus) => {
                  return (
                    <div
                      key={uuid.v4()}
                      className={Style.list_view_status}
                      style={{ backgroundColor: eachStatus && eachStatus.color }}
                    >
                      {eachStatus ? eachStatus.displayText : "-"}
                    </div>
                  );
                })
              );
            };
            break;
          case "date":
            eachHeader.renderComponent = (data) => {
              return data ? (
                <p>{getFormattedDate(data, eachHeader.format || "MMM D")}</p>
              ) : (
                <p>{eachHeader.defaultValue || "-"}</p>
              );
            };
            break;
          default:
            eachHeader.renderComponent = (data) => {
              return <p>{data || eachHeader.defaultValue || "-"}</p>;
            };
            break;
        }
      });

    return currentListViewHeaderConfig;
  };

  const getGridViewSearchString = (item, config) => {
    const { bottomContentList = [], subTitle = {}, title = {} } = config;
    let searchString = "";
    bottomContentList.forEach((element) => {
      const { type, id, format = "hh:mm A MMM D" } = element;
      if (item[id]) {
        searchString += `${JSON.stringify(type === "date" ? getFormattedDate(item[id], format) : item[id])}%`;
      }
    });

    if (item[subTitle.id]) {
      searchString += `${JSON.stringify(
        subTitle.type === "date" ? getFormattedDate(item[subTitle.id], subTitle.format) : item[subTitle.id]
      )}%`;
    }

    if (item[title.id]) {
      searchString += `${JSON.stringify(
        title.type === "date" ? getFormattedDate(item[title.id], title.format) : item[title.id]
      )}%`;
    }

    return searchString;
  };

  const getSearchString = (item, config) => {
    let searchString = "";
    config.forEach((element) => {
      const { type, id, format = "hh:mm A MMM D" } = element;
      if (item[id]) {
        searchString += `${JSON.stringify(type === "date" ? getFormattedDate(item[id], format) : item[id])}%`;
      }
    });
    return searchString;
  };

  // TODO: the functions below need to be refactor!!!!!!!!!!!!!!!!!!!!!!!!

  const listViewDataListControl = (groupby, dataList) => {
    switch (groupby) {
      case "location":
      case "type":
        return dataList.map((each) => {
          const {
            missingCount,
            eolCriticalCount,
            eolWarningCount,
            refreshWarningCount,
            refreshCriticalCount,
            usageCriticalCount
          } = each;
          let statusFlags = [{ id: "healthy", displayText: "Healthy", color: "#00B200", value: 0 }];
          if (eolWarningCount > 0 || refreshWarningCount > 0) {
            statusFlags = [{ id: "warning", displayText: "Warning", color: "orange", value: 1 }];
          }
          if (missingCount > 0 || eolCriticalCount > 0 || refreshCriticalCount > 0 || usageCriticalCount > 0) {
            statusFlags = [{ id: "critical", displayText: "Critical", color: "red", value: 2 }];
          }
          const dataObject = {
            ...each,
            statusFlags
          };

          return {
            ...dataObject,
            searchString: getSearchString(dataObject, listViewConfig[groupby])
          };
        });
      default:
        return dataList.map((each) => {
          return {
            ...each,
            searchString: getSearchString(each, listViewConfig["all-assets"])
          };
        });
    }
  };

  const getCurrentId = (currentTarget) => {
    const splitId = currentTarget.id.split(" ");
    return splitId[0];
  };

  const getListViewLinkDetails = ({ id }) => {
    const route =
      currentTab && currentTab !== "all-assets"
        ? `${history.location.pathname}${history.location.search}&id=${id}`
        : `${history.location.pathname}/detail?itemId=${id}`;

    return { route, isReplace: currentTab && currentTab !== "all-assets" };
  };

  const onMultiClick = async (ids, value) => {
    const tempCurrentDataList = [...currentDataList];
    ids.forEach((id) => {
      const currentObject = tempCurrentDataList.find((element) => {
        return element.id === id;
      });
      currentObject.isChecked = value;
    });

    setCurrentDataList(tempCurrentDataList);
  };

  return (
    <>
      {showDisplayBanner && (
        <DisplayBanner
          bannerError={displayBannerError}
          bannerTitle={displayBannerError ? "Error" : "Success"}
          bannerMessage={displayBannerMessage}
          onCloseBanner={() => {
            setShowDisplayBanner(false);
          }}
        />
      )}
      <TrackPageComponent
        mainColor={mainColor}
        secondaryColor={secondaryColor}
        title="Assets"
        titleIcon={
          <AssetIcon
            width={25}
            height={25}
            style={{ color: mainColor }}
          />
        }
        overviewComponent={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <AssetOverViewComponent
            dataList={[
              { id: "totalCount", title: "Total Count", value: overviewData.numOfAsset },
              { id: "totalMissingCount", title: "Total Missing Count", value: overviewData.numOfMissing },
              { id: "weeklyMissingCount", title: "Assets Missing Weekly", value: overviewData.numOfWeeklyMissing },
              { id: "outofCalibrationCount", title: "Calibration Due Soon", value: overviewData.outOfCalibrationCount }
            ]}
          />
        }
        buttonTitle="+ Add Asset"
        buttonOnClick={
          useAddAssetV2
            ? () => {
                window.fcWidget.hide();
                setShowCreateModal(true);
              }
            : undefined
        }
        groupbyComponent={
          !listLoaded ? (
            <Skeleton count={3} />
          ) : (
            <GroupbySideBarComponent
              tabStructure={groupbyStructure}
              focusedTab={currentTab || focusedTab}
            />
          )
        }
        sidebarFeatureButtons={getSidebarFeatureButtons()}
        breadcrumbsComponent={
          <BreadcrumbsComponent
            dataList={breadcrumbsDataList}
            onHomeClick={() => {
              handleTabClick("all-assets", overallDataRef.current.tracksItem);
              setFocusedTab(null);
              setCurrentTab(null);
              setBreadcrumbsDataList([]);
              history.replace(`${history.location.pathname}`);
            }}
          />
        }
        dataViewComponent={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <TrackPageDataViewComponent
            dataList={currentDataList}
            defaultViewMode={defaultViewMode}
            sortSchema={getSortSchema()}
            filterFunc={filterFunction}
            listViewFilterFunc={listViewFilterFunction} // needs to be refactor
            gridCardComponent={renderGridCard()}
            listViewStructure={listViewHeaderStructureListControl(currentTab)} // needs to be refactor
            listViewDataList={listViewDataListControl(currentTab, currentDataList)} // needs to be refactor
            getListViewLinkDetails={getListViewLinkDetails}
            handleListViewClick={({ currentTarget, name }) => {
              const currentId = getCurrentId(currentTarget);
              const location = currentDataList.findIndex((each) => {
                return each.id === currentId;
              });
              handleTabClick(null, currentDataList[location].tracksItem);
              breadcrumbsDataList.push({
                id: currentId,
                value: name
              });
              setBreadcrumbsDataList(breadcrumbsDataList);
            }}
            loading={!listLoaded}
            multiSelectEnabled={printEnabled}
            onMultiClick={onMultiClick}
            batchUpdateOptions={[
              {
                label: printTagsOptions.label || "Print Tags",
                onClick: () => {
                  setShowPrintModal(true);
                }
              }
            ]}
            setFormComponent={() => {}}
          />
        }
      />
      {showPrintModal && (
        <PrintTagsModal
          onCloseModal={(printAttempted, success, message) => {
            setShowPrintModal(false);

            if (printAttempted) {
              setShowDisplayBanner(true);
              setDisplayBannerError(!success);
              setDisplayBannerMessage(message);
            }
          }}
          printTagsOptions={printTagsOptions}
          selectedItems={currentDataList.filter((element) => {
            return element.isChecked;
          })}
          appId={APP_ID}
        />
      )}
      {useAddAssetV2 && showCreateModal && (
        <AddAssetFeature
          onClose={() => {
            window.fcWidget.show();
            setShowCreateModal(false);
            loadContent();
          }}
        />
      )}
    </>
  );
};

export default withRouter(AssetTrackPageFeature);
