export const getStatusForLocation = (colorMap) => {
  if (Object.keys(colorMap).length) {
    return [
      "match",
      ["get", "id"],
      ...Object.keys(colorMap).reduce((accumulator, eachColor) => {
        colorMap[eachColor].locations.forEach((eachLocationId) => {
          accumulator.push(eachLocationId);
          accumulator.push(eachColor);
        });
        return accumulator;
      }, []),
      "transparent"
    ];
  }
  return "transparent";
};
