import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Div } from "../../../../components/div";
import "./style.css";
import { LocationDetail } from "./location-detail";
import { FeatureConfigurationProvider } from "../../../../domains/feature-configuration-provider";
import { useXemelgoClient } from "../../../../services/xemelgo-service";

const EmptyPanel = () => {
  return <Div className="empty-operation">Select a location to see more information about it</Div>;
};

export const LocationDetailPane = ({ appId, featureId, locationInfo, onChanged, onDelete, configuration }) => {
  const [containsLocationCategoryInfo, setContainsLocationCategoryInfo] = useState(null);
  const [xemelgoClient] = useState(useXemelgoClient());
  const [containsLocationInfo, setContainsLocationInfo] = useState(null);
  const [location, setLocation] = useState(null);
  const [locationModelId, setLocationModelId] = useState(null);

  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    const { modelId, location: loc } = locationInfo || {};
    if (!cancelled) {
      setLocationModelId(modelId);
      setLocation(loc);
    }

    return cancelCallback;
  }, [locationInfo]);
  /**
   * Name: parseConfigForChildLocationInfo
   */
  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!configuration || !location || !locationModelId) {
      return cancelCallback;
    }

    // const { selectedModelId } = locationInfo;
    const provider = FeatureConfigurationProvider.parse("locationDetailPane", configuration);
    const locationModelProvider = provider.getModel(locationModelId);
    const childLocationModelId = locationModelProvider.getValue("containsLocationModel", "string", null);

    if (!childLocationModelId) {
      setContainsLocationCategoryInfo(null);
      return cancelCallback;
    }

    const childLocationModelProvider = provider.getModel(childLocationModelId);
    const childLocationCategory = childLocationModelProvider.getValue("category", "object", null);

    if (!cancelled) {
      setContainsLocationCategoryInfo(childLocationCategory);
    }

    return cancelCallback;
  }, [configuration, location, locationModelId]);

  /**
   * Name: prepareContainsLocationInfo
   * Query for children locations and prepare the model for detail page to display.
   */
  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!containsLocationCategoryInfo || !xemelgoClient || !locationInfo) {
      setContainsLocationInfo(null);
      return cancelCallback;
    }

    const { location: loc } = locationInfo;
    const { id } = loc;

    const locationClient = xemelgoClient.getLocationClient();
    locationClient.getLocationChildrenByParentId(id).then((results) => {
      const containsInfo = {
        category: containsLocationCategoryInfo ? { ...containsLocationCategoryInfo } : null,
        count: results.length,
        locations: results
      };

      if (!cancelled) {
        setContainsLocationInfo(containsInfo);
      }
    });

    return cancelCallback;
  }, [containsLocationCategoryInfo, xemelgoClient, locationInfo]);

  const onChangedCallback = useCallback(() => {
    onChanged(locationInfo.modelId);
  }, [onChanged, locationInfo]);

  const onDeleteCallback = useCallback(() => {
    if (locationInfo) {
      onDelete(locationInfo.modelId);
    }
  }, [onDelete, locationInfo]);

  return (
    <Div className="location-operation-detail">
      {location ? (
        <LocationDetail
          location={location}
          appId={appId}
          featureId={featureId}
          onEdit={onChangedCallback}
          onDelete={onDeleteCallback}
          containsLocationsInfo={containsLocationInfo}
          modelId={locationModelId}
        />
      ) : (
        <EmptyPanel />
      )}
    </Div>
  );
};

LocationDetailPane.defaultProps = {
  locationInfo: null,
  onChanged: () => {},
  onDelete: () => {},
  configuration: {}
};

LocationDetailPane.propTypes = {
  locationInfo: PropTypes.shape({
    location: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    modelId: PropTypes.string
  }),
  appId: PropTypes.string.isRequired,
  featureId: PropTypes.string.isRequired,
  onChanged: PropTypes.func,
  onDelete: PropTypes.func,
  configuration: PropTypes.shape({
    modelMap: PropTypes.objectOf(
      PropTypes.shape({
        containsLocationModel: PropTypes.string,
        category: PropTypes.shape({
          name: PropTypes.string.isRequired,
          displayName: PropTypes.string.isRequired,
          groupDisplayName: PropTypes.string.isRequired
        })
      })
    )
  })
};
