/**
 *
 * @param newTags { any[] }
 * @param existingItemByTag { any }
 * @param inventoryClient { any }
 * @param itemTypeQueryAttributes { string[] }
 * @return { Promise<Object.<string, ReducedItem> }
 */
export const queryItemsFromParsedTags = async (
  newTags,
  existingItemByTag,
  itemTypeClient,
  itemTypeQueryAttributes,
  upcNumberOfCharactersToTrim
) => {
  const upcsToQuery = [
    ...new Set(
      newTags.reduce((acc, tag) => {
        if (tag.analytic.name === "SGTIN" && tag.analytic.upc) {
          acc.push(tag.analytic.upc.slice(upcNumberOfCharactersToTrim));
        }
        return acc;
      }, [])
    )
  ];

  const queriedUpcs = Object.values(existingItemByTag).map((item) => {
    return item.type;
  });
  const queriedUpcsIdentifiers = queriedUpcs.map((type) => {
    return type.identifier;
  });
  const newUpcsToQuery = upcsToQuery.filter((upc) => {
    return !queriedUpcsIdentifiers.includes(upc);
  });

  if (!newTags.length) {
    return Promise.resolve({});
  }

  // Process all tags based on existing UPC data
  const newItemsByTag = processItems(newTags, queriedUpcs, existingItemByTag, upcNumberOfCharactersToTrim);
  if (!newUpcsToQuery.length) {
    return newItemsByTag;
  }

  const newlyQueriedUpcs = await itemTypeClient.getItemTypeByIdentifiers(newUpcsToQuery, [
    "id",
    ...itemTypeQueryAttributes
  ]);

  // Process all tags based on newly retrieved UPC data
  const processedResults = processItems(newTags, newlyQueriedUpcs, newItemsByTag, upcNumberOfCharactersToTrim);
  return processedResults;
};

export const processItems = (tagData, itemTypes = [], initialData, upcNumberOfCharactersToTrim) => {
  const firstSeenTimestamp = Date.now();
  return tagData.reduce((map, tag) => {
    const clonedMap = { ...map };
    const upcIdentifier = tag?.analytic?.upc?.slice(upcNumberOfCharactersToTrim);
    const item = itemTypes.find((itemType) => {
      return itemType?.identifier === upcIdentifier;
    });

    // If upc doesn't exist, display just the identifier without the metadata
    if (item || (upcIdentifier && !clonedMap[tag.Name])) {
      clonedMap[tag.Name] = { type: item || { identifier: upcIdentifier }, vid: tag.Name, firstSeenTimestamp };
    }

    return clonedMap;
  }, initialData);
};
