import { useState } from "react";
import {
  UPDATE_STATE_BATCH_SIZE,
  STATES,
  UPDATE_STATE_MAX_RETRY_COUNT
} from "../../../../constants/bulkUpdateItemStateConstant";
import { useXemelgoClient } from "../../../../services/xemelgo-service";

export const useUpdateItemState = () => {
  const [itemClient] = useState(useXemelgoClient().getItemClient());
  const [publishClient] = useState(useXemelgoClient().getPublishClient());
  const [trackingSessionClient] = useState(useXemelgoClient().getTrackingSessionClient());

  const updateState = async (itemPayloadList, updateBulkUpdateLoadingMessage) => {
    const stateToLocationToItemPayloadMap = groupByLocationAndStates(itemPayloadList);
    for (const state of Object.keys(stateToLocationToItemPayloadMap)) {
      for (const locationId of Object.keys(stateToLocationToItemPayloadMap[state])) {
        try {
          switch (state) {
            case STATES.ON_HAND:
              await resetToOnhand(
                stateToLocationToItemPayloadMap[state][locationId].items,
                locationId,
                stateToLocationToItemPayloadMap[state][locationId].identifier,
                0,
                updateBulkUpdateLoadingMessage
              );
              break;
            default:
              break;
          }
        } catch (e) {
          return Promise.reject(new Error("reset failed"));
        }
      }
    }
    return Promise.resolve("reset successful");
  };

  const resetToOnhand = async (
    itemPayload,
    locationId,
    locationIdentifier,
    retryCount,
    updateBulkUpdateLoadingMessage
  ) => {
    let itemFailedToReset = [];
    let currentSlice = [];

    const totalCount = itemPayload.length;
    let processedCount = 0;

    if (retryCount === 0) {
      updateBulkUpdateLoadingMessage(
        `Resetting items to on-hand for location ${locationIdentifier}, ${processedCount} of ${totalCount} processed`
      );
    }

    while (itemPayload.length) {
      try {
        currentSlice = itemPayload.splice(0, UPDATE_STATE_BATCH_SIZE);

        const itemData = await getItemDataForUpdateToOnhand(currentSlice);

        if (itemData.length > 0) {
          await restartTSAndMoveItemToLocation(itemData, locationId);
        }

        processedCount += currentSlice.length;

        if (retryCount === 0) {
          updateBulkUpdateLoadingMessage(
            `Resetting items for location ${locationIdentifier}, ${processedCount} of ${totalCount} processed`
          );
        }
      } catch (e) {
        itemFailedToReset = [...itemFailedToReset, ...currentSlice];
      }
    }

    if (itemFailedToReset.length) {
      if (retryCount < UPDATE_STATE_MAX_RETRY_COUNT) {
        updateBulkUpdateLoadingMessage(`Resetting items for location ${locationIdentifier}, retry #${retryCount + 1}`);
        return resetToOnhand(
          itemFailedToReset,
          locationId,
          locationIdentifier,
          retryCount + 1,
          updateBulkUpdateLoadingMessage
        );
      }

      return Promise.reject(new Error("reset failed"));
    }

    return Promise.resolve("reset successful");
  };

  const getItemDataForUpdateToOnhand = async (items) => {
    const itemIdentifiers = items.map((item) => {
      return item.identifier;
    });
    const res = await itemClient.getItemDataForBulkUpdate(["Inventory"], itemIdentifiers, ["is_consumed"], true);

    const itemData = res.map((item) => {
      return item.data;
    });

    return itemData;
  };

  const restartTSAndMoveItemToLocation = async (items, locationId) => {
    const sessionIds = getSessionIdsFromItems(items);
    const context = {
      actions: {
        endTrackingSession: true,
        unconsumeItem: true,
        resetProcessState: true
      }
    };

    await publishClient.userEvent(sessionIds, null, context);

    const newTsIds = [];
    for (let i = 0; i < items.length; i++) {
      const currentItem = items[i];
      newTsIds.push(...(await trackingSessionClient.startTrackingSessions([currentItem.id])));
    }
    // update location
    await publishClient.userEvent(newTsIds, locationId, null);
  };

  return {
    updateState
  };
};

const getSessionIdsFromItems = (items) => {
  return items
    .reduce((acc, item) => {
      return [...acc, ...(item.associatedWithSession || [])];
    }, [])
    .map((session) => {
      return session.id;
    });
};

const groupByLocationAndStates = (itemPayloadList) => {
  const stateToLocationToItemPayloadMap = {};
  itemPayloadList.forEach((curr) => {
    const { state, locationId } = curr; // assume state value is present and verified for each item.
    if (stateToLocationToItemPayloadMap[state]) {
      if (stateToLocationToItemPayloadMap[state][locationId]) {
        stateToLocationToItemPayloadMap[state][locationId].items.push(curr);
      } else {
        stateToLocationToItemPayloadMap[state][locationId] = {
          items: [curr],
          identifier: curr.last_known_location
        };
      }
    } else {
      stateToLocationToItemPayloadMap[state] = {
        [locationId]: {
          items: [curr],
          identifier: curr.last_known_location
        }
      };
    }
  });

  return stateToLocationToItemPayloadMap;
};
