import React from "react";
import PropTypes from "prop-types";
import GridCardContentDefaultStyle from "./GridCardContentGroupbyLocation.module.css";
import { getFormattedDate } from "../../../../../common/Utilities";

const GridCardContentGroupbyLocation = ({ locationName, velocity, numOfOrders, lastUpdatedTime }) => {
  return (
    <div className={GridCardContentDefaultStyle.container}>
      <div className={GridCardContentDefaultStyle.top_section}>
        <div className={GridCardContentDefaultStyle.top_left_container}>
          <div className={GridCardContentDefaultStyle.top_title}>{locationName}</div>
          <div className={GridCardContentDefaultStyle.top_metric_container}>
            <div className={GridCardContentDefaultStyle.top_metric_group}>
              Velocity Rate:
              <p className={GridCardContentDefaultStyle.top_metric_data}>{velocity}</p>
              orders/day
            </div>
          </div>
        </div>
      </div>
      <div className={GridCardContentDefaultStyle.bottom_section}>
        <div className={GridCardContentDefaultStyle.bottom_data_group}>
          # of Orders
          <p className={GridCardContentDefaultStyle.bottom_data_content}>{numOfOrders}</p>
        </div>
        <div className={GridCardContentDefaultStyle.bottom_data_group}>
          Last Updated
          <p className={GridCardContentDefaultStyle.bottom_data_content}>
            {lastUpdatedTime ? getFormattedDate(lastUpdatedTime, "hh:mm A - MMM D") : "-"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default GridCardContentGroupbyLocation;

GridCardContentGroupbyLocation.defaultProps = {
  locationName: "",
  velocity: "0",
  numOfOrders: null,
  lastUpdatedTime: null
};

GridCardContentGroupbyLocation.propTypes = {
  locationName: PropTypes.string,
  velocity: PropTypes.string,
  numOfOrders: PropTypes.number,
  lastUpdatedTime: PropTypes.number
};
