import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody } from "mdbreact";
import Style from "./SummaryTileComponent.module.css";

// TODO Componentize, without style; classname instead
const renderTitleContentDefault = (tileInfo) => {
  return (
    <div
      className={Style.summary_tile_title}
      style={{ backgroundColor: tileInfo.color }}
    >
      {tileInfo.title}
    </div>
  );
};

const renderTopContentDefault = (tileInfo, label) => {
  return (
    <div className={`${Style.summary_tile_top_content}`}>
      <p className={Style.top_content_number}>{tileInfo.totalItems}</p>
      <p className={Style.top_content_text}>{label}</p>
    </div>
  );
};

const renderBottomContentDefault = (badgeList) => {
  return (
    <div className={Style.badge_container}>
      {badgeList
        ? badgeList.map((badge) => {
            return (
              <div
                key={badge.id}
                className={Style.status_badge}
              >
                <p
                  className={Style.status_badge_count}
                  style={{ "background-color": badge.color || "gray" }}
                >
                  {badge.value}
                </p>
                <p className={Style.status_badge_text}>{badge.label}</p>
              </div>
            );
          })
        : null}
    </div>
  );
};

const SummaryTileComponent = ({
  titleRenderFunction,
  topContentRenderFunction,
  bottomContentRenderFunction,
  labelString,
  tileInfo,
  isFilterEnabled,
  shouldShowBadgeContainer,
  onClickFn
}) => {
  return (
    tileInfo && (
      <Card
        title="Click to view items in this group."
        className={`${Style.summary_tile} ${!isFilterEnabled && Style.filter_disabled}`}
        onClick={() => {
          return onClickFn(tileInfo.id, "locationSummary");
        }}
      >
        <CardBody className={Style.summary_tile_body}>
          {titleRenderFunction ? titleRenderFunction(tileInfo) : renderTitleContentDefault(tileInfo)}
          <div className={Style.summary_tile_content}>
            {topContentRenderFunction
              ? topContentRenderFunction(tileInfo)
              : renderTopContentDefault(tileInfo, labelString)}
          </div>
          {shouldShowBadgeContainer && (
            <div className={Style.border_top}>
              {bottomContentRenderFunction
                ? bottomContentRenderFunction(tileInfo.data.badgeList)
                : renderBottomContentDefault(tileInfo.data.badgeList)}
            </div>
          )}
        </CardBody>
      </Card>
    )
  );
};

export default SummaryTileComponent;

SummaryTileComponent.defaultProps = {
  titleRenderFunction: null,
  topContentRenderFunction: null,
  bottomContentRenderFunction: null,
  labelString: " Total Items ",
  tileInfo: null,
  isFilterEnabled: true,
  shouldShowBadgeContainer: false,
  onClickFn: () => {}
};

SummaryTileComponent.propTypes = {
  titleRenderFunction: PropTypes.func,
  topContentRenderFunction: PropTypes.func,
  bottomContentRenderFunction: PropTypes.func,
  labelString: PropTypes.string,
  tileInfo: PropTypes.object,
  isFilterEnabled: PropTypes.bool,
  shouldShowBadgeContainer: PropTypes.bool,
  onClickFn: PropTypes.func
};
