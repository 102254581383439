import React, { useContext, useState } from "react";

const PackageTrackPageDataSourceContext = React.createContext();

const initialState = {
  isLocationTreeMapLoading: true,
  locationTreeMap: {},
  isLocationMetricsMapLoading: true,
  locationMetricsMap: {},
  isLocationPackageListMapLoading: true,
  locationPackageListMap: {}
};

export const usePackageTrackPageDataSourceContext = () => {
  return useContext(PackageTrackPageDataSourceContext);
};

export const PackageTrackPageDataSourceContextProvider = ({ children }) => {
  const [locationTreeMap, setLocationTreeMap] = useState(initialState.locationTreeMap);
  const [locationMetricsMap, setLocationMetricsMap] = useState(initialState.locationMetricsMap);
  const [locationPackageListMap, setLocationPackageListMap] = useState(initialState.locationPackageListMap);
  const [isLocationTreeMapLoading, setIsLocationTreeMapLoading] = useState(initialState.isLocationTreeMapLoading);
  const [isLocationMetricsMapLoading, setIsLocationMetricsMapLoading] = useState(
    initialState.isLocationMetricsMapLoading
  );

  const [isLocationPackageListMapLoading, setIsLocationPackageListMapLoading] = useState(
    initialState.isLocationPackageListMapLoading
  );

  return (
    <PackageTrackPageDataSourceContext.Provider
      value={{
        locationTreeMap,
        setLocationTreeMap,
        locationMetricsMap,
        setLocationMetricsMap,
        locationPackageListMap,
        setLocationPackageListMap,
        isLocationTreeMapLoading,
        setIsLocationTreeMapLoading,
        isLocationMetricsMapLoading,
        setIsLocationMetricsMapLoading,
        isLocationPackageListMapLoading,
        setIsLocationPackageListMapLoading
      }}
    >
      {children}
    </PackageTrackPageDataSourceContext.Provider>
  );
};
