import { TIME_IN_MS } from "../../../common/Utilities";

export const ALL_TIME_ID = "all";
export const ALL_TIME_LABEL = "All Time";

export const QUICK_FILTER_OPTIONS = [
  { id: ALL_TIME_ID, label: ALL_TIME_LABEL, value: -1 },
  { id: "day", label: "Last 24 hours", value: TIME_IN_MS.DAYS },
  { id: "week", label: "Last 7 days", value: TIME_IN_MS.WEEKS },
  { id: "month", label: "Last 30 days", value: TIME_IN_MS.DAYS * 30 },
  { id: "quarter", label: "Last 90 days", value: TIME_IN_MS.DAYS * 90 }
];
