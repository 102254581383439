import _ from "lodash";
import { OnboardingV2FunctionMap } from "../../common/commonAPICalls";
import calculateDependentFormValue from "../../utils/calculate-dependent-form-value";
import { AddPageInputTypeMap } from "../../data/constants";
import useAvoidRacingAPIHelper from "../use-avoid-racing-api-helper";

/**
 * @typedef {Object} Field
 * @property {string} type - The type of the field .
 * @property {Object} [argsForAPI] - Additional arguments for API calls.
 * @property {string} id - The ID of the field.
 * @property {Array} [options] - The options for the field (if it is a dropdown).
 * @property {Function} [searchFn] - The debounce callback function (if it is a search field).
 * @property {string} [metaDataOf] - The ID of the parent field (if this field is a subfield).
 * @property {Object} [subFieldsMap] - The map of subfields for this field.
 * @property {Object} [dependency] - The dependency information for this field.
 * @property {Array<string>} dependency.dependentOn - The IDs of fields this field depends on.
 */

/**
 * This hook provides helper functions for the v2 onboarding form
 * @return {fetchFormOptions}
 */
export const useOnboardingForm = () => {
  const executeFn = useAvoidRacingAPIHelper();

  /**
   * This function is responsible for fetching options for different types of form fields
   * @param {Field} field
   * @param {*} featureFunctionMap
   * @param {string} solution
   * @returns
   */
  const fetchFieldOptions = async (field, featureFunctionMap, solution) => {
    const clonedField = _.cloneDeep(field);

    const { type, argsForAPI = {}, id } = clonedField;
    const getOptionsFn = featureFunctionMap[id];

    switch (type) {
      case "dropdownWithOptionsFromAPI":
        clonedField.options = getOptionsFn ? await getOptionsFn({ solution, type, argsForAPI }) : [];
        return clonedField;

      case "search":
        clonedField.searchFn = (string) => {
          return executeFn(getOptionsFn({ solution, type, input: string, argsForAPI }));
        };
        return clonedField;

      default:
        return field;
    }
  };

  /**
   * @typedef {Array<Field>} Section
   */

  /**
   * @typedef {Array<Section>} FormSections
   */

  /**
   * This function iterates over fields from each sections to fetch its options
   * @param {FormSections} formSections
   * @param {string} solution
   * @returns
   */
  const fetchFormOptions = async (formSections, solution) => {
    const updatedFormFields = {};
    const updatedFormSections = [];

    for (const section of formSections) {
      const formFieldsMap = {};
      const sectionMap = {};

      for (const field of section) {
        const { id } = field;
        const fetchedFormField = await fetchFieldOptions(field, OnboardingV2FunctionMap, solution);

        sectionMap[id] = fetchedFormField;
        formFieldsMap[field.id] = fetchedFormField;
      }

      // process metaDataOf fields to become sub fields
      Object.keys(sectionMap).forEach((fieldId) => {
        const { metaDataOf } = sectionMap[fieldId];
        if (metaDataOf) {
          if (!sectionMap[metaDataOf].subFieldsMap) {
            sectionMap[metaDataOf].subFieldsMap = {};
          }
          sectionMap[metaDataOf].subFieldsMap[fieldId] = sectionMap[fieldId];
          delete sectionMap[fieldId];
        }
      });

      updatedFormSections.push(Object.values(sectionMap));
      Object.assign(updatedFormFields, formFieldsMap);
    }

    return { fetchedFormFields: updatedFormFields, fetchedFormSections: updatedFormSections };
  };

  /**
   * @typedef {Object.<string, Field>} FormFieldsMap
   */

  /**
   * @typedef {Object.<string, any>} FormData
   */

  /**
   * This function handles changes in form field values. It updates the form data based on the field type (e.g., checkbox group)
   * and dependencies between fields.
   * @param {string} fieldId
   * @param {any} value
   * @param {FormSections} formSections
   * @param {FormFieldsMap} formFields
   * @param {FormData} formData
   * @returns
   */
  const onFormSectionsChange = (fieldId, value, formSections, formFields, formData) => {
    if (!formFields[fieldId]) {
      return formData;
    }

    let newValue = value;
    // Parse value returned from CheckBoxGroup
    if (formFields[fieldId].type === AddPageInputTypeMap.CHECK_BOX_GROUP) {
      newValue = "";
      for (const option in value) {
        if (value[option] && formData[fieldId] !== option) {
          newValue = option;
          break;
        }
      }
    }

    const newFormData = {
      ...formData,
      [fieldId]: newValue
    };

    formSections.forEach((section) => {
      section.forEach((field) => {
        // Looks for fields are dependent on other fields (eg. calibration date) and
        // calculates their values
        if (field.dependency && field.dependency.dependentOn.includes(fieldId)) {
          newFormData[field.id] = calculateDependentFormValue(field, newFormData, formFields);
        }

        const { subFieldsMap = {} } = field;

        if (Object.keys(subFieldsMap).length > 0 && fieldId === field.id && Object.keys(value).length) {
          const updatedSubFieldsMap = Object.keys(subFieldsMap).reduce((newSubFieldsMap, eachFieldId) => {
            return { ...newSubFieldsMap, [eachFieldId]: { ...subFieldsMap[eachFieldId], value: value[eachFieldId] } };
          }, {});
          newFormData[field.id].subFieldsMap = updatedSubFieldsMap;
        }
      });
    });
    return newFormData;
  };

  /**
   * This function processes a form field with a new value, updating the field's value and subfields if applicable.
   * @param {Field} field
   * @param {*} newValue
   * @returns
   */
  const processFormFieldWithNewValue = (field, newValue) => {
    const newField = { ...field };
    const { subFieldsMap } = newField;
    // Structure value that will be passed in to CheckBoxGroup
    if (newField.type === AddPageInputTypeMap.CHECK_BOX_GROUP && newField.options) {
      const checkBoxValue = {};
      newField.options.forEach((option) => {
        checkBoxValue[option.id] = newValue === option.id;
      });
      newField.value = checkBoxValue;
    } else {
      newField.value = newValue;

      // update subFields
      if (subFieldsMap) {
        newField.subFieldsMap = newValue?.subFieldsMap || {};
      }
    }
    return newField;
  };

  const updateFormSectionsWithFormData = (formData, formSections) => {
    const updatedFormSections = formSections.map((section) => {
      const sectionFieldList = section.map((field) => {
        const updatedField = processFormFieldWithNewValue(field, formData[field.id]);

        // disabled print_quantity field if RFID provided and vice versa
        if (field.id === "tracker_serial" && formData.print_quantity) {
          updatedField.value = "";
          updatedField.isDisabled = true;
        }
        if (field.id === "print_quantity" && formData.tracker_serial) {
          updatedField.value = "";
          updatedField.isDisabled = true;
        }
        return updatedField;
      });

      return sectionFieldList;
    });
    return updatedFormSections;
  };

  /**
   * @typedef {Object} FormData
   * @property {boolean} isError
   * @property {*} value
   */

  /**
   * @typedef {Object} FormField
   * @property {boolean} isRequired
   */

  /**
   * @typedef {Object.<string, FormField>} FormFields
   */

  /**
   * This function validate if all the fields pass the checks (i.e., no errors and all required fields have non-empty values)
   * indicate that the form is ready for submit
   * @param {Array<FormData>} formData
   * @param {FormFields} formFields
   * @returns {boolean}
   */
  const validateFormDataForSubmit = (formData, formFields) => {
    if (!formData || !formData.length) {
      return false;
    }
    return formData.reduce((__, eachForm) => {
      const isFormReady = Object.keys(eachForm).every((fieldId) => {
        let hasValue;
        const { type } = formFields[fieldId];
        const fieldValue = eachForm[fieldId];

        switch (type) {
          case AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
          case AddPageInputTypeMap.SEARCH:
            hasValue = fieldValue && !!Object.values(fieldValue).length;
            break;
          case AddPageInputTypeMap.INPUT:
          default:
            hasValue = !!fieldValue;
        }

        const { isRequired } = formFields[fieldId] || {};
        if (eachForm[fieldId]?.isError) {
          return false;
        }
        if (isRequired) {
          return hasValue;
        }
        return true;
      });
      return isFormReady;
    }, true);
  };

  return { fetchFormOptions, onFormSectionsChange, updateFormSectionsWithFormData, validateFormDataForSubmit };
};
