import React, { useEffect, useState } from "react";
import BulkUpdateComponent from "../../components/bulk-update-component/BulkUpdateComponent";
import { useAppConfigProvider } from "../../services/soft-cache-service";
import { XemelgoService } from "../../services/XemelgoService";
import { BulkUpdateInputTypeMap, validCSVHeaderCheck } from "../../common/Utilities";
import { BulkUpdateFeatureFunctionMap, processBulkUpdateAttributes } from "../../common/commonAPICalls";
import useUpdateItemState from "./hooks/use-update-item-state";
import useMixpanelContext from "../../context/mixpanel-context";
import {
  INVENTORY_BULK_UPDATE,
  INVENTORY_BULK_UPDATE_STEPS
} from "../../constants/mixpanel-constant/inventoryBulkUpdate";

const APP_ID = "inventory";
const POSSIBLE_DETECTOR_LOCATIONS = "possibleDetectorLocations";
const ATTRIBUTE_MAP = "attributeMap";

const BulkUpdateItemsFeature = () => {
  const configProvider = useAppConfigProvider(APP_ID);
  const [defaultAttributeMap, setDefaultAttributeMap] = useState({});
  const [customAttributeMap, setCustomAttributeMap] = useState({});
  const [loading, setLoading] = useState(true);
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [bannerError, setBannerError] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [itemClient, setItemClient] = useState(null);
  const [publishClient, setPublishClient] = useState(null);
  const [errorExists, setErrorExists] = useState(false);
  const [possibleDetectorLocationCategories, setPossibleDetectorLocationCategories] = useState([]);
  const [isResetItemState, setIsResetItemState] = useState(false);
  const [resetItemStateAttribute, setResetItemStateAttribute] = useState(null);
  const { sendMixPanelEvent } = useMixpanelContext();

  const { updateState } = useUpdateItemState();

  useEffect(() => {
    sendMixPanelEvent(INVENTORY_BULK_UPDATE, INVENTORY_BULK_UPDATE_STEPS.ENTRY);
    onLoad();
    // eslint-disable-next-line
  }, []);

  const onLoad = async () => {
    setLoading(true);

    const xemelgoClient = XemelgoService.getClient();
    const possibleLocations = configProvider.getValue(POSSIBLE_DETECTOR_LOCATIONS, "array");
    const attributeMap = configProvider.getValue(ATTRIBUTE_MAP, "object") || {};
    const { bulkUpdateStateAttribute = null } = attributeMap;

    const { defaultAttributeMap: newDefaultAttributeMap = {}, customAttributeMap: newCustomAttributeMap = {} } =
      attributeMap;

    const processedAttributes = await processBulkUpdateAttributes(
      newDefaultAttributeMap,
      newCustomAttributeMap,
      BulkUpdateFeatureFunctionMap,
      { possibleDetectorLocationCategories: possibleLocations, itemClass: ["Inventory"] },
      true // filterOnly
    );

    setResetItemStateAttribute(bulkUpdateStateAttribute);
    setItemClient(xemelgoClient.getItemClient());
    setPublishClient(xemelgoClient.getPublishClient());
    setDefaultAttributeMap(processedAttributes.defaultAttributeMap);
    setCustomAttributeMap(processedAttributes.customAttributeMap);
    setPossibleDetectorLocationCategories(possibleLocations);
    setLoading(false);
  };

  const closeBannerFn = () => {
    setBannerError(false);
    setBannerMessage("");
    setShowBanner(false);
  };

  const validateBulkUpdateCSVInput = async (data, defaultAttributeMap, customAttributeMap) => {
    let valid = true;
    let errorMessage;
    let processedAttributes;

    const defaultAttributeMapWithInput = Object.keys(defaultAttributeMap).reduce((acc, attr) => {
      acc[attr] = {
        ...defaultAttributeMap[attr],
        inputValues: defaultAttributeMap[attr].validateCSVInputWithOptions ? [] : undefined
      };
      return acc;
    }, {});

    data.forEach((each) => {
      Object.keys(each).forEach((id) => {
        if (defaultAttributeMapWithInput[id] && defaultAttributeMapWithInput[id].inputValues) {
          defaultAttributeMapWithInput[id].inputValues.push(each[id]);
        }
      });
    });

    try {
      // Get options from API
      setLoading(true);
      processedAttributes = await processBulkUpdateAttributes(
        defaultAttributeMapWithInput,
        customAttributeMap,
        BulkUpdateFeatureFunctionMap,
        { possibleDetectorLocationCategories, itemClass: ["Inventory"] },
        false // filterOnly
      );
      setDefaultAttributeMap(processedAttributes.defaultAttributeMap);
      setCustomAttributeMap(processedAttributes.customAttributeMap);
    } catch (e) {
      setLoading(false);
      valid = false;
      errorMessage = `${e}`;
      return { valid, errorMessage };
    }

    data.forEach((each) => {
      Object.keys(each).forEach((id) => {
        const attributeMap = processedAttributes.defaultAttributeMap[id] || processedAttributes.customAttributeMap[id];
        if (attributeMap) {
          const { type, label, options, validateCSVInputWithOptions, required } = attributeMap;

          if (each[id]) {
            switch (type) {
              case BulkUpdateInputTypeMap.DATE_PICKER:
                const date = Date.parse(each[id]);
                if (isNaN(date)) {
                  valid = false;
                  errorMessage = `Cannot import csv file: ${each[id]} is not a valid date`;
                }
                break;
              /* for future use
               case BulkUpdateInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
                 if (typeof each[id] === 'string' && validateCSVInputWithOptions) {
                   const inputValue = each[id].trim().toUpperCase();
                   if (options && options.length) {
                     if (
                       !options.find((option) => option.label.trim().toUpperCase() === inputValue)
                     ) {
                       valid = false;
                       errorMessage = `Invalid ${label} - ${each[id]}`;
                     }
                   }
                 }
                 break;
              */
              case BulkUpdateInputTypeMap.INPUT:
                const inputValue = each[id].trim().toUpperCase();
                if (validateCSVInputWithOptions && options) {
                  if (!options[inputValue]) {
                    valid = false;
                    errorMessage = `Invalid ${label} - ${each[id]}`;
                  }
                }
                break;
              // eslint-disable-next-line
              /* For future use
               case BulkUpdateInputTypeMap.CHECK_BOX_GROUP:
               case BulkUpdateInputTypeMap.SEARCH_DROP_DOWN:
              */
              default:
                break;
            }
          } else if (required) {
            valid = false;
            errorMessage = `Cannot import csv file: One or more rows is missing the required ${label} value`;
          }
        } else {
          valid = false;
          errorMessage = "Cannot import csv file: Please check the provided data";
        }
      });
    });
    setLoading(false);
    setErrorExists(!valid);
    return { valid, errorMessage };
  };

  const onUploadCSVSubmit = async (dataList) => {
    const dataListWithStatus = [];
    let hasError = false;
    setLoading(true);

    const itemPayloadList = parseItemPayloadListForBulkUpdate(dataList);

    const locationIdToItemIdMap = {};
    for (let i = 0; i < itemPayloadList.length; i++) {
      const itemPayload = itemPayloadList[i];
      setLoadingMessage(`Updating item ${i + 1} of ${itemPayloadList.length}`);
      const processedData = { ...itemPayload };

      const { locationId, id, ...itemFields } = itemPayload;
      delete itemFields.last_known_location; // not a field to be updated
      delete itemFields.identifier; // not a field to be updated
      const updatePayload = Object.keys(itemFields).reduce((prev, key) => {
        prev[key] = itemFields[key] || null;
        return prev;
      }, {});

      try {
        if (id) {
          await itemClient.updateItemV2(id, updatePayload);
        }
        if (id && locationId) {
          if (locationIdToItemIdMap[locationId]) {
            locationIdToItemIdMap[locationId].push(id);
          } else {
            locationIdToItemIdMap[locationId] = [id];
          }
        }
      } catch (err) {
        setBannerError(true);
        setBannerMessage(err);
        hasError = true;
        processedData.errorMessage = err.errorMessage ? err.errorMessage.toString() : err.toString();
      }
      dataListWithStatus.push(processedData);
    }

    try {
      if (Object.keys(locationIdToItemIdMap).length > 0) {
        setLoadingMessage(`Updating item location`);
        for (const locationId of Object.keys(locationIdToItemIdMap)) {
          const itemIds = locationIdToItemIdMap[locationId];
          if (itemIds && itemIds.length > 0) {
            while (itemIds.length > 0) {
              const currentSlice = itemIds.splice(0, 50);
              await publishClient.publishUserEvent([...currentSlice], locationId);
            }
          }
        }
      }
    } catch (err) {
      const errMessage = err.errorMessage ? err.errorMessage.toString() : err.toString();
      setBannerError(true);
      setBannerMessage(`Error updating item location - ${errMessage}`);
      hasError = true;
    }

    const displayedErrorMessage =
      bannerMessage || "One or more items could not be updated: Please check the following item(s) and retry.";
    setBannerError(hasError);
    setBannerMessage(hasError ? displayedErrorMessage : "Item(s) updated successfully.");

    if (hasError) {
      sendMixPanelEvent(INVENTORY_BULK_UPDATE, INVENTORY_BULK_UPDATE_STEPS.BULK_UPDATE_FAILURE, {
        error_message: displayedErrorMessage
      });
    } else {
      sendMixPanelEvent(INVENTORY_BULK_UPDATE, INVENTORY_BULK_UPDATE_STEPS.BULK_UPDATE_SUCCESS);
    }

    setShowBanner(true);
    setLoading(false);
    setLoadingMessage("");

    return dataListWithStatus;
  };

  const onUploadCSVSubmitForHiscoReset = async (dataList) => {
    const dataListWithStatus = [];
    let hasError = false;
    setLoading(true);

    const itemPayloadList = parseItemPayloadListForBulkUpdate(dataList);

    for (let i = 0; i < itemPayloadList.length; i++) {
      const itemPayload = itemPayloadList[i];
      setLoadingMessage(`Updating item ${i + 1} of ${itemPayloadList.length}`);
      const processedData = { ...itemPayload };

      const { locationId, id, ...itemFields } = itemPayload;
      delete itemFields.last_known_location; // not a field to be updated. location will be upodated through user event
      delete itemFields.identifier; // not a field to be updated
      delete itemFields.state; // not a field to be updated
      const updatePayload = Object.keys(itemFields).reduce((prev, key) => {
        prev[key] = itemFields[key] || null;
        return prev;
      }, {});

      try {
        if (id) {
          await itemClient.updateItemV2(id, updatePayload);
        }
      } catch (err) {
        setBannerError(true);
        setBannerMessage(err);
        hasError = true;
        processedData.errorMessage = err.errorMessage ? err.errorMessage.toString() : err.toString();
      }
      dataListWithStatus.push(processedData);
    }

    try {
      await updateState(itemPayloadList, setLoadingMessage);
    } catch (error) {
      hasError = true;
      setBannerMessage("Error resetting items. Please reach out to xemelgo support");
    }

    const errorMessage =
      bannerMessage || "One or more items could not be updated: Please check the following item(s) and retry.";
    setBannerError(hasError);
    setBannerMessage(hasError ? errorMessage : "Item(s) updated successfully.");

    if (hasError) {
      sendMixPanelEvent(INVENTORY_BULK_UPDATE, INVENTORY_BULK_UPDATE_STEPS.BULK_RESET_FAILURE, {
        error_message: errorMessage
      });
    } else {
      sendMixPanelEvent(INVENTORY_BULK_UPDATE, INVENTORY_BULK_UPDATE_STEPS.BULK_RESET_SUCCESS);
    }

    cleanUpBulkUpdateStateSetup();

    setShowBanner(true);
    setLoading(false);
    setLoadingMessage("");

    return dataListWithStatus;
  };

  const parseItemPayloadListForBulkUpdate = (dataList) => {
    return dataList.map((eachData) => {
      const itemPayload = {};
      let attributeMap = {};
      Object.keys(eachData).forEach((id) => {
        attributeMap = defaultAttributeMap[id] || customAttributeMap[id];
        const value = eachData[id];
        const { type, transformInput, options } = attributeMap;
        let finalValue;

        switch (type) {
          case BulkUpdateInputTypeMap.DATE_PICKER:
            finalValue = value ? Date.parse(value) : undefined;
            break;
          case BulkUpdateInputTypeMap.INPUT:
            if (value && transformInput) {
              if (transformInput === "toUpperCase") {
                finalValue = value.toUpperCase();
                break;
              }
            }
            finalValue = value;
            break;
          default:
            finalValue = value;
            break;
        }

        if (
          Object.keys(customAttributeMap).find((key) => {
            return key === id;
          })
        ) {
          if (!itemPayload.customFields) {
            itemPayload.customFields = {};
          }
          itemPayload.customFields[id] = finalValue;
        } else {
          itemPayload[id] = finalValue;
        }
        switch (id) {
          case "identifier":
            if (options && finalValue && options[finalValue]) {
              itemPayload.id = options[finalValue].id;
            }
            break;
          case "last_known_location":
            if (options && finalValue && options[finalValue.trim().toUpperCase()]) {
              itemPayload.locationId = options[finalValue.trim().toUpperCase()].id;
              itemPayload.last_known_location = finalValue;
            }
            break;
          default:
            break;
        }
      });
      return itemPayload;
    });
  };

  const cleanUpBulkUpdateStateSetup = () => {
    if (isResetItemState) {
      setIsResetItemState(false);
      const defaultAttributeMapCopy = { ...defaultAttributeMap };
      delete defaultAttributeMapCopy.state;
      defaultAttributeMapCopy.last_known_location.required = false;
      setDefaultAttributeMap(defaultAttributeMapCopy);
    }
  };

  return (
    <BulkUpdateComponent
      loading={loading}
      loadingMessage={loadingMessage}
      title="Inventory"
      validCSVHeaderCheck={(data) => {
        let { valid, errorMessage } = validCSVHeaderCheck(data, defaultAttributeMap, customAttributeMap);
        const locationColumnLabel = defaultAttributeMap.last_known_location.label;
        if (
          resetItemStateAttribute &&
          data.includes(resetItemStateAttribute.label) &&
          !data.includes(locationColumnLabel)
        ) {
          valid = false;
          errorMessage = `Cannot import csv file: ${locationColumnLabel} column is missing for reset items to on-hand`;
        }

        if (!valid) {
          sendMixPanelEvent(INVENTORY_BULK_UPDATE, INVENTORY_BULK_UPDATE_STEPS.INPUT_VALIDATION_FAILURE, {
            error_message: errorMessage
          });
          setBannerError(true);
          setBannerMessage(errorMessage);
          setShowBanner(true);
        }
        return valid;
      }}
      validCSVDataCheck={async (data, isHeaderIncludeState) => {
        setShowBanner(false);
        const defaultAttributeMapCopy = { ...defaultAttributeMap };

        // if isHeaderIncludeState could be true only if resetItemStateAttribute is defined
        if (isHeaderIncludeState) {
          defaultAttributeMapCopy.state = resetItemStateAttribute;
          defaultAttributeMapCopy.last_known_location.required = true;
        }

        const { valid, errorMessage } = await validateBulkUpdateCSVInput(
          data,
          defaultAttributeMapCopy,
          customAttributeMap
        );

        if (!valid) {
          sendMixPanelEvent(INVENTORY_BULK_UPDATE, INVENTORY_BULK_UPDATE_STEPS.INPUT_VALIDATION_FAILURE, {
            error_message: errorMessage
          });
          setBannerError(!valid);
          setBannerMessage(errorMessage);
          setShowBanner(true);
        } else {
          sendMixPanelEvent(INVENTORY_BULK_UPDATE, INVENTORY_BULK_UPDATE_STEPS.INPUT_VALIDATION_SUCCESS);
        }

        return valid;
      }}
      bannerError={bannerError}
      showBanner={showBanner}
      bannerMessage={bannerMessage}
      onCloseBanner={() => {
        closeBannerFn();
      }}
      resetItemStateAttribute={resetItemStateAttribute}
      isResetItemState={isResetItemState}
      setIsResetItemState={(isResetItemState_) => {
        setIsResetItemState(isResetItemState_);
        if (isResetItemState_) {
          sendMixPanelEvent(INVENTORY_BULK_UPDATE, INVENTORY_BULK_UPDATE_STEPS.BULK_RESET_ENTRY);
        }
      }}
      defaultAttributeMap={defaultAttributeMap}
      customAttributeMap={customAttributeMap}
      onUploadCSVSubmit={onUploadCSVSubmit}
      onUploadCSVSubmitForHiscoReset={onUploadCSVSubmitForHiscoReset}
      errorExists={errorExists}
      setErrorExistsFn={setErrorExists}
      closeBannerFn={closeBannerFn}
      cleanUpBulkUpdateStateSetup={cleanUpBulkUpdateStateSetup}
    />
  );
};

export default BulkUpdateItemsFeature;
