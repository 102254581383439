import React, { useState, useEffect } from "react";
import Amplify from "aws-amplify";
import { useXemelgoClient } from "../../services/xemelgo-service";
import { getFormattedDate, uploadToS3 } from "../../common/Utilities";
import useConfigurationInfo from "../../hooks/use-configuration-info";
import { CreateReportComponent } from "../../components/reports-page-component/CreateReportComponent";
import LoadingCircle from "../../components/loading/LoadingCircle";
import { ProgressBar } from "../../components/progress-bar/ProgressBar";
import { ModalForm } from "../../components/modal-form";
import { useAppConfigProvider } from "../../services/soft-cache-service";
import ReportPageStyle from "../../components/reports-page-component/ReportsPageComponent.module.css";
import "./style.css";

import CONFIGURATION from "../../configuration.json";

const APP_ID = "reports";
const ADD_CONFIG = "createReport";

export const CreateReportModalFeature = ({ show, toggleModal }) => {
  const configProvider = useAppConfigProvider(APP_ID);
  const AddConfig = configProvider.getValue(ADD_CONFIG, "object");
  const [locationClient] = useState(useXemelgoClient().getLocationClient());
  const [itemClient] = useState(useXemelgoClient().getItemClient());
  const [bannerMessage, setBannerMessage] = useState(undefined);
  const [progressBarInfo, setProgressBarInfo] = useState({});
  const [reportClient] = useState(useXemelgoClient().getReportClient());
  const [locationConfig, setLocationConfig] = useState(["Plants", "Departments"]);
  const [locations, setLocations] = useState([]);
  const [locationIdMap, setLocationIdMap] = useState({});
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const { stage } = useConfigurationInfo();

  useEffect(() => {
    if (show) {
      onLoad();
    }
  }, [show]);

  const getTime = (time) => {
    const milliseconds = Math.floor((time % 1000) / 100);
    let seconds = Math.floor((time / 1000) % 60);
    let minutes = Math.floor((time / (1000 * 60)) % 60);
    let hours = Math.floor(time / (1000 * 60 * 60));

    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${hours} hrs ${minutes} mins ${seconds} sec ${milliseconds} ms`;
  };

  const handleChange = (id, value) => {
    const tempFormData = { ...formData };
    const tempErrors = { ...errors };
    tempFormData[id] = value;

    delete tempErrors[id];

    const { endDate, startDate } = tempFormData;
    if (endDate !== undefined && startDate !== undefined) {
      if (endDate < startDate) {
        if (id === "endDate") {
          tempErrors[id] = "End Date cannot be less than Start Date!";
        } else {
          tempErrors[id] = "Start Date cannot be greater than End Date!";
        }
      } else {
        delete tempErrors.startDate;
        delete tempErrors.endDate;
      }
    }

    setFormData(tempFormData);
    setErrors(tempErrors);
  };

  const onLoad = async () => {
    setBannerMessage(undefined);
    setLoading(true);
    setErrors({});
    setFormData({});
    const { locationCategories = ["Plant", "Department"] } = AddConfig;
    const locations = await locationClient.getLocationsOfCategory(locationCategories);
    const locIdMap = {};
    locations.forEach((location) => {
      locIdMap[location.id] = {
        name: location.name
      };
    });
    setLocationConfig(locationCategories);
    setLocationIdMap(locIdMap);
    setLocations(locations);
    setLoading(false);
  };

  const handleValidation = () => {
    const tempErrors = { ...errors };
    const { startDate, endDate, childLocation, type } = formData;
    if (!startDate) {
      tempErrors.startDate = "Start Date is required.";
    }

    if (!endDate) {
      tempErrors.endDate = "End Date is required";
    }

    if (!type) {
      tempErrors.type = "Type is required";
    }

    if (!childLocation || !childLocation.length) {
      tempErrors.childLocation = `${locationConfig[1]} is required`;
    }

    return tempErrors;
  };

  const onSubmit = async () => {
    const tempErrors = handleValidation();
    if (!Object.keys(tempErrors).length) {
      await handleCreate();
    } else {
      setErrors(tempErrors);
    }
  };

  const getSessions = async (startDate, endDate, location, batchSize, page, trackingSessionIdMap) => {
    const personIdList = [];
    let trackingSessionsResult;
    trackingSessionsResult = await reportClient.getPersonTrackingSessionsWithDetectionsInRange(
      startDate,
      endDate,
      location,
      batchSize,
      page
    );
    trackingSessionsResult.personTrackingSessions.forEach((personTrackingSession) => {
      const { tracksPerson = [], id } = personTrackingSession;
      const { given_name = "Unknown", family_name = "" } = tracksPerson[0] || {};
      personIdList.push(id);
      trackingSessionIdMap[id] = `${given_name} ${family_name}`;
    });

    return personIdList;
  };

  const createPersonsReport = async () => {
    const { startDate, endDate, childLocation } = formData;
    let page = 0;
    const batchSize = 50;
    const trackingSessionIdMap = {};
    const trackingSessionIds = [];
    const personTrackingSessionIds = [];

    // The following create report logic will be changed to consume from dynamodb
    // instead of generating reports on the fly
    const personIdList = await getSessions(startDate, endDate, childLocation, batchSize, page, trackingSessionIdMap);

    personTrackingSessionIds.push(...personIdList);

    let donePaging = personIdList.length < batchSize;

    while (!donePaging) {
      page++;
      const personIdList = await getSessions(startDate, endDate, childLocation, batchSize, page, trackingSessionIdMap);

      personTrackingSessionIds.push(...personIdList);

      donePaging = personIdList.length < batchSize;
    }

    if (personTrackingSessionIds.length) {
      page = 0;
      const eventList = [];
      const selectedLocationMap = {};

      const count = await reportClient.getEventCount(trackingSessionIds, personTrackingSessionIds);
      setProgressBarInfo({ currentCount: 0, message: `Loading 0 out of ${count} detections` });

      let trackingSessionEventsResult = await reportClient.getTrackingSessionEventsFromTrackingSessions(
        trackingSessionIds,
        personTrackingSessionIds,
        batchSize,
        page
      );
      eventList.push(...trackingSessionEventsResult.trackingSessionEvents);
      donePaging = false;
      while (!donePaging) {
        if (trackingSessionEventsResult.trackingSessionEvents.length < batchSize) {
          donePaging = true;
        } else {
          page++;
          trackingSessionEventsResult = await reportClient.getTrackingSessionEventsFromTrackingSessions(
            trackingSessionIds,
            personTrackingSessionIds,
            batchSize,
            page
          );
          setProgressBarInfo({
            currentCount: (page * batchSize * 100) / count,
            message: `Loading ${page * batchSize} out of ${count} detections`
          });
          eventList.push(...trackingSessionEventsResult.trackingSessionEvents);
        }
      }

      setProgressBarInfo({
        currentCount: 100,
        message: `Loading ${count} out of ${count} detections`
      });

      eventList.sort((a, b) => {
        return a.node_start_time - b.node_start_time;
      });

      const trackingSessionMap = {};
      eventList.forEach((event) => {
        const { partOfTrackingSession = [], partOfPersonTrackingSession = [], detectedAt = [] } = event;
        const { id } = partOfTrackingSession[0] || partOfPersonTrackingSession[0] || {};
        if (detectedAt.length) {
          const { id: detectedAtId, name } = detectedAt[0];
          if (!trackingSessionMap[id]) {
            trackingSessionMap[id] = {
              identifier: trackingSessionIdMap[id],
              location: name,
              locationId: detectedAtId,
              events: []
            };
          }
          if (!selectedLocationMap[detectedAtId] && childLocation.includes(detectedAtId)) {
            selectedLocationMap[detectedAtId] = true;
          }
          trackingSessionMap[id].events.push(event);
        }
      });

      let records = [];

      for (const id in trackingSessionMap) {
        const locationAndWorkOrder = trackingSessionMap[id];
        const { events, identifier } = locationAndWorkOrder;
        events.sort((a, b) => {
          return a.node_start_time - b.node_start_time;
        });
        let sessionStartTime = null;
        let currentLocationName = null;
        let currentLocationId = null;
        for (const event of events) {
          const { is_exit: isExitEvent, node_start_time: eventTime, detectedAt } = event;
          const locationName = detectedAt[0]?.name;
          const locationId = detectedAt[0]?.id;

          if (!isExitEvent) {
            if (
              sessionStartTime &&
              sessionStartTime > startDate &&
              sessionStartTime < endDate &&
              selectedLocationMap[currentLocationId || locationId]
            ) {
              records.push({
                startTime: sessionStartTime,
                location: currentLocationName || locationName,
                identifier
              });
            }
            sessionStartTime = eventTime;
          } else {
            // isExitEvent
            const timeDiff = sessionStartTime ? eventTime - sessionStartTime : 0;
            if (currentLocationId !== locationId) {
              if (
                sessionStartTime > startDate &&
                sessionStartTime < endDate &&
                selectedLocationMap[currentLocationId || locationId]
              ) {
                records.push({
                  startTime: sessionStartTime,
                  endTime: undefined,
                  location: currentLocationName,
                  identifier
                });
              }

              if (eventTime > startDate && eventTime < endDate && selectedLocationMap[locationId]) {
                records.push({
                  startTime: undefined,
                  endTime: eventTime,
                  location: locationName,
                  identifier
                });
              }
            } else if (
              ((sessionStartTime > startDate && sessionStartTime < endDate) ||
                (eventTime > startDate && eventTime < endDate)) &&
              selectedLocationMap[locationId]
            ) {
              records.push({
                startTime: sessionStartTime,
                endTime: eventTime,
                location: locationName,
                identifier,
                timeDifference: timeDiff > 0 ? getTime(timeDiff) : 0
              });
            }
            sessionStartTime = null;
          }
          currentLocationName = locationName;
          currentLocationId = locationId;
        }
        if (sessionStartTime > startDate && sessionStartTime < endDate && selectedLocationMap[currentLocationId]) {
          records.push({
            startTime: sessionStartTime,
            endTime: undefined,
            location: currentLocationName,
            identifier
          });
        }
      }

      records = records.sort((a, b) => {
        return a.sortTime - b.sortTime;
      });
      await generateCSV(records);
    } else {
      setBannerMessage("There was no data for the given timeframe!");
    }
  };

  const createWorkOrderReport = async () => {
    const { startDate, endDate, childLocation } = formData;
    const records = [];
    const orderIds = [];
    for (const locationId of childLocation) {
      const currentLocationInformation = await reportClient.getTimeTrackingInformationForLocation(
        startDate,
        endDate,
        locationId
      );
      const { touchTimeReport = [] } = currentLocationInformation;
      touchTimeReport.forEach((report) => {
        const location = locationIdMap[report.locationId] || {};
        const { workOrderId, session_records = [] } = report;
        session_records.forEach((sessionRecord) => {
          const timeDifference = sessionRecord.end_time - sessionRecord.start_time;
          records.push({
            id: workOrderId,
            location: location.name || "Unknown",
            startTime: sessionRecord.start_time,
            endTime: sessionRecord.end_time,
            timeDifference: timeDifference > 0 ? getTime(timeDifference) : 0
          });
        });
        orderIds.push(workOrderId);
      });
    }
    const itemResults = await itemClient.getItemsByIds([...new Set(orderIds)], ["id", "identifier"]);
    const itemIdMap = {};
    itemResults.forEach((item) => {
      itemIdMap[item.id] = item;
    });
    const finalRecords = [];
    records.forEach((record) => {
      const itemObject = itemIdMap[record.id];
      if (itemObject) {
        record.identifier = itemObject.identifier;
        finalRecords.push(record);
      }
    });
    await generateCSV(records);
  };

  const createTimeTrackingReport = async () => {
    const { startDate, endDate, childLocation } = formData;
    const records = [];
    const orderIds = [];
    for (const locationId of childLocation) {
      const currentLocationInformation = await reportClient.getTimeTrackingInformationForLocation(
        startDate,
        endDate,
        locationId
      );
      const { touchTimeReport = [] } = currentLocationInformation;
      touchTimeReport.forEach((report) => {
        const location = locationIdMap[report.locationId] || {};
        const personTouchTimes = Object.keys(report.total_touch_time);
        let touchTime = 0;
        personTouchTimes.forEach((key) => {
          touchTime += report.total_touch_time[key];
        });
        records.push({
          id: report.workOrderId,
          location: location.name,
          startTime: report.initial_start_time,
          endTime: report.last_end_time,
          timeDifference: report.total_time > 0 ? getTime(report.total_time) : 0,
          totalTouchTime: getTime(touchTime),
          activePercent: `${Math.round((touchTime * 100) / report.total_time)}%`
        });
        orderIds.push(report.workOrderId);
      });
    }
    const itemResults = await itemClient.getItemsByIds([...new Set(orderIds)], ["id", "identifier"]);
    const itemIdMap = {};
    itemResults.forEach((item) => {
      itemIdMap[item.id] = item;
    });
    const finalRecords = [];
    records.forEach((record) => {
      const itemObject = itemIdMap[record.id];
      if (itemObject) {
        record.identifier = itemObject.identifier;
        finalRecords.push(record);
      }
    });

    await generateCSV(records);
  };

  const handleCreate = async () => {
    setLoading(true);
    const { type } = formData;
    switch (type) {
      case "Work Orders":
        await createWorkOrderReport();
        break;
      case "Persons":
        await createPersonsReport();
        break;
      case "Time Tracking":
        await createTimeTrackingReport();
        break;
      default:
        break;
    }
  };

  const generateCSV = async (records) => {
    const params = {
      Bucket: CONFIGURATION.s3_report_configuration.bucket_name,
      Key: "",
      Body: ""
    };
    let fileRecords;
    let fileContent;
    let fileName;

    const currentUserInfo = await Amplify.Auth.currentUserInfo();
    const dateString = new Date().toISOString().replace(/-/g, "_").split("T")[0];

    const { attributes } = currentUserInfo;

    const tenantName = attributes["custom:tenantId"];
    const childLocation = formData.childLocation.map((each) => {
      const currLoc = locationIdMap[each] || {};
      return currLoc.name;
    });

    const folderPath = `${stage}/${tenantName}/Time Tracking/${dateString}/`;
    const locationsString = childLocation.join("_").replace(/[./,]/g, "");
    const startTimeString = getFormattedDate(formData.startDate, "MMDDYYYYHHmm");
    const endTimeString = getFormattedDate(formData.endDate, "MMDDYYYYHHmm");
    let reportType;

    if (formData.type === "Time Tracking") {
      reportType = "time_tracking";
      fileRecords = ["Work Order,Location,Start Time,End Time,Active Time,Total Time,Active %"];
      const body = records.map((element) => {
        return `${element.identifier},${element.location},${getFormattedDate(element.startTime)},${getFormattedDate(
          element.endTime
        )},${element.totalTouchTime},
          ${element.timeDifference},${element.activePercent}`.replaceAll("\n", "");
      });
      fileRecords.push(...body);
    } else {
      reportType = formData.type === "Work Orders" ? "work_order_time_tracking" : "users_time_tracking";
      fileRecords = [
        `${formData.type === "Work Orders" ? "Work Order" : "User"},Start Time,End Time,Process Time,Location`
      ];
      const body = records.map((element) => {
        return `${element.identifier},${getFormattedDate(element.startTime)},${getFormattedDate(element.endTime)},${
          element.timeDifference || "N/A"
        },${element.location}`;
      });
      fileRecords.push(...body);
    }
    fileName = `${folderPath}${reportType}_${locationsString}_starttime_${startTimeString}_endtime_${endTimeString}.csv`;
    fileContent = fileRecords.join("\r\n");
    params.Key = fileName;
    params.Body = fileContent;
    await uploadToS3(params);
    setTimeout(() => {
      toggleModal("submit");
      setLoading(false);
    }, 2000);
  };

  const closeBanner = () => {
    setBannerMessage(undefined);
  };

  const renderLoading = () => {
    if (Object.keys(progressBarInfo).length) {
      return (
        <div className={ReportPageStyle.progress_bar_container}>
          <ProgressBar
            percentage={progressBarInfo.currentCount}
            subText={progressBarInfo.message}
          />
        </div>
      );
    }
    return (
      <div className={ReportPageStyle.loading_container}>
        <LoadingCircle />
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div>
        {loading ? (
          renderLoading()
        ) : (
          <CreateReportComponent
            locations={locations}
            locationConfig={locationConfig}
            handleChange={handleChange}
            errors={errors}
            bannerMessage={bannerMessage}
            onCloseBanner={closeBanner}
          />
        )}
      </div>
    );
  };

  return (
    <ModalForm
      show={show}
      title={<div className={ReportPageStyle.create_modal_header}>Create Report</div>}
      body={renderBody()}
      footer={
        !loading ? (
          <div className={ReportPageStyle.create_modal_footer}>
            <div
              onClick={() => {
                toggleModal("cancel");
              }}
              className="cancel-button-small"
            >
              Cancel
            </div>
            <div
              onClick={onSubmit}
              className="submit-button-small"
            >
              Submit
            </div>
          </div>
        ) : undefined
      }
      className="createReportModal"
      prefix="report"
    />
  );
};
