import PropTypes from "prop-types";
import { AddPageInputTypeMap } from "../data/constants";

export const FormFieldsType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.oneOfType[("number", "string", "boolean")]
      })
    ]),
    type: PropTypes.oneOf(Object.values(AddPageInputTypeMap)),
    isHidden: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    isRequired: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string
      })
    ),
    numberOnly: PropTypes.bool
  })
);
