import React from "react";
import PropTypes from "prop-types";
import { Div } from "../../../../../components/div";
import { XButton } from "../../../../../components/xemelgo-button";
import "./style.css";
import { TextLink } from "../../../../../components/text-link";

export const AddLocationFooter = ({
  onCancel,
  onSubmit,
  showRemoveLastOperation,
  removeLastOnClick,
  addAnotherOnClick
}) => {
  return (
    <Div className="add-location-form-footer">
      <Div className="section-operations-group">
        {showRemoveLastOperation && (
          <TextLink
            label="- Remove last"
            onClick={removeLastOnClick}
            className="section-operation remove-section-operation"
          />
        )}
        <TextLink
          label="+ Add another"
          onClick={addAnotherOnClick}
          className="section-operation add-section-operation"
        />
      </Div>
      <Div className="buttons-group">
        <XButton
          label="Cancel"
          onClick={onCancel}
          className="cancel-button"
        />
        <XButton
          label="Submit"
          onClick={onSubmit}
          className="submit-button"
        />
      </Div>
    </Div>
  );
};

AddLocationFooter.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
  showRemoveLastOperation: false,
  removeLastOnClick: () => {},
  addAnotherOnClick: () => {}
};

AddLocationFooter.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  showRemoveLastOperation: PropTypes.bool,
  removeLastOnClick: PropTypes.func,
  addAnotherOnClick: PropTypes.func
};
