import React from "react";
import PropTypes from "prop-types";
import xemelgoStyle from "../../../../styles/variable";
import Selector from "../../../selector";
import Style from "../../PrinterSelectionComponent.module.css";

export const LabelSelection = ({ isLoading, templatesList, handleOnSelectTemplate, selectedLabel }) => {
  return (
    <div className={Style.body_child_container}>
      <p className={`${xemelgoStyle.subtextBold} ${Style.sub_header}`}>
        Label
        <span className={Style.asterisk}> *</span>
      </p>
      <div className={Style.dropdown_input_container}>
        <Selector
          disabled={isLoading}
          disableTabFocus
          options={templatesList.sort((a, b) => {
            return a.label.localeCompare(b.label);
          })}
          onSelect={handleOnSelectTemplate}
          value={selectedLabel}
          placeholder="Select a template"
        />
      </div>
    </div>
  );
};

const TemplateType = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  printerIP: PropTypes.string,
  template: PropTypes.string,
  type: PropTypes.string
});

LabelSelection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  templatesList: PropTypes.arrayOf(TemplateType).isRequired,
  handleOnSelectTemplate: PropTypes.func.isRequired,
  selectedLabel: PropTypes.shape({}).isRequired
};
