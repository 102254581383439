import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import FreeTextSearchInput from "../../../../../../../../components/free-text-search-input";
import useWorkOrderTrackPageDataSourceContext from "../../../../../../contexts/work-order-track-page-data-source-context";
import useDebounce from "../../../../../../../../hooks/use-debounce";
import useQueuePriorityWorkflowControlContext from "../../contexts/queue-priority-workflow-control-context";
import Style from "./AddNewLocation.module.css";
import { STEP_ID_MAP } from "../../data/constants";
import CheckboxLabel from "../../../../../../../../components/checkbox-label";
import { multipleSortComparison } from "../../../../../../../../utils";
import { ListEmpty } from "../../components/list-empty/ListEmpty";

export const AddNewLocation = ({ optionControl }) => {
  const {
    setModalHeader,
    setModalFooterButtons,
    setCurrentStep,
    selectedLocationIds,
    setSelectedLocationIds,
    setPreviousStep
  } = useQueuePriorityWorkflowControlContext();
  const { locationTreeMap } = useWorkOrderTrackPageDataSourceContext();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchInputText, setSearchInputText] = useState("");

  const searchInputTextDebounced = useDebounce(searchInputText, 250);

  const locationCategoriesList = useMemo(() => {
    if (optionControl.locationCategories.includes("all")) {
      return [
        ...new Set(
          Object.values(locationTreeMap).map(({ category }) => {
            return category;
          })
        )
      ]
        .filter((eachCategory) => {
          return (
            optionControl.locationCategories?.includes?.(eachCategory) ||
            optionControl.locationCategories?.includes("all")
          );
        })
        .sort(multipleSortComparison([{}]));
    }
    return optionControl.locationCategories;
  }, [locationTreeMap, optionControl]);

  useEffect(() => {
    setSelectedCategory(locationCategoriesList?.[0] || "");
  }, [locationCategoriesList]);

  const filteredLocationList = useMemo(() => {
    return Object.values(locationTreeMap)
      .filter(({ category, name, [optionControl.sortPreferenceKey]: sortPreferenceAttributeListString }) => {
        return (
          !sortPreferenceAttributeListString &&
          selectedCategory === category &&
          name?.toLowerCase()?.includes(searchInputText?.toLowerCase())
        );
      })
      .sort(multipleSortComparison([{ id: "name" }]));
  }, [locationTreeMap, selectedCategory, searchInputTextDebounced]);

  const header = () => {
    return (
      <div>
        <div className={Style.header_text}>Setting up a sort preference</div>
        <p className={Style.header_subtext}>Select where you would like to set up the sort preference</p>
      </div>
    );
  };

  useEffect(() => {
    setModalHeader(header);
    setModalFooterButtons([
      {
        id: "back",
        title: "Back",
        onClick: () => {
          setCurrentStep(STEP_ID_MAP.allLocationList);
          setSelectedLocationIds([]);
        }
      },
      {
        id: "next",
        title: "Next",
        onClick: () => {
          setPreviousStep(STEP_ID_MAP.addNewLocation);
          setCurrentStep(STEP_ID_MAP.editSortPreference);
        },
        disabled: !selectedLocationIds?.length,
        className: Style.save_button
      }
    ]);
  }, [selectedLocationIds]);

  return (
    <div className={Style.content}>
      <div className={Style.location_category_list_container}>
        <p className={Style.label}>Choose which locations you want to setup for sort preference for</p>
        <div className={Style.location_category_list}>
          {locationCategoriesList.map((eachLocationCategory) => {
            return (
              <CheckboxLabel
                key={eachLocationCategory}
                label={eachLocationCategory}
                rounded
                isChecked={selectedCategory === eachLocationCategory}
                onClick={() => {
                  setSelectedLocationIds([]);
                  setSelectedCategory(eachLocationCategory);
                }}
              />
            );
          })}
        </div>
      </div>
      <div className={Style.locations_list_container}>
        <div className={Style.free_text_search_container}>
          <FreeTextSearchInput
            placeholder="Search"
            value={searchInputText}
            onChangeText={setSearchInputText}
          />
        </div>
        <div className={Style.locations_list}>
          {filteredLocationList.length ? (
            filteredLocationList.map((eachLocation) => {
              return (
                <CheckboxLabel
                  key={eachLocation.id}
                  isChecked={selectedLocationIds.includes(eachLocation.id)}
                  onClick={() => {
                    if (selectedLocationIds.includes(eachLocation.id)) {
                      setSelectedLocationIds(
                        selectedLocationIds.filter((selectedLocationId) => {
                          return selectedLocationId !== eachLocation.id;
                        })
                      );
                    } else {
                      setSelectedLocationIds([...selectedLocationIds, eachLocation.id]);
                    }
                  }}
                  label={eachLocation.name}
                />
              );
            })
          ) : (
            <ListEmpty title="No locations match your search" />
          )}
        </div>
      </div>
    </div>
  );
};

AddNewLocation.defaultProps = {
  optionControl: {},
  onModalClose: () => {}
};

AddNewLocation.propTypes = {
  onModalClose: PropTypes.func,
  optionControl: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    locationCategories: PropTypes.arrayOf(PropTypes.string),
    sortPreferenceKey: PropTypes.string,
    sortPreferenceAttributeMap: PropTypes.object,
    sortPreferenceSections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        attributes: PropTypes.arrayOf(PropTypes.string)
      })
    )
  })
};
