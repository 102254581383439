import React, { useState, useCallback, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import useManagementDashboardConfigContext from "../../contexts/management-dashboard-config-context";
import Style from "./SearchBox.module.css";
import SearchDropdownStyle from "../../../../components/SearchDropdown/SearchDropdown.module.css";
import SearchDropdown from "../../../../components/SearchDropdown/SearchDropdown";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import useDebounce from "../../../../hooks/use-debounce";
import useManagementDashboardDataSourceContext from "../../contexts/management-dashboard-data-source-context";
import useManagementDashboardStateContext from "../../contexts/management-dashboard-state-context";
import { SEARCH_TYPE_MAP } from "../../data/constants";
import { naturalSort } from "../../../../common/Utilities";
import {
  MANAGEMENT_DASHBOARD_EVENT,
  MANAGEMENT_DASHBOARD_STEPS
} from "../../../../constants/mixpanel-constant/managementDashboard";
import useMixpanelContext from "../../../../context/mixpanel-context";

export const SearchBox = () => {
  const { setSearchResults, searchResults, setSearchWorkOrderResults } = useManagementDashboardDataSourceContext();
  const { selectedSearchResult, setSelectedSearchResult, setIsWorkOrderResultsLoading, setShowWorkOrderResults } =
    useManagementDashboardStateContext();
  const { searchControl } = useManagementDashboardConfigContext();
  const { sendMixPanelEvent } = useMixpanelContext();

  const xemelgoClient = useXemelgoClient();

  const [managementDashboardClient] = useState(xemelgoClient.getManagementDashboardClient());
  const [searchType, setSearchType] = useState({ ...searchControl?.[0] });
  const [searchString, setSearchString] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);

  const searchStringDebounce = useDebounce(searchString, 500);

  const searchFn = useCallback(
    async (searchText) => {
      sendMixPanelEvent(MANAGEMENT_DASHBOARD_EVENT, MANAGEMENT_DASHBOARD_STEPS.SEARCH_ATTEMPTED, {
        search_type: searchType.id,
        search_text: searchText
      });
      setSearchLoading(true);
      let response = [];
      switch (searchType?.id) {
        case SEARCH_TYPE_MAP.item_type.id:
          response = await managementDashboardClient.searchInputPartItemTypesByIdentifier(searchText);
          break;
        case SEARCH_TYPE_MAP.work_order.id:
        default:
          response = await managementDashboardClient.searchWorkOrdersByIdentifier(searchText);
          break;
      }
      setSearchResults(
        naturalSort(
          response.map(({ id, identifier }) => {
            return { id, label: identifier, value: id };
          }),
          "label"
        )
      );
      setSearchLoading(false);
    },
    [searchType]
  );

  useEffect(() => {
    setSearchType({ ...searchControl?.[0] });
  }, [searchControl]);

  useEffect(() => {
    setSearchString("");
    setSearchResults([]);
    setSelectedSearchResult({});
  }, [searchType]);

  useEffect(() => {
    setSearchResults([]);
    if (searchStringDebounce) {
      setSearchLoading(true);
      searchFn(searchStringDebounce);
    }
  }, [searchStringDebounce]);

  useEffect(() => {
    if (selectedSearchResult.id) {
      setIsWorkOrderResultsLoading(true);
      setShowWorkOrderResults(true);
      switch (searchType?.id) {
        case SEARCH_TYPE_MAP.item_type.id:
          managementDashboardClient.getWorkOrdersByInputPartItemTypeId(selectedSearchResult.id).then((data) => {
            setSearchWorkOrderResults(data);
            setIsWorkOrderResultsLoading(false);
          });
          break;
        case SEARCH_TYPE_MAP.work_order.id:
        default:
          managementDashboardClient.getWorkOrderByTaskId(selectedSearchResult.id).then((data) => {
            setSearchWorkOrderResults(data);
            setIsWorkOrderResultsLoading(false);
          });

          break;
      }
    }
  }, [selectedSearchResult, searchType]);

  const clearSearchResult = () => {
    setSelectedSearchResult({});
    setShowWorkOrderResults(false);
    setSearchWorkOrderResults([]);
    setSearchResults([]);
  };

  if (!searchControl?.length) {
    return null;
  }

  return (
    <div className={Style.container}>
      <div className={Style.search_type_container}>
        <SearchDropdown
          showIcon
          withoutOptionFilter
          options={searchControl}
          selectedItem={searchType}
          onItemSelected={(type) => {
            clearSearchResult();
            setSearchType(type);
          }}
          textareaClassName={Style.search_type_textarea}
        />
      </div>
      <div className={`${Style.search_container}`}>
        <SearchDropdown
          options={searchResults}
          inputValue={selectedSearchResult.id ? selectedSearchResult.label : searchString}
          onChangeText={(text) => {
            if (!text) {
              setSearchString(text);
              setShowWorkOrderResults(false);
            }
            if (text !== selectedSearchResult.label) {
              setSelectedSearchResult({});
              setSearchString(text);
              setShowWorkOrderResults(false);
            }
          }}
          selectedItem={selectedSearchResult}
          textareaClassName={Style.search_textarea}
          renderContent={(data, blurFunction) => {
            if (searchLoading) {
              return (
                <div style={{ padding: "10px 20px" }}>
                  {[...Array(3)].map((_, index) => {
                    return <Skeleton key={index} />;
                  })}
                </div>
              );
            }
            if (data.length) {
              return data.map((item, index) => {
                const { id, label } = item;
                return (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <li
                    key={`${id}_${index}`}
                    className={SearchDropdownStyle.dropdown_item}
                    onClick={() => {
                      sendMixPanelEvent(MANAGEMENT_DASHBOARD_EVENT, MANAGEMENT_DASHBOARD_STEPS.SEARCH_SUCCESS);
                      setSelectedSearchResult(item);
                      blurFunction();
                    }}
                  >
                    {label}
                  </li>
                );
              });
            }
            return (
              <li className={SearchDropdownStyle.dropdown_no_result_message}>
                {!searchString ? `Please enter ${searchType.label} to search` : "No matching results"}
              </li>
            );
          }}
        />
      </div>
    </div>
  );
};
