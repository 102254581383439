import React, { useState, useEffect, useMemo } from "react";
import { cloneDeep } from "lodash";
import Style from "./PopupFilter.module.css";
import { ReactComponent as FilterIcon } from "../../../../assets/icons/filter.svg";
import { ReactComponent as ChevDownIcon } from "../../../../assets/icons/chev-down.svg";
import Filters, { useFiltersConfigBuilder, FILTER_TYPE } from "../../../../components/filters";
import useAssetMapConfigContext from "../../contexts/asset-map-config-context";
import useAssetMapStateContext from "../../contexts/asset-map-state-context";
import SelectedFiltersControl from "../../../../components/selected-filters-control";

export const PopupFilter = () => {
  const { popupFilterControl } = useAssetMapConfigContext();
  const { filterValue, setFilterValue, setSelectedAssetTypeSet } = useAssetMapStateContext();
  const { filterConfiguration, initialFilterValue, isLoading } = useFiltersConfigBuilder(popupFilterControl);

  const [showMenu, setShowMenu] = useState(false);
  const [currentFiltersValue, setCurrentFiltersValue] = useState(initialFilterValue);

  const selectedFilters = useMemo(() => {
    return popupFilterControl.reduce((accumulator, eachFilterControl) => {
      const { id, type } = eachFilterControl || {};

      const eachFilterValue = currentFiltersValue?.[id];

      switch (type) {
        case FILTER_TYPE.checkboxes:
          return [
            ...accumulator,
            ...(
              (eachFilterValue || []).filter(({ value }) => {
                return value;
              }) || []
            ).map(({ id: filterId, label: value }) => {
              return { filterKey: id, id: filterId, value };
            })
          ];
        case FILTER_TYPE.searchDropdown:
          if (Object.keys(eachFilterValue?.value || {}).length) {
            accumulator.push({
              filterKey: id,
              value: eachFilterValue.value.label
            });
          }
          return accumulator;
        default:
          return accumulator;
      }
    }, []);
  }, [currentFiltersValue, popupFilterControl]);

  useEffect(() => {
    setCurrentFiltersValue(cloneDeep(filterValue || initialFilterValue));
  }, [initialFilterValue, filterValue, showMenu]);

  if (!popupFilterControl?.length) {
    return null;
  }

  return (
    <div className={Style.container}>
      <button
        type="button"
        className={Style.filter_button}
        onClick={() => {
          setShowMenu((currentValue) => {
            return !currentValue;
          });
        }}
      >
        <FilterIcon />
        <p className={Style.filter_button_text}>
          {`Filters ${selectedFilters.length > 0 ? `(${selectedFilters.length})` : ""}`}
        </p>
        <ChevDownIcon />
      </button>
      {showMenu && (
        <div className={Style.filter_menu}>
          <div className={Style.menu_content}>
            <div className={Style.menu_header_container}>
              <div className={Style.menu_title_container}>
                <p className={Style.menu_title_text}>Filter By</p>
                <p className={Style.menu_title_subtext}>
                  {`(${selectedFilters.length || 0} filter${selectedFilters.length === 1 ? "" : "s"} selected)`}
                </p>
              </div>
              <button
                type="button"
                className={Style.clear_all_text_button}
                onClick={() => {
                  setCurrentFiltersValue(cloneDeep(initialFilterValue));
                }}
              >
                Clear All
              </button>
            </div>
            {selectedFilters.length > 0 && (
              <div className={Style.selected_filters_container}>
                <SelectedFiltersControl
                  title=""
                  filters={selectedFilters}
                  showClearAll={false}
                  onRemoveFilter={(filter) => {
                    const { filterKey, id } = filter;
                    const newFilter = (cloneDeep(currentFiltersValue)[filterKey] || []).map((eachFilter) => {
                      if (eachFilter.id === id) {
                        return { ...eachFilter, value: false };
                      }
                      return eachFilter;
                    });
                    setCurrentFiltersValue({
                      ...currentFiltersValue,
                      [filterKey]: newFilter
                    });
                  }}
                />
              </div>
            )}
            <div>
              <Filters
                isLoading={isLoading}
                filterConfiguration={filterConfiguration}
                initialFilterValues={{ ...currentFiltersValue }}
                onFilterChange={({ allValues }) => {
                  setCurrentFiltersValue(allValues);
                }}
              />
            </div>
          </div>
          <div className={Style.menu_buttons_container}>
            <button
              type="button"
              className={`${Style.button_base} ${Style.cancel_button}`}
              onClick={() => {
                setShowMenu(false);
                setCurrentFiltersValue(cloneDeep(initialFilterValue));
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className={`${Style.button_base} ${Style.apply_button}`}
              onClick={() => {
                setShowMenu(false);
                setFilterValue(currentFiltersValue);

                const selectedAssetTypeSet = new Set();
                currentFiltersValue.itemType.forEach((filter) => {
                  const { id, value } = filter;
                  if (id && value) {
                    selectedAssetTypeSet.add(id);
                  }
                });
                setSelectedAssetTypeSet(selectedAssetTypeSet);
              }}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
