import React, { useMemo } from "react";
import PrintTagsModal from "../../../print-tags-modal";
import SetLocationModal from "../../features/set-location-modal";
import MarkAsCompleteModal from "../../features/mark-as-complete-modal";

export const useMultiSelectActionFactory = (allOrders, orderSelection, action, onModalClose) => {
  const selectedItems = useMemo(() => {
    return allOrders.filter((order) => {
      return orderSelection[order.id];
    });
  }, [orderSelection, allOrders]);

  const orderIds = useMemo(() => {
    return Object.keys(orderSelection || {}).filter((key) => {
      return !!orderSelection[key];
    });
  }, [orderSelection]);

  const ActionComponent = () => {
    switch (action.id) {
      case "printTags":
        return (
          <PrintTagsModal
            onCloseModal={onModalClose}
            selectedItems={selectedItems}
            printTagsOptions={action}
            appId="order"
          />
        );
      case "setLocation":
        const { locationCategory = "Department" } = action;
        return (
          <SetLocationModal
            onClose={onModalClose}
            locationCategory={locationCategory}
            orderIds={orderIds}
          />
        );
      case "markAsComplete":
        return (
          <MarkAsCompleteModal
            onClose={onModalClose}
            orderIds={orderIds}
          />
        );
      default:
        return null;
    }
  };

  return { MultiSelectActionComponent: ActionComponent };
};
