import { useStore } from "react-hookstore";
import { configStore } from "../state-managements/stores/config-store";
import { userProfileStore } from "../state-managements/stores/user-profile-store";

/**
 * @returns { UserConfiguration }
 */
export const useConfigurationProvider = () => {
  const [userConfig] = useStore(configStore);
  return userConfig;
};

/**
 * Return app-configuration provider from cache
 * @param appId
 * @returns { AppConfigurationProvider }
 */
export const useAppConfigProvider = (appId) => {
  const userConfig = useConfigurationProvider();
  return userConfig?.getAppConfigurationProvider(appId);
};

/**
 * Return feature config provider from given app and feature ids.
 * @param appId
 * @param featureId
 * @param defaultConfiguration
 * @returns {FeatureConfigurationProvider}
 */
export const useFeatureConfigProvider = (appId, featureId, defaultConfiguration = {}) => {
  const userConfig = useConfigurationProvider();
  return userConfig.getFeatureConfigurationProvider(appId, featureId, defaultConfiguration);
};

/**
 *
 * @param appId
 * @param featureId
 * @param subFeatureId
 * @returns { FeatureConfigurationProvider }
 */
export const useSubFeatureConfigProvider = (appId, featureId, subFeatureId) => {
  const userConfig = useConfigurationProvider();
  return userConfig.getSubFeatureConfigurationProvider(appId, featureId, subFeatureId);
};

export const useSoftCacheService = () => {
  const [, setUserConfig] = useStore(configStore);
  const [userProfile, setUserProfile] = useStore(userProfileStore);

  return {
    clearCaches: () => {
      setUserConfig(null);
      setUserProfile(null);
    },

    getUserProfile: () => {
      return userProfile;
    }
  };
};
