import React, { useMemo, useEffect } from "react";
import { useReportsPageContext } from "../../context/reportsPageContext";
import Style from "./ReportTypePicker.module.css";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";

const APP_ID = "reports";

const ReportTypePicker = ({ defaultReportType }) => {
  const { selectedTypeFilter, setSelectedTypeFilter, selectedClassFilter } = useReportsPageContext();
  const configProvider = useAppConfigProvider(APP_ID);
  const reportGroups = configProvider.getValue("reportGroups", "object") || {};

  useEffect(() => {
    if (selectedClassFilter) {
      setSelectedTypeFilter(defaultReportType || reportGroups[selectedClassFilter][0]);
    }
  }, [selectedClassFilter, reportGroups]);

  const typeList = useMemo(() => {
    return reportGroups[selectedClassFilter] || [];
  }, [selectedClassFilter, reportGroups]);

  return (
    <div className={`${Style.picker_container}`}>
      <p>Type:</p>
      {typeList.map((eachType) => {
        return (
          <button
            key={eachType.id}
            type="button"
            className={`${Style.type_button} ${selectedTypeFilter.id === eachType.id && Style.type_button_selected}`}
            onClick={() => {
              setSelectedTypeFilter(eachType);
            }}
          >
            {eachType.label}
          </button>
        );
      })}
    </div>
  );
};

export default ReportTypePicker;
