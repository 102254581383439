import { getCurrentTimestamp, getFormattedDate } from "../../../../common/Utilities";

const TAG_BLOCK_SIZE = 8;

export const generateTags = () => {
  const timestamp = getCurrentTimestamp();
  const date = getFormattedDate(timestamp, "YYMMDD");

  const paddingLength = TAG_BLOCK_SIZE - (`${date}${timestamp}1`.length % TAG_BLOCK_SIZE);
  const paddedString = "0".repeat(paddingLength) + 1;
  return `${date}${timestamp}${paddedString}`;
};
