import React, { useEffect, useMemo, useState } from "react";
import { ModalForm } from "../../../../../components/modal-form";
import Style from "../WorkOrderTable.module.css";
import "../WorkOrderTable.css";
import SearchDropDown from "../../../../../components/SearchDropdown/SearchDropdown";
import { DatePicker } from "../../../../../components/DateTimePicker/DateTimePicker";
import PropTypes from "prop-types";
import { useScheduleManagementContext } from "../../../context/ScheduleManagementContext.context";
import { Tooltip } from "../../../../../components/tool-tip/Tooltip";

export const EditScheduleModal = ({ selectedWorkOrders, showModal, onDiscardClick, onSubmitClick }) => {
  const OVERFLOW_LENGTH = 54;
  const { currentWorkCenterMap } = useScheduleManagementContext();
  const [selectedWorkCenter, setSelectedWorkCenter] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);

  const selectedWorkOrdersNumber = useMemo(() => {
    return Object.values(selectedWorkOrders)
      .map((selectedWorkOrder) => {
        return selectedWorkOrder.identifier;
      })
      .join(", ");
  }, [selectedWorkOrders]);

  const renderBodyModal = () => {
    return (
      <div className={Style.modal_body}>
        <p className={Style.modal_text}>You may update the date and work center for the selected work orders:</p>
        <div className={Style.modal_list_text}>
          {selectedWorkOrdersNumber.length > OVERFLOW_LENGTH ? (
            <Tooltip
              text={selectedWorkOrdersNumber}
              tooltipContainerClassName={Style.tooltip_container}
            >
              <div className={Style.edit_items_overflow}>{selectedWorkOrdersNumber}</div>
            </Tooltip>
          ) : (
            <span className={Style.bolded_text}>{selectedWorkOrdersNumber}</span>
          )}
        </div>
        <div className={`${Style.input_group_list} ${Style.grid}`}>
          <div className={Style.modal_item}>
            <p className={Style.bolded_text}>Scheduled Date</p>
            <DatePicker
              value={selectedDate}
              onTimeChange={(timestamp) => {
                setSelectedDate(timestamp);
              }}
              inputClassName={Style.date_picker}
            />
          </div>
          <div className={Style.modal_item}>
            <p className={Style.bolded_text}>Work Center</p>
            <SearchDropDown
              showIcon
              options={Object.values(currentWorkCenterMap)}
              selectedItem={selectedWorkCenter}
              onItemSelected={(item) => {
                setSelectedWorkCenter(item);
              }}
              textareaClassName={Style.search_dropdown}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderFooterModal = () => {
    return (
      <div className={`${Style.modal_footer} ${Style.flex_row}`}>
        <button onClick={onDiscardClick}>Discard</button>
        <button
          disabled={!selectedDate && !Object.keys(selectedWorkCenter).length}
          onClick={() => {
            onSubmitClick(selectedDate, selectedWorkCenter);
            setSelectedDate(null);
            setSelectedWorkCenter({});
          }}
        >
          Submit
        </button>
      </div>
    );
  };

  return (
    <ModalForm
      prefix="edit-schedule"
      title="Edit Schedule"
      show={showModal}
      body={renderBodyModal()}
      footer={renderFooterModal()}
      centered
      className={`${Style.edit_schedule_modal}`}
    />
  );
};

EditScheduleModal.propTypes = {
  selectedWorkOrders: PropTypes.arrayOf(PropTypes.object),
  showModal: PropTypes.bool,
  onDiscardClick: PropTypes.func,
  onSubmitClick: PropTypes.func
};

EditScheduleModal.defaultProps = {
  selectedWorkOrders: [],
  showModal: false,
  onDiscardClick: () => {},
  onSubmitClick: () => {}
};
