import { VALUE_TYPE_MAP } from "../../../../../components/paginated-list-table-with-types/data/constants";

export const DEFAULT_ROUTE_TABLE_HEADERS = [
  { id: "id", label: "Location" },
  {
    id: "startDate",
    label: "Entry Time",
    type: VALUE_TYPE_MAP.dateTime,
    timeFormat: "MMM D, h:mm A",
    defaultSort: true,
    defaultDirection: "desc"
  },
  {
    id: "endDate",
    label: "Exit Time",
    type: VALUE_TYPE_MAP.dateTime,
    timeFormat: "MMM D, h:mm A"
  },
  { id: "duration", label: "Time at Location", type: VALUE_TYPE_MAP.duration },
  { id: "timeToNext", label: "Time to Next Location", type: VALUE_TYPE_MAP.duration }
];
