import React, { useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { KeyboardArrowDown } from "@material-ui/icons";
import Style from "./MultiSelectDropown.module.css";
import AutoSizeTextArea from "../AutoSizeTextArea/AutoSizeTextArea";
import useOnClickOutside from "../../hooks/use-on-click-outside";
import { Checkbox } from "../checkbox-component/Checkbox";
import { pluralizeWord } from "../../common/Utilities";
import SelectedOptionsList from "../selected-options-list";

export const MultiSelectDropown = ({ options, selectedOptions, onOptionClick, placeholder, optionName, disabled }) => {
  const dropdownRef = useRef(null);
  const [inputText, setInputText] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  useOnClickOutside(dropdownRef, () => {
    setIsFocused(false);
    setInputText("");
  });

  const filteredOptions = useMemo(() => {
    if (!inputText) {
      return options;
    }
    return options.filter((option) => {
      return option.label.toLowerCase().includes(inputText.toLowerCase());
    });
  }, [options, inputText]);

  return (
    <div
      ref={dropdownRef}
      className={Style.container}
    >
      <AutoSizeTextArea
        value={inputText}
        onChangeText={(text) => {
          setInputText(text);
        }}
        onFocus={() => {
          setInputText("");
          setIsFocused(true);
        }}
        placeholder={placeholder}
        disabled={disabled}
        textareaClassName={Style.textarea}
      />
      {isFocused && (
        <div className={Style.dropdown_container}>
          <div className={Style.dropdown}>
            {filteredOptions.length === 0 && (
              <div className={Style.dropdown_item}>
                <div className={Style.no_results_text}>{`No ${pluralizeWord(optionName)} found`}</div>
              </div>
            )}
            {filteredOptions.length > 0 &&
              filteredOptions.map((option) => {
                const optionIndex = selectedOptions.findIndex((selectedOption) => {
                  return selectedOption.label === option.label;
                });
                const isSelected = optionIndex !== -1;

                return (
                  <div
                    key={option.label}
                    className={`${Style.dropdown_item} ${isSelected ? Style.selected_dropdown_item : ""}`}
                    onClick={() => {
                      const newSelectedOptions = [...selectedOptions];
                      if (optionIndex === -1) {
                        newSelectedOptions.push(option);
                      } else {
                        newSelectedOptions.splice(optionIndex, 1);
                      }

                      onOptionClick(newSelectedOptions);
                    }}
                  >
                    <Checkbox isChecked={isSelected} />
                    <div>{option.label}</div>
                  </div>
                );
              })}
            {selectedOptions.length > 0 && (
              <div className={Style.selected_count_container}>
                <p>
                  {`${selectedOptions.length} ${
                    selectedOptions.length > 1 ? pluralizeWord(optionName) : optionName
                  } selected`}
                </p>
                <div
                  className={Style.clear_all_text}
                  onClick={() => {
                    onOptionClick([]);
                  }}
                >
                  Clear
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {!disabled && (
        <div className={Style.icon_container}>
          <KeyboardArrowDown
            className={Style.icon}
            onClick={() => {
              setIsFocused(true);
            }}
          />
        </div>
      )}
      {selectedOptions.length > 0 && (
        <div className={`${Style.options_list_container} ${disabled ? Style.options_list_disabled : ""}`}>
          <SelectedOptionsList
            options={selectedOptions}
            onRemoveOption={(option) => {
              const newSelectedOptions = selectedOptions.filter((selectedOption) => {
                return selectedOption.label !== option.label;
              });

              onOptionClick(newSelectedOptions);
            }}
          />
        </div>
      )}
    </div>
  );
};

MultiSelectDropown.defaultProps = {
  options: [],
  selectedOptions: [],
  onOptionClick: () => {},
  placeholder: "Select...",
  optionName: "option",
  disabled: false
};

MultiSelectDropown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.any })),
  selectedOptions: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.any })),
  onOptionClick: PropTypes.func,
  placeholder: PropTypes.string,
  optionName: PropTypes.string,
  disabled: PropTypes.bool
};
