import React from "react";
import Style from "./LoadingCircle.module.css";
import PropTypes from "prop-types";

const LoadingCircle = ({ message, messageStyle = null, shouldCenter, isSmall }) => {
  return (
    <div className={shouldCenter ? Style.container_scaled : Style.container}>
      <div className={isSmall ? Style.lds_roller_scaled : Style.lds_roller}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
      <div className={messageStyle}>{message}</div>
    </div>
  );
};

export default LoadingCircle;

LoadingCircle.defaultProps = {
  message: "",
  messageStyle: null,
  shouldCenter: false,
  isSmall: false
};

LoadingCircle.propTypes = {
  message: PropTypes.string,
  messageStyle: PropTypes.object,
  shouldCenter: PropTypes.bool,
  isSmall: PropTypes.bool
};
