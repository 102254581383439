import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { STEP_ID_MAP } from "../../data/constants";

const QueuePriorityWorkflowControlContext = React.createContext();

export const useQueuePriorityWorkflowControlContext = () => {
  return useContext(QueuePriorityWorkflowControlContext);
};

export const QueuePriorityWorkflowControlContextProvider = ({ children }) => {
  const [previousStep, setPreviousStep] = useState("");
  const [currentStep, setCurrentStep] = useState(STEP_ID_MAP.allLocationList);
  const [modalHeader, setModalHeader] = useState(null);
  const [modalFooterButtons, setModalFooterButtons] = useState([]);

  const [selectedLocationIds, setSelectedLocationIds] = useState([]);

  return (
    <QueuePriorityWorkflowControlContext.Provider
      value={{
        previousStep,
        setPreviousStep,
        currentStep,
        setCurrentStep,
        modalHeader,
        setModalHeader,
        modalFooterButtons,
        setModalFooterButtons,
        selectedLocationIds,
        setSelectedLocationIds
      }}
    >
      {children}
    </QueuePriorityWorkflowControlContext.Provider>
  );
};

QueuePriorityWorkflowControlContextProvider.defaultProps = {
  children: null
};

QueuePriorityWorkflowControlContextProvider.propTypes = {
  children: PropTypes.element
};
