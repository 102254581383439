import { SingleSignOnDetectionDomainsWithCustomProviderMap } from "../../configuration.json";

export const isValidFederatedSignInEmail = (email) => {
  const regexDomainList = Object.keys(SingleSignOnDetectionDomainsWithCustomProviderMap).map((eachDomain) => {
    return new RegExp(`[a-zA-Z0-9+]*@${eachDomain}$`);
  });

  return regexDomainList.some((regex) => {
    return regex.test(email);
  });
};
