import React from "react";
import PropTypes from "prop-types";
import { Row } from "mdbreact";
import misroute_icon from "img/misroute_icon.png";
import missingItem_icon from "img/missingItem_icon.png";
import blue_circle from "img/blue_circle.png";
import OptionalLink from "components/optional-link/OptionalLink";
import Style from "./ItemTile.module.css";
import { sortByPriorityMap } from "../../common/Utilities";
import { useMixpanelContext } from "../../context/mixpanel-context/MixpanelContext";
import {
  SHIPMENT_TRACK_PAGE_V1_EVENT,
  SHIPMENT_TRACK_PAGE_V1_STEPS
} from "../../constants/mixpanel-constant/shipmentTrackpageV1";

const alertIconMap = {
  missingItem: missingItem_icon,
  misroutedItem: misroute_icon
};

const getAlertIcon = (alertType) => {
  return alertIconMap[alertType];
};

const getAlertIconContainer = (alertStatus) => {
  return (
    <div className={Style.alert_icon_container}>
      {alertStatus && (
        <img
          className={Style.alert_icon}
          src={getAlertIcon(alertStatus)}
          alt="!"
        />
      )}
    </div>
  );
};

const getRecentItemIndicationContainer = (isRecentlyScannedItem) => {
  return (
    isRecentlyScannedItem && (
      <div className={Style.alert_icon_container}>
        <img
          className={Style.recent_item_indicator}
          src={blue_circle}
          alt="!"
        />
      </div>
    )
  );
};

const getStatusFlagsContainer = (statusFlags) => {
  return (
    <div className={Style.statusFlags_container}>
      {statusFlags &&
        statusFlags.length > 0 &&
        statusFlags.map((status, index) => {
          return (
            <p
              key={index}
              className={Style.status_text}
              style={{
                color: status.color
              }}
            >
              {status.displayText}
              {index < statusFlags.length - 1 ? ` ⁃ ` : ""}
            </p>
          );
        })}
    </div>
  );
};

const getDataRow = (data) => {
  return (
    <Row
      md="4"
      key={data.itemIdentifier}
      className={Style.row}
    >
      {data.itemDataDisplayConfigMap.itemDataDisplayList.map((item, index) => {
        switch (item.type) {
          case "image":
            const image = data[item.key];
            return (
              <div
                key={index}
                className={Style.data_item_div}
              >
                {image && (
                  <img
                    className={Style.data_item_value}
                    style={item.style}
                    src={image}
                    alt="failed to load img"
                  />
                )}
              </div>
            );

          case "status":
            let sortedStatuses = [];
            if (data[item.key] && data[item.key].length) {
              sortedStatuses = data[item.key].sort((a, b) => {
                return a.priority < b.priority ? 1 : -1;
              });
              return (
                <div
                  key={index}
                  className={Style.data_item_div}
                >
                  <p
                    className={Style.data_item_value}
                    style={item.style}
                  >
                    {sortedStatuses.map((status) => {
                      return (
                        <p
                          style={{
                            color: status.color,
                            "margin-bottom": "3px"
                          }}
                        >
                          {status.displayText}
                        </p>
                      );
                    })}
                  </p>
                </div>
              );
            }
            return null;
          default:
            return (
              <div
                key={index}
                className={Style.data_item_div}
              >
                <p className={Style.data_item_title}>{item.header || ""}</p>
                <p
                  className={Style.data_item_value}
                  style={item.style}
                >
                  {data[item.key] || "-"}
                </p>
              </div>
            );
        }
      })}
    </Row>
  );
};

const ItemTile = ({ data }) => {
  const { sendMixPanelEvent } = useMixpanelContext();
  const onClick = () => {
    sendMixPanelEvent(SHIPMENT_TRACK_PAGE_V1_EVENT, SHIPMENT_TRACK_PAGE_V1_STEPS.NAVIGATED_TO_DETAIL_PAGE);
  };

  const OptionalLinkStyle = {
    backgroundColor: data.color || "white",
    borderLeft: `1.5em solid ${data.statusFlagMapList.length && data.statusFlagMapList[0].color}`,
    borderRight: `2px solid ${data.statusFlagMapList.length && data.statusFlagMapList[0].color}`,
    borderTop: `2px solid ${data.statusFlagMapList.length && data.statusFlagMapList[0].color}`,
    borderBottom: `2px solid ${data.statusFlagMapList.length && data.statusFlagMapList[0].color}`
  };

  return (
    data && (
      <OptionalLink
        className={`
          ${Style.display_block}
          ${Style.cell}
        `}
        key={data.id}
        active
        route={data.detailsPageLink}
        onClick={onClick}
        style={OptionalLinkStyle}
      >
        {getStatusFlagsContainer(data.statusFlagMapList)}
        {getRecentItemIndicationContainer(data.isRecentlyScannedItem)}
        {getAlertIconContainer(data.alertStatus)}
        {getDataRow(data)}
      </OptionalLink>
    )
  );
};

export default ItemTile;

ItemTile.defaultProps = {
  data: undefined
};

ItemTile.propTypes = {
  data: PropTypes.object
};
