import { Form } from "reactstrap";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Div } from "../../../../../components/div";
import "./style.css";
import { FlexibleInput } from "../../../../../components/flexible-input";

export const EditFormBody = ({ properties, resource, submitTicket, onSubmit }) => {
  const [payload, setPayload] = useState({});

  /**
   * Name: handleSubmit
   */
  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!submitTicket) {
      return cancelCallback;
    }

    if (!cancelled) {
      onSubmit({ ...payload, id: resource.id });
    }

    return cancelCallback;
  }, [submitTicket, payload, onSubmit, resource]);

  const onPropertyValueChanged = useCallback((propertyName, value) => {
    setPayload((current) => {
      const cloned = { ...current };
      cloned[propertyName] = value;
      return cloned;
    });
  }, []);

  return (
    <Form className="resource-edit-form">
      <Div class="edit-properties">
        {properties.map((property) => {
          const { name: propertyName, displayName, editable, input } = property;
          const value = resource[propertyName];
          return (
            <FlexibleInput
              label={displayName}
              propertyName={propertyName}
              editable={editable}
              options={input}
              defaultValue={value}
              onPayloadChanged={onPropertyValueChanged}
              key={propertyName}
            />
          );
        })}
      </Div>
    </Form>
  );
};

EditFormBody.defaultProps = {
  submitTicket: null,
  onSubmit: () => {}
};

EditFormBody.propTypes = {
  resource: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])).isRequired,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      editable: PropTypes.bool,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired
        })
      )
    })
  ).isRequired,
  onSubmit: PropTypes.func,
  submitTicket: PropTypes.string
};
