import generateBartenderPayload from '../../hooks/use-generate-print-tag-payload/utils/generate-bartender-payload';

/**
 * @typedef {Object} LabelConfig
 * @property {string} printerName
 * @property {string} templateFilePath
 * @property {{[propertyName: string]: string}} propertiesToHeaderMap
 */

/**
 * 
 * @param {{[propertyName: string]: string}[]} tags 
 * @param {LabelConfig} labelConfig 
 * @returns {string}
 */
export const generateBartenderCsv = (tags, labelConfig) => {
  const { propertiesToHeaderMap } = labelConfig || {};

  const titleKeys = Object.values(propertiesToHeaderMap);

  const refinedData = [];
  refinedData.push(titleKeys);

  tags.forEach((item) => {
    const bartenderData = generateBartenderPayload(item, labelConfig);
    refinedData.push(Object.values(bartenderData));
  });

  let csvContent = "";

  refinedData.forEach((row) => {
    csvContent += `${row.join(",")}\n`;
  });

  return csvContent;
};
