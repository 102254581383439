import React from "react";
import PropTypes from "prop-types";
import Styles from "./ModalFormFooter.module.css";

export const ModalFormFooter = ({
  onConfirm,
  onCancel,
  confirmDisabled,
  confirmLabel,
  cancelDisabled,
  containerClassName
}) => {
  return (
    <div className={`${Styles.modal_footer_container} ${containerClassName}`}>
      <button
        type="button"
        className={`${Styles.form_button} ${Styles.cancel_button}`}
        disabled={cancelDisabled}
        onClick={() => {
          onCancel();
        }}
      >
        Cancel
      </button>
      <button
        type="button"
        onClick={onConfirm}
        disabled={confirmDisabled}
        className={`${Styles.form_button} ${Styles.confirm_button} `}
      >
        {confirmLabel}
      </button>
    </div>
  );
};

ModalFormFooter.defaultProps = {
  onConfirm: () => {},
  onCancel: () => {},
  confirmDisabled: false,
  confirmLabel: "Confirm",
  cancelDisabled: false,
  containerClassName: ""
};

ModalFormFooter.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmDisabled: PropTypes.bool,
  confirmLabel: PropTypes.string,
  cancelDisabled: PropTypes.bool,
  containerClassName: PropTypes.string
};
