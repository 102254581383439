import React from "react";
import Style from "./ShowFilterButton.module.css";
import { ReactComponent as FiltersIcon } from "../../../../../../assets/icons/filter.svg";
import useInventoryTrackPageStateContext from "../../../../contexts/inventory-track-page-state-context";
import useInventoryTrackPageConfigContext from "../../../../contexts/inventory-track-page-config-context";

export const ShowFilterButton = () => {
  const { sideFilterControl } = useInventoryTrackPageConfigContext();
  const { isSideFilterHidden, setIsSideFilterHidden } = useInventoryTrackPageStateContext();
  if (!sideFilterControl?.length) {
    return null;
  }
  return (
    <button
      className={Style.title_right_action_button}
      onClick={() => {
        setIsSideFilterHidden((currentValue) => {
          return !currentValue;
        });
      }}
      type="button"
    >
      <FiltersIcon className={Style.title_right_action_button_icon} />
      <p className={Style.title_right_action_button_text}>{`${isSideFilterHidden ? "Show" : "Hide"} Filters`}</p>
    </button>
  );
};
