import React, { useMemo, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useHistory } from "react-router-dom";
import AmazonS3URI from "amazon-s3-uri";
import { TableSortLabel } from "@material-ui/core";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import PaginatedList from "../../../../components/PaginatedList/PaginatedList";
import { useReportsPageContext } from "../../context/reportsPageContext";
import { getFormattedDate, getItemFromS3 } from "../../../../common/Utilities";
import Style from "./ReportList.module.css";
import { isReportDetailSupported } from "../../../report-detail-feature/utils";
import { useDisplayBannerContext } from "../../../../context/DisplayBannerContext/DisplayBannerContext";
import { NO_REPORT_URL_KEYWORD } from "../../../report-detail-feature/data/constant";
import { SORT_DIRECTION } from "../../../../data/constants";
import { applySideFilters } from "../../../side-filter/services/side-filter-configuration-provider/sideFilterConfigurationProvider";
import formatReportName from "../../util/format-report-name";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";

const APP_ID = "reports";
const HeaderTitleMap = {
  report: "Report",
  timeCreated: "Time Created",
  empty: ""
};
const HeaderToAttributeMapMap = {
  Report: "identifier",
  "Time Created": "node_start_time",
  "": ""
};
const ReportList = React.forwardRef((_, ref) => {
  const {
    selectedTypeFilter,
    searchInputText,
    sideFilter,
    reportSideFilterConfiguration,
    setReportList,
    allowedReportStates
  } = useReportsPageContext();
  const [reports, setReports] = useState([]);
  const xemelgoClient = useXemelgoClient();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedHeader, setSelectedHeader] = useState(HeaderTitleMap.timeCreated);
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION.descending);

  const { setShowBanner, setBannerHasError, setBannerTitle } = useDisplayBannerContext();
  const history = useHistory();
  const configProvider = useAppConfigProvider(APP_ID);
  const reportGroups = configProvider.getValue("reportGroups", "object") || {};

  const getReportsByType = async () => {
    setIsLoading(true);
    const reportClient = xemelgoClient.getReportClient();
    const newReports = await reportClient.getReportsByClass([selectedTypeFilter.id]);
    setReports(newReports);
    setReportList(newReports);
    setIsLoading(false);
  };

  useEffect(() => {
    if (selectedTypeFilter.id) {
      getReportsByType();
    }
  }, [selectedTypeFilter]);

  const shouldShowReportStatus = useMemo(() => {
    return selectedTypeFilter?.showReportStatus;
  }, [selectedTypeFilter]);

  const reportList = useMemo(() => {
    return reports
      .filter((reportInfo) => {
        return (
          (reportInfo.identifier || "").toLowerCase().includes((searchInputText || "").toLowerCase()) &&
          applySideFilters(reportSideFilterConfiguration, sideFilter, reportInfo) &&
          (!allowedReportStates || allowedReportStates.length === 0 || allowedReportStates.includes(reportInfo.state))
        );
      })
      .sort((a, b) => {
        const sortAttribute = HeaderToAttributeMapMap[selectedHeader];

        const aValue = sortDirection === SORT_DIRECTION.ascending ? a[sortAttribute] : b[sortAttribute];
        const bValue = sortDirection === SORT_DIRECTION.ascending ? b[sortAttribute] : a[sortAttribute];

        switch (sortAttribute) {
          case HeaderToAttributeMapMap["Time Created"]:
            return aValue - bValue;
          case HeaderToAttributeMapMap.Report:
          default:
            return (aValue || "").localeCompare(bValue || "", undefined, {
              numeric: true,
              sensitivity: "base"
            });
        }
      });
  }, [reports, searchInputText, sideFilter, selectedHeader, sortDirection, applySideFilters]);

  const reportDetailRootUrl = useMemo(() => {
    return `${history.location.pathname}/detail?id=`;
  }, [history]);

  const getReport = async (url) => {
    try {
      if (url) {
        if (url.includes(NO_REPORT_URL_KEYWORD)) {
          setBannerHasError(true);
          setBannerTitle("This reports has no records. Please select a different report");
          setShowBanner(true);
        } else {
          const { bucket, key } = AmazonS3URI(url);
          await getItemFromS3(bucket, key);
        }
      }
    } catch {
      setBannerHasError(true);
      setBannerTitle("Can not download the report. Please try again later");
      setShowBanner(true);
    }
  };

  if (ref) {
    ref.current = { reload: getReportsByType };
  }

  return (
    <PaginatedList
      header={Object.keys(HeaderTitleMap).map((eachHeaderKey) => {
        return { id: eachHeaderKey, label: HeaderTitleMap[eachHeaderKey] };
      })}
      data={isLoading ? [] : reportList}
      headerContainerClassName={Style.table_header}
      renderEmptyList={() => {
        if (isLoading) {
          return [...Array(10)].map((_, index) => {
            return (
              <div
                key={index}
                className={`${Style.flex_row} ${Style.table_item}`}
              >
                <div className={`${Style.flex_row} ${Style.table_column}`}>
                  <Skeleton
                    containerClassName={Style.loading_icon}
                    height={24}
                  />
                </div>
                <div className={`${Style.flex_row} ${Style.table_column}`}>
                  <Skeleton
                    containerClassName={Style.loading_icon}
                    height={24}
                  />
                </div>
                <div className={`${Style.flex_row} ${Style.table_column}`}>
                  <Skeleton
                    containerClassName={Style.loading_icon}
                    height={24}
                  />
                </div>
              </div>
            );
          });
        }
        return (
          <div className={`${Style.flex_row} ${Style.empty_list_container}`}>
            <p className={Style.empty_list_text}>No Reports Available</p>
          </div>
        );
      }}
      renderHeader={(eachHeader, index) => {
        return (
          <div
            key={index}
            className={`${Style.table_column}`}
          >
            {eachHeader.label !== HeaderTitleMap.empty ? (
              <TableSortLabel
                active={selectedHeader === eachHeader.label}
                direction={sortDirection}
                className={Style.table_header_text}
                onClick={() => {
                  if (selectedHeader === eachHeader.label) {
                    setSortDirection(
                      sortDirection === SORT_DIRECTION.ascending ? SORT_DIRECTION.descending : SORT_DIRECTION.ascending
                    );
                  } else {
                    setSelectedHeader(eachHeader.label);
                    setSortDirection(SORT_DIRECTION.descending);
                  }
                }}
              >
                {eachHeader.label}
              </TableSortLabel>
            ) : (
              <p className={Style.table_header_text}>{eachHeader.label}</p>
            )}
          </div>
        );
      }}
      renderItem={(eachItem, index) => {
        return (
          <div
            key={index}
            className={`${Style.flex_row} ${Style.table_item}`}
          >
            <div className={`${Style.flex_row} ${Style.table_column} ${Style.row_identifier_column}`}>
              {isReportDetailSupported(eachItem.class) ? (
                <Link
                  to={`${reportDetailRootUrl}${eachItem.id}`}
                  className={`${Style.table_item_text}`}
                >
                  {formatReportName(eachItem, reportGroups)}
                </Link>
              ) : (
                <button
                  type="button"
                  className={`${Style.table_item_text}`}
                  onClick={() => {
                    getReport(eachItem.result?.reportLink);
                  }}
                >
                  {formatReportName(eachItem, reportGroups)}
                </button>
              )}
              {shouldShowReportStatus && eachItem.state === "AUTO_APPROVED" && (
                <div className={Style.auto_approved_text}>Auto Approved</div>
              )}
            </div>
            <div className={`${Style.flex_row} ${Style.table_column}`}>
              <p className={`${Style.table_item_text2}`}>
                {getFormattedDate(eachItem.node_start_time, "MMM DD, YYYY hh:mm a")}
              </p>
            </div>
            <div className={`${Style.flex_row} ${Style.table_column}`}>
              <button
                type="button"
                className={`${Style.table_item_text}`}
                onClick={() => {
                  getReport(eachItem.result?.reportLink);
                }}
              >
                Download CSV
              </button>
            </div>
          </div>
        );
      }}
    />
  );
});

export default ReportList;
