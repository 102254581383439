import React from "react";
import PropTypes from "prop-types";
import TabListComponent from "../../tab-list-component/TabListComponent";
import GroupbySidebarComponentStyle from "./GroupbySidebarComponent.module.css";

const GroupbySidebarComponent = ({ containerStyle, tabStructure, focusedTab }) => {
  return (
    <TabListComponent
      tabStructure={tabStructure}
      containerStyle={containerStyle}
      tabStyle={GroupbySidebarComponentStyle.tab_style}
      tabListStyle={GroupbySidebarComponentStyle.tab_list_style}
      focusedTabStyle={GroupbySidebarComponentStyle.focused_tab_style}
      tabListHeaderStyle={GroupbySidebarComponentStyle.groupby_label}
      focusedTab={focusedTab}
    />
  );
};

export default GroupbySidebarComponent;

GroupbySidebarComponent.defaultProps = {
  containerStyle: null,
  focusedTab: null
};

GroupbySidebarComponent.propTypes = {
  containerStyle: PropTypes.string,
  tabStructure: PropTypes.arrayOf(PropTypes.object).isRequired,
  focusedTab: PropTypes.string
};
