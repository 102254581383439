import React from "react";
import Style from "./TitleRightActions.module.css";
import ShowFilterButton from "./features/show-filter-button";
import ChangeDataView from "./features/change-data-view";

export const TitleRightActions = () => {
  return (
    <div className={Style.container}>
      <ShowFilterButton />
      <ChangeDataView />
    </div>
  );
};
