import React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import Style from "./StatusBar.module.css";

export const StatusBar = ({ status }) => {
    const COUNT_STATUS_DEFAULT = {
        MATCH: { id: "MATCH", label: "Completed", style: Style.status_match },
        INCOMPLETE: { id: "INCOMPLETE", label: "Not Scanned", style: Style.status_incomplete },
        PARTIAL: { id: "PARTIAL", label: "Partial", style: Style.status_partial },
        EXTRA: { id: "EXTRA", label: "Extra Scanned", style: Style.status_extra },
    }

    return (
      <div className={`${Style.status_container} ${COUNT_STATUS_DEFAULT[status].style}`}>
        <div className={Style.status_text}>
          {COUNT_STATUS_DEFAULT[status].label}
        </div>
      </div>
    );
};

StatusBar.propTypes = {
  status: PropTypes.string.isRequired, 
};
