import PropTypes from "prop-types";
import { getCurrentTimestamp, getFormattedDate } from "../../../../common/utils";

export const generateTagsWithPrefix = (quantity, prefix) => {
  const tags = [];
  const timeStamp = getCurrentTimestamp();
  const date = getFormattedDate(timeStamp, "YYMMDDhhmmssSSS");
  const startingSerial = 1;
  for (let i = startingSerial; i <= quantity; i++) {
    let vid = `${prefix}${date}`;
    const remainingDigits = 24 - vid.length;
    const paddedString = i.toString().padStart(remainingDigits, "0");
    vid += paddedString;
    tags.push(vid);
  }
  return tags;
};

generateTagsWithPrefix.PropTypes = {
  quantity: PropTypes.number.isRequired,
  prefix: PropTypes.string.isRequired
};
