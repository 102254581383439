import PropTypes from "prop-types";

const TEN_CHARACTIERS_MINIMUM = "10_CHARACTIERS_MINIMUM";
const TEN_CHARACTIERS_MINIMUM_LABEL = "10 characters minimum";
const ONE_LOWERCASE_LETTER = "ONE_LOWERCASE_LETTER";
const ONE_LOWERCASE_LETTER_LABEL = "One lowercase letter";
const ONE_UPPERCASE_LETTER = "ONE_UPPERCASE_LETTER";
const ONE_UPPERCASE_LETTER_LABEL = "One uppercase letter";
const ONE_SPECIAL_CHARACTER = "ONE_SPECIAL_CHARACTER";
const ONE_SPECIAL_CHARACTER_LABEL = "One special character (ex. %&#@&*!)";
const PASSWORDS_DO_NOT_MATCH = "PASSWORDS_DO_NOT_MATCH";
const PASSWORDS_DO_NOT_MATCH_LABEL = "Passwords do not match";

export const PASSWORD_REQUIREMENTS = [
  {
    name: TEN_CHARACTIERS_MINIMUM,
    label: TEN_CHARACTIERS_MINIMUM_LABEL,
    isValid: (password) => {
      return password.length >= 10;
    }
  },
  {
    name: ONE_LOWERCASE_LETTER,
    label: ONE_LOWERCASE_LETTER_LABEL,
    isValid: (password) => {
      return /[a-z]/.test(password);
    }
  },
  {
    name: ONE_UPPERCASE_LETTER,
    label: ONE_UPPERCASE_LETTER_LABEL,
    isValid: (password) => {
      return /[A-Z]/.test(password);
    }
  },
  {
    name: ONE_SPECIAL_CHARACTER,
    label: ONE_SPECIAL_CHARACTER_LABEL,
    isValid: (password) => {
      return /[!@#$%^&*()\-+=\[\]{}|\\:;'"<>,.?/~_]/.test(password);
    }
  }
];

export const CONFRIM_PASSWORD_REQUIREMENT = {
  name: PASSWORDS_DO_NOT_MATCH,
  label: PASSWORDS_DO_NOT_MATCH_LABEL
};

export const CLASS_OVERRIDE_SHAPE = PropTypes.shape({
  passwordInput: PropTypes.string,
  showPasswordToggleIcon: PropTypes.string,
  showPasswordToggleLabel: PropTypes.string,
  passwordRequirementLabelColor: PropTypes.string
});
