import React, { useMemo, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import GetAppIcon from "@material-ui/icons/GetApp";
import { TableSortLabel } from "@material-ui/core";
import { toFixedNumber } from "../../../../utils";
import PaginatedList from "../../../../components/PaginatedList/PaginatedList";
import BarGraph from "../../../../components/bar-graph";
import Style from "./PartVelocityReportDetail.module.css";
import { naturalSort, exportCsv } from "../../../../common/Utilities";
import usePartVelocityMetric from "./hooks/use-part-velocity-metric/usePartVelocityMetric";
import { useDisplayBannerContext } from "../../../../context/DisplayBannerContext/DisplayBannerContext";
import { NO_REPORT_URL_KEYWORD } from "../../data/constant";
import { SORT_DIRECTION } from "../../../../data/constants";
import { TableHeaderMap } from "./data/constant";
import { ReportHeader } from "../../components/report-header/ReportHeader";

const PartVelocityReportDetail = ({ headers, records, reportDetail }) => {
  const [selectedBarData, setSelectedBarData] = useState(null);

  const [selectedHeader, setSelectedHeader] = useState(TableHeaderMap.startDate);
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION.ascending);

  const { setShowBanner, setBannerHasError, setBannerTitle } = useDisplayBannerContext();

  const tableRecordList = useMemo(() => {
    let finalRecords = _.cloneDeep(records);

    const { param } = reportDetail;
    const { operationName, locationName } = param || {};

    if (!operationName) {
      const visitedWorkOrderAndDepartmentIndexMap = {};
      for (let i = 0; i < finalRecords.length; i++) {
        const { Department, [TableHeaderMap.workOrder]: WorkOrder } = finalRecords[i];
        const visitedMapHeader = `${Department}-${WorkOrder}`;
        if (!visitedWorkOrderAndDepartmentIndexMap[visitedMapHeader]) {
          visitedWorkOrderAndDepartmentIndexMap[visitedMapHeader] = i;
          finalRecords[i].Operation = "All";
        } else {
          const touchTimesToCombineList = [TableHeaderMap.unitTouchTime, TableHeaderMap.totalTouchTime];
          touchTimesToCombineList.forEach((eachTouchTimeName) => {
            finalRecords[visitedWorkOrderAndDepartmentIndexMap[visitedMapHeader]][eachTouchTimeName] = toFixedNumber(
              finalRecords[visitedWorkOrderAndDepartmentIndexMap[visitedMapHeader]][eachTouchTimeName] * 1 +
                finalRecords[i][eachTouchTimeName] * 1,
              2
            ).toString();
          });
        }
      }
      finalRecords = Object.values(visitedWorkOrderAndDepartmentIndexMap).map((eachIndex) => {
        return finalRecords[eachIndex];
      });
    }
    if (!locationName) {
      const visitedWorkOrderMap = {};
      finalRecords.forEach((eachRecord) => {
        const {
          [TableHeaderMap.workOrder]: WorkOrder,
          [TableHeaderMap.totalTouchTime]: totalTouchTime,
          [TableHeaderMap.unitTouchTime]: unitTouchTime,
          [TableHeaderMap.startDate]: startDate,
          [TableHeaderMap.department]: department
        } = eachRecord;
        if (department === "ALL" || department === "SHIPPING") {
          return;
        }
        if (!visitedWorkOrderMap[WorkOrder]) {
          visitedWorkOrderMap[WorkOrder] = eachRecord;
          visitedWorkOrderMap[WorkOrder].Department = "All";
        } else {
          visitedWorkOrderMap[WorkOrder][TableHeaderMap.startDate] = startDate.localeCompare(
            visitedWorkOrderMap[WorkOrder][TableHeaderMap.startDate],
            undefined,
            {
              numeric: true,
              sensitivity: "base"
            }
          )
            ? startDate
            : visitedWorkOrderMap[WorkOrder][TableHeaderMap.startDate];

          visitedWorkOrderMap[WorkOrder][TableHeaderMap.totalTouchTime] = toFixedNumber(
            +visitedWorkOrderMap[WorkOrder][TableHeaderMap.totalTouchTime] + +totalTouchTime,
            2
          ).toString();
          visitedWorkOrderMap[WorkOrder][TableHeaderMap.unitTouchTime] = toFixedNumber(
            +visitedWorkOrderMap[WorkOrder][TableHeaderMap.unitTouchTime] + +unitTouchTime,
            2
          ).toString();
        }
      });
      finalRecords = Object.values(visitedWorkOrderMap);
    }

    return naturalSort(finalRecords, selectedHeader, sortDirection);
  }, [records, selectedBarData, reportDetail, selectedHeader, sortDirection]);

  const {
    reportTitle,
    reportSubTitle,
    totalNumOfWorkOrders,
    averageUnitTouchTime,
    totalNumOfPartNumbers,
    barGraphData
  } = usePartVelocityMetric(tableRecordList, reportDetail);

  const filteredTableRecordList = useMemo(() => {
    if (selectedBarData) {
      const { workOrderList: selectedWorkOrderList = [] } = selectedBarData;
      return tableRecordList.filter((eachRecord) => {
        const { [TableHeaderMap.workOrder]: WorkOrder } = eachRecord;
        return selectedWorkOrderList.includes(WorkOrder);
      });
    }
    return tableRecordList;
  }, [tableRecordList]);

  const renderTitle = () => {
    return (
      <ReportHeader
        headerText={reportTitle}
        subHeaderText={reportSubTitle}
      />
    );
  };

  const renderOverview = () => {
    return (
      <div className={`${Style.flex_column} ${Style.overview}`}>
        <p className={`${Style.overview_title}`}>Overview</p>
        <div className={`${Style.flex_column}`}>
          <div className={`${Style.each_metric}`}>
            <p>Total number of WOs</p>
            <p>{totalNumOfWorkOrders}</p>
          </div>
          <div className={`${Style.each_metric}`}>
            <p>Total number of parts</p>
            <p>{totalNumOfPartNumbers}</p>
          </div>
          <div className={`${Style.each_metric}`}>
            <p>Average unit touch time</p>
            <p>{averageUnitTouchTime}</p>
          </div>
        </div>
        <div />
      </div>
    );
  };

  const renderBarGraph = () => {
    return (
      <div className={Style.bar_graph_container}>
        <BarGraph
          allowDecimalsYAxis={false}
          xAxisLabel="Unit Touch Time (mins)"
          yAxisLabel="# of Work Orders"
          defaultColor="#4a90ff"
          data={barGraphData}
          xAxisDataKey="unitTouchTime"
          yAxisDataKey="# of Work Orders"
          onBarClick={(data) => {
            setSelectedBarData(data);
          }}
        />
      </div>
    );
  };

  const onDownloadCSV = async () => {
    const { result } = reportDetail || {};
    const { reportLink } = result || {};
    try {
      if (reportLink && !reportLink.includes(NO_REPORT_URL_KEYWORD)) {
        const keywords = reportLink.split("/");
        exportCsv(tableRecordList, headers, keywords[keywords.length - 1]);
      }
    } catch {
      setBannerHasError(true);
      setBannerTitle("Can not download the report. Please try again later");
      setShowBanner(true);
    }
  };

  return (
    <div className={`${Style.flex_column} ${Style.container}`}>
      {renderTitle()}
      <div className={`${Style.flex_row} ${Style.metric_container}`}>
        {renderOverview()}
        {renderBarGraph()}
      </div>
      <div className={`${Style.flex_row} ${Style.action_button_container}`}>
        {reportDetail?.result?.reportLink && !reportDetail.result.reportLink.includes(NO_REPORT_URL_KEYWORD) && (
          <button
            type="button"
            className={`${Style.flex_row} ${Style.action_button}`}
            onClick={onDownloadCSV}
          >
            <GetAppIcon />
            Export as .CSV
          </button>
        )}
      </div>
      <PaginatedList
        header={headers}
        data={filteredTableRecordList}
        headerContainerClassName={Style.table_header}
        renderEmptyList={() => {
          return (
            <div className={`${Style.flex_row} ${Style.empty_list_container}`}>
              <p className={Style.empty_list_text}>No Reports Available</p>
            </div>
          );
        }}
        renderHeader={(eachHeader, index) => {
          return (
            <div
              key={index}
              className={`${Style.table_column}`}
            >
              <TableSortLabel
                active={selectedHeader === eachHeader}
                direction={sortDirection}
                className={Style.table_header_text}
                onClick={() => {
                  if (selectedHeader === eachHeader) {
                    setSortDirection(
                      sortDirection === SORT_DIRECTION.ascending ? SORT_DIRECTION.descending : SORT_DIRECTION.ascending
                    );
                  } else {
                    setSelectedHeader(eachHeader);
                    setSortDirection(SORT_DIRECTION.descending);
                  }
                }}
              >
                {eachHeader}
              </TableSortLabel>
            </div>
          );
        }}
        renderItem={(eachItem, index) => {
          return (
            <div
              key={index}
              className={`${Style.flex_row} ${Style.table_item}`}
            >
              {headers.map((eachHeader, subIndex) => {
                return (
                  <div
                    key={`${eachItem[eachHeader]}${subIndex}`}
                    className={`${Style.flex_row} ${Style.table_column}`}
                  >
                    <p className={`${Style.table_item_text2}`}>{eachItem[eachHeader] || "-"}</p>
                  </div>
                );
              })}
            </div>
          );
        }}
      />
    </div>
  );
};

PartVelocityReportDetail.defaultProps = {
  headers: [],
  records: [],
  reportDetail: {}
};

PartVelocityReportDetail.propTypes = {
  headers: PropTypes.array,
  records: PropTypes.array,
  reportDetail: PropTypes.object
};

export default PartVelocityReportDetail;
