import React from "react";
import PropTypes from "prop-types";
import ReactCalendar from "react-calendar";
import { ReactComponent as ChevronLeft } from "../../assets/icons/chev-left.svg";
import { ReactComponent as ChevronRight } from "../../assets/icons/chev-right.svg";
import xemelgoStyle from "../../styles/variable";
import formatText from "../../utils/format-text";
import { TIME_IN_MS } from "../../common/Utilities";
import "./Calendar.css";

const MAX_YEARS = 5;

export const Calendar = ({ date, onDateClick, maxDate, minDate }) => {
  return (
    <ReactCalendar
      calendarType="US"
      value={date}
      onChange={onDateClick}
      formatShortWeekday={(locale, weekdayDate) => {
        const shortWeekday = weekdayDate.toLocaleDateString(locale, { weekday: "short" });
        return formatText(shortWeekday, ["capitalize"]);
      }}
      formatMonth={(locale, monthDate) => {
        const month = monthDate.toLocaleDateString(locale, { month: "short" });
        return formatText(month, ["capitalize"]);
      }}
      formatMonthYear={(locale, monthDate) => {
        const month = monthDate.toLocaleDateString(locale, { month: "long" });
        const year = monthDate.toLocaleDateString(locale, { year: "numeric" });
        return `${formatText(month, ["capitalize"])} ${year}`;
      }}
      maxDate={maxDate}
      minDate={minDate}
      minDetail="decade"
      nextLabel={<ChevronRight color={xemelgoStyle.theme.APP_BLUE} />}
      prevLabel={<ChevronLeft color={xemelgoStyle.theme.APP_BLUE} />}
      next2Label={null}
      prev2Label={null}
    />
  );
};

Calendar.defaultProps = {
  date: undefined,
  maxDate: new Date(Date.now() + TIME_IN_MS.YEARS * MAX_YEARS),
  minDate: new Date(Date.now() - TIME_IN_MS.YEARS * MAX_YEARS)
};

Calendar.propTypes = {
  date: PropTypes.instanceOf(Date),
  onDateClick: PropTypes.func.isRequired,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date)
};
