import { useXemelgoAppsyncClient } from "../../../../services/xemelgo-appsync-service";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import usePackageTrackPageDataSourceContext from "../../contexts/package-track-page-data-source-context";

const useGetLocationTreeHook = () => {
  const client = useXemelgoClient();
  const { setLocationTreeMap, setIsLocationTreeMapLoading } = usePackageTrackPageDataSourceContext();

  const call = async () => {
    setIsLocationTreeMapLoading(true);
    const locationClient = client.getLocationClient();
    const newLocationTreeMap = await locationClient.getLocationTree([]);
    setLocationTreeMap(newLocationTreeMap);
    setIsLocationTreeMapLoading(false);

    return newLocationTreeMap;
  };
  return call;
};

const useGetLocationMetricsHook = () => {
  const client = useXemelgoAppsyncClient();
  const { setLocationMetricsMap, setIsLocationMetricsMapLoading } = usePackageTrackPageDataSourceContext();

  const call = async (locationIds) => {
    setIsLocationMetricsMapLoading(true);
    const packageClient = client.getPackageClient();
    const newLocationMetrics = await packageClient.getTrackPageMetricsByLocationIds(locationIds);
    setLocationMetricsMap(newLocationMetrics);
    setIsLocationMetricsMapLoading(false);

    return newLocationMetrics;
  };

  return call;
};

const useGetLocationPackagesHook = () => {
  const client = useXemelgoAppsyncClient();
  const { setLocationPackageListMap, setIsLocationPackageListMapLoading } = usePackageTrackPageDataSourceContext();

  const call = async (locationIds) => {
    setIsLocationPackageListMapLoading(true);
    const packageClient = client.getPackageClient();
    const newLocationPackageListMap = await packageClient.getPackagesByLocationIds(locationIds);
    setLocationPackageListMap(newLocationPackageListMap);
    setIsLocationPackageListMapLoading(false);

    return newLocationPackageListMap;
  };

  return call;
};

export const usePackageTrackPageInitializationHook = () => {
  const getLocationTree = useGetLocationTreeHook();
  const getLocationMetrics = useGetLocationMetricsHook();
  const getLocationPackages = useGetLocationPackagesHook();

  const call = async () => {
    const locationTree = await getLocationTree();
    const locationIds = Object.keys(locationTree);
    const promises = [getLocationMetrics(locationIds), getLocationPackages(locationIds)];
    await Promise.all(promises);
  };
  return call;
};
