import React, { useContext, useState, useEffect } from "react";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";

const PackageTrackPageConfigContext = React.createContext();

const initialState = {
  listTable: {
    headers: [],
    pageSize: 10
  },
  sideFilter: [],
  rootLocationCategories: [],
  initialURLParameters: ""
};

const SOLUTION_ID = "package";
const TRACK_PAGE_FEATURE_ID = "trackPage";

export const usePackageTrackPageConfigContext = () => {
  return useContext(PackageTrackPageConfigContext);
};

export const PackageTrackPageConfigContextProvider = ({ children }) => {
  const packageSolutionConfigProvider = useAppConfigProvider(SOLUTION_ID);
  const trackPageConfig = packageSolutionConfigProvider.getValue(TRACK_PAGE_FEATURE_ID, "object") || {};

  const [listTable, setListTable] = useState(initialState.listTable);
  const [sideFilter, setSideFilter] = useState(initialState.sideFilter);
  const [rootLocationCategories, setRootLocationCategories] = useState(initialState.rootLocationCategories);
  const [initialURLParameters, setInitialURLParameters] = useState(initialState.initialURLParameters);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const {
      listTable: newListTable = initialState.listTable,
      sideFilter: newSideFilter = initialState.sideFilter,
      rootLocationCategories: newRootLocationCategories = initialState.rootLocationCategories,
      initialURLParameters: newInitialURLParameters = initialState.initialURLParameters
    } = trackPageConfig;

    setListTable(newListTable);
    setSideFilter(newSideFilter);
    setRootLocationCategories(newRootLocationCategories);
    setInitialURLParameters(newInitialURLParameters || initialState.initialURLParameters);
    setIsLoading(false);
  }, [packageSolutionConfigProvider]);

  return (
    <PackageTrackPageConfigContext.Provider
      value={{
        listTable,
        sideFilter,
        rootLocationCategories,
        initialURLParameters,
        isLoading
      }}
    >
      {children}
    </PackageTrackPageConfigContext.Provider>
  );
};
