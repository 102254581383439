import React from "react";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";
import Style from "../../AssetMap.module.css";

export const LoadingTable = ({ numOfRows }) => {
  const renderSkeletonIconRows = (rowSize) => {
    const rows = [];
    for (let index = 0; index < rowSize; index++) {
      rows.push(
        <div
          key={index}
          className={`${Style.flex_row} ${Style.table_item}`}
        >
          <div className={`${Style.flex_row} ${Style.table_column}`}>
            <Skeleton
              count={1}
              height={20}
              width={120}
            />
          </div>
          <div className={`${Style.flex_row} ${Style.table_column}`}>
            <Skeleton
              height={20}
              width={120}
            />
          </div>
        </div>
      );
    }
    return rows;
  };
  return (
    <div className={`${Style.flex_column} ${Style.paginated_list_table_loading_container}`}>
      {renderSkeletonIconRows(numOfRows)}
    </div>
  );
};

LoadingTable.defaultProps = {
  numOfRows: 5
};

LoadingTable.propTypes = {
  numOfRows: PropTypes.number
};
