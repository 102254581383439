import { useEffect, useState, useCallback } from "react";

export const useComponentDimensions = (componentRef) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const getDimensions = useCallback(() => {
    return {
      width: componentRef?.current?.offsetWidth,
      height: componentRef?.current?.offsetHeight
    };
  }, []);

  const handleResize = useCallback(() => {
    setDimensions(getDimensions());
  }, [getDimensions]);

  useEffect(() => {
    if (componentRef?.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("load", handleResize);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("load", handleResize);
    };
  }, [componentRef]);

  useEffect(() => {
    handleResize();
  }, [componentRef?.current?.offsetHeight, componentRef?.current?.offsetWidth]);

  return dimensions;
};
