import * as turf from "@turf/turf";

export const getMapFeatureCenter = (currentFeature) => {
  let center;
  if (currentFeature) {
    switch (currentFeature.geometry.type) {
      case "Point":
        center = currentFeature.geometry.coordinates;
        break;
      case "Polygon":
        const area = turf.points(currentFeature.geometry.coordinates[0]);
        center = turf.center(area).geometry.coordinates;
        break;
      default:
        break;
    }
  }
  return center;
};
