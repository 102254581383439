import React from "react";
import PropTypes from "prop-types";
import xemelgoStyle from "../../../../styles/variable";
import Style from "./UploadStatusComponent.module.css";
import { ReactComponent as SuccessIcon } from "../../../../assets/icons/success.svg";
import { ReactComponent as FailIcon } from "../../../../assets/icons/fail.svg";

export const UploadStatusComponent = ({
  uploadProgress,
  uploadMessage,
  uploadError,
  showModalNavigatorMessage,
  onNavigatorClick
}) => {
  if (uploadProgress < 100) {
    return <p className={`${Style.upload_progress_text} ${xemelgoStyle.subtext}`}>{`${uploadProgress}% Completed`}</p>;
  }

  // status message
  if (uploadMessage) {
    return (
      <>
        <div className={Style.upload_status}>
          <div
            className={`${xemelgoStyle.subtext} ${Style.status_message}`}
            style={{ color: uploadError ? xemelgoStyle.theme.STATUS_RED : xemelgoStyle.theme.STATUS_GREEN }}
          >
            {uploadError ? (
              <FailIcon
                height={20}
                width={20}
                className={Style.status_icon}
                color={xemelgoStyle.theme.STATUS_RED}
              />
            ) : (
              <SuccessIcon
                className={Style.status_icon}
                color={xemelgoStyle.theme.STATUS_GREEN}
              />
            )}
            <p>
              {uploadMessage}
              {showModalNavigatorMessage && (
                <>
                  <span>Click</span>
                  <button
                    className={Style.clickable_text}
                    type="button"
                    onClick={() => {
                      onNavigatorClick(true);
                    }}
                  >
                    here
                  </button>
                  <span>to resolve. You may also upload an updated csv file.</span>
                </>
              )}
            </p>
          </div>
        </div>
      </>
    );
  }

  return null;
};

UploadStatusComponent.defaultProps = {
  showModalNavigatorMessage: false,
  onNavigatorClick: () => {}
};

UploadStatusComponent.propTypes = {
  uploadProgress: PropTypes.number.isRequired,
  uploadMessage: PropTypes.string.isRequired,
  uploadError: PropTypes.bool.isRequired,
  showModalNavigatorMessage: PropTypes.bool,
  onNavigatorClick: PropTypes.func
};
