import React, { useEffect, useState, useMemo } from "react";
import useWorkOrderTrackPageConfigContext from "../../contexts/work-order-track-page-config-context";
import useWorkOrderTrackPageStateContext from "../../contexts/work-order-track-page-state-context";
import { SideFilter, useSideFilterConfigBuilder } from "../../../side-filter";
import { TAB_OPTION_MAP } from "../../data/constants";
import {
  WORK_ORDER_TRACK_PAGE_V2_EVENT,
  WORK_ORDER_TRACK_PAGE_V2_STEPS
} from "../../../../constants/mixpanel-constant/workOrderTrackpageV2";
import useMixpanelContext from "../../../../context/mixpanel-context";

export const SideFilters = () => {
  const { sideFilter } = useWorkOrderTrackPageConfigContext();
  const { sendMixPanelEvent } = useMixpanelContext();

  const {
    filterConfiguration,
    initialFilterValue,
    applySideFilters,
    isLoading,
    getOnlyActiveValues,
    applyActiveValueOnInitialFilterValue
  } = useSideFilterConfigBuilder(sideFilter);

  const [isTelemetrySent, setIsTelemetrySent] = useState(false);

  const { setApplySideFilterFn, selectedTab, sideFilterValue, setSideFilterValue } =
    useWorkOrderTrackPageStateContext();

  const currentSideFilterValue = useMemo(() => {
    return applyActiveValueOnInitialFilterValue(sideFilterValue);
  }, [initialFilterValue, sideFilterValue, sideFilter]);

  useEffect(() => {
    setApplySideFilterFn(() => {
      return (item) => {
        return applySideFilters(filterConfiguration, currentSideFilterValue, item);
      };
    });
  }, [currentSideFilterValue]);

  if (selectedTab === TAB_OPTION_MAP.orderTab) {
    return (
      <SideFilter
        isLoading={isLoading}
        filterConfiguration={filterConfiguration}
        initialFilterValues={{ ...currentSideFilterValue }}
        onFilterChange={({ allValues }) => {
          if (!isTelemetrySent) {
            setIsTelemetrySent(true);
            sendMixPanelEvent(WORK_ORDER_TRACK_PAGE_V2_EVENT, WORK_ORDER_TRACK_PAGE_V2_STEPS.SIDE_FILTER_APPLIED);
          }
          setSideFilterValue(getOnlyActiveValues(allValues));
        }}
      />
    );
  }
  return null;
};
