import React, { useContext, useState, useEffect } from "react";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";
import { useRoleAuthorization, DEFAULT_ROLES } from "../../../../apps/hooks/useRoleAuthorization";

const WorkOrderTrackPageConfigContext = React.createContext();

const initialState = {
  addOrderEnabled: true,
  bulkUpdateEnabled: false,
  useAddOrderV2: false,
  listTable: {
    locations: {
      headers: [{ id: "name", label: "Location", isNavLink: true, defaultSort: true, defaultDirection: "asc" }],
      pageSize: 10
    },
    orders: {
      headers: [
        {
          id: "identifier",
          label: "Order #",
          defaultSort: true,
          defaultDirection: "asc",
          isNavLink: true
        }
      ],
      pageSize: 10
    }
  },
  sideFilter: [],
  metricsGroup: [],
  multiSelectOptions: [],
  initialURLParameters: "?locationId&tab=locations",
  liveQueue: {
    enable: false,
    locationCategories: "all",
    prioritizedMode: false
  },
  settings: {
    options: [],
    optionControl: {}
  },
  // this is a temporary config control to hideLateToStartStatus.
  hideLateToStartStatus: false,
  chartsGroup: [],
  useBacklogStatusOnly: false,
  locationCategories: ["all"],
  unknownLocationControl: {
    enabled: false,
    properties: {
      id: "unknown-location-112358",
      name: "Unknown",
      identifier: "Unknown"
    }
  }
};

const SOLUTION_ID = "order";
const TRACK_PAGE_FEATURE_ID = "trackPage";
const ADD_ORDER_FEATURE = "addOrder";

export const useWorkOrderTrackPageConfigContext = () => {
  return useContext(WorkOrderTrackPageConfigContext);
};

export const WorkOrderTrackPageConfigContextProvider = ({ children }) => {
  const workOrderSolutionConfigProvider = useAppConfigProvider(SOLUTION_ID);
  const trackPageConfig = workOrderSolutionConfigProvider.getValue(TRACK_PAGE_FEATURE_ID, "object") || {};
  const addOrderConfig = workOrderSolutionConfigProvider.getValue(ADD_ORDER_FEATURE, "object");

  const [addOrderEnabled, setAddOrderEnabled] = useState(initialState.addOrderEnabled);
  const [bulkUpdateEnabled, setBulkUpdateEnabled] = useState(initialState.bulkUpdateEnabled);
  const [useAddOrderV2, setUseAddOrderV2] = useState(initialState.useAddOrderV2);
  const [listTable, setListTable] = useState(initialState.listTable);
  const [sideFilter, setSideFilter] = useState(initialState.sideFilter);
  const [metricsGroup, setMetricsGroup] = useState(initialState.metricsGroup);
  const [initialURLParameters, setInitialURLParameters] = useState(initialState.initialURLParameters);
  const [liveQueue, setLiveQueue] = useState(initialState.liveQueue);
  const [multiSelectOptions, setMultiSelectOptions] = useState(initialState.multiSelectOptions);
  const [settings, setSettings] = useState(initialState.settings);

  // this is a temporary config control to hideLateToStartStatus.
  const [hideLateToStartStatus, setHideLateToStartStatus] = useState(initialState.hideLateToStartStatus);

  const [chartsGroup, setChartsGroup] = useState(initialState.chartsGroup);
  const [useBacklogStatusOnly, setUseBacklogStatusOnly] = useState(initialState.useBacklogStatusOnly);
  const [locationCategories, setLocationCategories] = useState(initialState.locationCategories);
  const [unknownLocationControl, setUnknownLocationControl] = useState(initialState.unknownLocationControl);

  const [isLoading, setIsLoading] = useState(true);

  const { roles: addOrderRoles } = addOrderConfig;
  const addOrderFeature = { id: ADD_ORDER_FEATURE, roles: addOrderRoles || DEFAULT_ROLES };

  const { isAuthorized } = useRoleAuthorization({
    config: [addOrderFeature]
  });

  useEffect(() => {
    setIsLoading(true);
    const { v2: trackPageConfigV2 = {} } = trackPageConfig;
    const {
      bulkUpdateEnabled: newBulkUpdateEnabled = initialState.bulkUpdateEnabled,
      listTable: newListTable = initialState.listTable,
      sideFilter: newSideFilter = initialState.sideFilter,
      metricsGroup: newMetricsGroup = initialState.metricsGroup,
      initialURLParameters: newInitialURLParameters = initialState.initialURLParameters,
      liveQueue: newLiveQueue = initialState.liveQueue,
      hideLateToStartStatus: newHideLateToStartStatus = initialState.hideLateToStartStatus,
      chartsGroup: newChartsGroup = initialState.chartsGroup,
      useBacklogStatusOnly: newUseBacklogStatus = initialState.useBacklogStatusOnly,
      multiSelectOptions: newMultiSelectOptions = initialState.multiSelectOptions,
      settings: newSettings = initialState.settings,
      locationCategories: newLocationCategories = initialState.locationCategories,
      unknownLocationControl: newUnknownLocationControl = initialState.unknownLocationControl
    } = trackPageConfigV2;

    const { v2: newUseAddOrderV2 } = addOrderConfig;

    setAddOrderEnabled(isAuthorized(ADD_ORDER_FEATURE));
    setBulkUpdateEnabled(newBulkUpdateEnabled);
    setUseAddOrderV2(newUseAddOrderV2);
    setListTable(newListTable);
    setSideFilter(newSideFilter);
    setMetricsGroup(newMetricsGroup);
    setInitialURLParameters(newInitialURLParameters || initialState.initialURLParameters);
    setLiveQueue(newLiveQueue);
    setHideLateToStartStatus(newHideLateToStartStatus);
    setChartsGroup(newChartsGroup);
    setUseBacklogStatusOnly(newUseBacklogStatus);
    setMultiSelectOptions(newMultiSelectOptions);
    setSettings(newSettings || initialState.settings);
    setLocationCategories(newLocationCategories || initialState.locationCategories);
    setUnknownLocationControl(newUnknownLocationControl);
    setIsLoading(false);
  }, [workOrderSolutionConfigProvider]);

  return (
    <WorkOrderTrackPageConfigContext.Provider
      value={{
        addOrderEnabled,
        bulkUpdateEnabled,
        useAddOrderV2,
        listTable,
        sideFilter,
        metricsGroup,
        initialURLParameters,
        liveQueue,
        settings,
        hideLateToStartStatus,
        chartsGroup,
        useBacklogStatusOnly,
        multiSelectOptions,
        locationCategories,
        unknownLocationControl,
        isLoading
      }}
    >
      {children}
    </WorkOrderTrackPageConfigContext.Provider>
  );
};
