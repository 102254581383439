import { useCallback } from "react";
import useAutoGenerateWorkOrderIdentifier from "../use-auto-generate-work-order-identifiers/useAutoGenerateWorkOrderIdentifiers";
import { getValue, getIdentifiersQuantity, AddPageInputTypeMap } from "../../utils/AddOrderUtils";

const WO_IDENTIFIER_KEY = "order_number";
const SENSOR_PROFILE_VID_KEY = "tracker_serial";
const ONBOARDING_LOCATION_KEY = "onboardingLocation";
const useWorkOrderFormDataParser = (
  defaultAttributeMap,
  customAttributeMap,
  identifierSeparator,
  partConstraintConfig
) => {
  const { generate: generateWorkOrderIdentifier } = useAutoGenerateWorkOrderIdentifier();

  const getPartsPayload = (partForm, partConstraint) => {
    const partsList = partForm.map((partIdentifier, quantity, rfid) => {
      return { product_number: partIdentifier, quantity: quantity * 1, tracker_serial: rfid };
    });
    if (
      partConstraint.quantity === "none" ||
      !partsList.length ||
      (!partConstraint?.input && !partConstraint?.output)
    ) {
      return {};
    }
    return { [partConstraint.output ? "outputParts" : "inputParts"]: partsList };
  };

  const generateIdentifiers = async (workOrderForm) => {
    const generateIdentifierPromiseList = Object.entries(getIdentifiersQuantity(workOrderForm[WO_IDENTIFIER_KEY])).map(
      async ([groupKey, quantity]) => {
        return generateWorkOrderIdentifier(groupKey, quantity, identifierSeparator);
      }
    );

    const generatedIdentifiers = await (
      await Promise.all(generateIdentifierPromiseList)
    ).reduce((accumulator, currentValue) => {
      return [...accumulator, ...currentValue];
    }, []);
    return generatedIdentifiers;
  };

  const processDefaultAttributes = (processedFormValue, workOrderForm) => {
    const newProcessedFormValue = { ...processedFormValue };
    const onboardingLocationToWorkOrderMap = {};
    Object.keys(defaultAttributeMap).forEach((eachWorkOrderAttribute) => {
      const { type, autoGenerateValueUsingKey, numberOnly } = defaultAttributeMap[eachWorkOrderAttribute];
      let defaultAttributeValue = getValue(
        type,
        workOrderForm[eachWorkOrderAttribute].value,
        autoGenerateValueUsingKey,
        workOrderForm,
        numberOnly
      );
      if (type === AddPageInputTypeMap.CHECK_BOX_GROUP) {
        defaultAttributeValue = defaultAttributeValue?.[0] || null;
      }
      if (eachWorkOrderAttribute === ONBOARDING_LOCATION_KEY) {
        if (defaultAttributeValue) {
          onboardingLocationToWorkOrderMap[defaultAttributeValue] = Array.isArray(
            workOrderForm[WO_IDENTIFIER_KEY].value
          )
            ? workOrderForm[WO_IDENTIFIER_KEY].value
            : [workOrderForm[WO_IDENTIFIER_KEY].value];
        }
      } else {
        newProcessedFormValue[eachWorkOrderAttribute] = defaultAttributeValue;
      }
    });
    return { newProcessedFormValue, onboardingLocationToWorkOrderMap };
  };

  const processCustomAttributes = (processedFormValue, workOrderForm) => {
    const newProcessedFormValue = { ...processedFormValue };

    Object.keys(customAttributeMap).forEach((eachWorkOrderCustomAttribute) => {
      const { type, autoGenerateValueUsingKey, numberOnly } = customAttributeMap[eachWorkOrderCustomAttribute];
      let customAttributeValue = getValue(
        type,
        workOrderForm[eachWorkOrderCustomAttribute].value,
        autoGenerateValueUsingKey,
        workOrderForm,
        numberOnly
      );
      if (type === AddPageInputTypeMap.CHECK_BOX_GROUP) {
        customAttributeValue = customAttributeValue?.[0] || null;
      }
      newProcessedFormValue.custom_properties = {
        ...newProcessedFormValue.custom_properties,
        [eachWorkOrderCustomAttribute]: customAttributeValue
      };
    });
    return newProcessedFormValue;
  };

  const processParts = (processedFormValue, partForm) => {
    return { ...processedFormValue, ...getPartsPayload(partForm, partConstraintConfig) };
  };

  const convertToWorkOrdersPayload = (processedFormValue) => {
    const newProcessedFormValue = { ...processedFormValue };
    newProcessedFormValue[WO_IDENTIFIER_KEY] = Array.isArray(newProcessedFormValue[WO_IDENTIFIER_KEY])
      ? newProcessedFormValue[WO_IDENTIFIER_KEY]
      : [newProcessedFormValue[WO_IDENTIFIER_KEY]];

    newProcessedFormValue[SENSOR_PROFILE_VID_KEY] = Array.isArray(newProcessedFormValue[SENSOR_PROFILE_VID_KEY])
      ? newProcessedFormValue[SENSOR_PROFILE_VID_KEY]
      : [newProcessedFormValue[SENSOR_PROFILE_VID_KEY]];

    return newProcessedFormValue[WO_IDENTIFIER_KEY].map((eachWorkOrderIdentifier, index) => {
      return {
        ...newProcessedFormValue,
        [WO_IDENTIFIER_KEY]: eachWorkOrderIdentifier,
        [SENSOR_PROFILE_VID_KEY]: newProcessedFormValue[SENSOR_PROFILE_VID_KEY][index]
      };
    });
  };

  const parse = useCallback(
    async (workOrderForm, partForm) => {
      // auto generate work order number, if multiOrder is true
      const isAutoGeneratedIdentifier = defaultAttributeMap[WO_IDENTIFIER_KEY].multiOrder;
      const newWorkOrderForm = { ...workOrderForm };

      if (isAutoGeneratedIdentifier) {
        const generatedIdentifiers = await generateIdentifiers(newWorkOrderForm);
        newWorkOrderForm[WO_IDENTIFIER_KEY].value = generatedIdentifiers;
      }

      let processedFormValue = {};

      const { onboardingLocationToWorkOrderMap, newProcessedFormValue } = processDefaultAttributes(
        processedFormValue,
        newWorkOrderForm
      );
      processedFormValue = newProcessedFormValue;

      processedFormValue = processCustomAttributes(processedFormValue, newWorkOrderForm);

      processParts(processedFormValue, partForm);

      const workOrdersPayload = convertToWorkOrdersPayload(processedFormValue);

      return { workOrdersPayload, onboardingLocationToWorkOrderMap };
    },
    [defaultAttributeMap, customAttributeMap, identifierSeparator, partConstraintConfig]
  );

  return { parse };
};

export default useWorkOrderFormDataParser;
