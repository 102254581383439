import React from "react";
import usePackageTrackPageStateContext from "../../contexts/package-track-page-state-context";
import useBreadcrumbBuilder from "./hooks/use-breadcrumb-builder";
import BreadcrumbComponent from "../../../../components/breadcrumb";

export const Breadcrumb = () => {
  const { selectedLocationId, filteredLocationTreeMap } = usePackageTrackPageStateContext();
  const { breadcrumbDataList } = useBreadcrumbBuilder({ selectedLocationId, locationTreeMap: filteredLocationTreeMap });

  return <BreadcrumbComponent dataList={breadcrumbDataList} />;
};
