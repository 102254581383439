import React from "react";
import PropTypes from "prop-types";
import { ClipLoader } from "react-spinners";
import Style from "./PartialLoadingMessage.module.css";
import xemelgoStyle from "../../../../../../../../styles/variable";

export const PartialLoadingMessage = ({ message }) => {
  return (
    <div className={Style.partial_loading_container}>
      <div className={Style.partial_loading_circle_container}>
        <ClipLoader
          color={xemelgoStyle.theme.APP_LIGHTGREY}
          size={18}
        />
      </div>
      <p className={Style.partial_loading_message}>{message}</p>
    </div>
  );
};

PartialLoadingMessage.defaultProps = {
  message: "Loading more..."
};

PartialLoadingMessage.propTypes = {
  message: PropTypes.string
};
