/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React from "react";
import { SwiperSlide } from "swiper/react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import Style from "./MetricsSlide.module.css";

export const MetricsSlide = ({ title, value, isLoading }) => {
  return (
    <SwiperSlide className={`${Style.flex_column} ${Style.slide}`}>
      <p className={Style.metric_label}>{title}</p>
      {isLoading ? (
        <Skeleton
          containerClassName={Style.loading_icon}
          height={24}
        />
      ) : (
        <div className={Style.metric_value}>{value}</div>
      )}
    </SwiperSlide>
  );
};

// displayName is needed do to the bug (https://github.com/nolimits4web/swiper/issues/4413)
MetricsSlide.displayName = "SwiperSlide";

MetricsSlide.defaultProps = {
  title: "",
  value: "",
  isLoading: false
};

MetricsSlide.propTypes = {
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node, PropTypes.object]),
  isLoading: PropTypes.bool
};
