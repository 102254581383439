import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./style.css";

export const InteractiveImageMapper = ({ imageSrc, imageRef, onLoad, className, onClick, onResize }) => {
  const [ref, setRef] = useState(null);

  const feedback = useCallback(
    (element) => {
      if (!element) {
        return;
      }
      imageRef(element);
      setRef(element);
    },
    [imageRef, setRef]
  );

  /**
   * Name: subscribeForWindowResizeEvent
   * This effect will subscribe for the window resize event to notify onResize callback subscription.
   */
  useEffect(() => {
    const resizeCallback = () => {
      if (!ref) {
        return;
      }
      const { width, height, naturalWidth, naturalHeight } = ref;
      onResize({ width, height }, { width: naturalWidth, height: naturalHeight });
    };

    window.addEventListener("resize", resizeCallback);
    return () => {
      return window.removeEventListener("resize", resizeCallback);
    };
  }, [ref, onResize]);

  const onLoadCallback = useCallback(
    (element) => {
      const { target } = element;
      const { width, height, naturalWidth, naturalHeight } = target;
      onLoad({ width, height }, { width: naturalWidth, height: naturalHeight });
    },
    [onLoad]
  );

  const style = `interactive-image ${className}`;

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <img
      ref={feedback}
      className={style}
      src={imageSrc}
      alt="alt"
      onLoad={onLoadCallback}
      onClick={onClick}
    />
  );
};

InteractiveImageMapper.defaultProps = {
  imageRef: () => {},
  onLoad: () => {},
  onClick: () => {},
  onResize: () => {},
  className: ""
};

InteractiveImageMapper.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  imageRef: PropTypes.func,
  onLoad: PropTypes.func,
  onClick: PropTypes.func,
  onResize: PropTypes.func,
  className: PropTypes.string
};
