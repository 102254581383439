import React from "react";
import PropTypes from "prop-types";

export const MissingImageBlock = ({ height, width, className }) => {
  return (
    <div className={`missing-image-block ${className}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        viewBox="0 0 24 24"
        width={width}
      >
        <path
          d="M0 0h24v24H0V0z"
          fill="none"
        />
        <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4.86 8.86l-3 3.87L9 13.14 6 17h12l-3.86-5.14z" />
      </svg>
    </div>
  );
};

MissingImageBlock.defaultProps = {
  width: "24",
  height: "24",
  className: ""
};

MissingImageBlock.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string
};
