import React from "react";
import { Modal, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import Style from "./AlertModal.module.css";
import ActionButtons from "../ActionButtons/ActionButtons";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 520,
  height: 260,
  bgcolor: "background.paper",
  boxShadow: "0px 2px 15px 3px #585454",
  borderRadius: 3
};

const AlertModal = ({ show, title, message, cancelButtonText, onCancel, confirmButtonText, onConfirm }) => {
  return (
    <Modal open={show}>
      <Box sx={modalStyle}>
        <div className={Style.modal_title}>{title}</div>
        <div className={Style.modal_body}>{message}</div>
        <div className={Style.modal_footer}>
          <ActionButtons
            cancelButtonStyle={Style.cancel_button}
            cancelButtonText={cancelButtonText}
            onCancel={onCancel}
            confirmButtonStyle={Style.confirm_button}
            confirmButtonText={confirmButtonText}
            onConfirm={onConfirm}
          />
        </div>
      </Box>
    </Modal>
  );
};

AlertModal.defaultProps = {
  show: false,
  title: "",
  message: "",
  cancelButtonText: "",
  onCancel: () => {},
  confirmButtonText: "",
  onConfirm: () => {}
};

AlertModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onCancel: PropTypes.func,
  confirmButtonText: PropTypes.string,
  onConfirm: PropTypes.func
};

export default AlertModal;
