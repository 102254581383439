import React from "react";
import PropTypes from "prop-types";
import SummaryTileComponent from "./SummaryTileComponent";
import Style from "./SummaryTilesHolder.module.css";

const SummaryTilesHolder = ({
  locationSummaryTileState,
  summaryTileMetadataList,
  renderSummaryTileComponent,
  showBadgeContainer,
  onClick
}) => {
  return (
    <div className={`${Style.summary_tile_holder} ${showBadgeContainer && Style.extraHeight}`}>
      {summaryTileMetadataList && summaryTileMetadataList.length > 0 ? (
        summaryTileMetadataList.map((data) => {
          if (renderSummaryTileComponent) {
            return renderSummaryTileComponent(data);
          }

          return (
            <SummaryTileComponent
              key={data.id}
              tileInfo={data}
              isFilterEnabled={locationSummaryTileState && locationSummaryTileState.locationSummaryTileMap[data.id]}
              shouldShowBadgeContainer={showBadgeContainer}
              onClickFn={onClick}
            />
          );
        })
      ) : (
        <p> Shipment locations not configured. </p>
      )}
    </div>
  );
};

export default SummaryTilesHolder;

SummaryTilesHolder.defaultProps = {
  locationSummaryTileState: undefined,
  summaryTileMetadataList: [],
  renderSummaryTileComponent: undefined,
  showBadgeContainer: false,
  onClick: () => {}
};

SummaryTilesHolder.propTypes = {
  locationSummaryTileState: PropTypes.object,
  summaryTileMetadataList: PropTypes.array,
  renderSummaryTileComponent: PropTypes.func,
  showBadgeContainer: PropTypes.bool,
  onClick: PropTypes.func
};
