const validate = (formData) => {
  let report = {
    ok: true,
    errorMessage: null
  };
  const validations = formData.validationRules;
  validations.forEach((validation) => {
    const result = validation(formData);
    if (!result.ok) {
      report = result;
    }

    return result;
  });

  return report;
};

const validationResult = (ok, message) => {
  const response = {
    ok
  };

  if (message) {
    response.errorMessage = message;
  }

  return response;
};

// Generic input validator
// Returns a list of result object {ok, errorMessage}
export function inputValidation(formData) {
  return Object.values(formData).map((value) => {
    const data = value;
    const result = validate(data);
    data.valid = result.ok;
    data.errorMessage = result.errorMessage;
    return result.ok;
  });
}

/**
 * Given the list of required fields, check 'data' object to ensure it contains all fields
 * @param requiredFields
 * @param data
 * @param errorMessage
 * @returns {{ok, message}}
 */
export function isRequired(data) {
  return validationResult(!!data.value, `${data.label} is a required field`);
}

export function validateDate(data) {
  const { value } = data;
  const minDate = new Date(1900, 1, 1);
  const maxDate = new Date(2100, 1, 1);
  const valid = value > minDate && value < maxDate;
  return validationResult(valid, "Please input a valid date");
}

// Validates the value does not exist in any parameters of an object set
export function valueDoesNotExistinObjectSet(value, set) {
  const result = !set.find((object) => {
    return Object.values(object).includes(value);
  });
  return validationResult(result, `${value} already exists`);
}
