import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Style from "./RankingControlTable.module.css";
import ListTable from "../ListTable/ListTable";
import { ReactComponent as ArrowUpIcon } from "../../assets/icons/arrow-up.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/arrow-down.svg";
import CollapsibleSectionList from "../collapsible-section-list";
import CheckboxLabel from "../checkbox-label";

export const RankingControlTable = ({ value, onChange, optionSections }) => {
  const checkboxControlMap = useMemo(() => {
    return value.reduce((accumulator, eachRank) => {
      return { ...accumulator, [eachRank.id]: true };
    }, {});
  }, [value]);

  const renderListTableHeader = (eachHeader, index) => {
    return (
      <div
        key={`${eachHeader} ${index}`}
        className={Style.list_column}
      >
        <p className={Style.list_header_text}>{eachHeader}</p>
      </div>
    );
  };

  const renderListTableItem = ({ label }, index) => {
    return (
      <div className={`${Style.list_row_container} ${Style.row_separator}`}>
        <div className={Style.list_column}>
          <p className={Style.list_text_bold}>{index + 1}</p>
        </div>
        <div className={Style.list_column}>
          <p className={Style.list_text_semibold}>{label}</p>
        </div>
        <div className={Style.list_column}>
          <div
            className={`${Style.direction_button} ${!index ? Style.direction_button_disabled : ""}`}
            onClick={() => {
              if (index) {
                const newValue = [...value];
                const element = newValue.splice(index, 1)[0];
                newValue.splice(index - 1, 0, element);
                onChange(newValue);
              }
            }}
          >
            <ArrowUpIcon />
          </div>
          <div
            className={`${Style.direction_button} ${index >= value.length - 1 ? Style.direction_button_disabled : ""}`}
            onClick={() => {
              if (index < value.length - 1) {
                const newValue = [...value];
                const element = newValue.splice(index, 1)[0];
                newValue.splice(index + 1, 0, element);
                onChange(newValue);
              }
            }}
          >
            <ArrowDownIcon />
          </div>
        </div>
        <div
          className={`${Style.list_column} ${Style.remove_button}`}
          onClick={() => {
            const newValue = [...value];
            newValue.splice(index, 1);
            onChange(newValue);
          }}
        >
          <p>Remove</p>
        </div>
      </div>
    );
  };

  return (
    <div className={Style.container}>
      {optionSections?.length && (
        <div className={Style.left_container}>
          <p className={Style.header}>Data Points</p>
          <div className={Style.table_container}>
            <CollapsibleSectionList
              sectionContainerClassName={Style.section_list}
              sections={optionSections}
              renderItem={(eachItem) => {
                return (
                  <div className={Style.checkbox_container}>
                    <CheckboxLabel
                      label={eachItem.label}
                      isChecked={checkboxControlMap[eachItem.id]}
                      onClick={() => {
                        const newValue = [...value];
                        if (checkboxControlMap[eachItem.id]) {
                          onChange(
                            newValue.filter((eachValue) => {
                              return eachValue.id !== eachItem.id;
                            })
                          );
                        } else {
                          onChange([...newValue, eachItem]);
                        }
                      }}
                    />
                  </div>
                );
              }}
            />
          </div>
        </div>
      )}
      <div className={Style.right_container}>
        <p className={Style.header}>Priority</p>
        <div className={Style.table_container}>
          <ListTable
            header={["Sort Preference", "Data Point", "", ""]}
            headerContainerClassName={Style.list_row_container}
            renderHeader={renderListTableHeader}
            data={value}
            renderItem={renderListTableItem}
          />
        </div>
      </div>
    </div>
  );
};

RankingControlTable.defaultProps = {
  value: [],
  onChange: () => {},
  optionSections: []
};

RankingControlTable.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element])
    })
  ),
  onChange: PropTypes.func,
  optionSections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired }))
    })
  )
};
