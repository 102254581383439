import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { CheckCircleOutline, ErrorOutline, HourglassEmptyTwoTone } from "@material-ui/icons";
import Style from "./StatusPopupComponent.module.css";
import { STATUS_OPTIONS } from "./data/constants";
import useOnClickOutside from "../../hooks/use-on-click-outside";

const getIcon = (status) => {
  switch (status) {
    case STATUS_OPTIONS.ERROR:
      return <ErrorOutline />;
    case STATUS_OPTIONS.LOADING:
      return <HourglassEmptyTwoTone />;
    case STATUS_OPTIONS.SUCCESS:
    default:
      return <CheckCircleOutline />;
  }
};

export const StatusPopupComponent = ({
  showPopup,
  message,
  status,
  isCenterPopup,
  durationMs,
  onPopupClose,
  closeOnClickOutside
}) => {
  const timeoutIdRef = useRef(null);
  const popupRef = useRef(null);

  useOnClickOutside(popupRef, () => {
    if (closeOnClickOutside) {
      onPopupClose();
    }
  });

  useEffect(() => {
    if (showPopup && durationMs) {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      timeoutIdRef.current = setTimeout(() => {
        onPopupClose();
      }, durationMs);
    }

    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }
    };
  }, [showPopup]);

  if (!showPopup) {
    return null;
  }

  return (
    <div
      ref={popupRef}
      className={isCenterPopup ? Style.centered_popup_container : ""}
    >
      <div className={`${Style.status_modal} ${status === STATUS_OPTIONS.ERROR ? `${Style.error}` : ""}`}>
        {getIcon(status)}
        <p className={Style.message_text}>{message}</p>
      </div>
    </div>
  );
};

StatusPopupComponent.defaultProps = {
  showPopup: false,
  status: STATUS_OPTIONS.SUCCESS,
  isCenterPopup: false,
  durationMs: 0,
  closeOnClickOutside: false,
  onPopupClose: () => {}
};

StatusPopupComponent.propTypes = {
  showPopup: PropTypes.bool,
  message: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.values(STATUS_OPTIONS)),
  isCenterPopup: PropTypes.bool,
  durationMs: PropTypes.number,
  closeOnClickOutside: PropTypes.bool,
  onPopupClose: PropTypes.func
};
