import React from "react";
import PropTypes from "prop-types";

import GeneralizedModal from "../../../../../../components/generalized-modal/GeneralizedModal";
import Style from "./RemoveConsumptionModal.module.css";

const MESSAGE_TEXT =
  "You are about to delete the selected SKU(s). The deleted items will be moved to standby. Note, you cannot undo this action";

export const RemoveConsumptionModal = ({ show, onCancel, onDelete }) => {
  const deleteConsumptionModalButtons = () => {
    return [
      {
        id: "cancel-button",
        title: "Cancel",
        onClick: onCancel,
        className: "cancel-button"
      },
      {
        id: "submit-button",
        title: "Delete",
        onClick: onDelete,
        className: "confirm-delete-button"
      }
    ];
  };

  return (
    <GeneralizedModal
      title="Delete Consumption"
      modalBodyComponent={<div>{MESSAGE_TEXT}</div>}
      modalFooterButtons={deleteConsumptionModalButtons()}
      showModal={show}
      classNameOverrides={{
        content: Style.modal_content,
        header: Style.modal_header,
        body: Style.modal_body
      }}
    />
  );
};

RemoveConsumptionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};
