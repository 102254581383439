import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useStore } from "react-hookstore";
import { userProfileStore } from "../../../../state-managements/stores/user-profile-store";

const AssetTrackPageDataSourceContext = React.createContext();

export const useAssetTrackPageDataSourceContext = () => {
  return useContext(AssetTrackPageDataSourceContext);
};

export const AssetTrackPageDataSourceContextProvider = ({ children }) => {
  const [locationTreeMap, setLocationTreeMap] = useState({});
  const [assetMap, setAssetMap] = useState({});
  const [locationsToAssetsMap, setLocationsToAssetMap] = useState({});
  const [locationToMetricFieldsMap, setLocationToMetricFieldsMap] = useState({});
  const [locationToTypeMetricsMap, setLocationToTypeMetricsMap] = useState({});
  const [flatCategoryTrees, setFlatCategoryTrees] = useState(undefined);
  const [userProfile] = useStore(userProfileStore);

  return (
    <AssetTrackPageDataSourceContext.Provider
      value={{
        locationTreeMap,
        setLocationTreeMap,
        assetMap,
        setAssetMap,
        locationsToAssetsMap,
        setLocationsToAssetMap,
        locationToMetricFieldsMap,
        setLocationToMetricFieldsMap,
        locationToTypeMetricsMap,
        setLocationToTypeMetricsMap,
        flatCategoryTrees,
        setFlatCategoryTrees,
        isAdmin: userProfile.isSuperAdmin
      }}
    >
      {children}
    </AssetTrackPageDataSourceContext.Provider>
  );
};

AssetTrackPageDataSourceContextProvider.defaultProps = {
  children: null
};

AssetTrackPageDataSourceContextProvider.propTypes = {
  children: PropTypes.element
};
