import React from "react";
import "./SummaryTileList.css";
import SummaryTile from "../SummaryTile/SummaryTile";

export default class SummaryTileList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      summaryTileMap: {
        New: true,
        Backorder: true,
        Arriving: true,
        Arrived: true
      },
      totalShow: true
    };
  }

  componentDidMount() {
    if (this.props.defaultTab) {
      const summaryTileMap = {
        New: false,
        Ordered: false,
        Backorder: false,
        Arriving: false,
        Arrived: false
      };
      const totalShow = false;
      summaryTileMap[this.props.defaultTab] = true;
      this.props.filterFunction([this.props.defaultTab]);
      this.setState({
        summaryTileMap,
        totalShow
      });
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.tileData !== newProps.tileData) {
      if (this.props.defaultTab !== newProps.defaultTab || this.props.selectedVendor !== newProps.selectedVendor) {
        const summaryTileMap = {
          New: true,
          Backorder: true,
          Arriving: true,
          Arrived: true
        };
        let totalShow = true;
        if (newProps.defaultTab) {
          Object.keys(summaryTileMap).forEach((eachKey) => {
            summaryTileMap[eachKey] = false;
          });
          summaryTileMap[newProps.defaultTab] = true;
          totalShow = false;
          this.props.filterFunction([newProps.defaultTab]);
        }
        this.setState({
          summaryTileMap,
          totalShow
        });
      } else {
        const filterList = [];
        Object.keys(this.state.summaryTileMap).forEach((eachKey) => {
          if (this.state.summaryTileMap[eachKey]) {
            filterList.push(eachKey);
          }
        });
        this.props.filterFunction(filterList);
      }
    }
  }

  handleToggleSelectAll = () => {
    const newMap = this.state.summaryTileMap;
    Object.keys(newMap).forEach((eachKey) => {
      newMap[eachKey] = !this.state.totalShow;
    });
    const filterList = Object.keys(this.state.summaryTileMap);
    this.props.filterFunction(!this.state.totalShow ? filterList : []);
    this.setState({
      totalShow: !this.state.totalShow,
      summaryTileMap: newMap
    });
  };

  handleToggle = (value) => {
    const newMap = this.state.summaryTileMap;
    if (this.state.totalShow) {
      Object.keys(newMap).forEach((eachKey) => {
        newMap[eachKey] = false;
      });
    }
    newMap[value] = !newMap[value];

    let totalShow = true;
    Object.keys(newMap).forEach((eachKey) => {
      if (!newMap[eachKey]) {
        totalShow = false;
      }
    });

    let filterList = [];
    if (totalShow) {
      filterList = Object.keys(this.state.summaryTileMap);
    } else {
      Object.keys(this.state.summaryTileMap).forEach((eachKey) => {
        if (this.state.summaryTileMap[eachKey]) {
          filterList.push(eachKey);
        }
      });
    }
    this.props.filterFunction(filterList);
    this.setState({ totalShow, summaryTileMap: newMap });
  };

  calculateTotal = () => {
    let totalCount = 0;
    this.props.tileData.forEach((each) => {
      totalCount += each.totalOrders;
    });
    return { title: "Total Requests", totalOrders: totalCount, status: "Total" };
  };

  render() {
    return (
      <div className="tile-list">
        <SummaryTile
          data={this.calculateTotal()}
          filterEnabled={this.state.totalShow}
          callbackFn={this.handleToggleSelectAll}
        />
        {this.props.tileData.map((each) => {
          return (
            <SummaryTile
              key={each.title}
              data={each}
              filterEnabled={this.state.summaryTileMap[each.status]}
              callbackFn={this.handleToggle}
            />
          );
        })}
      </div>
    );
  }
}
