import React, { useMemo } from "react";
import PropTypes from "prop-types";
import PaginatedList from "../../../../../../components/PaginatedList/PaginatedList";
import { ApprovalReportChildTable } from "../approval-report-child-table/ApprovalReportChildTable";
import { ApprovalReportRow } from "../approval-report-row/ApprovalReportRow";
import useSortableHeader from "../../../../../../hooks/use-sortable-header";
import { useConfigurationProvider } from "../../../../../../services/soft-cache-service";
import getAliasedHeaders from "../../util/get-aliased-headers";
import Style from "./ApprovalReportTable.module.css";

export const ApprovalReportTable = ({
  rows,
  selectable,
  onSelectClick,
  isItemSelectableFunc,
  onExpandStateChange,
  childSelectable,
  onChildSelectClick,
  actionBar,
  columnsToExclude
}) => {
  const { getSortedData, getSortableHeader, order, orderBy } = useSortableHeader();
  const reportsConfig = useConfigurationProvider().config?.features?.reports;

  const sortedData = useMemo(() => {
    return getSortedData(rows);
  }, [rows, order, orderBy]);

  const headers = useMemo(() => {
    return getAliasedHeaders(
      [
        { id: "identifier", configId: "itemTypeIdentifier", label: "SKU" },
        { id: "lotNumber", label: "Lot Number" },
        { id: "consumed", label: "Consumed Quantity" },
        { id: "approved", label: "Approved Quantity" }
      ],
      reportsConfig,
      columnsToExclude
    );
  }, [reportsConfig, columnsToExclude]);

  return (
    <PaginatedList
      header={headers}
      data={sortedData}
      headerContainerClassName={Style.table_header}
      selectable={selectable}
      isItemSelectableFunc={isItemSelectableFunc}
      onSelectClick={onSelectClick}
      onExpandStateChange={onExpandStateChange}
      actionBar={actionBar}
      renderEmptyList={() => {
        return (
          <div className={`${Style.flex_row} ${Style.empty_list_container}`}>
            <p className={Style.empty_list_text}>No Rows Available</p>
          </div>
        );
      }}
      renderHeader={(eachHeader) => {
        return getSortableHeader(eachHeader, { headerContainerClass: Style.header_container });
      }}
      renderItem={(eachItem) => {
        return (
          <ApprovalReportRow
            headers={headers}
            row={eachItem}
          />
        );
      }}
      renderItemChild={(parentRow) => {
        const childRows = parentRow.items;
        return (
          <ApprovalReportChildTable
            rows={childRows}
            onSelectClick={onChildSelectClick}
            selectable={childSelectable}
            isItemSelectableFunc={isItemSelectableFunc}
          />
        );
      }}
    />
  );
};

ApprovalReportTable.defaultProps = {
  selectable: true,
  isItemSelectableFunc: null,
  onSelectClick: null,
  onExpandStateChange: null,
  onChildSelectClick: null,
  childSelectable: true,
  actionBar: null,
  columnsToExclude: null
};

ApprovalReportTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      identifier: PropTypes.string,
      lotNumber: PropTypes.string,
      consumed: PropTypes.number,
      approved: PropTypes.number
    })
  ).isRequired,
  selectable: PropTypes.bool,
  isItemSelectableFunc: PropTypes.func,
  onSelectClick: PropTypes.func,
  onExpandStateChange: PropTypes.func,
  childSelectable: PropTypes.bool,
  onChildSelectClick: PropTypes.func,
  actionBar: PropTypes.node,
  columnsToExclude: PropTypes.arrayOf(PropTypes.string)
};
