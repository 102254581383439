import React, { useEffect, useRef, useState, useMemo } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import OnboardItemModal from "../../components/onboard-item-modal";
import { useXemelgoClient } from "../../services/xemelgo-service";
import usePrintService from "../../hooks/use-print-service";
import TabBar from "../../components/tab-bar";
import { PRINT_TIMES, PRINT_TYPES, SOLUTIONS, TAB_OPTIONS } from "../../data/constants";
import { ReactComponent as AssetIcon } from "../../assets/icons/asset.svg";
import StatusPopupComponent from "../../components/status-popup-component";
import useGeneratePrintTagCommands from "../../hooks/use-generate-print-tag-payload";
import {
  AddAssetFeatureConfigContextProvider,
  useAddAssetFeatureConfigContext
} from "./contexts/add-asset-feature-config-context";
import {
  AddAssetFeatureStateContextProvider,
  useAddAssetFeatureStateContext
} from "./contexts/add-asset-feature-state-context";
import AssetBulkCreateTabFeature from "./features/asset-bulk-create-tab-feature";
import useAssetFormDataParser from "./hooks/use-asset-form-parser";
import AssetSingleCreateTab from "./features/asset-single-create-tab";
import Style from "./AddAssetFeatureV2.module.css";
import useMixpanelContext from "../../context/mixpanel-context";
import { ASSET_ONBOARDING_V2, ASSET_ONBOARDING_V2_STEPS } from "../../constants/mixpanel-constant/assetOnboardingV2";
import useOnboardingForm from "../../hooks/use-onboarding-form";
import PrinterSelectionComponent from "../../components/printer-selection-component";
import { Checkbox } from "../../components/checkbox-component/Checkbox";
import { STATUS_OPTIONS } from "../../components/status-popup-component/data/constants";

const CREATE_BATCH_SIZE = 50;
const POPUP_DURATION_MS = 7000;
const FEATURE_ID = "Asset";

const AddAssetFeature = ({ onClose }) => {
  const [assetClient] = useState(useXemelgoClient().getAssetClient());
  const {
    formFields,
    isPrintEnabled,
    printType,
    zplConfig,
    isBulkCreateEnabled,
    isPrintLaterEnabled,
    bartenderConfig,
    showSaveEntriesCheckbox
  } = useAddAssetFeatureConfigContext();

  const {
    formDataMap,
    setFormDataMap,
    isSubmitDisabled,
    setIsSubmitDisabled,
    submitStatus,
    setSubmitStatus,
    isSaveLastSubmittedEntriesChecked,
    setIsSaveLastSubmittedEntriesChecked
  } = useAddAssetFeatureStateContext();

  const { generatePayload } = useAssetFormDataParser();
  const { generateMultiplePrintCommands } = useGeneratePrintTagCommands(SOLUTIONS.ASSET, printType);
  const { validateFormDataForSubmit } = useOnboardingForm();
  const { endpoint: bartenderUrl } = bartenderConfig;

  const printService = usePrintService(
    isPrintEnabled,
    printType,
    bartenderUrl ? bartenderConfig : zplConfig.customTemplate,
    FEATURE_ID
  );

  const [currentTab, setCurrentTab] = useState(TAB_OPTIONS.SINGLE);
  const [statusMessage, setStatusMessage] = useState("");
  const [popupExpired, setPopupExpired] = useState(false);
  const popupTimeoutRef = useRef(null);

  const { print, isPrintReady, selectedTemplateConfig, printTime } = printService;

  const { sendMixPanelEvent } = useMixpanelContext();

  const tabList = useMemo(() => {
    const newTabList = [
      {
        id: TAB_OPTIONS.SINGLE,
        label: "Create an Asset"
      }
    ];

    if (isBulkCreateEnabled) {
      newTabList.push({
        id: TAB_OPTIONS.BULK,
        label: "Bulk Create"
      });
    }

    return newTabList;
  }, [isBulkCreateEnabled]);

  const showSaveLastEntriesByCurrentTab = useMemo(() => {
    return showSaveEntriesCheckbox && currentTab === TAB_OPTIONS.SINGLE;
  }, [showSaveEntriesCheckbox, currentTab]);

  const printTags = async (payloads, formData) => {
    // get item type numbers and query for additional item type attributes
    const itemTypesInfoMap = formData.reduce((newItemTypeInfoMap, eachForm) => {
      const { item_number: itemNumber } = eachForm;
      const { value } = itemNumber;
      newItemTypeInfoMap[value] = { ...itemNumber };
      return newItemTypeInfoMap;
    }, {});

    const newPayloads = [];
    payloads.forEach((payload) => {
      const { item_number: itemNumber, description, print_quantity: printQuantity = 1 } = payload;
      const newPayload = {
        ...payload,
        ...itemTypesInfoMap[itemNumber],
        item_description: description,
        total_count: printQuantity
      };

      const printPayloads = Array(Number(printQuantity))
        .fill(newPayload)
        .map((printPayload, i) => {
          return {
            ...printPayload,
            count: i + 1
          };
        });

      newPayloads.push(...printPayloads);
    });

    const printConfig = printType === PRINT_TYPES.ZPL ? zplConfig : bartenderConfig;
    const printPayloads = await generateMultiplePrintCommands(newPayloads, {
      ...printConfig,
      ...selectedTemplateConfig
    });

    for (const printPayload of printPayloads) {
      await print(printPayload);
    }
  };

  const onToggleCheckbox = () => {
    setIsSaveLastSubmittedEntriesChecked(!isSaveLastSubmittedEntriesChecked);
  };

  const onSubmit = async () => {
    setIsSubmitDisabled(true);
    setSubmitStatus(STATUS_OPTIONS.LOADING);
    setStatusMessage("The assets are being added...");
    setPopupExpired(false);

    const promises = [];

    if (popupTimeoutRef.current) {
      clearTimeout(popupTimeoutRef.current);
    }

    try {
      // ------------------ CREATE ------------------ //
      const formData = Object.values(formDataMap).map((eachForm) => {
        return eachForm.data;
      });

      const payloadsByLocations = await generatePayload(formData, formFields);
      const printPayloads = [].concat(
        ...payloadsByLocations.map((item) => {
          return item.payloads;
        })
      );

      payloadsByLocations.forEach((payloadsByLocation) => {
        const { payloads, locationId } = payloadsByLocation;
        const createAssetPayloads = payloads.map((payload) => {
          const { print_quantity: quantity, ...rest } = payload;
          return rest;
        });
        while (createAssetPayloads.length) {
          const currentSlice = createAssetPayloads.splice(0, CREATE_BATCH_SIZE);
          promises.push(assetClient.createAssetSet(currentSlice, locationId));
        }
      });

      await Promise.all(promises);
      // ------------------ PRINT ------------------ //
      if (isPrintEnabled && printTime === PRINT_TIMES.now.id) {
        setStatusMessage("The assets have been added and submitted for printing...");
        await printTags(printPayloads, formData);
        setStatusMessage("The assets have been added and submitted for printing.");
      } else {
        setStatusMessage("The assets have been added successfully.");
      }
    } catch (err) {
      let errorMessage;
      if (err.response && typeof err.response.data === "string" && err.response.data.includes("[BadRequest] vid")) {
        errorMessage = "Failed to create asset. RFID tag number already exists.";
      } else if (err?.message?.includes("Duplicated identifiers")) {
        errorMessage = `Failed to create asset. ${
          _.capitalize(formFields?.item_identifier?.label) || "Identifier"
        } already exists.`;
      } else {
        errorMessage = "Failed to create asset. Please contact Xemelgo Support for assistance";
      }
      setStatusMessage(errorMessage);

      setSubmitStatus(STATUS_OPTIONS.ERROR);
      sendMixPanelEvent(
        ASSET_ONBOARDING_V2,
        currentTab === TAB_OPTIONS.BULK
          ? ASSET_ONBOARDING_V2_STEPS.BULK_CREATE_FAILED
          : ASSET_ONBOARDING_V2_STEPS.ITEM_ONBOARD_FAILED,
        {
          error_message: typeof err === "string" ? err : err.message
        }
      );

      popupTimeoutRef.current = setTimeout(() => {
        setPopupExpired(true);
      }, POPUP_DURATION_MS);
      return;
    }

    sendMixPanelEvent(
      ASSET_ONBOARDING_V2,
      currentTab === TAB_OPTIONS.BULK
        ? ASSET_ONBOARDING_V2_STEPS.BULK_CREATE_SUCCESS
        : ASSET_ONBOARDING_V2_STEPS.ITEM_ONBOARD_SUCCESS,
      {
        item_onboarded_count: Object.values(formDataMap).length
      }
    );
    popupTimeoutRef.current = setTimeout(() => {
      setPopupExpired(true);
    }, POPUP_DURATION_MS);
    setSubmitStatus(STATUS_OPTIONS.SUCCESS);
  };

  useEffect(() => {
    sendMixPanelEvent(
      ASSET_ONBOARDING_V2,
      currentTab === TAB_OPTIONS.BULK ? ASSET_ONBOARDING_V2_STEPS.BULK_CREATE_ENTRY : ASSET_ONBOARDING_V2_STEPS.ENTRY
    );
  }, [currentTab]);

  useEffect(() => {
    const formData = Object.values(formDataMap).map((eachForm) => {
      return eachForm.data;
    });

    const isFormReady = validateFormDataForSubmit(formData, formFields);

    setIsSubmitDisabled((isPrintEnabled && !isPrintReady) || !isFormReady);
  }, [formDataMap, currentTab, formFields, isPrintReady]);

  useEffect(() => {
    return () => {
      if (popupTimeoutRef.current) {
        clearTimeout(popupTimeoutRef.current);
      }
    };
  }, []);

  return (
    <OnboardItemModal
      title="Add Asset"
      titleIconComponent={
        <div className={Style.title_icon}>
          <AssetIcon
            width={25}
            height={25}
          />
        </div>
      }
      footerLeftComponent={
        showSaveLastEntriesByCurrentTab && (
          <div
            className={Style.save_checkbox_container}
            onClick={onToggleCheckbox}
          >
            <Checkbox isChecked={isSaveLastSubmittedEntriesChecked} />
            <p className={Style.save_label}>Remember asset information</p>
          </div>
        )
      }
      onSubmit={onSubmit}
      onClose={onClose}
      onToggleCheckbox={onToggleCheckbox}
      isPrintEnabled={isPrintEnabled && printTime === PRINT_TIMES.now.id}
      submitDisabled={isSubmitDisabled}
      submitLoading={submitStatus === STATUS_OPTIONS.LOADING}
      modalContainerClassName={Style.modal_container}
      popupComponent={
        <StatusPopupComponent
          showPopup={!popupExpired && submitStatus !== STATUS_OPTIONS.NONE && !!statusMessage}
          message={statusMessage}
          status={submitStatus}
        />
      }
    >
      <div className={Style.modal_body_container}>
        <div className={Style.main_container}>
          {tabList.length > 1 && (
            <TabBar
              containerClassName={Style.tab_bar}
              tabList={tabList}
              currentTabId={currentTab}
              onTabClick={(newTab) => {
                setCurrentTab(newTab);
                setFormDataMap({});
              }}
            />
          )}
          <div className={Style.tab_container}>
            {currentTab === TAB_OPTIONS.SINGLE ? <AssetSingleCreateTab /> : <AssetBulkCreateTabFeature />}
            {isPrintEnabled && printType !== PRINT_TYPES.BARTENDER_UPLOAD && (
              <div className={Style.printer_component}>
                <PrinterSelectionComponent
                  printService={printService}
                  hideLabelSelection={!!bartenderUrl || !!zplConfig.customTemplate}
                  solutionType={FEATURE_ID}
                  isPrintLaterEnabled={isPrintLaterEnabled}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </OnboardItemModal>
  );
};

export default (props) => {
  return (
    <AddAssetFeatureConfigContextProvider>
      <AddAssetFeatureStateContextProvider>
        <AddAssetFeature {...props} />
      </AddAssetFeatureStateContextProvider>
    </AddAssetFeatureConfigContextProvider>
  );
};

AddAssetFeature.propTypes = {
  onClose: PropTypes.func.isRequired
};
