import React from "react";

import { Checkbox, FormGroup, FormControlLabel, FormLabel, FormControl } from "@material-ui/core";
import PropTypes from "prop-types";
import { useFormControlStyle, useFormGroupStyle, useFormLabelStyle } from "./AddPageInputsStyle";

const CheckBoxGroup = ({ id, options = [], onChange, label, minimumChecked, error, onBlur, value }) => {
  const formControlStyle = useFormControlStyle();
  const formGroupStyle = useFormGroupStyle();
  const formLabelStyle = useFormLabelStyle();
  return (
    <FormControl classes={formControlStyle}>
      <FormLabel
        classes={formLabelStyle}
        required={!!minimumChecked}
        error={error}
      >
        {label}
      </FormLabel>
      <FormGroup
        classes={formGroupStyle}
        onBlur={onBlur}
      >
        {options.reduce((accum, each) => {
          const { id: checkBoxId, label: checkBoxLabel } = each;
          const checkBoxValue = value[checkBoxId];
          const { disabled } = value;
          if (checkBoxValue !== undefined) {
            accum.push(
              <FormControlLabel
                key={checkBoxId}
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Checkbox
                    id={id}
                    color="primary"
                    checked={checkBoxValue}
                    disabled={disabled}
                    onChange={(event) => {
                      const {
                        target: { checked: newValue }
                      } = event;
                      onChange(id, { ...value, [checkBoxId]: newValue });
                    }}
                  />
                }
                label={checkBoxLabel}
              />
            );
          }
          return accum;
        }, [])}
      </FormGroup>
    </FormControl>
  );
};

export default CheckBoxGroup;

CheckBoxGroup.defaultProps = {
  id: "",
  options: [],
  label: "",
  value: null,
  onChange: () => {},
  minimumChecked: 0,
  error: false,
  onBlur: () => {}
};

CheckBoxGroup.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  onBlur: PropTypes.func,
  options: PropTypes.array,
  minimumChecked: PropTypes.number
};
