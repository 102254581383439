export const SORT_DIRECTION = {
  ascending: "asc",
  descending: "desc"
};

export const SOLUTIONS = {
  WORK_ORDER: "workOrder",
  INVENTORY: "inventory",
  ASSET: "asset"
};

export const FEATURES = {
  DETAILS_PAGE: "detailsPage"
};

export const SOLUTION_UNITS = {
  workOrder: "work orders",
  inventory: "items",
  asset: "assets"
};

export const PRINT_TYPES = {
  ZPL: "zpl",
  BARTENDER: "bartender",
  BARTENDER_UPLOAD: "bartenderUpload"
};

export const PRINT_TIMES = {
  now: { id: "now", label: "Print Now" },
  later: { id: "later", label: "Print Later" }
};

export const TAB_OPTIONS = {
  SINGLE: "single",
  BULK: "bulk"
};

export const AddPageInputTypeMap = {
  COMMENT: "comment",
  DATE_PICKER: "datepicker",
  DATE_TIME_PICKER: "datetimepicker",
  INPUT: "input",
  SEARCH_DROP_DOWN: "dropdown",
  CHECK_BOX_GROUP: "checkboxGroup",
  SEARCH_DROP_DOWN_FROM_API: "dropdownWithOptionsFromAPI",
  MULTI_SELECT_DROP_DOWN: "multiSelectDropdown",
  NUMBER: "number",
  SEARCH: "search"
};

export const METADATAOF = {
  item: "item",
  item_number: "item_number"
};
